import { noop } from '@ours/utils';

import { stopTracks } from './stopTracks';

export interface AudioDevice {
  label: string;
  value: string;
}
export const filterDefaultAudioDevice = ({ label }: MediaDeviceInfo) =>
  !label.includes('Default - ');
export const enumerateFormattedDevices = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();

  return devices
    .filter(({ deviceId, kind }) => kind === 'audioinput' && deviceId)
    .filter(filterDefaultAudioDevice)
    .map(({ deviceId, label }) => ({ label, value: deviceId }));
};

export const enumerateDevicesAfterStartingStream = async (args: {
  audio: boolean;
  video?: boolean;
}) => {
  const stream = await window.navigator.mediaDevices
    .getUserMedia(args)
    .then((o) => o)
    .catch(noop);
  const d = await enumerateFormattedDevices();

  stopTracks(stream);
  return d;
};

export const hasDevicePermission = async () => {
  return (await enumerateFormattedDevices()).filter((d) => d.label).length > 0;
};

/**
 * Determines the permissions state of the device request
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Permissions/query
 * Safari @see https://github.com/WebKit/WebKit/blob/main/Source/WebCore/Modules/permissions/PermissionName.idl
 * FireFox @see https://searchfox.org/mozilla-central/source/dom/webidl/Permissions.webidl#10
 * Chrome @see https://chromium.googlesource.com/chromium/src/+/refs/heads/main/third_party/blink/renderer/modules/permissions/permission_descriptor.idl
 */
export const determinePermissionStatus = async () => {
  try {
    // @ts-expect-error camera permissions
    const res = await navigator.permissions.query({ name: 'camera' });
    return res.state;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    return 'unknown';
  }
};

const ensureFirefoxHasPermissions = (devices: AudioDevice[]) => {
  return devices.every((d) => d.label === '');
};
const isValidId = (devices: AudioDevice[], id: string | undefined) =>
  id && devices.find((d) => d.value === id);

export const listAudioOptions = async (
  selectedDeviceId: string | undefined,
  isMuted: boolean
): Promise<{
  devices: AudioDevice[];
  mediaStream: MediaStream | undefined;
  selectedDeviceId: string | undefined;
}> => {
  try {
    let formattedDevices = await enumerateFormattedDevices();

    if (formattedDevices.length === 0 || ensureFirefoxHasPermissions(formattedDevices)) {
      await window.navigator.mediaDevices.getUserMedia({ audio: true });
      formattedDevices = await enumerateFormattedDevices();
    }

    const _selectedDeviceId = isValidId(formattedDevices, selectedDeviceId)
      ? selectedDeviceId
      : formattedDevices[0]?.value;

    const stream =
      isMuted || !_selectedDeviceId
        ? undefined
        : await window.navigator.mediaDevices.getUserMedia({
            audio: { deviceId: _selectedDeviceId },
          });

    return {
      devices: formattedDevices,
      mediaStream: stream,
      selectedDeviceId: _selectedDeviceId,
    };
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error.name);
    // eslint-disable-next-line no-console
    console.log(error.message);

    const content = error.name === 'NotAllowedError' ? 'browserBlocked' : 'unknown';
    throw new Error(content);
  }
};
