import { assertType } from '@ours/utils';
import { assign } from 'xstate';

import { updateSessionPlaybackByNodeId } from '../helpers/updateSessionPlaybackByNodeId';
import type { CSDActions, CSDContext } from '../types';

export const onInitPreviewForSession = assign<CSDContext, CSDActions>((ctx, ev) => {
  assertType(ev, 'ON_INIT_PREVIEW_FOR_SESSION');
  const { macroModuleId, moduleId, nodeId } = ev;

  return updateSessionPlaybackByNodeId(ctx, {
    autoPlay: true,
    dir: 'next',
    moduleIdToAppend: moduleId || undefined,
    nextMacroModuleId: macroModuleId || undefined,
    nextModuleId: moduleId || undefined,
    nextNodeId: nodeId,
  });
});
