/* eslint-disable max-lines-per-function */
import { omit, uuid } from '@ours/utils';
import type { IXStateState } from '@ours/utils';
import { produce } from 'immer';
import type { StoreApi } from 'zustand';

import type { StoreState } from '../store/types';

export interface IDraftItem {
  entityId: string;
  entityKind: 'membership';
  id: string;
  isOpen: boolean;
  kind: 'note';
  value: string;
}
export interface IProgress {
  friendlyName: string;
  percentage: number;
  state: 'empty' | 'error' | 'inprogress' | 'complete';
  type: 'upload' | 'download' | 'ui';
}

export interface IModalBaseProperties {
  data?: Record<string, unknown>;
  isOpen: boolean;
}

export interface IModalOpenPayload {
  data?: Record<string, any>;
  isOpen: boolean;
  name: keyof IModalState;
}

export interface IModalState {
  invitePartnerModal: IModalBaseProperties;
  removeModuleModal: IModalBaseProperties;
  selectModuleModal: IModalBaseProperties;
}

export type AdminSlice = {
  drafts: {
    addNewDraft: (args: {
      entityId: string;
      entityKind: IDraftItem['entityKind'];
      isOpen: boolean;
      kind: IDraftItem['kind'];
    }) => void;
    draftsById: Record<string, IDraftItem>;
    removeDraft: (id: string) => void;
    setDraftItemValue: (args: { id: string; value: string }) => void;
    toggleDraftOpen: (id: string) => void;
  };
  /** @deprecated - these should be removed. Just manage your modal state locally w useBoolean() */
  modals: {
    items: IModalState;
    onClose: (name: keyof IModalState) => void;
    onOpen: (p: IModalOpenPayload) => void;
  };
  progress: {
    completeProgress: (args: { id: string; percentage: number; state: IProgress['state'] }) => void;
    items: { [key: string]: IProgress };
    removeProgress: (id: string) => void;
    setProgress: (id: string, perc: number) => void;
    startProgress: (args: { id: string; name: string; type: IProgress['type'] }) => void;
  };
  sessionEditor: {
    selectedSessionNodeIdAtom: string | null;
    setSelectedNodeId: (id: string | null) => void;
    setXstate: (x: IXStateState | null) => void;
    xStateState: IXStateState | null;
  };
};

export const adminSlice = (set: StoreApi<StoreState>['setState']): AdminSlice => ({
  drafts: {
    addNewDraft: (args) => {
      return set((s) => {
        return produce(s, (d) => {
          const id = uuid();
          d.admin.drafts.draftsById[id] = { ...args, id, isOpen: true, value: '' };
        });
      });
    },
    draftsById: {},
    removeDraft: (id) => {
      return set((s) => {
        return produce(s, (d) => {
          d.admin.drafts.draftsById = omit(d.admin.drafts.draftsById, [id]);
        });
      });
    },
    setDraftItemValue(args) {
      return set((s) => {
        return produce(s, (d) => {
          const item = d.admin.drafts.draftsById[args.id];
          if (item) {
            item.value = args.value;
          }
        });
      });
    },
    toggleDraftOpen: (id) => {
      return set((s) => {
        return produce(s, (d) => {
          const item = d.admin.drafts.draftsById[id];
          if (item) {
            item.isOpen = !item.isOpen;
          }
        });
      });
    },
  },
  modals: {
    items: {
      invitePartnerModal: { isOpen: false },
      removeModuleModal: { isOpen: false },
      selectModuleModal: { isOpen: false },
    },
    onClose(name) {
      return set((s) => {
        return produce(s, (d) => {
          d.admin.modals.items[name] = { data: undefined, isOpen: false };
        });
      });
    },
    onOpen(args) {
      return set((s) => {
        return produce(s, (d) => {
          d.admin.modals.items[args.name] = { data: args.data, isOpen: true };
        });
      });
    },
  },
  progress: {
    completeProgress(args) {
      return set((s) => {
        return {
          ...s,
          admin: {
            ...s.admin,
            progress: {
              ...s.admin.progress,
              items: {
                ...s.admin.progress.items,
                [args.id]: {
                  ...s.admin.progress.items[args.id],
                  friendlyName: s.admin.progress.items[args.id]?.friendlyName || '',
                  percentage: args.percentage,
                  state: args.state,
                  type: s.admin.progress.items[args.id]?.type || 'download',
                },
              },
            },
          },
        };
      });
    },
    items: {},
    removeProgress(id) {
      return set((s) => {
        return produce(s, (d) => {
          d.admin.progress.items = omit(d.admin.progress.items, [id]);
        });
      });
    },
    setProgress(id, perc) {
      return set((s) => {
        return produce(s, (d) => {
          const cur = d.admin.progress.items[id];
          if (cur) {
            cur.percentage = perc;
            cur.state = 'inprogress';
          }
        });
      });
    },
    startProgress: (args) => {
      return set((s) => {
        return {
          ...s,
          admin: {
            ...s.admin,
            progress: {
              ...s.admin.progress,
              items: {
                ...s.admin.progress.items,
                [args.id]: {
                  friendlyName: args.name,
                  percentage: 0,
                  state: 'inprogress',
                  type: args.type,
                },
              },
            },
          },
        };
      });
    },
  },
  sessionEditor: {
    selectedSessionNodeIdAtom: null,
    setSelectedNodeId: (id) =>
      set((s) => {
        return produce(s, (d) => {
          d.admin.sessionEditor.selectedSessionNodeIdAtom = id;
        });
      }),
    setXstate: (xStateState) =>
      set((s) => {
        return produce(s, (d) => {
          d.admin.sessionEditor.xStateState = xStateState;
        });
      }),
    xStateState: {},
  },
});
