import { gql } from '@apollo/client';
import type { Maybe } from '@ours/types';
import { replaceVariables } from '@ours/utils';
import { useCallback } from 'react';

import { useContentVariableStringsQuery } from '../../generated/custom-hooks';

gql`
  query contentVariableStrings {
    fetchSelf {
      id
      fullName

      latestMembershipId
      latestMembership {
        id

        partnerUserId
        partnerUser {
          id
          fullName
        }

        guideId
        guide {
          id
          fullName
        }
      }
    }
  }
`;

export const variables = {
  fullName: undefined,
  guideName: undefined,
  partnersFullName: undefined,
};

export const useToVariableString = () => {
  const { data } = useContentVariableStringsQuery({ context: { batch: true } });

  // NOTE: cannot be useEvent b/c we need to re-render on data change
  return useCallback(
    (str: Maybe<string>) => {
      const self = data?.fetchSelf;
      const membership = self?.latestMembership;

      const fullName = self?.fullName;
      const guideName = membership?.guide?.fullName;
      const partnersFullName = membership?.partnerUser?.fullName;

      const knownVariables: Record<keyof typeof variables, any> = {
        fullName,
        guideName,
        partnersFullName,
      };

      return replaceVariables(str || '', knownVariables);
    },
    [data]
  );
};
