import type { AppActions } from '../actionTypes';

export const listenToBrowserLanguage = (cb: (args: AppActions) => void) => {
  const onLangChange = () => {
    cb({ language: navigator.language, type: 'LANG_CHANGE' });
  };

  onLangChange();
  window.addEventListener('languagechange', onLangChange);
  return () => {
    window.removeEventListener('languagechange', onLangChange);
  };
};
