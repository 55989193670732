import Link from 'next/link';
import type { FC } from 'react';
import { memo } from 'react';

import { ButtonBase } from './ButtonBase';
import type { APrivateProps } from './types';

export const AButtton: FC<Required<APrivateProps>> = memo(
  ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    as,
    children,
    href,
    includeLink,
    target,
    ...rest
  }) => {
    if (includeLink) {
      return (
        <Link href={href} target={target}>
          <ButtonBase as="span" {...rest}>
            {children}
          </ButtonBase>
        </Link>
      );
    }

    return (
      <a href={href} target={target}>
        <ButtonBase as="span" {...rest}>
          {children}
        </ButtonBase>
      </a>
    );
  }
);
