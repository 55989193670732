import { Box } from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { memo } from 'react';
import type { LiComponent } from 'react-markdown/lib/ast-to-react';

export interface Props {
  children?: ReactNode;
}

export const Li: LiComponent = memo(({ children }) => {
  return (
    <Box as="li" pb="50" textStyle="xs">
      {children}
    </Box>
  );
});
