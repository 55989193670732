import type { TransitionConfigOrTarget } from 'xstate';

import { canProgressSession } from '../guards/canProgressSession';
import type { CSDContext } from '../types';

export const RESUME_SESSION: TransitionConfigOrTarget<CSDContext, any> = [
  { cond: (ctx) => !canProgressSession(ctx) },
  {
    actions: ['onShouldGatherAV'],
    cond: 'shouldGatherAVPermissions',
  },
  { actions: ['onPreloadContentModules', 'onResumeSession'] },
];
