import { assertIsUUID, assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { CSDActions, CSDContext } from '../types';

export const onModuleRatingChange = assign<CSDContext, CSDActions>((ctx, ev) => {
  assertType(ev, 'ON_MODULE_RATING_CHANGE');
  const moduleId = ctx.store.display.currentModuleId;
  assertIsUUID(moduleId);

  return produce(ctx, (draft) => {
    draft.store.sessionMetadata.ratings[moduleId] = ev.rating;
  });
});
