/* eslint-disable complexity */
/* eslint-disable no-console */
import { isArray, unexpected } from '@ours/utils';
import { captureException } from '@sentry/nextjs';

import type { IConditionConfiguration } from '../types';

export const moduleResponseDecision: IConditionConfiguration['decide'] = (
  { data, op, value },
  gqlData
) => {
  const moduleId = data?.moduleId || '';
  const moduleResponses = gqlData?.moduleResponses;
  if (!moduleId || !moduleResponses) {
    console.error('Missing moduleResponses or moduleId', { moduleId });
    captureException(unexpected({ name: 'MissingModuleResponses' }));
    return true;
  }

  const answeredValue = moduleResponses[moduleId]?.value || '';
  if (!answeredValue) {
    // NOTE: A user can not have a response for a module if they were
    // not the purchaser. As it may have been hidden from them.
    return false;
  }

  if (isArray(value)) {
    if (op === 'in') {
      return value.includes(answeredValue);
    } else if (op === 'nin') {
      return !value.includes(answeredValue);
    }
  } else if (value) {
    if (op === 'eq') {
      return value === answeredValue;
    } else if (op === 'neq') {
      return value !== answeredValue;
    }
  }

  // eslint-disable-next-line no-console
  console.error('completedSessionCountDecision called value op and value: ', {
    op,
    value,
  });
  captureException(unexpected({ name: 'InvalidCaseDecision' }));

  return true;
};
