import { useEvent } from '../../../hooks/useEvent';
import { _useStore } from '../../store/_useStore';
import type { StoreState } from '../../store/types';
import type {
  ON_APP_HYDRATE_CONTEXT,
  ON_LOGIN,
  ON_REMOVE_SESSION,
  ON_UPDATE_MODULE,
  ON_UPDATE_MODULES,
  ON_UPDATE_SESSION,
} from '../actionTypes';
import type { SessionsForPurchase } from '../types';

const selector = (s: StoreState) => s.app.send;
export const useAppActions = () => {
  const send = _useStore(selector);

  const onSetTitle = useEvent((title: string | null | undefined) => {
    send({ title, type: 'UPDATE_META' });
  });

  const onLogout = useEvent((cb?: () => void) => {
    send({ type: 'ON_LOGOUT' });
    cb?.();
  });

  const onLogin = useEvent((args: ON_LOGIN) => {
    send(args);
  });

  const onUpdateModule = useEvent(({ module, moduleId }: Omit<ON_UPDATE_MODULE, 'type'>) => {
    send({ module, moduleId, type: 'ON_UPDATE_MODULE' });
  });

  const onUpdateModules = useEvent(({ modules }: Omit<ON_UPDATE_MODULES, 'type'>) => {
    send({ modules, type: 'ON_UPDATE_MODULES' });
  });

  const onUpdateSession = useEvent(({ session, sessionId }: Omit<ON_UPDATE_SESSION, 'type'>) => {
    send({ session, sessionId, type: 'ON_UPDATE_SESSION' });
  });

  const onRemoveSession = useEvent(({ sessionId }: Omit<ON_REMOVE_SESSION, 'type'>) => {
    send({ sessionId, type: 'ON_REMOVE_SESSION' });
  });

  const onInApp = useEvent((args: Omit<ON_APP_HYDRATE_CONTEXT, 'type'>) => {
    send({ ...args, type: 'ON_APP_HYDRATE_CONTEXT' });
  });

  const onPurchasableSessionAdd = useEvent((sessions: SessionsForPurchase[]) => {
    send({ sessions, type: 'ON_PURCHASABLE_SESSIONS_ADD' });
  });

  return {
    onInApp,
    onLogin,
    onLogout,
    onPurchasableSessionAdd,
    onRemoveSession,
    onSetTitle,
    onUpdateModule,
    onUpdateModules,
    onUpdateSession,
  };
};
