/* eslint-disable sort-exports/sort-exports */

import type { XState } from '../generated/operations';
// eslint-disable-next-line no-restricted-imports
import { uuid } from '../id/uuid';
import type { conditionOPOptions } from '../xstateConditions/conditions';
import type { conditionOptions } from '../xstateConditions/conditionsOptions';

export const CONDITION_PROPERTY = 'condition';
export const MULTI_CONDITIONS_PROPERTY = 'multiConditions';
export const MODULE_ID_PROPERTY = 'moduleId';

export const getStartingXStateState = () => {
  const endingId = uuid();
  return [
    { data: undefined, id: uuid(), on: { NEXT: endingId }, type: 'start' },
    { data: undefined, id: endingId, type: 'final' },
  ] as XState[];
};

export interface IStartNode {
  data: undefined;
  on: { NEXT: string };
  type: 'start';
}

export interface IFinalNode {
  data: undefined;
  on: undefined;
  type: 'final';
}

export interface IModuleNode {
  data: { [MODULE_ID_PROPERTY]: string };
  on: { NEXT: string };
  type: 'module';
}

export interface IConditions {
  data?: Record<string, string>;
  op?: (typeof conditionOPOptions)[number];
  property: (typeof conditionOptions)[number];
  value?: string | readonly string[];
}

export interface IfBooleanNode {
  /**
   * JSON.stringify(encodeURIComponent(atob(IConditions)))
   *
   * @examples
   * { condition: { property: 'user.tags', op: "in", value: "advanced"  } }
   * { condition: { property: 'moduleResponse', data: { moduleId: '444333222' }, op: 'eq', value: '222333222' } }
   **/
  data: { [CONDITION_PROPERTY]: string };
  on: { FALSE: string; TRUE: string };
  type: 'ifBoolean';
}

export interface IMultiBranchNode {
  /**
   *  JSON.stringify(encodeURIComponent(atob(IConditions[])))
   *  @examples
   *  conditions:
   *      [
   *          { property: 'user.tags', op: "in", value: "advanced", NEXT: uuid(), },
   *          { property: 'user.tags', op: "in", value: "beginner", NEXT: uuid(), }
   *      ]
   *
   * The UI then interprets this as 3 separate branches... | Beginner Users | Advanced Users | All Others
   **/
  data: { [MULTI_CONDITIONS_PROPERTY]: string };
  on: Record<string, string>;
  type: 'mulitbranch';
}

export type ValidXStateInputNodes = IModuleNode | IfBooleanNode;
export type IXStateNodeTypes =
  | IStartNode
  | IFinalNode
  | IModuleNode
  | IfBooleanNode
  | IMultiBranchNode;
export type XStateOrderedArray = ({ id: string } & IXStateNodeTypes)[];
export type IXStateState = Record<string, IXStateNodeTypes>;

export type XStateDataType = {
  [CONDITION_PROPERTY]?: string;
  [MODULE_ID_PROPERTY]?: string;
};
