import { produce } from 'immer';
import { assign } from 'xstate';

import type { CardDeckActions } from '../actionTypes';
import type { CardDeckContext } from '../types';

export const onPlayDifferentDeck = assign<CardDeckContext, CardDeckActions>((ctx) => {
  return produce(ctx, (d) => {
    d.selectedDeckId = undefined;
    d.selectedDeckShuffledCards = [];
  });
});
