import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { CartActions } from '../actionTypes';
import type { CartContext } from '../types';

export const onAdCompletedCheckoutListener = assign<CartContext, CartActions>((ctx, ev) => {
  assertType(ev, 'ON_ADD_COMPLETED_CHECKOUT_LISTENER');
  return produce(ctx, (d) => {
    d.onCloseCompletedCheckout = ev.fn;
  });
});
