import {
  assertType,
  buildXStateFromModuleIds,
  getModuleIdFromNode,
  isNotNil,
  xStateToGraphQLXState,
} from '@ours/utils';

import { AddModuleToSessionDocument } from '../../../generated/custom-hooks';
import type {
  AddModuleToSessionMutation,
  AddModuleToSessionMutationVariables,
} from '../../../generated/custom-hooks';
import { getApolloClient } from '../../../lib/apollo/getApolloClient';
import { duplicateModule } from '../../../lib/dataTransfer/duplicateModule';
import { sendOnUpdateXState } from '../../appMachine/externalActions/sendOnUpdateXState';
import { store } from '../../store/_useStore';
import { selectModuleById } from '../../store/lib/selectModuleById';
import { selectXStateBySessionId } from '../../store/lib/selectXStateBySessionId';
import type { SessionEditorActions, SessionEditorContext } from '../types';

export const onDuplicateNode = (ctx: SessionEditorContext, ev: SessionEditorActions): void => {
  assertType(ev, 'ON_DUPLICATE_NODE');
  const selectedNodeIds = ctx.display.selectedNodeIds;
  const focusedNodeId = ctx.display.focusedNodeId;
  const xState = selectXStateBySessionId(ctx.store.sessionId);
  const sessionId = ctx.store.sessionId;
  const moduleTypesOfSelectedNodes = selectedNodeIds.map((nodeId) => xState[nodeId]?.type);

  if (moduleTypesOfSelectedNodes.some((type) => type !== 'module')) {
    store.getState().app.state.context.toast({
      title:
        'Cannot duplicate with non-modules selected. Please make sure you only select modules when duplicating',
    });

    return;
  }

  const modulesToCreate = selectedNodeIds
    .map((nodeId) => {
      const id = getModuleIdFromNode(xState[nodeId]);
      return id ? selectModuleById(id) : undefined;
    })
    .filter(isNotNil)
    .map(duplicateModule);

  const nextXState = buildXStateFromModuleIds(
    modulesToCreate.map((m) => m.id),
    focusedNodeId,
    xState
  );

  const client = getApolloClient();
  client.mutate<AddModuleToSessionMutation, AddModuleToSessionMutationVariables>({
    mutation: AddModuleToSessionDocument,
    variables: {
      id: sessionId,
      modules: modulesToCreate,
      xState: xStateToGraphQLXState(nextXState),
    },
  });

  modulesToCreate.forEach((mod) => {
    store.getState().app.send({ module: mod, moduleId: mod.id, type: 'ON_UPDATE_MODULE' });
  });
  sendOnUpdateXState({ sessionId, xState: nextXState });
};
