import { Box, Flex } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo, useEffect } from 'react';

import { OursContainer } from '../../components/OursContainer/OursContainer';
import { YellowCircleWithText } from '../../components/YellowCircleWithText/YellowCircleWithText';
import { useAppActions } from '../../state/appMachine/externalActions/useAppActions';
import { PinkFooter } from '../PinkFooter/PinkFooter';

import { LOGIN_COMPONENT_TEXT } from './content';
import { LoginEmailInput } from './lib/LoginEmailInput';
import { LoginWhiteBox } from './lib/LoginWhiteBox';

export interface Props {
  loading: boolean;
  onSubmit: (email: string) => Promise<void>;
}

export const LoginComponent: FC<Props> = memo(({ loading, onSubmit }) => {
  const { onSetTitle } = useAppActions();
  useEffect(() => {
    onSetTitle('Login');
  }, [onSetTitle]);

  return (
    <Flex flexDir="column" h="full" justifyContent="space-between" w="full">
      <Box
        as="main"
        bg="brand.beige"
        data-testid="LoginComponent"
        pos="relative"
        py={{ base: '300', md: '500' }}
        zIndex="1"
      >
        <OursContainer>
          <Flex alignItems="center" flexDir="column" w="full">
            <YellowCircleWithText text={LOGIN_COMPONENT_TEXT.HEADING} />
            <LoginWhiteBox>
              <LoginEmailInput _onSubmit={onSubmit} loading={loading} />
            </LoginWhiteBox>
          </Flex>
        </OursContainer>
      </Box>

      <PinkFooter />
    </Flex>
  );
});
