import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { VideoActions } from '../actionTypes';
import { videoSettingsLocalStorage } from '../externalActions/videoSettingsLocalStorage';
import type { VideoContext } from '../types';

export const onSetVideoPlaybackRate = assign<VideoContext, VideoActions>((ctx, ev) => {
  assertType(ev, 'SetVideoPlaybackRate');

  return produce(ctx, (d) => {
    videoSettingsLocalStorage.setByKey('playbackRate', ev.playbackRate);
    d.settings.playbackRate = ev.playbackRate;
  });
});
