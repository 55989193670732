import { produce } from 'immer';
import { assign } from 'xstate';

import type { AdminActions } from '../actionTypes';
import type { AdminContext } from '../types';

export const onShowPii = assign<AdminContext, AdminActions>((ctx) => {
  return produce(ctx, (d) => {
    d.ui.isShowingPII = true;
  });
});
