import {
  assertIsString,
  findPreviousNodeId,
  findStartNodeId,
  first,
  getModuleIdFromNode,
  last,
} from '@ours/utils';

import { selectModuleById } from '../../store/lib/selectModuleById';
import { selectXStateBySessionId } from '../../store/lib/selectXStateBySessionId';
import type { CSDContext } from '../types';

type PrevModuleState = {
  nextMacroModuleId: string | undefined;
  nextModuleId: string | undefined;
  nextNodeId: string;
};
/**
 *  @see https://www.notion.so/withours/Macro-modules-62d325e199e549b3991973db21b5e597?pvs=4#2eeb86833896437c9c44a47975650dfa
 **/
export const determinePrevModule = (ctx: CSDContext): PrevModuleState => {
  assertIsString(ctx.store.display.currentNodeId);
  const currentNodeId = ctx.store.display.currentNodeId;
  const completedModuleIds = ctx.store.sessionMetadata.completedModuleIds;
  const currentMacroModuleId = ctx.store.display.currentMacroModuleId;
  const currentModuleId = ctx.store.display.currentModuleId;
  const xstate = selectXStateBySessionId(ctx.store.sessionMetadata.id);
  const startNodeId = findStartNodeId(xstate) || '';
  const currentMacroModule = currentMacroModuleId
    ? selectModuleById(currentMacroModuleId)
    : undefined;

  const onToPrevModule = (): PrevModuleState => {
    const nextNodeId = findPreviousNodeId(currentNodeId, xstate, completedModuleIds) || startNodeId;
    const nextModuleId = getModuleIdFromNode(xstate[nextNodeId]);
    const nextModule = nextModuleId ? selectModuleById(nextModuleId) : undefined;

    if (nextModule?.interactionKind === 'MacroModules') {
      const mmIds = nextModule.macroModuleIds || [];

      return {
        nextMacroModuleId: nextModuleId,
        nextModuleId: last(mmIds),
        nextNodeId,
      };
    }

    return {
      nextMacroModuleId: undefined,
      nextModuleId,
      nextNodeId,
    };
  };

  const onToPrevInMacroModule = (): PrevModuleState => {
    const mmIds = currentMacroModule?.macroModuleIds || [];
    const currentModuleIdxInMM = mmIds.indexOf(currentModuleId!);
    const isAtFirstModuleIdInMM = first(mmIds) === currentModuleId;

    if (isAtFirstModuleIdInMM) {
      return onToPrevModule();
    }

    return {
      nextMacroModuleId: currentMacroModuleId,
      nextModuleId: mmIds.at(currentModuleIdxInMM - 1),
      nextNodeId: currentNodeId,
    };
  };

  return currentMacroModuleId ? onToPrevInMacroModule() : onToPrevModule();
};
