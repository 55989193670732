import { getCurrentSession } from '../../../lib/cognito/getCurrentSession';
import type { AppActions } from '../actionTypes';

export const listenToAuthStatus = (cb: (args: AppActions) => void) => {
  const id = setInterval(async () => {
    const user = await getCurrentSession();
    if (!user) {
      cb({ type: 'ON_LOGOUT' });
    }
  }, 6000);

  return () => {
    clearInterval(id);
  };
};
