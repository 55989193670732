import { produce } from 'immer';
import { assign } from 'xstate';

import type { LiveSessionActions, LiveSessionContext } from '../types';

export const onToggleWaitingRoomMode = assign<LiveSessionContext, LiveSessionActions>((ctx) => {
  return produce(ctx, (draft) => {
    draft.store.metadata.waitingRoomMode =
      draft.store.metadata.waitingRoomMode === 'autoJoin' ? 'manualJoin' : 'autoJoin';
  });
});
