import { now, uuid } from '@ours/utils';
import * as Factory from 'factory.ts';

export const commonFactory = Factory.Sync.makeFactory({
  createdAt: Factory.each(() => now()),
  createdByUserId: Factory.each(() => uuid()),
  id: Factory.each(() => uuid()),
  updatedAt: Factory.each(() => now()),
  updatedByUserId: Factory.each(() => uuid()),
});

export type RequiredBaseEntity<T> = Omit<
  Required<T>,
  'createdAt' | 'createdByUserId' | 'id' | 'updatedAt' | 'updatedByUserId'
>;
