import { Box } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

export interface Props {
  children?: any;
}

export const P: FC<Props> = memo(({ children }) => {
  return (
    <Box pb="100" textStyle="xs">
      {children}
    </Box>
  );
});
