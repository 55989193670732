import { isNil } from './isNil';

export const getItemAtRelIndex = <T>(arr: readonly T[], index: number): T => {
  const item = arr[index];
  if (!isNil(item)) {
    return item;
  }

  return getItemAtRelIndex(arr, index % arr.length);
};
