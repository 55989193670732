import type { PaymentMethod } from '@ours/utils';
import { getItemAtRelIndex } from '@ours/utils';
import * as Factory from 'factory.ts';

import type { RequiredBaseEntity } from './commonFactory';
import { commonFactory } from './commonFactory';

export const paymentMethodFactory = commonFactory.combine(
  Factory.Sync.makeFactory<RequiredBaseEntity<PaymentMethod>>({
    __typename: 'PaymentMethod',
    brand: Factory.each((idx) =>
      getItemAtRelIndex(['amex', 'mastercard', 'visa', 'discover'], idx)
    ),
    expMonth: 9,
    expYear: 2022,
    last4: '8023',
  })
);
