/* eslint-disable react-memo/require-memo */
import type { LoadableComponent } from '@loadable/component';
import loadable from '@loadable/component';
import type { DisplayKind } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

import { useCSDModuleById } from '../../../state/contentSessionDriver/selectors/modules/useCSDModuleById';

import type { ModuleId } from './types';

const NotEnabled = loadable(
  () => import('../../ContentSessionHStack/lib/NotEnabled').then((c) => c.NotEnabled),
  { ssr: false }
);
const AnimatedInterstitialSessionModule = loadable(
  () =>
    import('./AnimatedInterstitialSessionModule').then((c) => c.AnimatedInterstitialSessionModule),
  { ssr: false }
);
const AudioCaptionDisplayOnly = loadable(
  () => import('./AudioCaptionDisplayOnly').then((c) => c.AudioCaptionDisplayOnly),
  { ssr: false }
);
const VideoDisplayOnly = loadable(
  () => import('./VideoDisplayOnly').then((c) => c.VideoDisplayOnly),
  { ssr: false }
);
const TextDisplayOnly = loadable(() => import('./TextDisplayOnly').then((c) => c.TextDisplayOnly), {
  ssr: false,
});

export const displayKindLookup: Record<DisplayKind, LoadableComponent<ModuleId>> = {
  Animation: AnimatedInterstitialSessionModule,
  AudioCaptionPlayer: AudioCaptionDisplayOnly,
  Image: NotEnabled,
  Text: TextDisplayOnly,
  Video: VideoDisplayOnly,
};

export const DisplayOnlyModule: FC<ModuleId> = memo(({ moduleId }) => {
  const module = useCSDModuleById(moduleId);
  if (!module.displayKind) {
    return <>display kind not set</>;
  }
  const Component = displayKindLookup[module?.displayKind];

  return <Component moduleId={moduleId} />;
});
