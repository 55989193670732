import { _useStore } from '../../store/_useStore';
import type { StoreState } from '../../store/types';

const select = (s: StoreState) => {
  return !!s?.app.state.context.device.isOnline;
};

export const useIsOnline = () => {
  return _useStore(select);
};
