import type { Maybe } from '@ours/types';
import type { AnalyticsEventType, Stage } from '@ours/utils';
import { pRetry, serverSideEvents } from '@ours/utils';
import { gql } from 'graphql-tag';
import mixpanel from 'mixpanel-browser';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { useLogMembershipActionMutation } from '../../generated/custom-hooks';
import { useUserId } from '../../state/appMachine/selectors/useUserId';
import { useEvent } from '../useEvent';

import { AnalyticsContextProvider } from './analyticsContext';
import { hydrateAnalyticsEvent } from './hydrateAnalyticsEvent';
import { sendGAEvent } from './sendGAEvent';
import type { TrackEventFN } from './useAnalyticsEvent';

interface Props {
  children: ReactNode;
  stage: Stage;
}

gql`
  mutation logMembershipAction($type: String!, $data: JSONObject!) {
    logMembershipAction(type: $type, data: $data)
  }
`;

export const AnalyticsContextProviderLive: FC<Props> = memo(({ children, stage }) => {
  const [logAction] = useLogMembershipActionMutation();
  const trackServerSideEvent = useEvent((type: string, data: Record<string, any>) => {
    logAction({ context: { batch: true }, variables: { data, type } });
  });
  const userId = useUserId();

  const trackEvent: TrackEventFN = useEvent(async (event) => {
    sendGAEvent(stage, event.type);

    if (userId && serverSideEvents[event.type]) {
      return trackServerSideEvent(event.type, 'payload' in event ? event.payload : {});
    }

    const data = 'payload' in event ? hydrateAnalyticsEvent(event.payload) : { event: true };

    // Shared components in the admin app will not have mixpanel initialized
    await pRetry(
      () => {
        try {
          mixpanel.track(event.type, data);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log('Client-side mixpanel event not fired: ', event.type);
          throw new Error('Mixpanel not implemented yet');
        }
      },
      { retries: 20 }
    );
  });

  const startEvent = useEvent(async (event: Pick<AnalyticsEventType, 'type'>) => {
    mixpanel.time_event(event.type);
  });

  const identifyUserId = useEvent((userId: Maybe<string>) => {
    userId && mixpanel.identify(userId);
  });

  return (
    <AnalyticsContextProvider
      identifyUserId={identifyUserId}
      startEvent={startEvent}
      trackEvent={trackEvent}
    >
      {children}
    </AnalyticsContextProvider>
  );
});
