import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { VideoActions } from '../actionTypes';
import type { VideoContext } from '../types';
import { initialStateOfNewVideo } from '../types';

export const onVideoEnded = assign<VideoContext, VideoActions>((ctx, ev) => {
  assertType(ev, 'OnVideoEnded');

  return produce(ctx, (d) => {
    d.videosById[ev.videoId] = {
      ...initialStateOfNewVideo,
      ...d.videosById[ev.videoId],
      ended: true,
    };
  });
});
