import { assertType } from '@ours/utils';
import { assign } from 'xstate';

import { store } from '../../store/_useStore';
import type { SessionEditorActions, SessionEditorContext } from '../types';

export const onPasteNode = assign<SessionEditorContext, SessionEditorActions>((ctx, ev) => {
  assertType(ev, 'ON_PASTE_NODE');
  store.getState().app.state.context.toast({ title: 'Paste not implemented' });
  return ctx;
});
