import { unexpected } from '../error/unexpected';
import { append } from '../lang/append';
import { entries } from '../lang/entries';
import { omit } from '../lang/omit';

import type { IMultiBranchNode, IXStateState, IfBooleanNode } from './xStateConstants';

const removeReferencesToStep = (
  removedStepId: string,
  next: string,
  xStateState: IXStateState,
  keysToRemove: string[]
): IXStateState => {
  return omit(
    // @ts-expect-error error
    entries(xStateState).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: {
          ...value,
          on: !value.on
            ? undefined
            : Object.entries(value.on).reduce(
                (acc, [_k, _v]) => ({ ...acc, [_k]: _v === removedStepId ? next : _v }),
                {}
              ),
        },
      };
    }, {} as IXStateState),
    // @ts-expect-error error
    keysToRemove
  );
};

const nodeArrayToEnd = (stepId: string, arr: string[], xStateState: IXStateState): string[] => {
  const next = xStateState[stepId];
  if (!next || next?.type === 'final') {
    return arr;
  }

  if (next.type === 'module') {
    arr = append(next.on.NEXT, arr);
    return nodeArrayToEnd(next.on.NEXT, arr, xStateState);
  }

  if (next.type === 'ifBoolean') {
    arr = append(next.on.FALSE, arr);
    arr = append(next.on.TRUE, arr);

    return [
      ...nodeArrayToEnd(next.on.FALSE, arr, xStateState),
      ...nodeArrayToEnd(next.on.TRUE, arr, xStateState),
    ];
  }

  throw unexpected({ name: 'StepTypeNotImplemented' });
};

const nearestCommonAncestor = (
  removedStep: IfBooleanNode | IMultiBranchNode,
  xStateState: IXStateState
): [string, string[]] => {
  const left = removedStep.on.FALSE;
  const right = removedStep.on.TRUE;

  if (left === right) {
    return [left, []];
  }

  const leftArr = nodeArrayToEnd(left, [left], xStateState);
  const rightArr = nodeArrayToEnd(right, [right], xStateState);

  const nextStep = leftArr.find((_) => rightArr.includes(_));
  if (!nextStep) {
    // eslint-disable-next-line no-console
    console.log({ removedStep, xStateState });
    throw unexpected({ name: 'NextStepIsMissing' });
  }

  const lIndex = leftArr.indexOf(nextStep);
  const lKeysToRemove = leftArr.splice(0, lIndex);

  const rIndex = rightArr.indexOf(nextStep);
  const rKeysToRemove = rightArr.splice(0, rIndex);

  return [nextStep, [...lKeysToRemove, ...rKeysToRemove]];
};

export const removeNodeFromXState = (
  removedStepId: string,
  xStateState: IXStateState
): IXStateState => {
  const removedStep = xStateState[removedStepId];
  const isEnd = removedStep?.type === 'final';
  const isStart = removedStep?.type === 'start';

  if (isStart || isEnd) {
    return xStateState;
  }

  if (!removedStep) {
    return xStateState;
  }

  if (removedStep.type === 'module') {
    return removeReferencesToStep(removedStepId, removedStep.on.NEXT, xStateState, [removedStepId]);
  }

  const [nextStep, additionalStepsToRemove] = nearestCommonAncestor(removedStep, xStateState);

  if (nextStep) {
    return removeReferencesToStep(removedStepId, nextStep, xStateState, [
      removedStepId,
      ...additionalStepsToRemove,
    ]);
  }

  return {};
};
