/* eslint-disable sort-keys-fix/sort-keys-fix */
/** @see https://codesandbox.io/s/clamp-linear-intepolation-based-on-viewport-width-builder-xgkft?from-embed */
export const DeprecatedTypes = {
  '3xs': { fontSize: '11px', lineHeight: '200' },
  '2xs': { fontSize: '14px', lineHeight: '300' },
  xs: { fontSize: '16px', lineHeight: '400' },
  sm: { fontSize: '18px', lineHeight: '500' },
  md: { fontSize: '20px', lineHeight: '600' },
  mdClamp: {
    fontSize: 'clamp(18px, 17.3333px + 0.1852vw, 20px)',
    lineHeight: 'clamp(18px, 17.3333px + 0.1852vw, 20px)',
  },
  lg: { fontSize: '24px', lineHeight: '700' },
  lgClamp: {
    fontSize: 'clamp(20px, 18.6667px + 0.3704vw, 24px)',
    lineHeight: 'clamp(20px, 19px + 0.3704vw, 24px)',
  },
  xl: { fontSize: '30px', lineHeight: '32px' },
  xlClamp: {
    fontSize: 'clamp(24px, 22.0000px + 0.5556vw, 30px);',
    lineHeight: 'clamp(24px, 23.0000px + 0.5556vw, 30px);',
  },
  '2xl': { fontSize: '45px', lineHeight: '900' },
  '2xlClamp': {
    fontSize: 'clamp(30px,25px + 1.3889vw, 45px)',
    lineHeight: 'clamp(30px,27px + 1.3889vw, 45px)',
  },
  '3xl': { fontSize: '60px', lineHeight: '72px' },
};
export const Typography = {
  h1: { fontSize: { base: '40px', md: '96px' }, lineHeight: { base: '48px', md: '104px' } },
  h2: { fontSize: { base: '32px', md: '60px' }, lineHeight: { base: '40px', md: '68px' } },
  h3: { fontSize: { base: '28px', md: '48px' }, lineHeight: { base: '36px', md: '60px' } },
  h4: { fontSize: { base: '24px', md: '32px' }, lineHeight: { base: '32px', md: '40px' } },
  h5: { fontSize: { base: '20px', md: '24px' }, lineHeight: { base: '24px', md: '32px' } },
  h6: {
    textTransform: 'uppercase',
    fontSize: { base: '12px', md: '12px' },
    lineHeight: { base: '20px', md: '20px' },
  },
  body1: { fontSize: { base: '16px', md: '18px' }, lineHeight: { base: '24px', md: '26px' } },
  body2: { fontSize: { base: '14px', md: '16px' }, lineHeight: { base: '20px', md: '24px' } },
  body3: { fontSize: { base: '12px', md: '14px' }, lineHeight: { base: '16px', md: '18px' } },
  caption: { fontSize: { base: '10px', md: '12px' }, lineHeight: { base: '14px', md: '16px' } },
};

export const FontSizes = {
  100: '11px',
  150: '12px',
  200: '14px',
  300: '16px',
  400: '18px',
  500: '20px',
  600: '24px',
  700: '30px',
  800: '45px',
  // IMPROVE: Remove these stock chakra defaults
  '2xl': '1.5rem',
  '2xs': '0.625rem',
  '3xl': '1.875rem',
  '3xs': '0.45rem',
  lg: '1.125rem',
  md: '1rem',
  sm: '0.875rem',
  xl: '1.25rem',
  xs: '0.75rem',
};

export const FontWeights = {
  200: 200,
  300: 300,
  400: 400,
  500: 500,
};

export const LineHeights = {
  200: '14px',
  300: '16px',
  400: '18px',
  500: '21px',
  600: '24px',
  700: '30px',
  750: '36px',
  800: '45px',
  900: '54px',
};
