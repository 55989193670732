import { Auth } from '@aws-amplify/auth';
import { getCognitoVars } from '@ours/utils';

export const configureCognito = () => {
  const config = {
    Auth: {
      authenticationFlowType: 'CUSTOM_AUTH',
      region: 'us-east-1',
      ...getCognitoVars(process.env.NEXT_PUBLIC_STAGE),
    },
    ssr: false,
  };
  Auth.configure(config);
};
