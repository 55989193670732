import { unexpected } from '../error/unexpected';
import type { Stage } from '../generated/operations';

const cognitoVars = {
  dev: {
    userPoolId: 'us-west-2_2Pue17bOs',
    userPoolWebClientId: '65ubqse46c2c0n4v5i550fdfgh',
  },
  local: {
    userPoolId: 'us-west-2_OpHScI3vS',
    userPoolWebClientId: '7ft8dh0m7bplk58e1fhk2omn1k',
  },
  production: {
    userPoolId: 'us-west-2_PDXmZSScg',
    userPoolWebClientId: '6hl9q8eo27oqu02j3rn7553ra9',
  },
} as const;

export const getCognitoVars = (
  stage: undefined | string
): { userPoolId: string; userPoolWebClientId: string } => {
  const result = cognitoVars[stage as Stage];

  if (!result.userPoolId) {
    throw unexpected({ name: 'MissingCognitoVars' });
  }

  return result;
};
