import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const onLanguageChange = assign<AppContext, AppActions>((ctx, ev) => {
  assertType(ev, 'LANG_CHANGE');

  return produce(ctx, (draft) => {
    draft.device.language = ev.language;
  });
});
