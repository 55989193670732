import type { FieldPolicy } from '@apollo/client';

import type { StrictTypedTypePolicies } from '../../generated/apollo-helpers';

import { resolveIdField } from './resolvers/resolveIdField';
import { resolveIdsField } from './resolvers/resolveIdsField';
import { toFindReference } from './resolvers/toFindReference';

const resolvedValues = {
  merge(existing, incoming) {
    return { ...existing, ...incoming };
  },
} as FieldPolicy;
export const typePolicies: StrictTypedTypePolicies = {
  Bookmark: {
    fields: { resolvedValues },
  },
  Membership: {
    fields: {
      guide: resolveIdField('guideId', 'PartialUser'),
      resolvedValues: {
        merge(existing, incoming) {
          return { ...existing, ...incoming };
        },
      },
      sessions: {
        merge(_, incoming) {
          return [...(incoming || [])];
        },
        read: resolveIdsField('sessionIds', 'Session').read,
      },
    },
  },
  Module: { fields: { resolvedValues } },
  Query: {
    fields: {
      findEmail: toFindReference('Email'),
      findEvent: toFindReference('Event'),
      findMembership: toFindReference('Membership'),
      findModule: toFindReference('Module'),
      findNote: toFindReference('Note'),
      findSession: toFindReference('Session'),
      findUser: toFindReference('User'),
    },
  },
  Session: {
    fields: {
      resolvedValues: {
        merge(existing, incoming) {
          return { ...existing, ...incoming };
        },
      },
    },
  },
  SessionResolved: {
    fields: {
      sessionXState: {
        merge(_, incoming) {
          return [...(incoming || [])];
        },
      },
    },
  },
};
