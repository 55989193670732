import type { FieldPolicy } from '@apollo/client';

export const resolveIdsField: (key: string, __typename: string) => FieldPolicy<null | unknown[]> = (
  key,
  __typename
) => ({
  read(_, { readField, toReference }) {
    const ids = readField<string[] | undefined | null>(key);
    if (!ids) {
      return null;
    }
    return ids.map((id) => toReference({ __typename, id })) || [];
  },
});
