import { assertType } from '@ours/utils';
import { assign } from 'xstate';

import { store } from '../../store/_useStore';
import type { SessionEditorActions, SessionEditorContext } from '../types';

export const onCopyNode = assign<SessionEditorContext, SessionEditorActions>((ctx, ev) => {
  assertType(ev, 'ON_COPY_NODE');

  store.getState().app.state.context.toast({ title: 'Copy not implemented' });
  return ctx;
});
