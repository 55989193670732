import { Box } from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { memo } from 'react';
import type { UnorderedListComponent } from 'react-markdown/lib/ast-to-react';

export interface Props {
  children?: ReactNode;
}

export const Ul: UnorderedListComponent = memo(({ children }) => {
  return (
    <Box as="ul" listStyleType="disc" pb="50" pl="200" textAlign="left" textStyle="xs">
      {children}
    </Box>
  );
});
