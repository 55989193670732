import { uniq } from './uniq';

export const append = <T>(value: T, list: readonly T[], unique?: boolean): T[] => {
  const x = [...list, value];

  if (unique) {
    return uniq(x);
  }

  return x;
};
