import { HMSVirtualBackgroundPlugin } from '@100mslive/hms-virtual-background';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { LiveSessionActions, LiveSessionContext } from '../types';

export const onCanUseVirtualBackground = assign<LiveSessionContext, LiveSessionActions>((ctx) => {
  const bg = new HMSVirtualBackgroundPlugin('none', true);

  return produce(ctx, (draft) => {
    draft.store.supports.isVirtualBackgroundSupported = bg.checkSupport().isSupported;
  });
});
