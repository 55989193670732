import type { MutateModuleInput } from '@ours/utils';

import { arrayTranslator } from './translators/arrayTranslator';

export const modulePropertiesToCopy: Record<
  keyof MutateModuleInput,
  boolean | ((str: string) => any)
> = {
  animatedVariant: true,
  answerMode: true,
  answersVisibleTo: true,
  audioDirectory: false,
  backgroundColor: true,
  buttonLink: true,
  buttonSecondaryText: true,
  buttonText: true,
  conditionallyDisabled: true,
  description: true,
  displayKind: true,
  downloadableS3Key: false,
  durationInSeconds: true,
  id: false,
  imageS3Key: true,
  inputType: true,
  interactionColor: true,
  interactionKind: true,
  layout: true,
  lowerLimitHelper: true,
  macroModuleIds: false,
  multipleChoiceAllowMany: true,
  multipleChoiceVariant: true,
  multipleChoices: arrayTranslator.toArray,
  openEndedPrompt: true,
  paymentBlocks: false,
  photoboothNumberOfPhotos: true,
  photoboothPrompts: true,
  placeholder: true,
  presenterUserId: false,
  purchasableSessionIds: false,
  quizPairs: false,
  schedulerSessionId: false,
  sentenceBuilderString: true,
  sideEffectActions: arrayTranslator.toArray,
  tags: arrayTranslator.toArray,
  textDisplayOnly: true,
  timeInSec: true,
  title: true,
  upperLimitHelper: true,
  videoDirectory: false,
};
