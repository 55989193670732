import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { CartActions } from '../actionTypes';
import type { CartContext } from '../types';

export const onToggleBillingDrawer = assign<CartContext, CartActions>((ctx, ev) => {
  assertType(ev, 'ON_TOGGLE_BILLING_DRAWER');

  return produce(ctx, (d) => {
    d.billingDrawerOpen = !d.billingDrawerOpen;
  });
});
