import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { VideoActions } from '../actionTypes';
import type { VideoContext } from '../types';

export const onHydrateSettingsFromStorage = assign<VideoContext, VideoActions>((ctx, ev) => {
  assertType(ev, 'OnHydrateSettingsFromStorage');

  return produce(ctx, (d) => {
    d.settings = ev.settings;
  });
});
