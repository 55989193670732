import { Box, Grid } from '@chakra-ui/react';
import { SplitLogoOR, SplitLogoUS } from '@ours/web-icons';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

export interface OursContainerProps {
  children: ReactNode;
}
export const OursContainer: FC<OursContainerProps> = memo(({ children }) => {
  return (
    <Grid
      alignItems={{ base: 'start', lg: 'end' }}
      gridColumnGap="-40px"
      gridTemplate={{
        base: `". content content ."". or us ."`,
        lg: `"or content us"`,
      }}
      gridTemplateColumns={{
        base: '1fr 2fr 2fr 1fr',
        lg: '1fr auto 1fr',
      }}
      gridTemplateRows={{
        base: '1fr 1fr',
        lg: '1fr',
      }}
      justifyContent="center"
      pb={{ base: '400', lg: '200', md: '400' }}
      px={{ base: '200', lg: '0' }}
    >
      <Box gridArea="content" zIndex="1">
        {children}
      </Box>
      <Box
        gridArea="or"
        justifySelf={{ base: 'end', lg: 'flex-end' }}
        pos="relative"
        sx={{ svg: { ml: 'auto' } }}
        transform="scale(1.02)"
        w={{ base: '100%', lg: '270px' }}
        zIndex="0"
      >
        <SplitLogoOR />
      </Box>
      <Box
        gridArea="us"
        position="relative"
        right={{ base: '4%', lg: '0', md: '-1%' }}
        transform={{ base: 'scale(1.2)', lg: 'scale(1.04)' }}
        w={{ base: '100%', lg: '270px' }}
        zIndex="0"
      >
        <SplitLogoUS />
      </Box>
    </Grid>
  );
});
