/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable import/exports-last */
import { extendTheme as extendThemeChakra } from '@chakra-ui/react';
import { Colors, Spacing } from '@ours/utils';

import { ModifiedComponents } from './modifiedComponents';
import { DeprecatedTypes, FontSizes, FontWeights, LineHeights, Typography } from './typography';

const styles = {
  global: {
    body: {
      color: 'black',
    },
    'html, body': {
      background: Colors.brand.beige,
      fontSize: 500,
      lineHeight: 600,
    },
  },
};
const radii = {
  400: '6px',
  80: '8px',
  500: '8px',
  600: '15px',
  700: '25px',
  300: '30px',
  750: '45px',
  800: '65px',
  900: '78px',
  1000: '200px',
  full: '100%',
};
export const BreakPoints = {
  '2xl': '1800px',
  xl: '1440px',
  lg: '1220px',
  md: '750px',
  smPlus: '600px',
  sm: '360px',
  xs: '299px',
};

// IMPROVE: Pull more of these out of the extendTheme to avoid injecting chakras defaults into our kit
export const extendTheme = {
  ...extendThemeChakra({
    colors: Colors,
    components: ModifiedComponents,
    lineHeights: LineHeights,
    styles,
    sizes: {
      container: {
        '2xs': '360px',
        xs: '420px',
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
        '2xl': '1440px',
        '3xl': '2000px',
      },
    },
    radii,
    space: Spacing,
  }),
  letterSpacings: { '0.10em': '0.10em' },
  fontSizes: FontSizes,
  textStyles: { ...Typography, ...DeprecatedTypes },
  breakpoints: BreakPoints,
  fontWeights: FontWeights,
  fonts: {},
};

// Default export needed for theme generation.
// we modify it slightly to make the theming colors stricter than what chakra will apply.
// eslint-disable-next-line import/no-default-export
export default { ...extendTheme, colors: Colors };
