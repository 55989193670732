import { findFinalNodeId, findStartNodeId } from '@ours/utils';
import { assign } from 'xstate';

import { selectTrackEvent } from '../../appMachine/selectors/selectTrackEvent';
import { selectXStateBySessionId } from '../../store/lib/selectXStateBySessionId';
import { determineNextModule } from '../helpers/determineNextModule';
import { onNextStep } from '../helpers/onNextStep';
import { updateSessionPlaybackByNodeId } from '../helpers/updateSessionPlaybackByNodeId';
import type { CSDActions, CSDContext } from '../types';

const shouldUseInitialNode = (ctx: CSDContext): boolean => {
  const xstate = selectXStateBySessionId(ctx.store.sessionMetadata.id);
  const initialNodeId = ctx._private.initial.initialCurrentNodeId;

  return xstate[initialNodeId || '']?.type === 'module';
};

/**
 *
 * @see  https://www.notion.so/withours/Resuming-a-session-brings-you-to-the-module-after-what-you-left-off-on-448b1d9c7486435aa44b003c7891f90f?pvs=4#3c9125cc55044a17a6bab0c21043349d
 */
export const onResumeSession = assign<CSDContext, CSDActions>((ctx) => {
  const sessionId = ctx.store.sessionMetadata.id;
  const isFirst = ctx.store.sessionMetadata.isFirstSession;
  const isLast = ctx.store.sessionMetadata.isLastSession;
  const initialCurrentNodeId = ctx._private.initial.initialCurrentNodeId;
  const xstate = selectXStateBySessionId(ctx.store.sessionMetadata.id);
  const startNodeId = findStartNodeId(xstate) || '';
  const finalNodeId = findFinalNodeId(xstate) || '';

  const preferredStartingNodeId =
    shouldUseInitialNode(ctx) && initialCurrentNodeId && initialCurrentNodeId !== finalNodeId
      ? initialCurrentNodeId
      : onNextStep(startNodeId, ctx);

  const { nextMacroModuleId, nextModuleId, nextNodeId } = determineNextModule(
    preferredStartingNodeId,
    ctx
  );

  ctx._private.cbs?.sideEffects.onStart();
  if (ctx.store.sessionMetadata.mode === 'public') {
    const trackEvent = selectTrackEvent();
    trackEvent({
      payload: { isFirst, isLast, sessionId },
      type: initialCurrentNodeId ? 'RESUME_CONTENT_SESSION' : 'START_CONTENT_SESSION',
    });
  }

  ctx._private.cbs?.analytics.start({ type: 'CONTENT_SESSION_DURATION' });

  return updateSessionPlaybackByNodeId(ctx, {
    autoPlay: true,
    dir: 'next',
    nextMacroModuleId,
    nextModuleId,
    nextNodeId,
  });
});
