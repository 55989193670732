import type { FC } from 'react';
import { memo } from 'react';

interface Props {}

export const SplitLogoOR: FC<Props> = memo(() => {
  return (
    <svg
      fill="none"
      // height
      viewBox="0 0 205 397"
      width="205"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.08721e-07 94.4614C2.49996e-07 75.7785 5.54022 57.5151 15.9201 41.9808C26.2999 26.4466 41.0531 14.3393 58.314 7.18989C75.575 0.0405127 94.5683 -1.82977 112.892 1.81555C131.216 5.46087 148.047 14.4581 161.258 27.6694C174.468 40.8807 183.464 57.7127 187.109 76.0368C190.753 94.3609 188.881 113.354 181.731 130.615C174.58 147.875 162.472 162.627 146.937 173.006C131.402 183.385 113.138 188.924 94.4551 188.923C82.0506 188.923 69.7675 186.479 58.3073 181.732C46.847 176.985 36.4341 170.027 27.663 161.255C18.892 152.484 11.9346 142.07 7.18816 130.61C2.44172 119.149 -0.000824397 106.866 2.08721e-07 94.4614"
        fill="#FFAB00"
      />
      <path
        d="M84.7095 197.871C75.0328 203.896 66.6378 211.769 60.0038 221.039C53.3698 230.31 48.6268 240.796 46.0456 251.899C43.4643 263.002 43.0955 274.505 44.96 285.751C46.8245 296.997 50.8859 307.766 56.9123 317.442L105.964 396.212L151.487 367.869L102.429 289.092L204.281 225.674C198.254 215.998 190.381 207.604 181.111 200.97C171.84 194.337 161.354 189.594 150.251 187.014C139.148 184.433 127.645 184.065 116.399 185.93C105.153 187.795 94.3853 191.857 84.7095 197.883"
        fill="#FFA6E3"
      />
    </svg>
  );
});
