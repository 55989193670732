/* eslint-disable complexity */
import {
  arrayToMap,
  assertIsArray,
  assertType,
  findFinalNodeId,
  findStartNodeId,
  getItemAtRandom,
  getSignalingEndpoints,
  graphqlXStateToXState,
} from '@ours/utils';
import { assign } from 'xstate';
import * as awarenessProtocol from 'y-protocols/awareness';
import { WebrtcProvider } from 'y-webrtc';
import * as Y from 'yjs';

import { liveVideoIconNames } from '../../../features/LiveVideoMeeting/LiveMeetingToolbar/lib/videoIconImageLinks';
import { isMultiplayerSession } from '../../../lib/contentSessions/isMultiplayerSession';
import { sendOnUpdateXState } from '../../appMachine/externalActions/sendOnUpdateXState';
import { storeAddModulesById } from '../../appMachine/externalActions/storeAddModulesById';
import { normalizeGQLPurchaseSession } from '../../appMachine/lib/normalizeGQLPurchaseSession';
import { selectAppStage } from '../../appMachine/selectors/useAppStage';
import { store } from '../../store/_useStore';
import type { CSDActions, CSDContext, CSDPeer } from '../types';

// eslint-disable-next-line max-lines-per-function
export const onInit = assign<CSDContext, CSDActions>((_, event) => {
  assertType(event, 'INIT');
  assertIsArray(event.session.resolvedValues?.sessionXState);
  const isPublic = event.mode === 'public';
  const isMultiplayer = isMultiplayerSession(event.session);

  const doc = new Y.Doc();
  const awarenessDoc = new Y.Doc();
  const awareness = new awarenessProtocol.Awareness(awarenessDoc);

  const peer: CSDPeer = {
    fullName: event.peer.fullName,
    heartbeat: 1,
    icon: getItemAtRandom(liveVideoIconNames),
    sessionId: event.peer.sessionId,
    userId: event.peer.userId,
  };

  awareness.setLocalState(peer);

  let provider: WebrtcProvider | null = null;

  if (event.webrtc && isMultiplayer) {
    provider = new WebrtcProvider(
      event.webrtc.roomName,
      doc,
      // @ts-expect-error they are fixing this
      {
        awareness,
        filterBcConns: null,
        maxConns: 15,
        password: event.webrtc.password,
        signaling: [getSignalingEndpoints(selectAppStage())],
      }
    );
  }

  const xStateState = graphqlXStateToXState(event.session.resolvedValues.sessionXState);
  const startNodeId = findStartNodeId(xStateState) || '';

  store
    .getState()
    .app.send({ session: event.session, sessionId: event.session.id, type: 'ON_UPDATE_SESSION' });
  sendOnUpdateXState({ sessionId: event.session.id, xState: xStateState });
  storeAddModulesById([...(event.session.resolvedValues.modules || [])]);

  const purchasableSessions = (event?.session?.resolvedValues?.modules || []).flatMap(
    (m) => m.resolvedValues?.purchasableSessions || []
  );

  store.getState().app.send({
    sessions: normalizeGQLPurchaseSession(purchasableSessions),
    type: 'ON_PURCHASABLE_SESSIONS_ADD',
  });

  const initState: CSDContext = {
    _private: {
      cbs: {
        analytics: event.analytics,
        sideEffects: event.sideEffects,
      },
      initial: {
        initialCurrentNodeId: event.metadata.initialNodeId || undefined,
        isPurchaser: event.metadata.isPurchaser,
        localPeer: event.peer,
      },
      yjs: {
        _doc: doc,
        awareness,
        joiningArr: doc.getArray('joiningArr'),
        progress: doc.getMap('progress'),
        provider,
        sharedDrawing: doc.getMap('sharedDrawing'),
        sharedJournal: doc.getMap('sharedJournal'),
      },
    },

    store: {
      display: {
        autoPlay: false,
        currentMacroModuleId: undefined,
        currentModuleId: undefined,
        currentNodeId: startNodeId,
        direction: 'next',
        endNodeId: findFinalNodeId(xStateState),
        isShowingTranscriptPlayer: false,
        isTransitionComplete: false,
        likelyProgress: 0,
        shouldGatherAV: false,
        startNodeId,
        videoElement: null,
      },
      interaction: {
        isButtonDisabled: false,
        moduleResponseDraft: undefined,
      },
      membershipMetadata: {
        completedSessions: event.metadata.completedSessions,
      },
      peers: {
        peersById: {},
        sessionIdJoiningOrder: [],
      },
      permissions: {
        canBookmark: !isPublic,
        canGoBackHome: !isPublic,
        canSkipVerification: !!isPublic,
        hasGrantedAVPermissions: false,
      },
      sessionMetadata: {
        bookmarks: arrayToMap('moduleId', event.metadata.initialBookmarks || []),
        completedModuleIds: [...event.metadata.initialCompletedModuleIds],
        id: event.session.id,
        isFirstSession: event.metadata.isFirstSession,
        isLastSession: event.metadata.isLastSession,
        isMultiplayer,
        isWelcomeAssessment: event.metadata.onboardingSessionId === event.session.id,
        mode: event.mode,
        moduleResponses: event.metadata.initialModuleResponses || {},
        ratings: {},
        readOnly: event.metadata.previouslyCompleted,
      },
    },
  };
  return initState;
});
