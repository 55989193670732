import type { FC } from 'react';
import { memo } from 'react';

import type { HoverProps } from './HoverLink';
import { HoverLink } from './HoverLink';

export const BlueLink: FC<HoverProps> = memo(({ children, ...props }) => {
  // Note - inherits fontsize
  return (
    <HoverLink {...props} color="brand.blue" isBold>
      {children}
    </HoverLink>
  );
});
