import type { ModuleForAppContextFragment } from '@ours/utils';
import { assertType } from '@ours/utils';
import { produce } from 'immer';
import type { DeepWritable } from 'ts-essentials';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const onUpdateModule = assign<AppContext, AppActions>((ctx, ev) => {
  assertType(ev, 'ON_UPDATE_MODULE');

  return produce(ctx, (draft) => {
    draft.content.modulesById[ev.moduleId] = {
      ...draft.content.modulesById[ev.moduleId],
      ...(ev.module as DeepWritable<ModuleForAppContextFragment>),
      id: ev.moduleId,
    };
  });
});
