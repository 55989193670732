import type { BrandColors } from '../generated/operations';

type ColorType = {
  background: { light: string };
  black: string;
  brand: Record<BrandColors, string>;
  text: { primary: string; secondary: string };
  ui: {
    ctaShadows: string;
    error: string;
    gray: {
      '01': string;
      '02': string;
      '03': string;
      '04': string;
      '05': string;
      '06': string;
      '07': string;
      '08': string;
    };
    info: {
      blue: string;
      blueDark: string;
      green: string;
      greenDark: string;
      red: string;
      redDark: string;
      yellow: string;
      yellowDark: string;
    };
    success: string;
  };
  white: string;
};

export const Colors: ColorType = {
  background: {
    light: '#F9F9F9',
  },
  black: '#000000',
  brand: {
    beige: '#F0EAE5',

    blue: '#69A1F2',
    blueDisabled: '#B4D0F9',
    blueHover: '#2c58b8',

    orange: '#FF7760',
    orangeDisabled: '#FFBBAF',
    orangeHover: '#F95135',

    pink: '#FFA6E3',
    pinkDisabled: '#FFD3F1',
    pinkHover: '#FE6ACF',

    red: '#AB4A42',
    redDisabled: '#D5A4A0',
    redHover: '#7B1209',

    yellow: '#FFAB00',
    yellowDisabled: '#FFD580',
    yellowHover: '#FF9900',
  },
  text: { primary: '#000000', secondary: '#6A6A6A' },
  ui: {
    ctaShadows: '#532420',
    error: '#FF1100',
    gray: {
      '01': '#F7F7F7',
      '02': '#EEEEEE',
      '03': '#E2E2E2',
      '04': '#BBBBBB',
      '05': '#949494',
      '06': '#737373',
      '07': '#6A6A6A',
      '08': '#535353',
    },
    info: {
      blue: '#A7D4F3',
      blueDark: '#437DC3',
      green: '#BEEAB9',
      greenDark: '#51A248',
      red: '#F59596',
      redDark: '#C81B1C',
      yellow: '#F8D78C',
      yellowDark: '#CFA552',
    },
    success: '#00CF85',
  },
  white: '#FFFFFF',
};

/** IMPROVE: Share */
export const MarketingColors = {
  blue: '#69a1f2',
  darkred: '#ab4a42',
  gray: '#f0eae5',
  pink: '#ffa6e3',
  red: '#ff7760',
  white: '#fff',
  yellow: '#ffab00',
};
