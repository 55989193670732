import { isValidUUID } from '@ours/utils';

import { selectIsLocalMainPeer } from '../selectors/peers/selectIsLocalMainPeer';
import type { CSDActions, CSDContext } from '../types';

export const listenToYJSNodeId = (ctx: CSDContext, cb: (args: CSDActions) => void) => {
  const progress = ctx._private.yjs?.progress;

  const handleChange = () => {
    if (progress) {
      const prog = progress.toJSON();
      const nextNodeId = prog.nodeId;
      if (
        isValidUUID(nextNodeId) &&
        ctx.store.sessionMetadata.isMultiplayer &&
        !selectIsLocalMainPeer(ctx)
      ) {
        cb({ nextNodeId, type: 'SYNC_NEXT_NODE_ID' });
      }
    }
  };

  handleChange();
  progress?.observe(handleChange);

  return () => {
    progress?.unobserve(handleChange);
  };
};
