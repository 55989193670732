import { assign } from 'xstate';

import { determineNextModule } from '../helpers/determineNextModule';
import { onNextStep } from '../helpers/onNextStep';
import { updateSessionPlaybackByNodeId } from '../helpers/updateSessionPlaybackByNodeId';
import type { CSDActions, CSDContext } from '../types';

export const onNext = assign<CSDContext, CSDActions>((ctx) => {
  const { nextMacroModuleId, nextModuleId, nextNodeId } = determineNextModule(
    onNextStep(ctx.store.display.currentNodeId || '', ctx),
    ctx
  );
  const currentModuleId = ctx.store.display.currentModuleId;

  ctx._private.cbs?.sideEffects.onToNextModule({ moduleId: currentModuleId, nextNodeId });
  return updateSessionPlaybackByNodeId(ctx, {
    autoPlay: true,
    dir: 'next',
    moduleIdToAppend: currentModuleId,
    nextMacroModuleId,
    nextModuleId,
    nextNodeId,
  });
});
