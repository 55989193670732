import type { FieldPolicy } from '@apollo/client';

export const resolveIdField: (key: string, __typename: string) => FieldPolicy<unknown> = (
  key,
  __typename
) => ({
  read(existing, { readField, toReference }) {
    const id = readField<string | null | undefined>(key);
    if (!id) {
      return null;
    }
    const reference = toReference({ __typename, id });
    return reference;
  },
});
