import { appendAnalyticsData, cloneDeep, isValidUUID, unexpected } from '@ours/utils';
import { captureException } from '@sentry/nextjs';

import { selectMembership } from '../../state/store/lib/selectMembership';
import { selectModuleById } from '../../state/store/lib/selectModuleById';
import { selectSessionById } from '../../state/store/lib/selectSessionById';

export const hydrateAnalyticsEvent = (_payload: Record<string, any> | undefined) => {
  let payload = cloneDeep(_payload);
  if (!payload) {
    return payload;
  }

  try {
    if ('sessionId' in payload && isValidUUID(payload.sessionId)) {
      // eslint-disable-next-line max-depth
      try {
        payload = appendAnalyticsData(payload, selectSessionById(payload.sessionId), 'session.');
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }

    payload = appendAnalyticsData(payload, selectMembership(), 'membership.');

    if ('moduleId' in payload) {
      payload = appendAnalyticsData(payload, selectModuleById(payload.moduleId), 'module.');
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    captureException(unexpected({ name: 'FrontEndAnalyticHydrationFailedSilently' }));
  }

  return payload;
};
