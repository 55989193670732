import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const onAppHydrateContext = assign<AppContext, AppActions>((ctx, ev) => {
  assertType(ev, 'ON_APP_HYDRATE_CONTEXT');

  return produce(ctx, (d) => {
    d.trackEvent = ev.trackEvent;
    d.toast = ev.toast;
  });
});
