import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { VideoActions } from '../actionTypes';
import { videoSettingsLocalStorage } from '../externalActions/videoSettingsLocalStorage';
import type { VideoContext } from '../types';

export const onSetVideoQuality = assign<VideoContext, VideoActions>((ctx, ev) => {
  assertType(ev, 'SetVideoQuality');

  return produce(ctx, (d) => {
    videoSettingsLocalStorage.setByKey('playbackQuality', ev.quality);
    d.settings.playbackQuality = ev.quality;
  });
});
