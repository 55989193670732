import type { FC } from 'react';
import { memo } from 'react';

import { useEvent } from '../../../hooks/useEvent';
import { useAppActions } from '../../../state/appMachine/externalActions/useAppActions';

import { RequestedLink } from './RequestedLink';

export interface Props {
  email: string;
  onToLogin: () => void;
}

export const RequestedLinkContainer: FC<Props> = memo(({ email, onToLogin }) => {
  const { onLogin } = useAppActions();

  const onComplete = useEvent(async (val: string) => {
    onLogin({ code: val, email, type: 'ON_LOGIN' });
  });

  return (
    <RequestedLink email={email} loading={false} onComplete={onComplete} onToLogin={onToLogin} />
  );
});
