export const ServerEndpoints = {
  GraphQL: 'graphql',
  Slack: 'slack',
  Stripe: 'stripe',
  Zapier: 'zapier',
};

export const StatusCodes = {
  FORBIDDEN: 403,
  Redirect: 302,
  SERVER_ERROR: 500,
  SUCCESS: 200,
  UNAUTHORIZED: 401,
} as const;
export const CustomAppHeaders = {
  baggage: 'baggage',
  originHref: 'x-request-origin-href',
  sentryTrace: 'sentry-trace',
  waf: 'x-bypass-waf',
};
