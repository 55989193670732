import { Box, Center } from '@chakra-ui/react';
import { Button, FullpageLayout, useAppActions, useEvent } from '@ours/web-utils';
import type { FC } from 'react';
import { memo } from 'react';

export interface Props {}

export const WrongAppView: FC<Props> = memo(() => {
  const { onLogout } = useAppActions();
  const onClick = useEvent(() => {
    onLogout();
  });

  return (
    <FullpageLayout>
      <Center data-testid="no-auth" flexDir="column" gap="200" h="100vh">
        <Box>Your user role does not let you use this application.</Box>
        <Box>
          <Button as="button" onClick={onClick} type="button">
            Signout
          </Button>
        </Box>
      </Center>
    </FullpageLayout>
  );
});
