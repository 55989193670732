import { Box, Flex, FormControl, FormLabel, Text } from '@chakra-ui/react';
import { useInputState } from '@mantine/hooks';
import type { FC, FormEventHandler } from 'react';
import { memo } from 'react';

import { Button } from '../../../components/Buttons/Button';
import { Input } from '../../../components/Inputs/Input';
import { useEvent } from '../../../hooks/useEvent';
import { LOGIN_COMPONENT_TEXT } from '../content';

export interface LoginEmailInputProps {
  _onSubmit: (email: string) => Promise<void>;
  loading: boolean;
}

export const LoginEmailInput: FC<LoginEmailInputProps> = memo(({ _onSubmit, loading }) => {
  const [email, setEmail] = useInputState('');

  const onSubmit: FormEventHandler = useEvent(async (e) => {
    e.preventDefault();
    await _onSubmit(email);
    setEmail('');
  });

  return (
    <Flex as="form" data-testid="loginForm" flexDir="column" gap="300" onSubmit={onSubmit} w="full">
      <Text fontSize={{ base: '400', md: '500' }}>{LOGIN_COMPONENT_TEXT.SUB_HEADING}</Text>
      <Box maxW="full">
        <FormControl>
          <FormLabel>{LOGIN_COMPONENT_TEXT.LABEL}</FormLabel>
          <Input
            autoComplete="off"
            isRequired
            onChange={setEmail}
            placeholder={LOGIN_COMPONENT_TEXT.PLACEHOLDER}
            type="email"
            value={email}
          />
        </FormControl>
      </Box>
      <Box>
        <Button as="button" isLoading={loading} testId="loginFormButton" type="submit">
          {LOGIN_COMPONENT_TEXT.SUBMIT_TEXT}
        </Button>
      </Box>
    </Flex>
  );
});
