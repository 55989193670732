import { unexpected } from '../error/unexpected';
import type { Stage } from '../generated/operations';

const apiEndpoints = {
  dev: 'https://pp2vgt84l6.execute-api.us-west-2.amazonaws.com/dev/graphql',
  local: 'https://3u8gofrnxb.execute-api.us-west-2.amazonaws.com/local/graphql',
  production: 'https://64t6et3hz8.execute-api.us-west-2.amazonaws.com/production/graphql',
};

export const getApiEndpoint = (stageEnv: Stage): string => {
  const result = apiEndpoints[stageEnv];

  if (!result) {
    throw unexpected({ name: 'MissingAPIEndpoint' });
  }

  return result;
};
