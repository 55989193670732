import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { SessionEditorActions, SessionEditorContext } from '../types';

export const onFocusPanel = assign<SessionEditorContext, SessionEditorActions>((ctx, ev) => {
  assertType(ev, 'ON_FOCUS_PANEL');

  return produce(ctx, (d) => {
    d.display.focusedPanel = ev.panelName;
  });
});
