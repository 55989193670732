import { Box, Flex } from '@chakra-ui/react';
import { DownCaret } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';

import { FOOTER_CONTENT } from '../footerConstants';

import { FooterListItem } from './FooterListItem';

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

export const PinkFooterMobile: FC<Props> = memo(({ isOpen, toggle }) => {
  return (
    <Flex flexDir="column" fontSize="400" justifyContent="center" px="300" py="300" w="full">
      <Flex justifyContent="center" listStyleType="none" pb="100" w="full">
        <FooterListItem isBold text="Questions" />
      </Flex>
      <Flex as="nav" pb="200" w="full">
        <Flex
          as="ul"
          flexWrap="wrap"
          fontSize="300"
          gap="50"
          justifyContent="center"
          listStyleType="none"
          textAlign="center"
          w="full"
        >
          {FOOTER_CONTENT.LINKS.slice(1).map(({ isBold, link, text }) => (
            <FooterListItem isBold={isBold} key={text} link={link} text={text} />
          ))}
        </Flex>
      </Flex>

      <Box as="button" data-testid="PinkFooter.Toggle" gap="2" onClick={toggle} type="button">
        {FOOTER_CONTENT.DISCLOSURES}{' '}
        <Box display="inline-block" transform={!isOpen ? `rotate(0deg)` : `rotate(180deg)`}>
          <DownCaret />
        </Box>
      </Box>
    </Flex>
  );
});
