import type { ModalProps } from '@chakra-ui/react';
import { Box, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import type { FC, ReactElement } from 'react';
import { memo } from 'react';

import { CloseButton } from '../CloseButton/CloseButton';

const variants = {
  base: { content: {} },
  beige: { content: { bg: 'brand.beige' } },
} as const;

export interface ISimpleModalProps {
  blockScrollOnMount?: ModalProps['blockScrollOnMount'];
  children: ReactElement;
  includeCloseIcon?: boolean;
  isCentered?: boolean;
  isOpen: boolean;
  motionPreset?: ModalProps['motionPreset'];
  onClose: ModalProps['onClose'];
  scrollBehavior?: ModalProps['scrollBehavior'];
  size?: ModalProps['size'];
  variant?: keyof typeof variants;
}

export const SimpleModal: FC<ISimpleModalProps> = memo(
  ({
    blockScrollOnMount,
    children,
    includeCloseIcon = true,
    isCentered,
    isOpen,
    motionPreset,
    onClose,
    scrollBehavior,
    size,
    variant = 'base',
  }) => {
    const { content } = variants[variant];
    return (
      <Modal
        blockScrollOnMount={blockScrollOnMount}
        isCentered={isCentered}
        isOpen={isOpen}
        motionPreset={motionPreset}
        onClose={onClose}
        scrollBehavior={scrollBehavior}
        size={size || 'lg'}
      >
        <ModalOverlay />
        <ModalContent {...content}>
          <ModalBody>
            {includeCloseIcon ? (
              <Box pos="absolute" right="20px">
                <CloseButton onClick={onClose} />
              </Box>
            ) : null}
            {isOpen ? children : null}
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
);
