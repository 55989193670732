import { Flex } from '@chakra-ui/react';
import { Border } from '@ours/utils';
import type { FC, FormEventHandler, ReactNode } from 'react';
import { memo } from 'react';

interface Props {
  children: ReactNode;
  onSubmit?: FormEventHandler;
  testId?: string;
}

export const LoginWhiteBox: FC<Props> = memo(({ children, onSubmit, testId }) => {
  return (
    <Flex
      alignItems="center"
      bg="white"
      border={Border}
      borderTopLeftRadius="20px"
      borderTopRightRadius="20px"
      data-testid={testId}
      flexDir="column"
      gap={{ base: '20px', md: '30px' }}
      h={{ base: '337px', md: '393px' }}
      justifyContent="center"
      maxW="full"
      onSubmit={onSubmit}
      position="relative"
      px={{ base: '200', md: '130px' }}
      textAlign="center"
      w={{ base: 'full', md: '650px' }}
    >
      {children}
    </Flex>
  );
});
