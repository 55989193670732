import { store } from '../../store/_useStore';
import { isValidAnswer } from '../guards/isValidAnswer';
import type { CSDContext } from '../types';

export const onInvalidAnswerToast = (ctx: CSDContext) => {
  const title = isValidAnswer(ctx);

  if (title === true) {
    return;
  }
  store.getState().app.state.context.toast({ title: title || 'Invalid answer' });
};
