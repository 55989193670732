import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const onTabVisibility = assign<AppContext, AppActions>((ctx, ev) => {
  assertType(ev, 'TAB_VISIBILITY_CHANGE');

  return produce(ctx, (draft) => {
    draft.device.isVisible = ev.isVisible;
  });
});
