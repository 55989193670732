import { Box } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

import { buttonSizes } from '../buttonVariants';

export interface ButtonDropShadowProps {
  disabled: boolean;
  size: keyof typeof buttonSizes;
}

export const ButtonDropShadow: FC<ButtonDropShadowProps> = memo(({ disabled, size }) => {
  const { boxShadowProps, ...sizeProps } = buttonSizes[size];
  return disabled ? null : (
    <Box
      bg="#532420"
      borderRadius={sizeProps.borderRadius}
      bottom="-3px"
      h="full"
      pos="absolute"
      right="-3px"
      w="full"
      {...boxShadowProps}
    />
  );
});
