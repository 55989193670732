import { unexpected } from '../error/unexpected';

import { isArray } from './isArray';

type Assert = (str: unknown, errMsg?: string) => asserts str is Array<any>;

export const assertIsArray: Assert = (str, errMsg) => {
  if (!str || !isArray(str)) {
    throw unexpected({ name: errMsg || 'ValueIsNotAnArray' });
  }
};
