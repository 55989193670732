import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { CSDActions, CSDContext } from '../types';

export const onUpdatePeers = assign<CSDContext, CSDActions>((ctx, ev) => {
  assertType(ev, 'SYNC_PEERS');

  return produce(ctx, (d) => {
    d.store.peers.peersById = ev.peers;
  });
});
