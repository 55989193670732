import { Global, css } from '@emotion/react';
import type { FC } from 'react';
import { memo } from 'react';
import './swiper.css';

export const fontsArray = [
  {
    name: 'NeueMontreal-Light.otf',
    style: 'normal',
    weight: '300',
  },
  {
    name: 'NeueMontreal-LightItalic.otf',
    style: 'italic',
    weight: '300',
  },
  {
    name: 'NeueMontreal-Regular.otf',
    style: 'normal',
    weight: '400',
  },
  {
    name: 'NeueMontreal-RegularItalic.otf',
    style: 'italic',
    weight: '400',
  },
  {
    name: 'NeueMontreal-Medium.otf',
    style: 'normal',
    weight: '500',
  },
  {
    name: 'NeueMontreal-MediumItalic.otf',
    style: 'italic',
    weight: '500',
  },
  {
    name: 'NeueMontreal-Bold.otf',
    style: 'normal',
    weight: '600',
  },
  {
    name: 'NeueMontreal-BoldItalic.otf',
    style: 'italic',
    weight: '600',
  },
];

const fontCss = css`
  ${fontsArray
    .map(
      (font) => `
        @font-face {
          font-family: 'NeueMontreal';
          src: url('/shared/fonts/${font.name}');
          font-style: ${font.style};
          font-weight: ${font.weight};
          font-display: swap;
        }
      `
    )
    .join('\n')}
`;

export const GlobalStyles: FC<{ includeFonts?: boolean }> = memo(({ includeFonts }) => {
  return (
    <Global
      styles={`
      ${includeFonts ? fontCss.styles : ''}
    .sb-show-main.sb-main-fullscreen,#root {
      height:100%;
      display: flex;
      flex-direction: column;
    }
    html {
      height: 100%;
    }
    body {
      min-height: 100%;
      display: flex;
    }
    img, svg {
      max-width: 100%;
    }
    div#__next {
      width: 100%;
      display: flex;
    }`}
    />
  );
});
