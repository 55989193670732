import type { MutateModuleInput } from '@ours/utils';
import { addNode, assertType, uuid, xStateToGraphQLXState } from '@ours/utils';
import { gql } from 'graphql-tag';
import { produce } from 'immer';
import { assign } from 'xstate';

import type {
  AddModuleToSessionMutation,
  AddModuleToSessionMutationVariables,
} from '../../../generated/custom-hooks';
import { AddModuleToSessionDocument } from '../../../generated/custom-hooks';
import { getApolloClient } from '../../../lib/apollo/getApolloClient';
import { sendOnUpdateXState } from '../../appMachine/externalActions/sendOnUpdateXState';
import { store } from '../../store/_useStore';
import { selectXStateBySessionId } from '../../store/lib/selectXStateBySessionId';
import { getSrcAndTarget } from '../helpers/getSrcAndTarget';
import type { SessionEditorActions, SessionEditorContext } from '../types';

gql`
  mutation AddModuleToSession(
    $id: UUID!
    $modules: [MutateModuleInput!]!
    $xState: [XStateInput!]!
  ) {
    createModules(modules: $modules) {
      ...ModuleFields
    }

    putSessionXState(id: $id, xState: $xState) {
      id
      resolvedValues {
        sessionXState {
          ...XState
        }
      }
    }
  }
`;
export const onInsertNode = assign<SessionEditorContext, SessionEditorActions>((ctx, ev) => {
  assertType(ev, 'ON_INSERT_NODE');
  const module = {
    buttonText: 'Next',
    id: uuid(),
    interactionKind: 'Button',
    layout: 'InteractionOnly',
    openEndedPrompt: 'New Module',
  } satisfies MutateModuleInput;
  const sessionId = ctx.store.sessionId;
  const moduleId = module.id;
  const staleXState = selectXStateBySessionId(ctx.store.sessionId);
  const nodeId = uuid();
  const xState = addNode(
    { data: { moduleId }, id: nodeId, type: 'module' },
    staleXState,
    getSrcAndTarget(ctx)
  );

  const client = getApolloClient();
  client.mutate<AddModuleToSessionMutation, AddModuleToSessionMutationVariables>({
    mutation: AddModuleToSessionDocument,
    variables: {
      id: sessionId,
      modules: [module],
      xState: xStateToGraphQLXState(xState),
    },
  });
  store.getState().app.send({ module, moduleId, type: 'ON_UPDATE_MODULE' });
  sendOnUpdateXState({ sessionId, xState });

  return produce(ctx, (d) => {
    d.display.selectedNodeIds = [nodeId];
    d.display.focusedNodeId = nodeId;
  });
});
