import { append, assertNotNil, assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const onPurchasedSessionAdd = assign<AppContext, AppActions>((ctx, ev) => {
  assertType(ev, 'ON_PURCHASED_SESSION_ADD');

  return produce(ctx, (draft) => {
    assertNotNil(draft.content.membership, 'MissingMembershipIn.onPurchasedSessionAdd');
    draft.content.membership.purchasedSessionIds = append(
      ev.sessionId,
      draft.content.membership.purchasedSessionIds
    );
  });
});
