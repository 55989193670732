import type { FC } from 'react';
import { memo } from 'react';
import ReactMarkDown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

import { A } from './lib/A';
import { H1 } from './lib/H1';
import { H2 } from './lib/H2';
import { Li } from './lib/Li';
import { Ol } from './lib/Ol';
import { P } from './lib/P';
import { Ul } from './lib/Ul';

export interface MarkdownProps {
  value: string;
}
const remarkPlugins = [remarkGfm, remarkBreaks];

export const MarkDown: FC<MarkdownProps> = memo(({ value }) => {
  return (
    <ReactMarkDown
      components={{
        a: A,
        h1: H1,
        h2: H2,
        li: Li,
        ol: Ol,
        p: P,
        ul: Ul,
      }}
      remarkPlugins={remarkPlugins}
    >
      {value}
    </ReactMarkDown>
  );
});
