import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { LiveSessionActions, LiveSessionContext } from '../types';

export const onLocalVideoMinimizeToggle = assign<LiveSessionContext, LiveSessionActions>(
  (ctx, ev) => {
    assertType(ev, 'ON_LOCAL_VIDEO_MINIMIZE_TOGGLE');

    return produce(ctx, (d) => {
      d.store.display.localPeerVideo.isMinimized = !ctx.store.display.localPeerVideo.isMinimized;
    });
  }
);
