import { Box } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

interface ButtonProps {
  as: 'button';
  children: ReactNode;
  onClick: () => void;
  type: 'button' | 'submit';
}
interface LinkProps {
  as: 'a';
  children: ReactNode;
  href: string;
  target?: '_blank';
}

export type HoverProps = (ButtonProps | LinkProps) & {
  color?: string;
  isBold?: boolean;
};

export const HoverLink: FC<HoverProps> = memo(({ as, children, color, isBold, ...props }) => {
  const rel = as === 'a' ? 'noopener noreferrer' : undefined;
  // Note - inherits fontsize
  return (
    <Box
      _hover={{ textDecorationColor: 'inherit' }}
      color={color}
      fontWeight={isBold ? '600' : 'inherit'}
      textDecoration="underline"
      textDecorationColor="transparent"
      transition="text-decoration .25s ease"
      {...props}
      as={as}
      rel={rel}
    >
      {children}
    </Box>
  );
});
