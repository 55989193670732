import { CustomError } from './CustomError';

export class Unexpected extends CustomError {
  constructor({
    code = 'UNEXPECTED',
    message = 'An unexpected error occurred',
    name = 'Unexpected',
  }: IUnexpected) {
    super({ code, message, name });
  }
}

export interface IUnexpected {
  code?: string;
  message?: string;
  name?: string;
}
