import { unexpected } from '../error/unexpected';

import { isNull } from './isNull';
import { isUndefined } from './isUndefined';

type Assert = <T>(val: T | null | undefined, errMsg: string) => asserts val is T;
export const assertNotNil: Assert = (val, errMsg) => {
  if (isNull(val) || isUndefined(val)) {
    throw unexpected({ name: errMsg || 'ValueIsNil' });
  }
};
