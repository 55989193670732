/* eslint-disable react/no-unused-prop-types */
import type { InputProps } from '@chakra-ui/react';
// eslint-disable-next-line no-restricted-imports
import { Input as ChakraInput, Skeleton } from '@chakra-ui/react';
import { Colors } from '@ours/utils';
import { forwardRef, memo } from 'react';

import type { Size } from './sizeLookup';
import { sizeLookup } from './sizeLookup';

const variants = {
  base: {
    bg: 'brand.beige',
    borderColor: 'black',
    focusColor: Colors.brand.blue,
    invalidColor: Colors.ui.error,
  },
  white: {
    bg: 'white',
    borderColor: 'black',
    focusColor: Colors.brand.blue,
    invalidColor: Colors.ui.error,
  },
} as const;

export interface OursInputProps extends Omit<InputProps, 'size'> {
  loading?: boolean;
  size?: Size;
  variant?: keyof typeof variants;
}

export const Input = memo(
  forwardRef<any, OursInputProps>(({ loading, variant = 'base', ...props }, ref) => {
    const { bg, borderColor, focusColor, invalidColor } = variants[variant];
    const { h } = sizeLookup[props.size || 'md'];
    if (loading) {
      return <Skeleton h="55px" />;
    }

    return (
      <ChakraInput
        {...props}
        _focus={{ borderColor: focusColor }}
        _hover={{ borderColor }}
        _invalid={{ borderColor: invalidColor }}
        bg={bg}
        borderBottomLeftRadius="8px"
        borderBottomRightRadius="0px"
        borderColor={borderColor}
        borderTopLeftRadius="0px"
        borderTopRightRadius="8px"
        borderWidth={{ base: '1px', md: '2px' }}
        h={h}
        ref={ref}
        w="full"
      />
    );
  })
);
