import type { Maybe } from '@ours/types';
import type { AppMachineMutation } from '@ours/web-utils';

const sessionProgressesIsUsers = (
  partialProgress: { studentId?: Maybe<string> },
  userId: string
): boolean => {
  // this is an edge case and should almost never be true in production data
  if (!partialProgress.studentId) {
    return true;
  }

  return partialProgress.studentId === userId;
};

export const determineIsOnboardingComplete = (data: AppMachineMutation): boolean => {
  const membership = data.onUserLogin?.latestMembership;
  const onboardingSessionId = membership?.onboardingSessionId;
  const user = data.onUserLogin!;
  if (!onboardingSessionId) {
    return true;
  }
  const onboardingProgress = (membership?.sessionProgresses || []).find(
    (progress) =>
      progress.sessionId === onboardingSessionId && sessionProgressesIsUsers(progress, user.id)
  );

  return !!onboardingProgress?.completed;
};
