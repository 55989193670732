import type { Maybe } from '@ours/types';

import type { InteractionKind, ModuleResponseInput, ModuleTags } from '../generated/operations';
import { isFinite } from '../lang/isFinite';
import { toNumber } from '../lang/toNumber';

type Args = Pick<ModuleResponseInput, 'value' | 'valueDescription'>;
type Result = {
  answerLength: number | undefined;
  mcValue: string | undefined;
  numberRating: number | undefined;
  textResponse?: string | undefined;
};
type ToTrackingValue = (
  args: Args,
  module: Maybe<{ interactionKind?: Maybe<InteractionKind>; tags?: Maybe<readonly ModuleTags[]> }>
) => Result;

const defaultLookup: ToTrackingValue = (args, module) => ({
  answerLength: args.value?.length,
  mcValue: undefined,
  numberRating: undefined,
  textResponse: module?.tags?.includes('Feedback') ? args.value : undefined,
});
const lookup: Record<InteractionKind, ToTrackingValue> = {
  AudioRecording: defaultLookup,
  Button: defaultLookup,
  Camera: defaultLookup,
  Discussion: defaultLookup,
  Downloadable: defaultLookup,
  Drawing: defaultLookup,
  ImageUpload: defaultLookup,
  InAppPurchase: defaultLookup,
  Input: defaultLookup,
  Journal: defaultLookup,
  JournalSeparate: defaultLookup,
  MacroModules: defaultLookup,
  MultipleChoice: (args) => ({
    answerLength: undefined,
    mcValue: args?.valueDescription,
    numberRating: undefined,
  }),
  NumberSelect: (args) => {
    const parsed = toNumber(args?.value);
    const numberRating = isFinite(parsed) ? parsed : undefined;
    return { answerLength: undefined, mcValue: undefined, numberRating };
  },
  OpenEndedText: defaultLookup,
  Photobooth: defaultLookup,
  PricingBlock: defaultLookup,
  QuizMatching: defaultLookup,
  Scheduler: defaultLookup,
  SentenceBuilder: defaultLookup,
  TrueFalseButton: defaultLookup,
  VideoRecording: defaultLookup,
};

export const toTrackingValueFromInteractionKind: ToTrackingValue = (args, module) => {
  return lookup[module?.interactionKind || 'Button'](args, module);
};
