import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const onInit = assign<AppContext, AppActions>((ctx, ev) => {
  assertType(ev, 'ON_INIT');

  return produce(ctx, (draft) => {
    draft.app = {
      appKind: ev.appKind,
      stage: ev.stage,
      version: ev.version,
    };
  });
});
