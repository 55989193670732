import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { CSDActions, CSDContext } from '../types';

export const onGrantAVPermissions = assign<CSDContext, CSDActions>((ctx, event) => {
  assertType(event, 'ON_GRANT_AV_PERMISSIONS');

  return produce(ctx, (d) => {
    d.store.display.shouldGatherAV = false;
    d.store.permissions.hasGrantedAVPermissions = event.hasGrantedAVPermissions;
  });
});
