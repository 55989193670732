import { COMPANY_MAIL_TO_LINK, LINKS } from '@ours/utils';

export const FOOTER_CONTENT = {
  DISCLOSURES: 'Important Disclosures',
  LEGAL: `By using this website you agree to our Terms of Use and Privacy Policy. No information here constitutes medical advice and no client / therapist relationship is being formed. Further, our programs do not currently constitute therapy and are closer to coaching or psychoeducation. You should not delay treatment for any reason and dial 911 for any emergency or call the National Suicide Prevention Lifeline at 1-800-273-TALK (8255) or [suicidepreventionlifeline.org](https://suicidepreventionlifeline.org) or contact the National Domestic Violence Hotline at [https://www.thehotline.org/](https://www.thehotline.org/)`,
  LINKS: [
    { isBold: true, text: 'Questions?' },
    { link: COMPANY_MAIL_TO_LINK, text: 'Contact Us' },
    { link: LINKS.TERMS_OF_SERVICE, text: 'Terms' },
    { link: LINKS.PRIVACY_POLICY, text: 'Privacy Policy' },
  ],
};
