import { produce } from 'immer';
import { assign } from 'xstate';

import type { CSDActions, CSDContext } from '../types';

export const onTransitionComplete = assign<CSDContext, CSDActions>((ctx) => {
  return produce(ctx, (d) => {
    d.store.display.isTransitionComplete = true;
  });
});
