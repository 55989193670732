import { Flex } from '@chakra-ui/react';
import localFont from '@next/font/local';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

export interface GlobalFontsProps {
  children: ReactNode;
}

const customFont = localFont({
  src: [
    {
      path: './../../../../sharedPublic/fonts/NeueMontreal-Light.otf',
      style: 'normal',
      weight: '300',
    },
    {
      path: './../../../../sharedPublic/fonts/NeueMontreal-LightItalic.otf',
      style: 'italic',
      weight: '300',
    },
    {
      path: './../../../../sharedPublic/fonts/NeueMontreal-Regular.otf',
      style: 'normal',
      weight: '400',
    },
    {
      path: './../../../../sharedPublic/fonts/NeueMontreal-RegularItalic.otf',
      style: 'italic',
      weight: '400',
    },
    {
      path: './../../../../sharedPublic/fonts/NeueMontreal-Medium.otf',
      style: 'normal',
      weight: '500',
    },
    {
      path: './../../../../sharedPublic/fonts/NeueMontreal-MediumItalic.otf',
      style: 'italic',
      weight: '500',
    },
    {
      path: './../../../../sharedPublic/fonts/NeueMontreal-Bold.otf',
      style: 'normal',
      weight: '600',
    },
    {
      path: './../../../../sharedPublic/fonts/NeueMontreal-BoldItalic.otf',
      style: 'italic',
      weight: '600',
    },
  ],
});

typeof document !== 'undefined' && document.body.classList.add(customFont.className);
export const GlobalFonts: FC<GlobalFontsProps> = memo(({ children }) => {
  return (
    // NOTE: 100% required as this maybe outside context of chakra provider
    <Flex className={customFont.className} flexDir="column" h="100%" w="100%">
      {children}
    </Flex>
  );
});
