import { _useStore } from '../../store/_useStore';
import type { StoreState } from '../../store/types';

const select = (s: StoreState) => {
  if (s.app.state.value === 'authenticated') {
    return 'authenticated';
  }

  if (s.app.state.value === 'unauthenticated') {
    return 'unauthenticated';
  }
  return 'loading';
};

export const useAppStateValue = () => {
  return _useStore(select);
};
