import type { CSDActions, CSDContext } from '../types';

export const listenToYJSJoiningArr = (ctx: CSDContext, cb: (args: CSDActions) => void) => {
  const joiningArr = ctx._private.yjs?.joiningArr;

  joiningArr?.push([ctx._private.initial.localPeer.sessionId]);

  const handleChange = () => {
    joiningArr && cb({ type: 'SYNC_JOINING_ORDER', value: joiningArr.toJSON() });
  };
  joiningArr?.observe(handleChange);

  return () => {
    joiningArr?.unobserve(handleChange);
  };
};
