import type { XState } from '../generated/operations';
import { entries } from '../lang/entries';

import type { IXStateState } from './xStateConstants';

export const xStateToGraphQLXState = (xState: IXStateState): Omit<XState, '__typename'>[] => {
  return entries(xState).map(([id, { data, on, type }]) => {
    return { data: data || null, id, on: on || null, type };
  });
};
