import { assertType } from '@ours/utils';
import type { TransitionConfigOrTarget } from 'xstate';

import { selectNodeById } from '../selectors/xstate/selectNodeById';
import type { CSDActions, CSDContext } from '../types';

export const SYNC_NEXT_NODE_ID: TransitionConfigOrTarget<CSDContext, any> = [
  {
    actions: 'onSyncNextNodeId',
    cond: (ctx, ev: CSDActions) => {
      assertType(ev, 'SYNC_NEXT_NODE_ID');
      return selectNodeById(ev.nextNodeId, ctx)?.type === 'final';
    },
  },
];
