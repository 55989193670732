import { selectIsLocalMainPeer } from '../selectors/peers/selectIsLocalMainPeer';
import { selectIsValidNumberOfPeers } from '../selectors/peers/selectIsValidNumberOfPeers';
import type { CSDContext } from '../types';

export const canProgressSession = (ctx: CSDContext): boolean => {
  if (ctx.store.sessionMetadata.isMultiplayer === false) {
    return true;
  }

  return selectIsLocalMainPeer(ctx) && selectIsValidNumberOfPeers(ctx);
};
