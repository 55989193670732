/* eslint-disable complexity */
import type { ModuleResponseInput } from '@ours/utils';
import { unexpected } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import { performClientSideSideEffects } from '../../../lib/contentSessions/performClientSideSideEffects';
import { selectTrackEvent } from '../../appMachine/selectors/selectTrackEvent';
import { selectMembershipId } from '../../store/lib/selectMembershipId';
import { selectModuleById } from '../../store/lib/selectModuleById';
import { selectSessionById } from '../../store/lib/selectSessionById';
import { trackModuleResponseCompletion } from '../helpers/trackModuleResponseCompletion';
import type { CSDActions, CSDContext } from '../types';

export const onSaveAnswer = assign<CSDContext, CSDActions>((ctx) => {
  const moduleId = ctx.store.display.currentModuleId;
  const membershipId = selectMembershipId();
  const trackEvent = selectTrackEvent();
  const module = moduleId ? selectModuleById(moduleId) : undefined;
  const update = ctx.store.interaction.moduleResponseDraft;
  const session = selectSessionById(ctx.store.sessionMetadata.id);

  if (!trackEvent) {
    throw unexpected({ name: 'MissingTrackEventInSaveAnswer' });
  }

  if (!moduleId) {
    throw unexpected({ name: 'MissingModuleId' });
  }

  if (!module) {
    throw unexpected({ name: 'MissingModule' });
  }

  if (!update) {
    // eslint-disable-next-line no-console
    console.log(ctx);
    throw unexpected({ name: 'MissingModuleResponse' });
  }

  const moduleResponse: ModuleResponseInput = {
    label: update.label || '',
    mediaCaption: update.mediaCaption || '',
    s3Key: update.s3Key || '',
    value: update.value || '',
    valueDescription: update.valueDescription || update.value || '',
  };
  const sideEffects = module.sideEffectActions || [];
  ctx._private.cbs?.sideEffects.onAddModuleResponse({ moduleId, moduleResponse, sideEffects });
  if (ctx.store.sessionMetadata.mode === 'public') {
    trackModuleResponseCompletion({
      membershipId,
      module,
      moduleResponse,
      session,
      trackEvent,
    });
  }
  performClientSideSideEffects({ sideEffects, trackEvent, value: update.value });

  return produce(ctx, (draft) => {
    draft.store.sessionMetadata.moduleResponses[moduleId] = {
      ...update,
      ...moduleResponse,
    };
  });
});
