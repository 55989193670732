import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { LiveSessionActions, LiveSessionContext } from '../types';

export const onChangeDrawer = assign<LiveSessionContext, LiveSessionActions>((ctx, ev) => {
  assertType(ev, 'ON_CHANGE_DRAWER');

  return produce(ctx, (draft) => {
    draft.store.display.drawer = ev.state;
  });
});
