import type { MachineConfig } from 'xstate';
import { createMachine } from 'xstate';

import type { CardDeckActions } from './actionTypes';
import { onAnswerCard } from './actions/onAnswerCard';
import { onCompleteOnboarding } from './actions/onCompleteOnboarding';
import { onInit } from './actions/onInit';
import { onPassCard } from './actions/onPassCard';
import { onPlayDeck } from './actions/onPlayDeck';
import { onPlayDifferentDeck } from './actions/onPlayDifferentDeck';
import { onReset } from './actions/onReset';
import { onSelectCardDeck } from './actions/onSelectCardDeck';
import { onSetActiveIdx } from './actions/onSetActiveIdx';
import type { CardDeckContext } from './types';
import { cardDeckInitContext } from './types';

const cartSchema: MachineConfig<CardDeckContext, any, CardDeckActions> = {
  context: cardDeckInitContext,
  description: '',
  id: 'cardDeckMachine',
  initial: 'idle',
  on: {
    RESET: { target: 'idle' },
  },
  predictableActionArguments: true,
  states: {
    idle: {
      on: {
        INIT: { actions: ['onInit'], target: 'selectDeck' },
      },
    },
    playDeck: {
      on: {
        ON_ANSWER_ON_CARD: { actions: ['onAnswerCard'] },
        ON_COMPLETE_ONBOARDING: { actions: ['onCompleteOnboarding'] },
        ON_PASS_ON_CARD: { actions: ['onPassCard'] },
        ON_PLAY_DIFFERENT_DECK: { actions: ['onPlayDifferentDeck'], target: 'selectDeck' },
        ON_SET_ACTIVE_IDX: { actions: ['onSetActiveIdx'] },
      },
    },
    selectDeck: {
      on: {
        ON_SELECT_CARD_DECK: { actions: 'onSelectCardDeck' },
        ON_TO_PLAY_DECK: { actions: ['onPlayDeck'], cond: 'canPlayDeck', target: 'playDeck' },
      },
    },
  },
};

export const cardDeckMachine = createMachine(cartSchema, {
  actions: {
    onAnswerCard,
    onCompleteOnboarding,
    onInit,
    onPassCard,
    onPlayDeck,
    onPlayDifferentDeck,
    onReset,
    onSelectCardDeck,
    onSetActiveIdx,
  },
  guards: {
    canPlayDeck: (ctx) => !!ctx.selectedDeckId,
  },
});
