import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const onUpdateEvent = assign<AppContext, AppActions>((ctx, ev) => {
  assertType(ev, 'ON_UPDATE_EVENT');

  return produce(ctx, (draft) => {
    draft.content.eventsById[ev.eventId] = {
      ...draft.content.eventsById[ev.eventId],
      ...ev.event,
      id: ev.eventId,
    };
  });
});
