import { produce } from 'immer';
import { assign } from 'xstate';

import type { CardDeckActions } from '../actionTypes';
import type { CardDeckContext } from '../types';
import { cardDeckInitContext } from '../types';

export const onReset = assign<CardDeckContext, CardDeckActions>((ctx) => {
  return produce(ctx, (d) => {
    d = {
      ...cardDeckInitContext,
    };
    return d;
  });
});
