import type { Maybe } from '@ours/types';

export const safelyParseJSON = <T>(probablyJSON: Maybe<string>): T | undefined => {
  if (!probablyJSON) {
    return;
  }

  try {
    return JSON.parse(probablyJSON);
  } catch (_error) {
    //
    return undefined;
  }
};
