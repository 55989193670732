import { produce } from 'immer';
import { assign } from 'xstate';

import type { SessionEditorActions, SessionEditorContext } from '../types';

export const onReset = assign<SessionEditorContext, SessionEditorActions>((ctx) => {
  return produce(ctx, (draft) => {
    draft.store.sessionId = '';
  });
});
