import { assertIsObject, toContentSession, unexpected } from '@ours/utils';
import Router from 'next/router';

import type {
  AppMachineMutation,
  AppMachineMutationVariables,
} from '../../../generated/custom-hooks';
import { AppMachineDocument } from '../../../generated/custom-hooks';
import { getApolloClient } from '../../../lib/apollo/getApolloClient';
import type { AppActions } from '../actionTypes';
import { determineIsOnboardingComplete } from '../lib/determineIsOnboardingComplete';
import type { AppContext } from '../types';

import { listenToAuthedAnalytics } from './listenToAuthedAnalytics';

export const onInitAuthedData = async (ctx: AppContext, cb: (args: AppActions) => void) => {
  assertIsObject(ctx.app);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { data } = await getApolloClient().mutate<AppMachineMutation, AppMachineMutationVariables>({
    mutation: AppMachineDocument,
    variables: { timezone },
  });

  cb({ data, type: 'ON_SET_MEMBERSHIP_INFORMATION' });
  const appKind = ctx.app.appKind;

  const version = ctx.app.version;
  const user = data?.onUserLogin;
  const membership = data?.onUserLogin?.latestMembership;
  const userId = user?.id;
  const sessionId = membership?.onboardingSessionId;
  const membershipId = membership?.id;
  listenToAuthedAnalytics(version, data?.onUserLogin);

  if (appKind === 'admin' && membershipId) {
    // eslint-disable-next-line no-console
    console.log({ userId });
    throw unexpected({ name: 'AdminAppUserCannotHaveMembership' });
  }

  if (!determineIsOnboardingComplete(data!)) {
    Router.replace(toContentSession({ membershipId, sessionId, title: 'onboarding' }));
  }
};
