import { isEqual } from '@ours/utils';

import { _useStore } from '../../../store/_useStore';
import type { StoreState } from '../../../store/types';

import { selectIsLocalMainPeer } from './selectIsLocalMainPeer';

const select = (arg: StoreState) => {
  return selectIsLocalMainPeer(arg.csd.state.context);
};
export const useIsLocalMainPeer = () => {
  return _useStore(select, isEqual);
};
