import { paymentMethodFactory } from '@ours/factories';
import { unexpected } from '@ours/utils';
import type { MachineConfig } from 'xstate';
import { createMachine } from 'xstate';

import type {
  PurchaseSessionsForMembershipMutation,
  PurchaseSessionsForMembershipMutationVariables,
} from '../../generated/custom-hooks';
import { PurchaseSessionsForMembershipDocument } from '../../generated/custom-hooks';
import { getApolloClient } from '../../lib/apollo/getApolloClient';
import { selectMembershipId } from '../store/lib/selectMembershipId';

import type { CartActions } from './actionTypes';
import { onAdCompletedCheckoutListener } from './actions/onAdCompletedCheckoutListener';
import { onAddBillingLink } from './actions/onAddBillingLink';
import { onChangeBillingMethod } from './actions/onChangeBillingMethod';
import { onClearCart } from './actions/onClearCart';
import { onInit } from './actions/onInit';
import { onOpenBillingCenter } from './actions/onOpenBillingCenter';
import { onOpenBillingDrawer } from './actions/onOpenBillingDrawer';
import { onPurchaseError } from './actions/onPurchaseError';
import { onPurchaseSuccess } from './actions/onPurchaseSuccess';
import { onResetState } from './actions/onResetState';
import { onSyncBillingMethods } from './actions/onSyncBillingMethods';
import { onToggleBillingDrawer } from './actions/onToggleBillingDrawer';
import { onToggleSessionToCart } from './actions/onToggleSessionToCart';
import { syncCustomersBillingInfo } from './listeners/syncCustomersBillingInfo';
import type { CartContext } from './types';

const cartSchema: MachineConfig<CartContext, any, CartActions> = {
  context: {
    billingCenterLink: undefined,
    billingDrawerOpen: false,
    billingMethods: [],
    errorMessage: undefined,
    mode: 'preview',
    onCloseCompletedCheckout: undefined,
    purchaseLocation: '',
    selectedBillingMethodId: undefined,
    sessionIdsInCart: [],
  },
  description: '',
  id: 'cartMachine',
  initial: 'idle',
  predictableActionArguments: true,
  states: {
    checkoutSuccess: {
      on: {
        ON_CLOSE_BILLING_DRAWER: { actions: 'onResetState', target: 'init' },
      },
    },
    idle: {
      on: {
        INIT: { actions: 'onInit', target: 'init' },
      },
    },
    init: {
      invoke: {
        src: (ctx) => (cb: (args: CartActions) => void) => {
          if (ctx.mode === 'preview') {
            cb({
              methods: paymentMethodFactory.buildList(4),
              type: 'ON_SYNC_BILLING_METHODS',
            });
            return;
          }

          const cleanup = syncCustomersBillingInfo(cb);
          return () => {
            cleanup();
          };
        },
      },
      on: {
        ON_ADD_BILLING_LINK: { actions: 'onAddBillingLink' },
        ON_ADD_COMPLETED_CHECKOUT_LISTENER: { actions: 'onAdCompletedCheckoutListener' },
        ON_CHANGE_BILLING_METHOD: { actions: 'onChangeBillingMethod' },
        ON_CHECKOUT: { target: 'processingCheckout' },
        ON_CLEAR_CART: { actions: 'onClearCart', target: 'idle' },
        ON_OPEN_BILLING_DRAWER: { actions: 'onOpenBillingDrawer' },
        ON_OPEN_STRIPE_BILLING_CENTER: { actions: 'onOpenBillingCenter' },
        ON_SYNC_BILLING_METHODS: { actions: 'onSyncBillingMethods' },
        ON_TOGGLE_BILLING_DRAWER: { actions: 'onToggleBillingDrawer' },
        ON_TOGGLE_SESSION_TO_CART: { actions: 'onToggleSessionToCart' },
      },
    },
    processingCheckout: {
      invoke: {
        onDone: { actions: 'onPurchaseSuccess', target: 'checkoutSuccess' },
        onError: { actions: 'onPurchaseError', target: 'init' },
        src: async (ctx) => {
          if (ctx.mode === 'preview') {
            return;
          }
          if (!ctx.selectedBillingMethodId) {
            throw unexpected({ name: 'SelectBillingMethod' });
          }
          if (!ctx.sessionIdsInCart.length) {
            throw unexpected({ name: 'SelectSessionsToPurchase' });
          }

          const client = getApolloClient();
          const { data } = await client.mutate<
            PurchaseSessionsForMembershipMutation,
            PurchaseSessionsForMembershipMutationVariables
          >({
            mutation: PurchaseSessionsForMembershipDocument,
            variables: {
              cardId: ctx.selectedBillingMethodId,
              membershipId: selectMembershipId(),
              purchaseLocation: ctx.purchaseLocation,
              sessionIds: ctx.sessionIdsInCart,
            },
          });

          // eslint-disable-next-line no-console
          console.log(data?.purchaseSessionsForMembership);

          if (!data?.purchaseSessionsForMembership) {
            throw unexpected({ name: 'UnknownError' });
          }

          if (data.purchaseSessionsForMembership.message === 'Success') {
            return 'success';
          }

          throw unexpected({ name: data.purchaseSessionsForMembership.message });
        },
      },
    },
  },
};

export const cartMachine = createMachine(cartSchema, {
  actions: {
    onAdCompletedCheckoutListener,
    onAddBillingLink,
    onChangeBillingMethod,
    onClearCart,
    onInit,
    onOpenBillingCenter,
    onOpenBillingDrawer,
    onPurchaseError,
    onPurchaseSuccess,
    onResetState,
    onSyncBillingMethods,
    onToggleBillingDrawer,
    onToggleSessionToCart,
  },
});
