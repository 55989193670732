import { assertType, omit } from '@ours/utils';

import { selectTrackEvent } from '../../appMachine/selectors/selectTrackEvent';
import { selectMembershipId } from '../../store/lib/selectMembershipId';
import { selectSessionById } from '../../store/lib/selectSessionById';
import type { CSDActions, CSDContext } from '../types';

export const onRateSession = (ctx: CSDContext, ev: CSDActions) => {
  assertType(ev, 'ON_RATE_SESSION');

  const args = {
    membershipId: selectMembershipId(),
    rating: ev.rating,
    sessionId: ctx.store.sessionMetadata.id,
    sessionTitle: selectSessionById(ctx.store.sessionMetadata.id)?.title || '',
  };

  ctx._private.cbs?.sideEffects.onRateSession(omit(args, ['sessionTitle']));
  const trackEvent = selectTrackEvent();
  trackEvent({
    payload: args,
    type: 'SESSION_RATING',
  });
};
