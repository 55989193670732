import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const onUpdateEvents = assign<AppContext, AppActions>((ctx, ev) => {
  assertType(ev, 'ON_UPDATE_ALL_EVENTS');

  return produce(ctx, (draft) => {
    draft.content.eventsById = {};

    ev.events.forEach((ev) => {
      draft.content.eventsById[ev.id] = {
        ...ev,
        id: ev.id,
      };
    });
  });
});
