import type { FC } from 'react';
import { memo } from 'react';

import { AButtton } from './lib/AButtton';
import { BButton } from './lib/BButton';
import { DivButton } from './lib/DivButton';
import type { ButtonProps } from './lib/types';

const lookup: Record<ButtonProps['as'], FC<any>> = {
  a: AButtton,
  button: BButton,
  div: DivButton,
};

export const Button: FC<ButtonProps> = memo((props) => {
  const Component = lookup[props.as || 'button'];

  return <Component {...props} />;
});
