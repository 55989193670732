import { isArray } from '../lang/isArray';

import { graphQLErrorMessage } from './graphQLErrorMessage';

interface ExpectedGraphQLError {
  extensions: {
    code: 'EXPECTED';
  };
  /** The customer facing message for the error */
  message: string;
}

export const isExpectedGQLError = (value: unknown): value is ExpectedGraphQLError =>
  isArray((value as any)?.graphQLErrors) &&
  ((value as any)?.graphQLErrors?.[0]?.extensions?.code === 'EXPECTED' ||
    !!graphQLErrorMessage((value as any)?.graphQLErrors[0]?.message || '')?.startsWith('EXPECTED'));
