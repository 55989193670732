import type { AnalyticsEventType } from '@ours/utils';

import { useAnalyticsContext } from './analyticsContext';

export type TrackEventFN = (event: AnalyticsEventType) => void | Promise<void>;
export type StartEventFN = (event: Pick<AnalyticsEventType, 'type'>) => void;
export type StopEventFN = TrackEventFN;

/**
 * @see https://amplitude.github.io/Amplitude-JavaScript/
 * @see https://analytics.amplitude.com/with-ours/settings/projects/384444/general
 * @see https://help.amplitude.com/hc/en-us/articles/115000959052-For-developers-Getting-started
 */
export const useAnalyticsEvent = () => {
  return useAnalyticsContext();
};
