import { concat } from '../lang/concat';

import { findNextNodeFromCur } from './findNextNodeFromCur';
import { findStartNodeId } from './findStartNodeId';
import type { IXStateNodeTypes, IXStateState, XStateOrderedArray } from './xStateConstants';

export const xstateToArray = (xState: IXStateState): XStateOrderedArray => {
  let stepId = findStartNodeId(xState);

  if (!stepId) {
    return [];
  }

  let res: XStateOrderedArray = [];

  while (stepId) {
    const step: IXStateNodeTypes | undefined = xState[stepId];
    if (!step) {
      break;
    }
    res = concat(res, [{ ...step, id: stepId }]);
    stepId = stepId ? findNextNodeFromCur(step) : undefined;
  }

  return res;
};
