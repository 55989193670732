import { assertNotNil } from '@ours/utils';
import { produce } from 'immer';
import { useCallback, useMemo } from 'react';

import { useToVariableString } from '../../../../hooks/variableStrings/useToVariableString';
import { _useStore } from '../../../store/_useStore';
import { selectModuleById } from '../../../store/lib/selectModuleById';

export const useCSDModuleById = (moduleId: string) => {
  const select = useCallback(() => {
    const mod = selectModuleById(moduleId);
    assertNotNil(mod, 'ModuleMissingInModulesById');
    return mod;
  }, [moduleId]);
  const module = _useStore(select);
  const toVarStr = useToVariableString();
  return useMemo(() => {
    return produce(module, (d) => {
      d.openEndedPrompt = toVarStr(module?.openEndedPrompt) || '';
      d.placeholder = toVarStr(module?.placeholder) || '';
      d.title = toVarStr(module?.title) || '';
      d.description = toVarStr(module?.description) || '';
    });
  }, [module, toVarStr]);
};
