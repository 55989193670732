import type { FC } from 'react';
import { memo } from 'react';

import { ButtonBase } from './ButtonBase';
import type { DivPrivateProps } from './types';

export const DivButton: FC<DivPrivateProps> = memo((props) => {
  return (
    <ButtonBase {...props} as="span">
      {props.children}
    </ButtonBase>
  );
});
