import type { IModuleNode, IfBooleanNode, ValidXStateInputNodes } from './xStateConstants';

export const addNodeBuildNextItem = (
  target: string,
  { data, type }: Omit<ValidXStateInputNodes, 'on'>
): IModuleNode | IfBooleanNode => {
  if (type === 'ifBoolean') {
    // @ts-expect-error error
    return {
      data,
      on: { FALSE: target, TRUE: target },
      type: 'ifBoolean',
    };
  }

  // @ts-expect-error error
  return { data, on: { NEXT: target }, type: 'module' };
};
