import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const onUpdateMeta = assign<AppContext, AppActions>((ctx, ev) => {
  assertType(ev, 'UPDATE_META');

  return produce(ctx, (draft) => {
    if (ev.description) {
      draft.seo.description = ev.description;
    }
    if (ev.title) {
      draft.seo.title = ev.title;
    }
  });
});
