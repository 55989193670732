import type { Maybe } from '@ours/types';
import type { ModuleSideEffect } from '@ours/utils';
import { batchedForLoop, noop } from '@ours/utils';
import mixpanel from 'mixpanel-browser';

import type { TrackEventFN } from '../../hooks/analytics/useAnalyticsEvent';

export const clientSideSideEffects: ModuleSideEffect[] = [
  'ApplyEmailToMixpanelUser',
  'TrackUserAsLead',
];

type Options = {
  sideEffects: Maybe<readonly ModuleSideEffect[]>;
  trackEvent: TrackEventFN;
};
type FN = (value: string, opts: Options) => void | Promise<void>;

const effectLookup: Record<ModuleSideEffect, FN> = {
  AddLoveLetter: noop,
  AddToMailchimp: noop,
  ApplyEmailToMixpanelUser: (value) => {
    const update = { $email: value, email: value };
    mixpanel.register(update);
    mixpanel.people.set(update);
  },
  InsideJoke: noop,
  InvitePartner: noop,
  PartnerFullName: noop,
  PartnerInviteNote: noop,
  SongThatRemindsMeOfThem: noop,
  TrackUserAsLead: (value, opt) => {
    opt.trackEvent({
      // @ts-expect-error idk
      payload: { value },
      // @ts-expect-error idk
      type: 'TRACK_USER_AS_LEAD',
    });
  },
  UserFullName: noop,
  UserMobilePhone: noop,
  UserReportedReferrer: noop,
  WishICouldTellThem: noop,
};

export const performClientSideSideEffects = async (args: Options & { value: Maybe<string> }) => {
  const value = args.value;
  const sideEffects = args.sideEffects || [];
  if (!value) {
    return;
  }

  const effectsToRun = sideEffects.filter((effect) => clientSideSideEffects.includes(effect));

  await batchedForLoop(effectsToRun, async (eff) => {
    const fn = effectLookup[eff];
    await fn(value, { sideEffects, trackEvent: args.trackEvent });
  });
};
