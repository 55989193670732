import { isNotNil } from '../lang/isNotNil';

import { getModuleIdFromNode } from './getModuleIdFromNode';
import type { IXStateState } from './xStateConstants';

export const getModuleIdsFromXState = (xStateState: IXStateState): string[] => {
  return Object.values(xStateState)
    .map((node) => getModuleIdFromNode(node))
    .filter(isNotNil);
};
