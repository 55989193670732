import { setContext } from '@apollo/client/link/context';
import { CustomAppHeaders } from '@ours/utils';

export const getOriginUrlContext = () => {
  return setContext(async (operation, { headers }) => {
    return {
      headers: {
        ...headers,
        [CustomAppHeaders.originHref]:
          typeof window === 'undefined' ? 'ssr' : window?.location?.href || 'unknown',
      },
    };
  });
};
