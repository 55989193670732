/* eslint-disable complexity */
import { isArray, isFinite, toNumber, unexpected } from '@ours/utils';
import { captureException } from '@sentry/nextjs';

import type { IConditionConfiguration } from '../types';

export const completedSessionCountDecision: IConditionConfiguration['decide'] = (
  { op, value },
  data
) => {
  const usersCompletedSessions = data?.completedSessions || 0;
  if (isArray(value)) {
    const arrOfNumbers = value.map(toNumber);

    if (op === 'in') {
      return arrOfNumbers.includes(usersCompletedSessions);
    } else if (op === 'nin') {
      return !arrOfNumbers.includes(usersCompletedSessions);
    }
  } else {
    const number = toNumber(value);

    if (!isFinite(number)) {
      // eslint-disable-next-line no-console
      console.error('completedSessionCountDecision called without a finite number: ', {
        number,
        value,
      });
      captureException(unexpected({ name: 'InvalidValueNotNumber' }));
      return false;
    }

    if (op === 'eq') {
      return usersCompletedSessions === number;
    } else if (op === 'neq') {
      return usersCompletedSessions !== number;
    } else if (op === 'gt') {
      return usersCompletedSessions > number;
    } else if (op === 'gte') {
      return usersCompletedSessions >= number;
    } else if (op === 'lt') {
      return usersCompletedSessions < number;
    } else if (op === 'lte') {
      return usersCompletedSessions <= number;
    }
  }

  // eslint-disable-next-line no-console
  console.error('completedSessionCountDecision called value op and value: ', {
    op,
    value,
  });
  captureException(unexpected({ name: 'InvalidCaseDecision' }));

  return true;
};
