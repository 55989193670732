import type { Stage } from '@ours/utils';

export const sendGAEvent = (stage: Stage, type: string) => {
  if (stage !== 'production') {
    return;
  }

  try {
    // @ts-expect-error datalayer might exist
    if (typeof window?.dataLayer?.push === 'function') {
      // @ts-expect-error datalayer might exist
      window.dataLayer.push({ event: type });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('GA Failed');
  }
};
