import { assertType } from '@ours/utils';
import { assign } from 'xstate';

import type { CSDActions, CSDContext } from '../types';

export const onSetCurrentMedia = assign<CSDContext, CSDActions>((ctx, ev) => {
  assertType(ev, 'ON_SET_CURRENT_MEDIA_ELEMENT');

  // NOTE: intentionally not using immer to avoid wrapping in an immutable obj
  return {
    ...ctx,
    store: {
      ...ctx.store,
      display: {
        ...ctx.store.display,
        videoElement: ev.element,
      },
    },
  };
});
