/* eslint-disable complexity */
import type { Maybe } from '@ours/types';

import { first } from '../lang/first';
import { last } from '../lang/last';
import { round } from '../lang/round';
import { sortLongestToShortest } from '../sorters/sortLongestToShortest';

import { findAllPathsThroughState } from './findAllPathsThroughState';
import type { IXStateState } from './xStateConstants';

/**
 * Returns 0->1 as progress through the session. It's likely as we do not know the exact number of steps.
 */
export const calculateLikelyCurrentStepPerc = (
  currentNodeId: Maybe<string>,
  xState: IXStateState
) => {
  if (!currentNodeId) {
    return 0;
  }

  const currentNode = xState[currentNodeId];
  if (!currentNode) {
    return 0;
  }

  if (currentNode.type === 'final') {
    return 1;
  }

  if (currentNode.type === 'start') {
    return 0;
  }

  const longestPathIdsFromHereToEnd = first(
    findAllPathsThroughState(xState, {
      startingNodeId: currentNodeId,
    }).sort(sortLongestToShortest)
  );
  const longestPathFromHereToEnd = (longestPathIdsFromHereToEnd?.length || 1) - 1;

  const longestPathFromStartToHere =
    last(
      findAllPathsThroughState(xState, {
        endingNodeId: currentNodeId,
      }).sort(sortLongestToShortest)
    )?.length || 0;

  return round(
    longestPathFromStartToHere / (longestPathFromHereToEnd + longestPathFromStartToHere),
    4
  );
};
