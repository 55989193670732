import type { init } from '@sentry/nextjs';

type Options = Parameters<typeof init>[0];

export const getSentryInitDefaults = (args: { stage: string }): Options => {
  return {
    beforeBreadcrumb: (breadcrumb, hint) => {
      if (hint && breadcrumb.category?.startsWith('ui')) {
        const target = hint.event.target as HTMLElement | undefined;

        const customName =
          // @ts-expect-error should probably extract into a method & add button text
          target?.dataset?.['testid'] || target?.placeholder || target?.labels?.[0]?.textContent;

        breadcrumb.message = customName
          ? `${target?.tagName.toString()}: "${customName}"`
          : breadcrumb.message;
      }
      return breadcrumb;
    },
    enabled: args.stage !== 'local',
    environment: args.stage,
    // @see - https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447
    ignoreErrors: [
      'useIdIsMissingRouter',
      'TypeError: Failed to fetch',
      'TypeError: cancelled',
      'The network connection was lost.',
      'TypeError: Load failed',
      'TypeError: NetworkError when attempting to fetch resource.',
      'AbortError: The fetching process for the media resource was aborted',
      'ResizeObserver loop',
      'Cancel rendering route',
    ],
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0,
  };
};
