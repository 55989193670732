import type { CSDActions, CSDContext } from '../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const listenToYJS = (ctx: CSDContext, cb: (args: CSDActions) => void) => {
  const handleCleanup = () => {
    ctx._private.yjs?.provider?.disconnect();
    ctx._private.yjs?._doc.destroy();
  };
  window.addEventListener('beforeunload', handleCleanup);

  return () => {
    handleCleanup();
    window.removeEventListener('beforeunload', handleCleanup);
  };
};
