import { assign } from './assign';
import { toArray } from './toArray';

export const arrayToMap = <T extends Record<string, any>, Key extends keyof T>(
  key: Key,
  items?: null | undefined | readonly T[]
): Record<string, NonNullable<T>> => {
  return toArray(items).reduce(
    (acc, cur) => assign(acc, { [cur[key]]: cur }),
    {} as Record<string, NonNullable<T>>
  );
};
