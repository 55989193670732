import type { Maybe } from '@ours/types';
import type { AnalyticsEventType } from '@ours/utils';
import { unexpected } from '@ours/utils';
import type { FC, ReactNode } from 'react';
import { createContext, memo, useContext, useMemo } from 'react';

import type { StartEventFN, StopEventFN, TrackEventFN } from './useAnalyticsEvent';

export interface IAnalyticsContext {
  identifyUserId: (userId: Maybe<string>) => void;
  startEvent: StartEventFN;
  trackEvent: StopEventFN;
}

const AnalyticsContext = createContext<IAnalyticsContext>({
  identifyUserId: () => {
    throw unexpected({ name: 'SetupContextAnalyticsContext' });
  },
  startEvent: () => {
    throw unexpected({ name: 'SetupContextAnalyticsContext' });
  },
  trackEvent: () => {
    throw unexpected({ name: 'SetupContextAnalyticsContext' });
  },
});
export const useAnalyticsContext = () => {
  return useContext(AnalyticsContext);
};
interface Props {
  children: ReactNode;
  identifyUserId: IAnalyticsContext['identifyUserId'];
  startEvent: (event: Pick<AnalyticsEventType, 'type'>) => void;
  trackEvent: TrackEventFN;
}

export const AnalyticsContextProvider: FC<Props> = memo(
  ({ children, identifyUserId, startEvent, trackEvent }) => {
    const value = useMemo(
      () => ({ identifyUserId, startEvent, trackEvent }),
      [trackEvent, startEvent, identifyUserId]
    );

    return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
  }
);
