import { _useStore } from '../../store/_useStore';
import type { StoreState } from '../../store/types';

const select = (s: StoreState) => {
  return s.app.state.context.seo.description;
};

export const useMetaDescription = () => {
  return _useStore(select);
};
