import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { CardDeckActions } from '../actionTypes';
import { cardDeckInitContext } from '../types';
import type { CardDeckContext } from '../types';

export const onInit = assign<CardDeckContext, CardDeckActions>((ctx, ev) => {
  assertType(ev, 'INIT');

  return produce(ctx, (d) => {
    d = {
      ...cardDeckInitContext,
      decks: ev.decks,
    };
    return d;
  });
});
