import type { Maybe } from '@ours/types';

import { isNil } from '../lang/isNil';
import { omitBy } from '../lang/omitBy';

export const hydrateUserData = (
  data?: Maybe<{
    birthday?: Maybe<string>;
    email?: Maybe<string>;
    fullName?: Maybe<string>;
    selfReportedReferrer?: Maybe<string>;
    timezone?: Maybe<string>;
  }>
) => {
  return omitBy(
    {
      $email: data?.email,
      $name: data?.fullName,
      birthday: data?.birthday,
      email: data?.email,
      fullName: data?.fullName,
      selfReportedReferrer: data?.selfReportedReferrer,
      timezone: data?.timezone,
    },
    isNil
  );
};
