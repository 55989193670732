import {
  FullCenteredSquiggleLoader,
  LoginComponentContainer,
  UserStateModals,
  initApp,
  useAnalyticsEvent,
  useAppActions,
  useAppStateValue,
  useBrandedToast,
  useUserRole,
} from '@ours/web-utils';
import type { FC } from 'react';
import { memo, useEffect } from 'react';

import { WrongAppView } from '../../features/WrongAppView/WrongAppView';
import { getStageAndVersion } from '../../lib/getStageAndVersion';

const { stage, version } = getStageAndVersion();

initApp({ appKind: 'couple', stage, type: 'ON_INIT', version });
/**
 * We need to:
 * - Fetch self and prefetch the application data
 *    The need for this is determined and passed in as a var
 * - Kick off a listener for if we are logged in w/ cognito
 * - Notate that we've logged in
 */
export const AppWithAuth: FC<any> = memo(({ Component, Layout, isPublic, pageProps }) => {
  const { onInApp } = useAppActions();
  const appState = useAppStateValue();
  const role = useUserRole();
  const toast = useBrandedToast();
  const { trackEvent } = useAnalyticsEvent();

  useEffect(() => {
    onInApp({ toast, trackEvent });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onInApp]);

  if (isPublic) {
    return (
      <Layout>
        <Component {...pageProps} />
      </Layout>
    );
  }

  if (appState === 'loading') {
    return <FullCenteredSquiggleLoader />;
  }

  if (appState === 'unauthenticated') {
    return <LoginComponentContainer appKind="couple" />;
  }

  if (role && role !== 'CUSTOMER') {
    return <WrongAppView />;
  }

  return (
    <Layout>
      <UserStateModals />
      <Component {...pageProps} />
    </Layout>
  );
});
