import { BrandColors } from '@ours/utils';
import { z } from 'zod';

export const iconsArray = ['stairs', 'squareSmile', 'star', 'squiggle', 'roundedSmile'] as const;
export const cardDeckIconsSchema = z.enum(iconsArray);
export const cardDeckSchema = z.object({
  bgColor: z.nativeEnum(BrandColors),
  cards: z.array(z.string().min(12).max(75)).min(5).max(500),
  icon: cardDeckIconsSchema,
  id: z.string(),
  subtitle: z.string().min(10).max(80),
  title: z.string().min(5).max(40),
});
export const cardDecksSchema = z.array(cardDeckSchema).min(1).max(5);
export type CardDeckIcon = z.infer<typeof cardDeckIconsSchema>;
export type CardDeckType = z.infer<typeof cardDeckSchema>;

export interface CardDeckContext {
  activeIdx: number;
  answersCurrentStreak: number;
  answersTotalCards: number;
  decks: CardDeckType[];
  hasCompletedOnboarding: boolean;
  selectedDeckId: string | undefined;
  selectedDeckShuffledCards: string[];
}
export const cardDeckInitContext: CardDeckContext = {
  activeIdx: 0,
  answersCurrentStreak: 0,
  answersTotalCards: 0,
  decks: [],
  hasCompletedOnboarding: false,
  selectedDeckId: undefined,
  selectedDeckShuffledCards: [],
};
