import { graphQLErrorMessage, isExpectedGQLError } from '@ours/utils';
import { captureException } from '@sentry/nextjs';
import { useCallback } from 'react';

import { useBrandedToast } from './useBrandedToast';

export const useOnError = () => {
  const toast = useBrandedToast();

  return useCallback(
    (errors: unknown) => {
      if (isExpectedGQLError(errors)) {
        return toast({
          kind: 'error',
          title: graphQLErrorMessage(errors?.message || '')?.replace('EXPECTED: ', '') || '',
        });
      }

      toast({ kind: 'error', title: 'Unknown error' });
      captureException(errors);
      return;
    },
    [toast]
  );
};
