import { setContext } from '@apollo/client/link/context';

import { getAuthorizationToken } from '../../cognito/getAuthorizationToken';

export const getAuthLink = () => {
  return setContext(async (operation, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: await getAuthorizationToken(),
      },
    };
  });
};
