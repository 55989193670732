import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { CartActions } from '../actionTypes';
import type { CartContext } from '../types';

export const onAddBillingLink = assign<CartContext, CartActions>((ctx, ev) => {
  assertType(ev, 'ON_ADD_BILLING_LINK');

  return produce(ctx, (d) => {
    d.billingCenterLink = ev.link;
  });
});
