import { arrayToMap, assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { CSDActions, CSDContext } from '../types';

export const onRemoveBookmark = assign<CSDContext, CSDActions>((ctx, ev) => {
  assertType(ev, 'ON_REMOVE_BOOKMARK');

  ctx._private.cbs?.sideEffects.onRemoveBookmark(ev.id);
  const bookmarks = arrayToMap(
    'moduleId',
    Object.values(ctx.store.sessionMetadata.bookmarks).filter((val) => {
      return val.id !== ev.id;
    })
  );
  return produce(ctx, (d) => {
    d.store.sessionMetadata.bookmarks = bookmarks;
  });
});
