import {
  assertIsUUID,
  assertType,
  buildXStateFromModuleIds,
  cloneDeep,
  findStartNodeId,
  getModuleIdFromNode,
  isNotNil,
  removeNodeFromXState,
  xStateToGraphQLXState,
} from '@ours/utils';
import { gql } from 'graphql-tag';
import { produce } from 'immer';
import { assign } from 'xstate';

import { OnDropModulesDocument } from '../../../generated/custom-hooks';
import type {
  OnDropModulesMutation,
  OnDropModulesMutationVariables,
} from '../../../generated/custom-hooks';
import { getApolloClient } from '../../../lib/apollo/getApolloClient';
import { sendOnUpdateXState } from '../../appMachine/externalActions/sendOnUpdateXState';
import { store } from '../../store/_useStore';
import { selectXStateBySessionId } from '../../store/lib/selectXStateBySessionId';
import type { SessionEditorActions, SessionEditorContext } from '../types';

gql`
  mutation OnDropModules($id: UUID!, $xState: [XStateInput!]!) {
    putSessionXState(id: $id, xState: $xState) {
      id
      resolvedValues {
        sessionXState {
          ...XState
        }
      }
    }
  }
`;
export const onDrop = assign<SessionEditorContext, SessionEditorActions>((ctx, ev) => {
  assertType(ev, 'ON_DROP');
  const draggingId = ctx.display.draggingNodeId;
  const selectedNodeIds = ctx.display.selectedNodeIds;
  const xState = selectXStateBySessionId(ctx.store.sessionId);
  const startingNodeId = findStartNodeId(xState);
  assertIsUUID(startingNodeId);

  if (!draggingId || draggingId === ev.nodeId) {
    return ctx;
  }

  const moduleTypesOfSelectedNodes = selectedNodeIds.map((nodeId) => xState[nodeId]?.type);
  if (moduleTypesOfSelectedNodes.some((type) => type !== 'module')) {
    store.getState().app.state.context.toast({
      title: 'Cannot drag and drop when non module nodes are selected',
    });

    return ctx;
  }

  let nextXState = cloneDeep(xState);
  selectedNodeIds.forEach((nodeId) => {
    nextXState = removeNodeFromXState(nodeId, nextXState);
  });

  nextXState = buildXStateFromModuleIds(
    selectedNodeIds.map((nodeId) => getModuleIdFromNode(xState[nodeId])).filter(isNotNil),
    ev.nodeId,
    nextXState
  );
  const client = getApolloClient();
  client.mutate<OnDropModulesMutation, OnDropModulesMutationVariables>({
    mutation: OnDropModulesDocument,
    variables: {
      id: ctx.store.sessionId,
      xState: xStateToGraphQLXState(nextXState),
    },
  });

  sendOnUpdateXState({ sessionId: ctx.store.sessionId, xState: nextXState });

  return produce(ctx, (d) => {
    d.display.focusedNodeId = startingNodeId;
    d.display.selectedNodeIds = [startingNodeId];
  });
});
