import { assertNotNil, assertType, shuffle } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { CardDeckActions } from '../actionTypes';
import type { CardDeckContext } from '../types';

export const onSelectCardDeck = assign<CardDeckContext, CardDeckActions>((ctx, ev) => {
  assertType(ev, 'ON_SELECT_CARD_DECK');

  if (!ev.selectedDeckId) {
    return produce(ctx, (d) => {
      d.selectedDeckShuffledCards = [];
      d.selectedDeckId = undefined;
    });
  }

  const deck = ctx.decks.find((d) => d.id === ev.selectedDeckId);
  assertNotNil(deck, 'SelectedCardDeckNil');

  return produce(ctx, (d) => {
    d.activeIdx = 0;
    d.selectedDeckShuffledCards = shuffle(deck.cards);
    d.selectedDeckId = ev.selectedDeckId;
  });
});
