import type { ModuleForAppContextFragment } from '@ours/utils';
import { pick, uuid } from '@ours/utils';
import type { DeepWritable } from 'ts-essentials';

import { modulePropertiesToCopy } from './modulePropertiesToCopy';

export const duplicateModule = (
  mod: ModuleForAppContextFragment
): DeepWritable<ModuleForAppContextFragment> => {
  return {
    ...pick(mod, Object.keys(modulePropertiesToCopy) as any[]),
    id: uuid(),
  };
};
