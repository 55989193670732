import { CustomError } from './CustomError';

export class Expected extends CustomError {
  constructor({ message = 'An expected error occurred', name = 'Expected' }: IExpected) {
    super({ code: 'EXPECTED', message, name });
  }
}

export interface IExpected {
  message?: string;
  name: string;
}
