import type { PutBookmark } from '@ours/utils';
import { assertIsUUID, assertType, uuid } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import { selectMembershipId } from '../../store/lib/selectMembershipId';
import type { CSDActions, CSDContext } from '../types';

export const onAddBookmark = assign<CSDContext, CSDActions>((ctx, ev) => {
  assertType(ev, 'ON_ADD_BOOKMARK');
  const moduleId = ctx.store.display.currentModuleId;
  const membershipId = selectMembershipId();

  assertIsUUID(moduleId);
  const nextBookmark: PutBookmark = {
    content: ev.content,
    endTimeStampSec: 0,
    id: uuid(),
    membershipId,
    moduleId,
    sessionId: ctx.store.sessionMetadata.id,
    startTimeStampSec: 0,
  };

  ctx._private.cbs?.sideEffects.onAddBookmark(nextBookmark);
  return produce(ctx, (draft) => {
    draft.store.sessionMetadata.bookmarks[nextBookmark.moduleId] = nextBookmark;
  });
});
