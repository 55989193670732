import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { CSDActions, CSDContext } from '../types';

export const onUpdateJoiningOrder = assign<CSDContext, CSDActions>((ctx, ev) => {
  assertType(ev, 'SYNC_JOINING_ORDER');

  return produce(ctx, (d) => {
    d.store.peers.sessionIdJoiningOrder = ev.value;
  });
});
