import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { CartActions } from '../actionTypes';
import type { CartContext } from '../types';

export const onInit = assign<CartContext, CartActions>((ctx, ev) => {
  assertType(ev, 'INIT');

  return produce(ctx, (d) => {
    d.mode = ev.mode;
    d.purchaseLocation = ev.purchaseLocation;
  });
});
