import { assertIsUUID, assertType, now, uuid } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import { selectModuleById } from '../../store/lib/selectModuleById';
import type { CSDActions, CSDContext, LocalModuleResponse } from '../types';

export const onAnswerChangeValue = assign<CSDContext, CSDActions>((ctx, ev) => {
  assertType(ev, 'ON_MODULE_TEXT_ANSWER_CHANGE');
  const response = ctx.store.interaction.moduleResponseDraft;
  const moduleId = ctx.store.display.currentModuleId;
  assertIsUUID(moduleId);
  const sessionId = ctx.store.sessionMetadata.id;
  const module = selectModuleById(moduleId);
  const valueDescription = ev.value || '';

  const formattedResponse: LocalModuleResponse = {
    createdAt: now(),
    id: uuid(),
    moduleId,
    sessionId,
    ...response,
    label: response?.label || module?.openEndedPrompt,
    value: ev.value,
    valueDescription: valueDescription || response?.valueDescription,
  };
  return produce(ctx, (d) => {
    d.store.interaction.moduleResponseDraft = formattedResponse;
  });
});
