import type { HttpOptions } from '@apollo/client';
import { createHttpLink, from, split } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { RetryLink } from '@apollo/client/link/retry';
import { StatusCodes, TimeConstants, unexpected } from '@ours/utils';
import { captureException } from '@sentry/nextjs';

import { signOut } from '../../cognito/signOut';

import { getAuthLink } from './getAuthLink';
import { getDebounceLink } from './getDebounceLink';
import { getOriginUrlContext } from './getOriginUrlContext';

interface ILinkOptions {
  fetch?: HttpOptions['fetch'];
  uri: string;
}

export const createApolloLink = ({ fetch, uri }: ILinkOptions) => {
  const httplink = createHttpLink({ fetch, uri });
  const batchLink = new BatchHttpLink({ batchInterval: 100, batchMax: 50, uri });

  const retryLink = new RetryLink({
    attempts: {
      max: 10,
      retryIf: async (error, operation) => {
        const statusCode = (error?.statusCode || undefined) as number | undefined;
        if (statusCode === StatusCodes.FORBIDDEN) {
          await signOut();
          window.location.reload();
          return false;
        } else if (statusCode === StatusCodes.UNAUTHORIZED) {
          return true;
        } else if (error.message === 'Failed to fetch') {
          // @see https://deniapps.com/blog/finally-we-fixed-failed-to-fetch-error
          return true;
        } else if (error.message === 'Load failed') {
          // @see https://with-ours.sentry.io/issues/3963577709/?alert_rule_id=10637155&alert_timestamp=1677584115903&alert_type=email&environment=production&project=6061882&referrer=alert_email
          return true;
        }

        // eslint-disable-next-line no-console
        console.log(operation.operationName);
        // eslint-disable-next-line no-console
        console.error(error.message);
        // eslint-disable-next-line no-console
        console.error(statusCode);
        // eslint-disable-next-line no-console
        console.error(error.name);
        captureException(unexpected({ name: 'UnknownAPIError' }));

        return true;
      },
    },
    delay: { initial: TimeConstants.MILLISECONDS_IN_SECOND },
  });

  // https://www.apollographql.com/docs/react/api/link/apollo-link-ws/#websocketimpl
  // https://github.com/apollographql/subscriptions-transport-ws/issues/333#issuecomment-359261024
  const httpOrBatchedLink = split(({ getContext }) => getContext().batch, batchLink, httplink);
  const links = httpOrBatchedLink;

  /**
   * Note: The retryLink needs to be before the authLink. This makes sure the retry contains a new token.
   */
  return from([
    getOriginUrlContext(),
    retryLink,
    getDebounceLink(),
    ...('browser' in process ? [getAuthLink()] : []),
    links,
  ]);
};
