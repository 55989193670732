import { Box, Center } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

export interface NotMainPeerUIProps {}

export const NotMainPeerUI: FC<NotMainPeerUIProps> = memo(() => {
  return (
    <Center h="full">
      <Box>Look to your main screen to complete the module together!</Box>
    </Center>
  );
});
