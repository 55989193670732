import type { SessionForAppContextFragment } from '@ours/utils';
import { assertIsObject, assertIsUUID, unexpected } from '@ours/utils';
import { gql } from 'graphql-tag';
import type { DeepWritable } from 'ts-essentials';

import type {
  SessionForEditingQuery,
  SessionForEditingQueryVariables,
} from '../../../generated/custom-hooks';
import { SessionForEditingDocument } from '../../../generated/custom-hooks';
import { getApolloClient } from '../../../lib/apollo/getApolloClient';
import type { ON_SESSION_LOADED, SessionEditorContext } from '../types';

gql`
  query sessionForEditing($id: UUID!, $membershipId: UUID) {
    findSession(id: $id) {
      ...SessionForAppContext
    }
  }
`;

export const onLoadSession = async (ctx: SessionEditorContext) => {
  assertIsUUID(ctx.store.sessionId);
  const client = getApolloClient();
  const { data } = await client.query<SessionForEditingQuery, SessionForEditingQueryVariables>({
    query: SessionForEditingDocument,
    variables: { id: ctx.store.sessionId },
  });

  assertIsObject(data.findSession);
  if (data.findSession.type !== 'MODULE') {
    throw unexpected({ name: 'LoadedNonModuleSessionForEditing' });
  }

  const res: ON_SESSION_LOADED = {
    data: data.findSession as DeepWritable<SessionForAppContextFragment>,
    type: 'ON_SESSION_LOADED',
  };
  return res;
};
