import { debounce } from '@ours/utils';

import type { AppActions } from '../actionTypes';

export const listenToWindowSize = (cb: (args: AppActions) => void) => {
  const onSizeChange = debounce(() => {
    cb({
      height: document.documentElement.clientHeight,
      type: 'WINDOW_SIZE_CHANGE',
      width: document.documentElement.clientWidth,
    });
  }, 100);

  onSizeChange();

  window.addEventListener('resize', onSizeChange);
  window.addEventListener('orientationchange', onSizeChange);
  return () => {
    window.removeEventListener('resize', onSizeChange);
    window.removeEventListener('orientationchange', onSizeChange);
  };
};
