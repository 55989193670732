import { Box } from '@chakra-ui/react';
import { Close } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';

import { Button } from '../Buttons/Button';

const SimpleModalConst = {
  sx: { svg: { maxW: '90%' } },
};

export interface Props {
  onClick: () => void;
}

export const CloseButton: FC<Props> = memo(({ onClick }) => {
  return (
    <Button as="button" onClick={onClick} size="roundSm" type="button" variant="beige">
      <Box display="flex" justifyContent="center" sx={SimpleModalConst.sx} textAlign="center">
        <Close />
      </Box>
    </Button>
  );
});
