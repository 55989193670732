import { assertType } from '@ours/utils';

import type { CSDActions, CSDContext } from '../types';

export const onChangeJoiningOrder = (ctx: CSDContext, ev: CSDActions) => {
  assertType(ev, 'ON_CHANGE_JOINING_ORDER');

  const joiningArr = ctx._private.yjs?.joiningArr;
  joiningArr && joiningArr.insert(0, [ev.value]);
};
