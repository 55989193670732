import type { Maybe } from '@ours/types';
import type {
  ModuleForAppContextFragment,
  ModuleResponseInput,
  SessionForAppContextFragment,
} from '@ours/utils';
import { appendAnalyticsData, toTrackingValueFromInteractionKind } from '@ours/utils';

import type { TrackEventFN } from '../../../hooks/analytics/useAnalyticsEvent';

export const trackModuleResponseCompletion = async (args: {
  membershipId: string;
  module: ModuleForAppContextFragment;
  moduleResponse: ModuleResponseInput;
  session: Maybe<SessionForAppContextFragment>;
  trackEvent: TrackEventFN;
}) => {
  const trackingValues = toTrackingValueFromInteractionKind(
    { value: args.moduleResponse.value, valueDescription: args.moduleResponse.valueDescription },
    args.module
  );

  args.trackEvent({
    payload: {
      membershipId: args.membershipId,
      moduleId: module.id,
      sessionId: args.session?.id || '',
      ...trackingValues,
      ...appendAnalyticsData({}, args.module, 'module.'),
      ...appendAnalyticsData({}, args.session, 'session.'),
    },
    type: 'COMPLETE_SESSION_MODULE',
  });
};
