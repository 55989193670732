import type { MachineConfig } from 'xstate';
import { createMachine, forwardTo } from 'xstate';

import type { AdminActions } from './actionTypes';
import { onHidePii } from './actions/onHidePii';
import { onShowPii } from './actions/onShowPii';
import type { AdminContext } from './types';

const adminSchema: MachineConfig<AdminContext, any, AdminActions> = {
  context: { ui: { isShowingPII: false } },
  id: 'adminMachine',
  initial: 'inApp',
  predictableActionArguments: true,
  states: {
    inApp: {
      invoke: {
        id: 'hideAfter',
        src: () => (cb: (action: AdminActions) => void, receive) => {
          receive((event: AdminActions) => {
            if (event.type === 'OnShowPII') {
              setTimeout(() => {
                cb({ type: 'OnHidePII' });
              }, event.durationMS);
            }
          });
        },
      },
      on: {
        OnAlwaysShowPII: { actions: ['onShowPii'] },
        OnHidePII: { actions: 'onHidePii' },
        OnShowPII: { actions: ['onShowPii', forwardTo('hideAfter') as any] },
      },
    },
  },
};

export const adminMachine = createMachine(adminSchema, {
  actions: { onHidePii, onShowPii },
  guards: {},
});
