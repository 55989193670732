import type { InputType, InteractionKind } from '@ours/utils';
import { alwaysTrue, formatEmail } from '@ours/utils';

const isRequired = (o: string): boolean => !!o;

export const valueValidators: Record<
  InputType | InteractionKind,
  (val: string) => boolean | string
> = {
  AudioRecording: alwaysTrue,
  Button: alwaysTrue,
  Camera: isRequired,
  Discussion: alwaysTrue,
  Downloadable: alwaysTrue,
  Drawing: alwaysTrue,
  ImageUpload: isRequired,
  InAppPurchase: alwaysTrue,
  Input: isRequired,
  Journal: isRequired,
  JournalSeparate: alwaysTrue,
  MacroModules: alwaysTrue,
  MultipleChoice: isRequired,
  NumberSelect: isRequired,
  OpenEndedText: (o) =>
    !o ? 'Please enter an answer' : o.length > 5000 ? 'Please limit to 1000 characters' : true,
  Photobooth: alwaysTrue,
  PricingBlock: isRequired,
  QuizMatching: alwaysTrue,
  Scheduler: alwaysTrue,
  SentenceBuilder: alwaysTrue,
  TrueFalseButton: isRequired,
  VideoRecording: alwaysTrue,
  date: isRequired,
  email: (o) => !!formatEmail(o),
  phone: (o) => (o?.length === 12 ? true : false),
  text: isRequired,
};
