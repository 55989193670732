import { Box, useBoolean } from '@chakra-ui/react';
import { memo, useEffect } from 'react';

import { useIsOnline } from '../../state/appMachine/selectors/useIsOnline';
import { SimpleModal } from '../Modals/SimpleModal';

export const UserStateModals = memo(() => {
  const isOnline = useIsOnline();
  const [isOpen, isOpenHandler] = useBoolean(false);

  useEffect(() => {
    if (isOnline === false) {
      isOpenHandler.on();
    }
  }, [isOnline, isOpenHandler]);

  return (
    <SimpleModal isOpen={isOpen} onClose={isOpenHandler.off} size="xl">
      <Box style={{ paddingBottom: '30px', paddingTop: '30px' }}>
        {isOnline ? (
          <Box w="full">You are online!</Box>
        ) : (
          <Box> We’ve detected you’re offline. Please reconnect to the wifi and try again</Box>
        )}
      </Box>
    </SimpleModal>
  );
});
