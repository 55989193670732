import type { IXStateNodeTypes } from '@ours/utils';
import {
  MODULE_ID_PROPERTY,
  assertIsObject,
  assertIsString,
  findNextNodeFromCur,
  fromConditionsString,
  unexpected,
} from '@ours/utils';
import { captureException } from '@sentry/nextjs';

import { conditionOptionsConfiguration } from '../../../hooks/conditionalOptions/conditionOptionsConfiguration';
import type { ConditionedQueriedData } from '../../../hooks/conditionalOptions/types';
import { selectModuleById } from '../../store/lib/selectModuleById';
import { selectXStateBySessionId } from '../../store/lib/selectXStateBySessionId';
import type { CSDContext, LocalModuleResponse } from '../types';

export const onNextStep = (initialId: string, ctx: CSDContext) => {
  const xState = selectXStateBySessionId(ctx.store.sessionMetadata.id);
  const isPurchaser = ctx._private.initial.isPurchaser;
  const data: ConditionedQueriedData = {
    completedSessions: ctx.store.membershipMetadata.completedSessions,
    moduleResponses: ctx.store.sessionMetadata.moduleResponses || {},
  };
  const isNextValid = (step: IXStateNodeTypes) => {
    if (step?.type !== 'module') {
      return true;
    }

    const module = selectModuleById(step.data[MODULE_ID_PROPERTY]);
    if (!module) {
      return true;
    }

    if (module.answerMode === 'PurchaserOnly' && !isPurchaser) {
      return false;
    }

    return true;
  };

  const ifBoolean = (condition: string, moduleResponses: Record<string, LocalModuleResponse>) => {
    const parsedCondition = fromConditionsString(condition);
    if (!parsedCondition) {
      // eslint-disable-next-line no-console
      console.error('Invalid condition: ', condition);
      captureException(unexpected({ name: 'InvalidCondition' }));
      return true;
    }
    const configuration = conditionOptionsConfiguration[parsedCondition.property];
    if (!configuration) {
      // eslint-disable-next-line no-console
      console.error('Missing configuration: ', condition);
      captureException(unexpected({ name: 'MissingConfiguration' }));
      return true;
    }

    return configuration.decide(parsedCondition, { ...data, moduleResponses });
  };
  const validNextTypes: IXStateNodeTypes['type'][] = ['final', 'module'];
  const getNext = (
    step: IXStateNodeTypes,
    responses: Record<string, LocalModuleResponse>
  ): string => {
    const bestNext = findNextNodeFromCur(step, { ifBoolean, responses });
    assertIsString(bestNext);
    const next = xState[bestNext];
    if (!next) {
      throw unexpected({ name: 'MissingNextInGetNext' });
    }

    if (!isNextValid(next) || !validNextTypes.includes(next.type)) {
      return getNext(next, responses);
    }

    return bestNext;
  };

  const step = xState[initialId];
  assertIsObject(step);
  return getNext(step, ctx.store.sessionMetadata.moduleResponses || {});
};
