import { assertNotNil, assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const onPurchasedSessionSync = assign<AppContext, AppActions>((ctx, ev) => {
  assertType(ev, 'ON_PURCHASED_SESSION_SYNC');

  return produce(ctx, (draft) => {
    assertNotNil(draft.content.membership, 'membership should exist');
    draft.content.membership.purchasedSessionIds = [...ev.sessionIds];
  });
});
