import { append } from '../lang/append';

import { findFinalNodeId } from './findFinalNodeId';
import { findStartNodeId } from './findStartNodeId';
import type { IXStateState } from './xStateConstants';

export const findAllPathsThroughState = (
  xState: IXStateState,
  args?: { endingNodeId?: string; startingNodeId?: string }
) => {
  const id = findStartNodeId(xState);
  const startNodeId = args?.startingNodeId || id;

  if (!startNodeId) {
    return [];
  }

  const finalNodeId = args?.endingNodeId || findFinalNodeId(xState);
  const _findPaths = (
    nodeIdsOfModules: string[],
    nodeId: string,
    allPaths: string[][]
  ): string[][] => {
    const node = xState[nodeId];
    const nodeIds = node?.type === 'module' ? append(nodeId, nodeIdsOfModules) : nodeIdsOfModules;

    if (!node || nodeId === finalNodeId) {
      return append(nodeIds, allPaths);
    } else if (node.type === 'module' || node.type === 'start') {
      return _findPaths(nodeIds, node.on.NEXT, allPaths);
    } else if (node.type === 'ifBoolean') {
      return _findPaths(nodeIds, node.on.FALSE, _findPaths(nodeIds, node.on.TRUE, allPaths));
    }

    return append([], allPaths);
  };

  return _findPaths([], startNodeId, []).filter((arr) => arr.length > 0);
};
