export const LINKS = {
  INSTAGRAM_LINK: 'https://www.instagram.com/ours.wellness/',
  LINKEDIN_LINK: 'https://www.linkedin.com/company/ourswellness',
  MARKETING_SITE: 'https://www.withours.com',
  PRIVACY_POLICY:
    'https://withours.notion.site/Ours-Wellness-Inc-Privacy-Policy-46f3ee77fd6f4ed4a7d76f4bdfbc92c5',
  PURCHASE_LINK: 'https://www.withours.com/get-started',
  STRIPE_BILLING_LINK: 'https://checkout.withours.com/p/login/8wM2bK25ugH6geY4gg',
  TERMS_OF_SERVICE:
    'https://withours.notion.site/Ours-Wellness-Inc-Terms-and-Conditions-and-Privacy-Policy-d4445093e9254bcb87d756f64299270a',
  TWITTER_LINK: 'https://twitter.com/ourswellness',
};
