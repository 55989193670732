import { assertIsObject, assertIsUUID, assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import { store } from '../../store/_useStore';
import type { AppActions } from '../actionTypes';
import { determineIsOnboardingComplete } from '../lib/determineIsOnboardingComplete';
import type { AppContext } from '../types';

export const onSetMembershipInformation = assign<AppContext, AppActions>((ctx, rawEv) => {
  assertType(rawEv, 'ON_SET_MEMBERSHIP_INFORMATION');
  const ev = rawEv.data;
  assertIsObject(ev);
  const user = ev.onUserLogin;
  const membership = user?.latestMembership;
  assertIsUUID(user?.id);
  const role = user.role || 'CUSTOMER';
  if (role === 'GUIDE' || role === 'SUPERVISOR') {
    store.getState().adminMachine.send({ type: 'OnAlwaysShowPII' });
  }

  // eslint-disable-next-line complexity
  return produce(ctx, (d) => {
    d.content.user = {
      birthday: user.birthday,
      email: user.email || '',
      fullName: user.fullName || '',
      profilePhoto: user.profilePhotoSignedCDN || '',
      role,
      selfReportedReferrer: user.selfReportedReferrer,
      timezone: user.timezone,
      userId: user.id,
    };

    d.content.membership = {
      combinedStudentsName: membership?.combinedStudentsName || '',
      id: membership?.id || '',
      isOnboardingSessionComplete: determineIsOnboardingComplete(ev),
      onboardingSessionId: membership?.onboardingSessionId || '',
      purchasedSessionIds: [...(membership?.sessionIds || [])],
      purchaserUserEmail: membership?.resolvedValues?.purchaserUserEmail || '',
      purchaserUserFullName: membership?.resolvedValues?.purchaserUserEmail || '',
      purchaserUserSelfReportedReferrer: membership?.resolvedValues?.purchaserUserEmail || '',
    };
  });
});
