import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { LiveSessionActions, LiveSessionContext } from '../types';

export const onLocalVideoMove = assign<LiveSessionContext, LiveSessionActions>((ctx, ev) => {
  assertType(ev, 'ON_LOCAL_VIDEO_MOVE');

  return produce(ctx, (d) => {
    d.store.display.localPeerVideo.location = ev.location;
  });
});
