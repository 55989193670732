export const selectionToIcon = (str: string | undefined | null) =>
  `/shared/liveVideo/icons/${str || 'one.png'}`;
export const selectionToVideoBG = (str: string | undefined | null) =>
  `/shared/liveVideo/videoOff/${str || 'one.png'}`;

export const liveVideoIconNames = [
  'one.png',
  'two.png',
  'three.png',
  'four.png',
  'five.png',
  'six.png',
  'seven.png',
  'eight.png',
  'nine.png',
  'ten.png',
  'eleven.png',
];

export const VideoIconImageLink = '/shared/liveVideo/icons/';
export const VideoOffImageLink = '/shared/liveVideo/videoOff/';
