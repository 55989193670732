import type { Maybe } from '@ours/types';
import type { Stage } from '@ours/utils';
import { ANALYTICS_API_KEYS, assertIsString } from '@ours/utils';
import mixpanel from 'mixpanel-browser';

import { setMixpanelUTMParams } from './useUTMMixpanelEffect';

export const initMixpanel = (stage: Maybe<Stage>) => {
  assertIsString(stage);
  const apiKey = ANALYTICS_API_KEYS[stage];
  mixpanel.init(apiKey, { ignore_dnt: true, save_referrer: true, store_google: true });
  setMixpanelUTMParams();
};
