// eslint-disable-next-line
import{ uuid} from '../id/uuid';
import { addNodeBuildNextItem } from './addNodeBuildNextItem';
import type { IXStateState, ValidXStateInputNodes } from './xStateConstants';

interface IOptions {
  answer?: string;
  source: string;
  target: string;
}

export const addNode = (
  { data, id: _id, type }: Omit<ValidXStateInputNodes, 'on'> & { id?: string },
  xStateState: IXStateState,
  { answer, source, target }: IOptions
): IXStateState => {
  const id = _id || uuid();
  const newItemObj = addNodeBuildNextItem(target, { data, type });
  const srcOn =
    xStateState[source]?.type === 'ifBoolean'
      ? {
          // @ts-expect-error type this with type guards
          FALSE: answer === 'FALSE' ? id : xStateState[source]?.on.FALSE,
          // @ts-expect-error type this with type guards
          TRUE: answer === 'TRUE' ? id : xStateState[source]?.on.TRUE,
        }
      : { NEXT: id };

  const sourceObj = {
    [source]: { ...xStateState[source], on: srcOn },
  };

  // @ts-expect-error type this with type guards
  return { ...xStateState, [id]: newItemObj, ...sourceObj };
};
