import {
  assertIsUUID,
  findNextNodeFromCur,
  findStartNodeId,
  graphqlXStateToXState,
} from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import { sendOnUpdateXState } from '../../appMachine/externalActions/sendOnUpdateXState';
import { storeAddModulesById } from '../../appMachine/externalActions/storeAddModulesById';
import { storeAddPurchasableSessions } from '../../appMachine/externalActions/storeAddPurchasableSessions';
import { storeAddSession } from '../../appMachine/externalActions/storeAddSession';
import { normalizeGQLPurchaseSession } from '../../appMachine/lib/normalizeGQLPurchaseSession';
import { store } from '../../store/_useStore';
import type { ON_SESSION_LOADED, SessionEditorContext } from '../types';

export const onLoadSessionIntoContext = assign<SessionEditorContext, any>((ctx, ev) => {
  const action: ON_SESSION_LOADED = ev.data;
  const session = action.data;
  const sessionId = session.id;
  const xState = graphqlXStateToXState(session.resolvedValues?.sessionXState);
  const startingNodeId = findStartNodeId(xState);
  assertIsUUID(startingNodeId);
  const firstNodeId = findNextNodeFromCur(xState[startingNodeId]!);
  assertIsUUID(firstNodeId);
  const title = action.data.title || '';
  store.getState().app.send({ title: `Editing ${title}`, type: 'UPDATE_META' });

  storeAddModulesById([...(session?.resolvedValues?.modules || [])]);
  storeAddPurchasableSessions(
    normalizeGQLPurchaseSession(
      (session?.resolvedValues?.modules || []).flatMap(
        (m) => m.resolvedValues?.purchasableSessions || []
      )
    )
  );
  storeAddSession({ session, sessionId });
  sendOnUpdateXState({ sessionId: session.id, xState });

  return produce(ctx, (draft) => {
    draft.display.focusedNodeId = firstNodeId;
    draft.display.selectedNodeIds = [firstNodeId];
  });
});
