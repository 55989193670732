import { Box } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

import type { buttonSizes } from '../buttonVariants';
import { buttonLoaderBySize } from '../buttonVariants';

export interface ButtonLoaderProps {
  isLoading: boolean;
  size: keyof typeof buttonSizes;
  testId: string;
}

export const ButtonLoader: FC<ButtonLoaderProps> = memo(({ isLoading, size, testId }) => {
  const { Comp: Loader, loaderColor, loaderSize } = buttonLoaderBySize[size];
  return (
    <>
      {isLoading ? (
        <Box
          as="span"
          data-testid={testId ? `${testId}Loading` : undefined}
          display="flex"
          justifyContent="center"
          left="0"
          pos="absolute"
          right="0"
        >
          <Loader color={loaderColor} size={loaderSize} speedMultiplier={0.65} />
        </Box>
      ) : null}
    </>
  );
});
