import { unexpected } from '../error/unexpected';

import { isObject } from './isObject';

type Assert = <T extends string>(event: unknown, type: T) => asserts event is { type: T };

export const assertType: Assert = (event, type) => {
  if (!isObject(event) || event.type !== type) {
    throw unexpected({ name: 'UnexpectedType' });
  }
};
