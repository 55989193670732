import { produce } from 'immer';
import { assign } from 'xstate';

import type { CardDeckActions } from '../actionTypes';
import { userHasCompletedCardOnboarding } from '../actionTypes';
import type { CardDeckContext } from '../types';

export const onCompleteOnboarding = assign<CardDeckContext, CardDeckActions>((ctx) => {
  localStorage.setItem(userHasCompletedCardOnboarding, 'true');
  return produce(ctx, (d) => {
    d.hasCompletedOnboarding = true;
    return d;
  });
});
