import type { AppKind } from '@ours/utils';
import { gql } from 'graphql-tag';
import type { FC } from 'react';
import { memo, useState } from 'react';

import { useRequestMagicLinkMutation } from '../../generated/custom-hooks';
import { useBrandedToast } from '../../hooks/useBrandedToast';
import { useEvent } from '../../hooks/useEvent';
import { useOnError } from '../../hooks/useOnError';
import { useAppStage } from '../../state/appMachine/selectors/useAppStage';

import { LoginComponent } from './LoginComponent';
import { RequestedLinkContainer } from './RequestedLink/RequestedLinkContainer';

gql`
  mutation requestMagicLink($email: String!, $stage: Stage!, $appKind: AppKind!) {
    requestMagicLink(email: $email, stage: $stage, appKind: $appKind)
  }
`;
export interface LoginComponentContainerProps {
  appKind: AppKind;
}
type State = 'login' | 'requested';
export const LoginComponentContainer: FC<LoginComponentContainerProps> = memo(({ appKind }) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<State>('login');
  const [email, setEmail] = useState('');
  const onError = useOnError();
  const [requestLink] = useRequestMagicLinkMutation({ onError });
  const toast = useBrandedToast();
  const stage = useAppStage();

  const onSubmit = useEvent(async (email: string) => {
    setLoading(true);
    const response = await requestLink({ variables: { appKind, email, stage } });
    if (response.data?.requestMagicLink === 'Error') {
      toast({ title: 'Account not found.' });
    } else {
      setState('requested');
      setEmail(email);
    }
    setLoading(false);
  });

  const onToLogin = useEvent(() => {
    setState('login');
  });

  return state === 'login' ? (
    <LoginComponent loading={loading} onSubmit={onSubmit} />
  ) : (
    <RequestedLinkContainer email={email} onToLogin={onToLogin} />
  );
});
