import { produce } from 'immer';
import { assign } from 'xstate';

import type { CartActions } from '../actionTypes';
import type { CartContext } from '../types';

// IMPROVE: Tell them if it was a card or b/c they already owned stuff
export const onPurchaseError = assign<CartContext, CartActions>((ctx) => {
  return produce(ctx, (d) => {
    d.errorMessage = 'Payment failed.';
  });
});
