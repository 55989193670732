import type { IXStateNodeTypes } from './xStateConstants';
import { CONDITION_PROPERTY } from './xStateConstants';

interface IOptions {
  ifBoolean?: (condition: string, responses: Record<string, any>) => boolean;
  responses?: Record<string, any>;
}

export const findNextNodeFromCur = (step: IXStateNodeTypes, options?: IOptions) => {
  let bestNext: string | undefined;
  if (step.type === 'module' || step.type === 'start') {
    bestNext = step.on.NEXT;
  } else if (step.type === 'ifBoolean') {
    const isTrue = options?.ifBoolean
      ? options.ifBoolean(step.data[CONDITION_PROPERTY], options?.responses || {})
      : true;
    bestNext = step.on[isTrue ? 'TRUE' : 'FALSE'];
  } else if (step.type === 'final') {
    bestNext = undefined;
  } else {
    throw 'IMPLEMENT useStateMachine.next for type: ' + step.type;
  }

  return bestNext;
};
