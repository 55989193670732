export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Banking account number is a string of 5 to 17 alphanumeric values for representing an generic account number */
  AccountNumber: never;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: never;
  /** The `Byte` scalar type represents byte value as a Buffer */
  Byte: never;
  /** A country code as defined by ISO 3166-1 alpha-2 */
  CountryCode: never;
  /** A field whose value conforms to the standard cuid format as specified in https://github.com/ericelliott/cuid#broken-down */
  Cuid: never;
  /** A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217. */
  Currency: never;
  /** A field whose value conforms to the standard DID format as specified in did-core: https://www.w3.org/TR/did-core/. */
  DID: never;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  Duration: never;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: string;
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  GUID: never;
  /** A field whose value is a CSS HSL color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#hsl()_and_hsla(). */
  HSL: never;
  /** A field whose value is a CSS HSLA color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#hsl()_and_hsla(). */
  HSLA: never;
  /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
  HexColorCode: never;
  /** A field whose value is a hexadecimal: https://en.wikipedia.org/wiki/Hexadecimal. */
  Hexadecimal: never;
  /** A field whose value is an International Bank Account Number (IBAN): https://en.wikipedia.org/wiki/International_Bank_Account_Number. */
  IBAN: never;
  /** A field whose value is either an IPv4 or IPv6 address: https://en.wikipedia.org/wiki/IP_address. */
  IP: never;
  /** A field whose value is a IPv4 address: https://en.wikipedia.org/wiki/IPv4. */
  IPv4: never;
  /** A field whose value is a IPv6 address: https://en.wikipedia.org/wiki/IPv6. */
  IPv6: never;
  /** A field whose value is a ISBN-10 or ISBN-13 number: https://en.wikipedia.org/wiki/International_Standard_Book_Number. */
  ISBN: never;
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  ISO8601Duration: never;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: never;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: Record < string, any>;
  /** A JSONObject where the key is a string and the value is a boolean */
  JSONObjectStringBoolean: Record<string, boolean>;
  /** A JSONObject where the key is a string and the value is a number */
  JSONObjectStringNumber: Record<string, number>;
  /** A JSONObject where the key is a string and the value is a string */
  JSONObjectStringString: Record<string, string>;
  /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
  JWT: never;
  /** A field whose value is a valid decimal degrees latitude number (53.471): https://en.wikipedia.org/wiki/Latitude */
  Latitude: never;
  /** A local date string (i.e., with no associated timezone) in `YYYY-MM-DD` format, e.g. `2020-01-01`. */
  LocalDate: never;
  /** A local time string (i.e., with no associated timezone) in 24-hr `HH:mm[:ss[.SSS]]` format, e.g. `14:25` or `14:25:06` or `14:25:06.123`.  This scalar is very similar to the `LocalTime`, with the only difference being that `LocalEndTime` also allows `24:00` as a valid value to indicate midnight of the following day.  This is useful when using the scalar to represent the exclusive upper bound of a time block. */
  LocalEndTime: never;
  /** A local time string (i.e., with no associated timezone) in 24-hr `HH:mm[:ss[.SSS]]` format, e.g. `14:25` or `14:25:06` or `14:25:06.123`. */
  LocalTime: never;
  /** The locale in the format of a BCP 47 (RFC 5646) standard string */
  Locale: never;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  Long: never;
  /** A string shorter than 8000 char */
  LongString: string;
  /** A field whose value is a valid decimal degrees longitude number (53.471): https://en.wikipedia.org/wiki/Longitude */
  Longitude: never;
  /** A field whose value is a IEEE 802 48-bit MAC address: https://en.wikipedia.org/wiki/MAC_address. */
  MAC: never;
  /** Floats that will have a value less than 0. */
  NegativeFloat: never;
  /** Integers that will have a value less than 0. */
  NegativeInt: never;
  /** A string that cannot be passed as an empty value */
  NonEmptyString: string;
  /** Floats that will have a value of 0 or more. */
  NonNegativeFloat: number;
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: number;
  /** Floats that will have a value of 0 or less. */
  NonPositiveFloat: number;
  /** Integers that will have a value of 0 or less. */
  NonPositiveInt: number;
  /** A field whose value conforms with the standard mongodb object ID as described here: https://docs.mongodb.com/manual/reference/method/ObjectId/#ObjectId. Example: 5e5677d71bdc2ae76344968c */
  ObjectID: never;
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  PhoneNumber: string;
  /** A field whose value is a valid TCP port within the range of 0 to 65535: https://en.wikipedia.org/wiki/Transmission_Control_Protocol#TCP_ports */
  Port: never;
  /** Floats that will have a value greater than 0. */
  PositiveFloat: number;
  /** Integers that will have a value greater than 0. */
  PositiveInt: number;
  /** A field whose value conforms to the standard postal code formats for United States, United Kingdom, Germany, Canada, France, Italy, Australia, Netherlands, Spain, Denmark, Sweden, Belgium, India, Austria, Portugal, Switzerland or Luxembourg. */
  PostalCode: never;
  /** A field whose value is a CSS RGB color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba(). */
  RGB: never;
  /** A field whose value is a CSS RGBA color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba(). */
  RGBA: never;
  /** In the US, an ABA routing transit number (`ABA RTN`) is a nine-digit code to identify the financial institution. */
  RoutingNumber: never;
  /** The `SafeInt` scalar type represents non-fractional signed whole numeric values that are considered safe as defined by the ECMAScript specification. */
  SafeInt: never;
  /** A field whose value is a Semantic Version: https://semver.org */
  SemVer: never;
  /** A string shorter than 4000 char */
  ShortString: string;
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: string;
  /** A field whose value exists in the standard IANA Time Zone Database: https://www.iana.org/time-zones */
  TimeZone: never;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: number;
  /** Accepts any valid timezone. List of available [timezones is here](https://en.wikipedia.org/wiki/List_of_tz_database_time_zones) (column "TZ database name"). */
  Timezone: string;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: string;
  /** A currency string, such as $21.25 */
  USCurrency: never;
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: string;
  /** Floats that will have a value of 0 or more. */
  UnsignedFloat: never;
  /** Integers that will have a value of 0 or more. */
  UnsignedInt: never;
  /** A field whose value is a UTC Offset: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */
  UtcOffset: never;
  /** Represents NULL values */
  Void: never;
};

export type AddSendGridIdPayload = {
  readonly from?: InputMaybe<Scalars['String']>;
  readonly s3Key?: InputMaybe<Scalars['String']>;
  readonly sendGridMessageId: Scalars['String'];
  readonly subject?: InputMaybe<Scalars['String']>;
};

export const AnimatedVariant = {
  One: 'One',
  Three: 'Three',
  Two: 'Two'
} as const;

export type AnimatedVariant = typeof AnimatedVariant[keyof typeof AnimatedVariant];
export const AnswerVisibility = {
  Admins: 'Admins',
  Public: 'Public'
} as const;

export type AnswerVisibility = typeof AnswerVisibility[keyof typeof AnswerVisibility];
export const AppKind = {
  Admin: 'admin',
  Couple: 'couple'
} as const;

export type AppKind = typeof AppKind[keyof typeof AppKind];
export type AudioAssets = {
  readonly __typename?: 'AudioAssets';
  readonly isMP3Ready?: Maybe<Scalars['Boolean']>;
  readonly isTranscriptReady?: Maybe<Scalars['Boolean']>;
  readonly signedAudioMP3?: Maybe<Scalars['String']>;
  readonly transcriptCues?: Maybe<ReadonlyArray<TranscriptCue>>;
};

export type AudioCaptionPlayerSettings = {
  readonly __typename?: 'AudioCaptionPlayerSettings';
  readonly theme?: Maybe<AudioCaptionPlayerTheme>;
};

export const AudioCaptionPlayerTheme = {
  ColoredWords: 'ColoredWords'
} as const;

export type AudioCaptionPlayerTheme = typeof AudioCaptionPlayerTheme[keyof typeof AudioCaptionPlayerTheme];
export type AvailabilityPatchInput = {
  readonly endDateTime: Scalars['DateTime'];
  readonly startDateTime: Scalars['DateTime'];
};

export type AvailabilityQueryArgs = {
  readonly isThrottled: Scalars['Boolean'];
  readonly membershipId: Scalars['UUID'];
  readonly sessionId: Scalars['UUID'];
};

/**
 * A bookmark denotes a media or piece of content that the user on a membership wanted to save.
 *
 * @see https://www.notion.so/withours/Bookmarks-8ec337d498674620ae81567882cc265d
 */
export type Bookmark = {
  readonly __typename?: 'Bookmark';
  readonly actions?: Maybe<BookmarkActions>;
  readonly content: Scalars['String'];
  readonly createdAt: Scalars['Float'];
  readonly createdByUserId: Scalars['UUID'];
  readonly endTimeStampSec: Scalars['Int'];
  readonly id: Scalars['UUID'];
  readonly kind: Kinds;
  readonly membershipId: Scalars['UUID'];
  readonly moduleId: Scalars['UUID'];
  readonly resolvedValues?: Maybe<BookmarkResolved>;
  readonly sessionId: Scalars['UUID'];
  readonly startTimeStampSec: Scalars['Int'];
  readonly updatedAt?: Maybe<Scalars['Float']>;
};

export type BookmarkActions = {
  readonly __typename?: 'BookmarkActions';
  readonly removeBookmark?: Maybe<Scalars['Boolean']>;
};


export type BookmarkActionsRemoveBookmarkArgs = {
  id: Scalars['UUID'];
};

export type BookmarkResolved = {
  readonly __typename?: 'BookmarkResolved';
  readonly createdByUser?: Maybe<PartialUser>;
  readonly membership?: Maybe<Membership>;
  readonly module?: Maybe<Module>;
  readonly session?: Maybe<Session>;
};

export const BrandColors = {
  Beige: 'beige',
  Blue: 'blue',
  BlueDisabled: 'blueDisabled',
  BlueHover: 'blueHover',
  Orange: 'orange',
  OrangeDisabled: 'orangeDisabled',
  OrangeHover: 'orangeHover',
  Pink: 'pink',
  PinkDisabled: 'pinkDisabled',
  PinkHover: 'pinkHover',
  Red: 'red',
  RedDisabled: 'redDisabled',
  RedHover: 'redHover',
  Yellow: 'yellow',
  YellowDisabled: 'yellowDisabled',
  YellowHover: 'yellowHover'
} as const;

export type BrandColors = typeof BrandColors[keyof typeof BrandColors];
export const CancelReason = {
  Cancelled: 'Cancelled',
  Missed: 'Missed',
  Rescheduled: 'Rescheduled'
} as const;

export type CancelReason = typeof CancelReason[keyof typeof CancelReason];
export const CardBrand = {
  Amex: 'amex',
  Diners: 'diners',
  Discover: 'discover',
  Jcb: 'jcb',
  Mastercard: 'mastercard',
  Unionpay: 'unionpay',
  Unknown: 'unknown',
  Visa: 'visa'
} as const;

export type CardBrand = typeof CardBrand[keyof typeof CardBrand];
export const ChangeEventName = {
  Insert: 'INSERT',
  Modify: 'MODIFY',
  Remove: 'REMOVE',
  Unknown: 'UNKNOWN'
} as const;

export type ChangeEventName = typeof ChangeEventName[keyof typeof ChangeEventName];
export type ChangeHistory = {
  readonly __typename?: 'ChangeHistory';
  readonly eventName?: Maybe<ChangeEventName>;
  readonly id?: Maybe<Scalars['String']>;
  readonly kind?: Maybe<Kinds>;
  readonly next?: Maybe<Scalars['String']>;
  readonly prev?: Maybe<Scalars['String']>;
  readonly time?: Maybe<Scalars['Float']>;
  readonly user?: Maybe<PartialUser>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** Configuration for the application */
export type Configuration = {
  readonly __typename?: 'Configuration';
  readonly bannerLink?: Maybe<Scalars['String']>;
  readonly bannerText?: Maybe<Scalars['String']>;
  readonly coupleHasANewGuide?: Maybe<FeatureFlag>;
  readonly customerOnSessionComplete?: Maybe<FeatureFlag>;
  readonly customerWelcomeEmail?: Maybe<FeatureFlag>;
  readonly guideCoupleStartedSession?: Maybe<FeatureFlag>;
  readonly guideNotifiedOfSessionCompletion?: Maybe<FeatureFlag>;
  readonly invitePartnerToMembership?: Maybe<FeatureFlag>;
  readonly inviteUserEmail?: Maybe<FeatureFlag>;
  readonly newLiveEventToGuide?: Maybe<FeatureFlag>;
  readonly postProgramFeedbackEmail?: Maybe<FeatureFlag>;
  readonly postProgramReferralEmail?: Maybe<FeatureFlag>;
  readonly schedule2HourReminder?: Maybe<FeatureFlag>;
  readonly schedule48HourReminder?: Maybe<FeatureFlag>;
  readonly sundayGuidesAndSupervisors?: Maybe<FeatureFlag>;
  readonly welcomeSessionReminder?: Maybe<FeatureFlag>;
  readonly welcomeSurveyReminder?: Maybe<FeatureFlag>;
};

export type ConfigurationInput = {
  readonly bannerLink?: InputMaybe<Scalars['String']>;
  readonly bannerText?: InputMaybe<Scalars['String']>;
  readonly coupleHasANewGuide?: InputMaybe<FeatureFlag>;
  readonly customerOnSessionComplete?: InputMaybe<FeatureFlag>;
  readonly customerWelcomeEmail?: InputMaybe<FeatureFlag>;
  readonly guideCoupleStartedSession?: InputMaybe<FeatureFlag>;
  readonly guideNotifiedOfSessionCompletion?: InputMaybe<FeatureFlag>;
  readonly invitePartnerToMembership?: InputMaybe<FeatureFlag>;
  readonly inviteUserEmail?: InputMaybe<FeatureFlag>;
  readonly newLiveEventToGuide?: InputMaybe<FeatureFlag>;
  readonly postProgramFeedbackEmail?: InputMaybe<FeatureFlag>;
  readonly postProgramReferralEmail?: InputMaybe<FeatureFlag>;
  readonly schedule2HourReminder?: InputMaybe<FeatureFlag>;
  readonly schedule48HourReminder?: InputMaybe<FeatureFlag>;
  readonly sundayGuidesAndSupervisors?: InputMaybe<FeatureFlag>;
  readonly welcomeSessionReminder?: InputMaybe<FeatureFlag>;
  readonly welcomeSurveyReminder?: InputMaybe<FeatureFlag>;
};

export type Content = {
  readonly __typename?: 'Content';
  readonly content?: Maybe<Scalars['LongString']>;
  readonly contentKind: ContentKind;
  readonly id: Scalars['UUID'];
  readonly title?: Maybe<Scalars['ShortString']>;
};

export type ContentFilter = {
  readonly contentKind?: InputMaybe<StringFilter>;
};

export type ContentInput = {
  readonly content: Scalars['LongString'];
  readonly contentKind: ContentKind;
  readonly title: Scalars['ShortString'];
};

export const ContentKind = {
  AppContent: 'AppContent',
  PersonalizedNote: 'PersonalizedNote',
  WeeklyChallenges: 'WeeklyChallenges'
} as const;

export type ContentKind = typeof ContentKind[keyof typeof ContentKind];
export type CreateMembershipInput = {
  readonly id?: InputMaybe<Scalars['UUID']>;
  readonly initialPaymentCents?: InputMaybe<Scalars['Int']>;
  readonly onboardingSessionId?: InputMaybe<Scalars['UUID']>;
  readonly purchaserUserId: Scalars['UUID'];
};

export type CreateNoteInput = {
  readonly content?: InputMaybe<Scalars['LongString']>;
  readonly entityId: Scalars['UUID'];
  readonly entityKind: Kinds;
  readonly id?: InputMaybe<Scalars['UUID']>;
};

export type CreatePersonalVideoInput = {
  readonly id?: InputMaybe<Scalars['UUID']>;
  readonly membershipId: Scalars['UUID'];
  readonly tag: MediaTag;
  readonly videoDirectory: Scalars['ShortString'];
};

export type CustomerConfig = {
  readonly __typename?: 'CustomerConfig';
  readonly bannerLink?: Maybe<Scalars['String']>;
  readonly bannerText?: Maybe<Scalars['String']>;
};

export type DateFilter = {
  readonly eq?: InputMaybe<Scalars['String']>;
  readonly exists?: InputMaybe<Scalars['Boolean']>;
  readonly gt?: InputMaybe<Scalars['String']>;
  readonly gte?: InputMaybe<Scalars['String']>;
  readonly lt?: InputMaybe<Scalars['String']>;
  readonly lte?: InputMaybe<Scalars['String']>;
};

export const DeviceCategory = {
  Desktop: 'desktop',
  Mobile: 'mobile',
  Tablet: 'tablet',
  Tv: 'tv',
  Unknown: 'unknown'
} as const;

export type DeviceCategory = typeof DeviceCategory[keyof typeof DeviceCategory];
export const DisplayKind = {
  Animation: 'Animation',
  AudioCaptionPlayer: 'AudioCaptionPlayer',
  Image: 'Image',
  Text: 'Text',
  Video: 'Video'
} as const;

export type DisplayKind = typeof DisplayKind[keyof typeof DisplayKind];
export type DownloadItem = {
  readonly __typename?: 'DownloadItem';
  readonly description?: Maybe<Scalars['String']>;
  readonly src?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
};

export type DownloadItemInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly src?: InputMaybe<Scalars['String']>;
  readonly title?: InputMaybe<Scalars['String']>;
};

/**
 * An email is sent by a guide/admin, an automated system email (ex: magic link email), or an inbound email to our system.
 *
 * IDs:
 *   - Inbound emails are assigned an id on creation.
 *   - Outbound and system emails use the id generated from SendGrid
 */
export type Email = {
  readonly __typename?: 'Email';
  /**
   * Primarily for inbound email - this tracks if the email was read by an admin.
   *
   * It's at a global level and not at a per-user level.
   */
  readonly adminRead?: Maybe<Scalars['Boolean']>;
  /**
   * Guides get automatically assigned if it is to them if there is a “+” deliniated key for the email address.
   * Guides get automatically assigned if at the time of the email coming in there is a knownEmail that has a membership with a guide
   */
  readonly assignedAdminIds?: Maybe<ReadonlyArray<Scalars['UUID']>>;
  readonly attachmentS3Keys?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly bcc?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly cc?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly content?: Maybe<EmailContent>;
  readonly createdAt: Scalars['Float'];
  readonly createdByUserId: Scalars['String'];
  readonly emailEvents?: Maybe<ReadonlyArray<EmailEvent>>;
  readonly emailKind?: Maybe<EmailKind>;
  readonly from?: Maybe<Scalars['String']>;
  readonly id: Scalars['UUID'];
  readonly kind: Kinds;
  readonly labels?: Maybe<ReadonlyArray<EmailLabels>>;
  readonly membershipId?: Maybe<Scalars['UUID']>;
  /**
   * This should be for searching only and should not be expected to contain the whole formatted body.
   *
   * You can get the body and the full email from the s3Key
   */
  readonly partialBody?: Maybe<Scalars['String']>;
  /** Contains the raw email document */
  readonly rawS3Key?: Maybe<Scalars['String']>;
  /** Contains JSON.stringify({ html, text }) of the email content for all inbound and outbound emails */
  readonly s3Key?: Maybe<Scalars['String']>;
  /** The "ID" SendGrid gives to our email. This is used for outbound emails only. */
  readonly sendGridMessageId?: Maybe<Scalars['String']>;
  /**
   * The in reply id
   *
   * @see https://stackoverflow.com/questions/45690336/do-all-email-clients-use-in-reply-to-field-in-email-header
   */
  readonly smtpInReplyId?: Maybe<Scalars['String']>;
  /**
   * The Message-ID per the email protocol.
   * @example "<CAMYrRkvjPQvSRwpFRRTbMQ+SaKjeW_O_PD=2ZqFRXcb23Whovw@mail.gmail.com>"
   */
  readonly smtpMessageId?: Maybe<Scalars['String']>;
  readonly subject?: Maybe<Scalars['String']>;
  /**
   * An array of emails or Full Name <user@domain.com>
   * Example: ['user@domain.com', 'Full Name <user@domain.com>']
   */
  readonly to?: Maybe<ReadonlyArray<Scalars['String']>>;
  /**
   * The users attached to this email message.
   * Users get automatically assigned if there is a user with this email address in the emails table
   * Admin/Guide users can patch this field.
   */
  readonly userIds?: Maybe<ReadonlyArray<Scalars['UUID']>>;
  readonly wasBlocked?: Maybe<Scalars['Boolean']>;
  readonly wasBounced?: Maybe<Scalars['Boolean']>;
  readonly wasClicked?: Maybe<Scalars['Boolean']>;
  /**
   * This is a cache of booleans if we have at least one event for any of the following email activity.
   *
   * It makes querying easier.
   *
   * If you need more detailed data, pull the emailEvent data directly for the email in question.
   */
  readonly wasDelivered?: Maybe<Scalars['Boolean']>;
  readonly wasOpened?: Maybe<Scalars['Boolean']>;
  readonly wasSpamReport?: Maybe<Scalars['Boolean']>;
  readonly wasUnsubscribe?: Maybe<Scalars['Boolean']>;
};

export type EmailContent = {
  readonly __typename?: 'EmailContent';
  readonly html?: Maybe<Scalars['String']>;
  readonly text?: Maybe<Scalars['String']>;
};

/**
 * An event that happened for an email sent by our system or our users.
 *
 * @see https://docs.sendgrid.com/for-developers/tracking-events/event
 */
export type EmailEvent = {
  readonly __typename?: 'EmailEvent';
  readonly clickedUrl?: Maybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly entityId?: Maybe<Scalars['String']>;
  readonly eventKind?: Maybe<EmailEventKind>;
  readonly id?: Maybe<Scalars['String']>;
  readonly ip?: Maybe<Scalars['String']>;
  readonly sendGridEventID: Scalars['String'];
  readonly sendGridMessageId: Scalars['String'];
  readonly timestamp: Scalars['Float'];
  readonly userAgent?: Maybe<Scalars['String']>;
};

export type EmailEventInput = {
  readonly clickedUrl?: InputMaybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly eventKind?: InputMaybe<EmailEventKind>;
  readonly ip?: InputMaybe<Scalars['String']>;
  readonly sendGridEventID: Scalars['String'];
  readonly sendGridMessageId: Scalars['String'];
  readonly timestamp: Scalars['Float'];
  readonly userAgent?: InputMaybe<Scalars['String']>;
};

export const EmailEventKind = {
  Bounce: 'bounce',
  Click: 'click',
  Deferred: 'deferred',
  Delivered: 'delivered',
  Dropped: 'dropped',
  GroupResubscribe: 'group_resubscribe',
  GroupUnsubscribe: 'group_unsubscribe',
  Open: 'open',
  Processed: 'processed',
  Spamreport: 'spamreport',
  Unsubscribe: 'unsubscribe'
} as const;

export type EmailEventKind = typeof EmailEventKind[keyof typeof EmailEventKind];
/** Designates whether the email is */
export const EmailKind = {
  Inbound: 'INBOUND',
  Outbound: 'OUTBOUND',
  System: 'SYSTEM'
} as const;

export type EmailKind = typeof EmailKind[keyof typeof EmailKind];
export const EmailLabels = {
  Archived: 'ARCHIVED'
} as const;

export type EmailLabels = typeof EmailLabels[keyof typeof EmailLabels];
export type EmailShare = {
  readonly content: Scalars['String'];
  readonly email: Scalars['EmailAddress'];
};

/**
 * An event is a scheduled activity in the application.
 *
 * Memberships have events for module and live sessions. The live sessions have guideIds.
 */
export type Event = {
  readonly __typename?: 'Event';
  readonly archivedAt?: Maybe<Scalars['Float']>;
  readonly cancelReason?: Maybe<CancelReason>;
  readonly createdAt: Scalars['Float'];
  readonly createdByUser?: Maybe<PartialUser>;
  readonly createdByUserId: Scalars['String'];
  readonly endTime?: Maybe<Scalars['DateTime']>;
  readonly guide?: Maybe<PartialUser>;
  /** IMPROVE: This should be hostId now that we have admin hosts */
  readonly guideId?: Maybe<Scalars['UUID']>;
  readonly hmsRoomId?: Maybe<Scalars['ShortString']>;
  readonly id: Scalars['UUID'];
  /** IMPROVE: joinUrl is unneeded once we stop using Zoom URLs. */
  readonly joinUrl?: Maybe<Scalars['String']>;
  readonly kind: Kinds;
  readonly membership?: Maybe<Membership>;
  readonly membershipId?: Maybe<Scalars['UUID']>;
  readonly mode?: Maybe<ScheduleEventMode>;
  readonly resolvedValues?: Maybe<EventResolved>;
  readonly session?: Maybe<Session>;
  readonly sessionId: Scalars['UUID'];
  readonly startTime: Scalars['DateTime'];
  readonly title?: Maybe<Scalars['String']>;
  readonly token?: Maybe<Scalars['UUID']>;
  /** @deprecated */
  readonly type?: Maybe<Scalars['ShortString']>;
  readonly updatedAt?: Maybe<Scalars['Float']>;
  readonly updatedByUser?: Maybe<PartialUser>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly zoomMeetingId?: Maybe<Scalars['Int']>;
};

export type EventFilter = {
  readonly endTime?: InputMaybe<DateFilter>;
  readonly guideId?: InputMaybe<UuidFilter>;
  readonly isMyEvents?: InputMaybe<Scalars['Boolean']>;
  readonly startTime?: InputMaybe<DateFilter>;
};

export type EventResolved = {
  readonly __typename?: 'EventResolved';
  readonly guide?: Maybe<PartialUser>;
  readonly isHost?: Maybe<Scalars['Boolean']>;
  readonly membership?: Maybe<Membership>;
  readonly session?: Maybe<Session>;
};

export type EventSession = {
  readonly __typename?: 'EventSession';
  readonly event?: Maybe<Event>;
  readonly membership?: Maybe<Membership>;
  readonly session?: Maybe<Session>;
  readonly timeSlots?: Maybe<ReadonlyArray<TimeSlot>>;
};

export const FeatureFlag = {
  Disabled: 'Disabled',
  Enabled: 'Enabled'
} as const;

export type FeatureFlag = typeof FeatureFlag[keyof typeof FeatureFlag];
export type FrameToPhotoInput = {
  readonly kind: FrameToPhotoKind;
  readonly s3Key: Scalars['String'];
  readonly size: FrameToPhotoSize;
};

export const FrameToPhotoKind = {
  Confetti: 'confetti',
  Plain: 'plain',
  Sparkles: 'sparkles',
  Stacked: 'stacked'
} as const;

export type FrameToPhotoKind = typeof FrameToPhotoKind[keyof typeof FrameToPhotoKind];
export const FrameToPhotoSize = {
  FourBySix: 'FourBySix',
  NineByNine: 'NineByNine',
  NineBySixteen: 'NineBySixteen'
} as const;

export type FrameToPhotoSize = typeof FrameToPhotoSize[keyof typeof FrameToPhotoSize];
export type ImageParams = {
  /**
   * Disallows the server from modifying the image from its current content-type to a better once (webp // avif) based on the "Accepts" header.
   * @default - false
   */
  readonly disallowContentTypeModification?: InputMaybe<Scalars['Boolean']>;
  readonly maxW?: InputMaybe<Scalars['Int']>;
  /**
   * Allows downsizing the image quality
   * @default - 100
   */
  readonly quality?: InputMaybe<Scalars['Int']>;
};

export const IncludeArchived = {
  All: 'All',
  Archived: 'Archived',
  UnArchived: 'UnArchived'
} as const;

export type IncludeArchived = typeof IncludeArchived[keyof typeof IncludeArchived];
export type InputPaymentBlock = {
  readonly bulletPoints?: InputMaybe<ReadonlyArray<Scalars['ShortString']>>;
  readonly isMostPopular?: InputMaybe<Scalars['Boolean']>;
  readonly pricingDescription?: InputMaybe<Scalars['ShortString']>;
  readonly stripeId?: InputMaybe<Scalars['ShortString']>;
  readonly subtitle?: InputMaybe<Scalars['ShortString']>;
  readonly title?: InputMaybe<Scalars['ShortString']>;
};

export type InputQuizPairs = {
  readonly answer: Scalars['ShortString'];
  readonly question: Scalars['ShortString'];
};

export const InputType = {
  Date: 'date',
  Email: 'email',
  Phone: 'phone',
  Text: 'text'
} as const;

export type InputType = typeof InputType[keyof typeof InputType];
export type IntFilter = {
  readonly eq?: InputMaybe<Scalars['Float']>;
  readonly gt?: InputMaybe<Scalars['Float']>;
  readonly gte?: InputMaybe<Scalars['Float']>;
  readonly lt?: InputMaybe<Scalars['Float']>;
  readonly lte?: InputMaybe<Scalars['Float']>;
};

export const InteractionKind = {
  AudioRecording: 'AudioRecording',
  Button: 'Button',
  Camera: 'Camera',
  Discussion: 'Discussion',
  Downloadable: 'Downloadable',
  Drawing: 'Drawing',
  ImageUpload: 'ImageUpload',
  InAppPurchase: 'InAppPurchase',
  Input: 'Input',
  Journal: 'Journal',
  JournalSeparate: 'JournalSeparate',
  MacroModules: 'MacroModules',
  MultipleChoice: 'MultipleChoice',
  NumberSelect: 'NumberSelect',
  OpenEndedText: 'OpenEndedText',
  Photobooth: 'Photobooth',
  PricingBlock: 'PricingBlock',
  QuizMatching: 'QuizMatching',
  Scheduler: 'Scheduler',
  SentenceBuilder: 'SentenceBuilder',
  TrueFalseButton: 'TrueFalseButton',
  VideoRecording: 'VideoRecording'
} as const;

export type InteractionKind = typeof InteractionKind[keyof typeof InteractionKind];
/** InviteUserInput can only be called by Admins */
export type InviteUserInput = {
  readonly email: Scalars['ShortString'];
  readonly fullName?: InputMaybe<Scalars['ShortString']>;
  readonly role: Role;
};

export const Kinds = {
  AvailabilityException: 'availabilityException',
  Bookmark: 'bookmark',
  Configuration: 'configuration',
  Content: 'content',
  Email: 'email',
  EmailEvent: 'emailEvent',
  Event: 'event',
  File: 'file',
  Haiku: 'haiku',
  Media: 'media',
  Membership: 'membership',
  MembershipAnalytics: 'membershipAnalytics',
  Module: 'module',
  ModuleResponse: 'moduleResponse',
  Note: 'note',
  Purchase: 'purchase',
  Response: 'response',
  Session: 'session',
  SessionProgress: 'sessionProgress',
  UploadCode: 'uploadCode',
  User: 'user'
} as const;

export type Kinds = typeof Kinds[keyof typeof Kinds];
export const MagicLinkResponse = {
  Error: 'Error',
  Success: 'Success'
} as const;

export type MagicLinkResponse = typeof MagicLinkResponse[keyof typeof MagicLinkResponse];
/** A Membership is the purchase of a path. Usually, two users will become associated with this membership and their progress through the associated Path will be tracked. */
export type Media = {
  readonly __typename?: 'Media';
  readonly archivedAt?: Maybe<Scalars['Float']>;
  readonly createdAt: Scalars['Float'];
  readonly createdByUser?: Maybe<PartialUser>;
  readonly createdByUserId: Scalars['String'];
  readonly creatorId: Scalars['UUID'];
  readonly id: Scalars['UUID'];
  readonly imageS3Key?: Maybe<Scalars['ShortString']>;
  readonly kind: Kinds;
  /** Indicates the media was created for a specific membership. (Most likely by a guide for that specific membership) */
  readonly membershipId?: Maybe<Scalars['UUID']>;
  readonly resolvedValues?: Maybe<MediaResolved>;
  readonly signedImage?: Maybe<Scalars['String']>;
  readonly tag?: Maybe<MediaTag>;
  readonly type?: Maybe<MediaType>;
  readonly updatedAt?: Maybe<Scalars['Float']>;
  readonly updatedByUser?: Maybe<PartialUser>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly video?: Maybe<VideoAssets>;
  readonly videoDirectory?: Maybe<Scalars['ShortString']>;
  readonly visibility?: Maybe<MediaVisibility>;
};


/** A Membership is the purchase of a path. Usually, two users will become associated with this membership and their progress through the associated Path will be tracked. */
export type MediaSignedImageArgs = {
  image?: InputMaybe<ImageParams>;
};

export type MediaResolved = {
  readonly __typename?: 'MediaResolved';
  readonly membership?: Maybe<Membership>;
};

export const MediaTag = {
  Generic: 'Generic',
  WelcomeVideo: 'WelcomeVideo'
} as const;

export type MediaTag = typeof MediaTag[keyof typeof MediaTag];
export const MediaType = {
  Image: 'Image',
  Video: 'Video'
} as const;

export type MediaType = typeof MediaType[keyof typeof MediaType];
export const MediaVisibility = {
  /** Can be queried directly from a client if the membershipId matches the media's membershipId */
  MembershipId: 'MembershipId',
  /** Only visible when resolve on the backend via a parental relationship */
  Parent: 'Parent'
} as const;

export type MediaVisibility = typeof MediaVisibility[keyof typeof MediaVisibility];
/** A Membership is the purchase of a path. Usually, two users will become associated with this membership and their progress through the associated Path will be tracked. */
export type Membership = {
  readonly __typename?: 'Membership';
  /** @deprecated */
  readonly anniversaryDate?: Maybe<Scalars['Date']>;
  readonly archivedAt?: Maybe<Scalars['Float']>;
  readonly combinedStudentsName?: Maybe<Scalars['String']>;
  readonly combinedStudentsNameExclusive?: Maybe<Scalars['String']>;
  /**
   * Returns completed sessionIds that are in the current sessionIds list
   * The onboarding session will not be in this list. Also, any completed sessions that were then removed from the membership will be discarded.
   */
  readonly completedContentSessionIds?: Maybe<ReadonlyArray<Scalars['UUID']>>;
  /** Returns all completed sessionIds */
  readonly completedSessionIds?: Maybe<ReadonlyArray<Scalars['UUID']>>;
  readonly createdAt: Scalars['Float'];
  readonly createdByUser?: Maybe<PartialUser>;
  readonly createdByUserId: Scalars['String'];
  /** @deprecated */
  readonly engagementDate?: Maybe<Scalars['Date']>;
  readonly firstModuleSessionId?: Maybe<Scalars['UUID']>;
  readonly firstSessionEvent?: Maybe<Event>;
  readonly guide?: Maybe<PartialUser>;
  /** This is a manually adjusted field by the guide. It loosely designates when the experience is supposed to end. */
  readonly guideEndsAt?: Maybe<Scalars['Date']>;
  readonly guideId?: Maybe<Scalars['UUID']>;
  readonly id: Scalars['UUID'];
  readonly initialPaymentCents?: Maybe<Scalars['Int']>;
  readonly kind: Kinds;
  readonly lastModuleSessionId?: Maybe<Scalars['UUID']>;
  /**
   * The category of customer that the membership fits into. Eng controlled to help w upsell recommendations and dashboard personalization
   * Examples: Romantic, ParentChild, CoWorker
   */
  readonly membershipCategories?: Maybe<ReadonlyArray<PurchaseCategories>>;
  readonly membershipState?: Maybe<MembershipState>;
  readonly notes?: Maybe<ReadonlyArray<Note>>;
  readonly onboardingSession?: Maybe<Session>;
  /** If this exists, force all students to complete the onboarding survey before allowing them to complete anything else. */
  readonly onboardingSessionId?: Maybe<Scalars['UUID']>;
  readonly partnerUser?: Maybe<PartialUser>;
  readonly partnerUserId?: Maybe<Scalars['UUID']>;
  /** The literal stripeIds purchased by the customer when purchasing. */
  readonly productIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  /**
   * A description of the underlying product purchased by the customer. Non-eng controlled.
   * Example: Bite-sized
   */
  readonly purchaseTags?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly purchasedAt?: Maybe<Scalars['Float']>;
  readonly purchaserUser?: Maybe<PartialUser>;
  readonly purchaserUserId: Scalars['UUID'];
  readonly resolvedValues?: Maybe<MembershipResolved>;
  readonly scheduledEventIds?: Maybe<ReadonlyArray<Scalars['UUID']>>;
  readonly scheduledEvents?: Maybe<ReadonlyArray<Event>>;
  readonly sentNotifications?: Maybe<ReadonlyArray<Email>>;
  readonly session?: Maybe<Session>;
  readonly sessionEvent?: Maybe<Event>;
  readonly sessionIds?: Maybe<ReadonlyArray<Scalars['UUID']>>;
  readonly sessionProgresses?: Maybe<ReadonlyArray<SessionProgress>>;
  readonly sessionStatus?: Maybe<PathSessionStatus>;
  readonly sessions?: Maybe<ReadonlyArray<Session>>;
  readonly studentIds?: Maybe<ReadonlyArray<Scalars['UUID']>>;
  readonly studentResponses?: Maybe<ReadonlyArray<StudentResponses>>;
  readonly students?: Maybe<ReadonlyArray<User>>;
  readonly tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly updatedAt?: Maybe<Scalars['Float']>;
  readonly updatedByUser?: Maybe<PartialUser>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  /** @deprecated */
  readonly weddingDate?: Maybe<Scalars['Date']>;
  readonly welcomeSessionDate?: Maybe<Scalars['DateTime']>;
};


/** A Membership is the purchase of a path. Usually, two users will become associated with this membership and their progress through the associated Path will be tracked. */
export type MembershipSessionArgs = {
  sessionId: Scalars['UUID'];
};


/** A Membership is the purchase of a path. Usually, two users will become associated with this membership and their progress through the associated Path will be tracked. */
export type MembershipSessionEventArgs = {
  sessionId: Scalars['UUID'];
};

export type MembershipAnalytics = {
  readonly __typename?: 'MembershipAnalytics';
  readonly hasCreatedBookmark?: Maybe<Scalars['Boolean']>;
  readonly hasViewedBookmarksWithABookmark?: Maybe<Scalars['Boolean']>;
  readonly id?: Maybe<Scalars['String']>;
  readonly kind?: Maybe<Kinds>;
};

export type MembershipEventResponse = {
  readonly __typename?: 'MembershipEventResponse';
  readonly event?: Maybe<Event>;
  readonly membership?: Maybe<Membership>;
};

export type MembershipFilters = {
  readonly guideEndsAt?: InputMaybe<ReadonlyArray<DateFilter>>;
  readonly guideId?: InputMaybe<StringFilter>;
};

/** Membership resolved values should live in here. */
export type MembershipResolved = {
  readonly __typename?: 'MembershipResolved';
  readonly bookmarks?: Maybe<ReadonlyArray<Bookmark>>;
  readonly canInvitePartner?: Maybe<Scalars['Boolean']>;
  readonly findMediaByTag?: Maybe<Media>;
  readonly membershipAnalytics?: Maybe<MembershipAnalytics>;
  /** @deprecated on 3/7 */
  readonly moduleSessionIdx?: Maybe<Scalars['Int']>;
  readonly paginatedSessions?: Maybe<PaginatedMembershipSessions>;
  readonly partnerCompletedOnboarding?: Maybe<Scalars['Boolean']>;
  readonly purchasableSessions?: Maybe<ReadonlyArray<PurchasableSessions>>;
  readonly purchaserCompletedOnboarding?: Maybe<Scalars['Boolean']>;
  readonly purchaserUserEmail?: Maybe<Scalars['String']>;
  readonly purchaserUserFullName?: Maybe<Scalars['String']>;
  readonly purchaserUserSelfReportedReferrer?: Maybe<Scalars['String']>;
  readonly purchases?: Maybe<ReadonlyArray<Purchase>>;
  readonly schedulerStoredTasks?: Maybe<ReadonlyArray<SchedulerTasks>>;
  readonly sessionProgress?: Maybe<SessionProgress>;
  readonly timeSinceCreationInSec?: Maybe<Scalars['Int']>;
};


/** Membership resolved values should live in here. */
export type MembershipResolvedBookmarksArgs = {
  sessionId?: InputMaybe<Scalars['UUID']>;
};


/** Membership resolved values should live in here. */
export type MembershipResolvedFindMediaByTagArgs = {
  tag: MediaTag;
};


/** Membership resolved values should live in here. */
export type MembershipResolvedModuleSessionIdxArgs = {
  sessionId: Scalars['UUID'];
};


/** Membership resolved values should live in here. */
export type MembershipResolvedPaginatedSessionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** Membership resolved values should live in here. */
export type MembershipResolvedSessionProgressArgs = {
  sessionId: Scalars['UUID'];
};

export type MembershipSort = {
  readonly field: MembershipSortFields;
  readonly order: SortOrder;
};

export const MembershipSortFields = {
  CreatedAt: 'createdAt',
  GuideId: 'guideId',
  Title: 'title'
} as const;

export type MembershipSortFields = typeof MembershipSortFields[keyof typeof MembershipSortFields];
export const MembershipState = {
  Complete: 'Complete',
  InApp: 'InApp',
  IntakeScheduled: 'IntakeScheduled',
  RoadmapInProgress: 'RoadmapInProgress',
  RoadmapSent: 'RoadmapSent'
} as const;

export type MembershipState = typeof MembershipState[keyof typeof MembershipState];
export const MimeType = {
  Heic: 'HEIC',
  Jpg: 'JPG',
  Mp3: 'MP3',
  Mp4: 'MP4',
  Pdf: 'PDF',
  Png: 'PNG',
  Webm: 'WEBM'
} as const;

export type MimeType = typeof MimeType[keyof typeof MimeType];
/**
 * A Module is the core building block of content. A group of modules usually make up a session. And, a group of sessions usually make up a Path.
 * Modules can be videos, slideshows, or more.
 */
export type Module = {
  readonly __typename?: 'Module';
  readonly animatedVariant?: Maybe<AnimatedVariant>;
  readonly answerMode?: Maybe<ModuleAnswerMode>;
  readonly answersVisibleTo?: Maybe<AnswerVisibility>;
  readonly archivedAt?: Maybe<Scalars['Float']>;
  readonly audioCaptionPlayer?: Maybe<AudioCaptionPlayerSettings>;
  readonly audioDirectory?: Maybe<Scalars['ShortString']>;
  readonly backgroundColor?: Maybe<BrandColors>;
  readonly buttonLink?: Maybe<Scalars['ShortString']>;
  readonly buttonSecondaryText?: Maybe<Scalars['String']>;
  readonly buttonText?: Maybe<Scalars['String']>;
  readonly conditionallyDisabled?: Maybe<FeatureFlag>;
  readonly contentVersion?: Maybe<Scalars['Int']>;
  readonly createdAt: Scalars['Float'];
  readonly createdByUser?: Maybe<PartialUser>;
  readonly createdByUserId: Scalars['UUID'];
  readonly description?: Maybe<Scalars['ShortString']>;
  readonly displayKind?: Maybe<DisplayKind>;
  readonly downloadableS3Key?: Maybe<Scalars['ShortString']>;
  readonly durationInSeconds?: Maybe<Scalars['Int']>;
  readonly id: Scalars['UUID'];
  readonly imageS3Key?: Maybe<Scalars['ShortString']>;
  readonly inputType?: Maybe<InputType>;
  readonly interactionColor?: Maybe<BrandColors>;
  readonly interactionKind?: Maybe<InteractionKind>;
  readonly kind: Kinds;
  readonly layout?: Maybe<ModuleLayout>;
  readonly lowerLimitHelper?: Maybe<Scalars['ShortString']>;
  readonly macroModuleIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly multipleChoiceAllowMany?: Maybe<MultipleChoiceAllowMany>;
  readonly multipleChoiceVariant?: Maybe<MultipleChoiceVariant>;
  readonly multipleChoices?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly openEndedPrompt?: Maybe<Scalars['ShortString']>;
  readonly paymentBlocks?: Maybe<ReadonlyArray<PaymentBlock>>;
  readonly photoboothNumberOfPhotos?: Maybe<Scalars['Int']>;
  readonly photoboothPrompts?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly placeholder?: Maybe<Scalars['ShortString']>;
  readonly presenterUserId?: Maybe<Scalars['UUID']>;
  readonly presenterUserName?: Maybe<Scalars['String']>;
  readonly purchasableSessionIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly quizPairs?: Maybe<ReadonlyArray<QuizPairs>>;
  readonly resolvedValues?: Maybe<ModuleResolvedValues>;
  readonly schedulerSessionId?: Maybe<Scalars['UUID']>;
  readonly sentenceBuilderString?: Maybe<Scalars['String']>;
  readonly sideEffectActions?: Maybe<ReadonlyArray<ModuleSideEffect>>;
  readonly tags?: Maybe<ReadonlyArray<ModuleTags>>;
  readonly textDisplayOnly?: Maybe<Scalars['String']>;
  readonly timeInSec?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['ShortString']>;
  readonly updatedAt?: Maybe<Scalars['Float']>;
  readonly updatedByUser?: Maybe<PartialUser>;
  readonly updatedByUserId?: Maybe<Scalars['UUID']>;
  readonly upperLimitHelper?: Maybe<Scalars['ShortString']>;
  readonly videoDirectory?: Maybe<Scalars['ShortString']>;
};

export const ModuleAnswerMode = {
  Default: 'Default',
  PurchaserOnly: 'PurchaserOnly'
} as const;

export type ModuleAnswerMode = typeof ModuleAnswerMode[keyof typeof ModuleAnswerMode];
export type ModuleFilter = {
  readonly id?: InputMaybe<StringFilter>;
};

export type ModuleIds = {
  readonly membershipId: Scalars['UUID'];
  readonly moduleId: Scalars['UUID'];
  readonly sessionId: Scalars['UUID'];
};

export const ModuleLayout = {
  DisplayOnly: 'DisplayOnly',
  HStack: 'HStack',
  InteractionOnly: 'InteractionOnly'
} as const;

export type ModuleLayout = typeof ModuleLayout[keyof typeof ModuleLayout];
export type ModuleResolvedValues = {
  readonly __typename?: 'ModuleResolvedValues';
  readonly moduleAudio?: Maybe<AudioAssets>;
  readonly moduleVideo?: Maybe<VideoAssets>;
  readonly purchasableSessions?: Maybe<ReadonlyArray<PurchasableSessions>>;
  readonly signedDownloadable?: Maybe<Scalars['String']>;
  readonly signedImage?: Maybe<Scalars['String']>;
};


export type ModuleResolvedValuesSignedImageArgs = {
  image?: InputMaybe<ImageParams>;
};

export type ModuleResponse = {
  readonly __typename?: 'ModuleResponse';
  readonly createdAt: Scalars['Float'];
  readonly id: Scalars['String'];
  readonly kind: Kinds;
  /** The label for the prompt. Usually a question */
  readonly label?: Maybe<Scalars['ShortString']>;
  readonly localObjectUrl?: Maybe<Scalars['ShortString']>;
  readonly mediaCaption?: Maybe<Scalars['String']>;
  readonly membershipId: Scalars['UUID'];
  readonly moduleId: Scalars['UUID'];
  /** The location of the media on S3 (mp3, png, jpg, video) */
  readonly s3Key?: Maybe<Scalars['ShortString']>;
  readonly sessionId: Scalars['UUID'];
  readonly signedS3KeyUrl?: Maybe<Scalars['ShortString']>;
  readonly updatedAt?: Maybe<Scalars['Float']>;
  /** the userId field is filled out for modules that are configured for separate answers */
  readonly userId?: Maybe<Scalars['UUID']>;
  /** The value (uuid for multiple choice) */
  readonly value?: Maybe<Scalars['String']>;
  /** The description of the UUID value for multiple choice */
  readonly valueDescription?: Maybe<Scalars['String']>;
};

export type ModuleResponseInput = {
  readonly label: Scalars['ShortString'];
  readonly mediaCaption: Scalars['ShortString'];
  readonly s3Key?: InputMaybe<Scalars['ShortString']>;
  readonly value: Scalars['ShortString'];
  readonly valueDescription: Scalars['ShortString'];
};

export const ModuleSideEffect = {
  AddLoveLetter: 'AddLoveLetter',
  AddToMailchimp: 'AddToMailchimp',
  ApplyEmailToMixpanelUser: 'ApplyEmailToMixpanelUser',
  InsideJoke: 'InsideJoke',
  InvitePartner: 'InvitePartner',
  PartnerFullName: 'PartnerFullName',
  PartnerInviteNote: 'PartnerInviteNote',
  SongThatRemindsMeOfThem: 'SongThatRemindsMeOfThem',
  TrackUserAsLead: 'TrackUserAsLead',
  UserFullName: 'UserFullName',
  UserMobilePhone: 'UserMobilePhone',
  UserReportedReferrer: 'UserReportedReferrer',
  WishICouldTellThem: 'WishICouldTellThem'
} as const;

export type ModuleSideEffect = typeof ModuleSideEffect[keyof typeof ModuleSideEffect];
export type ModuleSort = {
  readonly field: ModuleSortFields;
  readonly order: SortOrder;
};

export const ModuleSortFields = {
  CreatedAt: 'createdAt',
  Title: 'title'
} as const;

export type ModuleSortFields = typeof ModuleSortFields[keyof typeof ModuleSortFields];
export const ModuleTags = {
  ArtProject: 'ArtProject',
  ConceptVoiceover: 'ConceptVoiceover',
  CoolDown: 'CoolDown',
  Discussion: 'Discussion',
  Feedback: 'Feedback',
  Game: 'Game',
  Journal: 'Journal',
  Share: 'Share',
  VideoOverview: 'VideoOverview',
  WarmUp: 'WarmUp',
  Divorce: 'divorce',
  Premarital: 'premarital'
} as const;

export type ModuleTags = typeof ModuleTags[keyof typeof ModuleTags];
export type ModulesOptions = {
  readonly __typename?: 'ModulesOptions';
  readonly id?: Maybe<Scalars['ID']>;
};

export type ModulesOptionsInput = {
  readonly id: Scalars['ID'];
};

export const MultipleChoiceAllowMany = {
  Many: 'Many',
  One: 'One'
} as const;

export type MultipleChoiceAllowMany = typeof MultipleChoiceAllowMany[keyof typeof MultipleChoiceAllowMany];
export const MultipleChoiceVariant = {
  Base: 'base',
  Circle: 'circle',
  Shapes: 'shapes'
} as const;

export type MultipleChoiceVariant = typeof MultipleChoiceVariant[keyof typeof MultipleChoiceVariant];
export type MutateModuleInput = {
  readonly animatedVariant?: InputMaybe<AnimatedVariant>;
  readonly answerMode?: InputMaybe<ModuleAnswerMode>;
  readonly answersVisibleTo?: InputMaybe<AnswerVisibility>;
  readonly audioDirectory?: InputMaybe<Scalars['ShortString']>;
  readonly backgroundColor?: InputMaybe<BrandColors>;
  readonly buttonLink?: InputMaybe<Scalars['ShortString']>;
  readonly buttonSecondaryText?: InputMaybe<Scalars['String']>;
  readonly buttonText?: InputMaybe<Scalars['ShortString']>;
  readonly conditionallyDisabled?: InputMaybe<FeatureFlag>;
  readonly description?: InputMaybe<Scalars['ShortString']>;
  readonly displayKind?: InputMaybe<DisplayKind>;
  readonly downloadableS3Key?: InputMaybe<Scalars['ShortString']>;
  readonly durationInSeconds?: InputMaybe<Scalars['Int']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly imageS3Key?: InputMaybe<Scalars['ShortString']>;
  readonly inputType?: InputMaybe<InputType>;
  readonly interactionColor?: InputMaybe<BrandColors>;
  readonly interactionKind?: InputMaybe<InteractionKind>;
  readonly layout?: InputMaybe<ModuleLayout>;
  readonly lowerLimitHelper?: InputMaybe<Scalars['ShortString']>;
  readonly macroModuleIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly multipleChoiceAllowMany?: InputMaybe<MultipleChoiceAllowMany>;
  readonly multipleChoiceVariant?: InputMaybe<MultipleChoiceVariant>;
  readonly multipleChoices?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly openEndedPrompt?: InputMaybe<Scalars['ShortString']>;
  readonly paymentBlocks?: InputMaybe<ReadonlyArray<InputPaymentBlock>>;
  readonly photoboothNumberOfPhotos?: InputMaybe<Scalars['Int']>;
  readonly photoboothPrompts?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly placeholder?: InputMaybe<Scalars['ShortString']>;
  readonly presenterUserId?: InputMaybe<Scalars['UUID']>;
  readonly purchasableSessionIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly quizPairs?: InputMaybe<ReadonlyArray<InputQuizPairs>>;
  readonly schedulerSessionId?: InputMaybe<Scalars['UUID']>;
  readonly sentenceBuilderString?: InputMaybe<Scalars['String']>;
  readonly sideEffectActions?: InputMaybe<ReadonlyArray<ModuleSideEffect>>;
  readonly tags?: InputMaybe<ReadonlyArray<ModuleTags>>;
  readonly textDisplayOnly?: InputMaybe<Scalars['String']>;
  readonly timeInSec?: InputMaybe<Scalars['Int']>;
  readonly title?: InputMaybe<Scalars['ShortString']>;
  readonly upperLimitHelper?: InputMaybe<Scalars['ShortString']>;
  readonly videoDirectory?: InputMaybe<Scalars['ShortString']>;
};

export type MutateSessionInput = {
  readonly answerMode?: InputMaybe<SessionAnswerMode>;
  readonly capSessionsAfterXDays?: InputMaybe<Scalars['Int']>;
  readonly cardDeckS3Key?: InputMaybe<Scalars['ShortString']>;
  readonly endingBtnText?: InputMaybe<Scalars['ShortString']>;
  readonly extraResources?: InputMaybe<ReadonlyArray<DownloadItemInput>>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly internalTitle?: InputMaybe<Scalars['ShortString']>;
  readonly linkLogo?: InputMaybe<FeatureFlag>;
  readonly liveSessionAgenda?: InputMaybe<ReadonlyArray<Scalars['ShortString']>>;
  readonly maxDaysAllowedToBookAfterGuideEnds?: InputMaybe<Scalars['Int']>;
  readonly maxDaysBeforeLastSlot?: InputMaybe<Scalars['Int']>;
  readonly membershipCategories?: InputMaybe<ReadonlyArray<PurchaseCategories>>;
  readonly minHrBeforeFirstSlot?: InputMaybe<Scalars['Int']>;
  readonly purchasePriceCents?: InputMaybe<Scalars['Int']>;
  readonly recapText?: InputMaybe<Scalars['ShortString']>;
  readonly roundRobinGuideIds?: InputMaybe<ReadonlyArray<Scalars['UUID']>>;
  readonly roundRobinMode?: InputMaybe<RoundRobinMode>;
  readonly sessionBulletPoints?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly sessionCategories?: InputMaybe<ReadonlyArray<SessionCategory>>;
  readonly sessionDescription?: InputMaybe<Scalars['LongString']>;
  readonly sessionPublicShareUrl?: InputMaybe<Scalars['String']>;
  readonly shareImageInstagramS3Key?: InputMaybe<Scalars['ShortString']>;
  readonly shareImageS3Key?: InputMaybe<Scalars['ShortString']>;
  readonly shareText?: InputMaybe<Scalars['String']>;
  readonly startBtnText?: InputMaybe<Scalars['ShortString']>;
  readonly startViewBG?: InputMaybe<BrandColors>;
  readonly theme?: InputMaybe<SessionTheme>;
  readonly title?: InputMaybe<Scalars['ShortString']>;
  readonly type?: InputMaybe<SessionType>;
  readonly visibility?: InputMaybe<SessionVisibility>;
  readonly weeklyCapByGuideId?: InputMaybe<Scalars['JSONObjectStringNumber']>;
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly addEmailSendGridId?: Maybe<Scalars['Boolean']>;
  readonly addModuleResponseIds?: Maybe<Scalars['Boolean']>;
  readonly addScheduledTimeSlot?: Maybe<Scalars['Boolean']>;
  readonly adminInvitePartnerToMembership?: Maybe<Membership>;
  readonly archiveEntity?: Maybe<Scalars['Boolean']>;
  readonly assignGuide?: Maybe<Membership>;
  readonly assignGuideSupervisor?: Maybe<Scalars['Boolean']>;
  readonly cancelEvent?: Maybe<PublicEvent>;
  readonly completeSession?: Maybe<SessionProgress>;
  readonly completeSessionModule?: Maybe<SessionProgress>;
  readonly createBookmark?: Maybe<Bookmark>;
  readonly createContent: Content;
  readonly createHMSRoom?: Maybe<Event>;
  readonly createInternalSelfHostedEvent?: Maybe<Event>;
  readonly createMembership?: Maybe<Membership>;
  readonly createMembershipEvent?: Maybe<MembershipEventResponse>;
  readonly createModules?: Maybe<ReadonlyArray<Module>>;
  readonly createNote?: Maybe<Note>;
  readonly createPersonalVideo?: Maybe<Media>;
  readonly createSession?: Maybe<Session>;
  readonly findBookmark?: Maybe<Bookmark>;
  readonly invitePartnerToMembership?: Maybe<Membership>;
  readonly inviteUser?: Maybe<User>;
  readonly logMembershipAction?: Maybe<Scalars['Boolean']>;
  readonly markGuideReviewed?: Maybe<ReadonlyArray<SessionProgress>>;
  readonly markLiveSessionIncomplete?: Maybe<SessionProgress>;
  readonly missEvent?: Maybe<Event>;
  readonly onConfirmationCode?: Maybe<User>;
  readonly onRequestEmailChange?: Maybe<Scalars['Boolean']>;
  readonly onShareViaEmail?: Maybe<Scalars['Boolean']>;
  readonly onShareViaSMS?: Maybe<Scalars['Boolean']>;
  readonly onUserLogin?: Maybe<User>;
  readonly patchConfiguration?: Maybe<Configuration>;
  readonly patchContent: Content;
  readonly patchEventJoinUrl?: Maybe<Event>;
  readonly patchMembership?: Maybe<Membership>;
  readonly patchModule?: Maybe<Module>;
  readonly patchNote?: Maybe<Note>;
  readonly patchScheduledTimeSlot?: Maybe<Scalars['Boolean']>;
  readonly patchSelf?: Maybe<User>;
  readonly patchSession?: Maybe<Session>;
  readonly patchUser?: Maybe<User>;
  readonly purchaseSessionsForMembership?: Maybe<PurchaseSessionResponse>;
  readonly putHiFiTranscriptCue?: Maybe<Module>;
  readonly putSessionXState?: Maybe<Session>;
  readonly putTranscriptCues?: Maybe<Module>;
  readonly putVideoChapters?: Maybe<Module>;
  readonly rateSession?: Maybe<SessionProgress>;
  readonly registerEmailForMembership?: Maybe<Membership>;
  readonly removeScheduledTimeSlot?: Maybe<Scalars['Boolean']>;
  readonly requestGuideVideoPutUrl?: Maybe<PutUrl>;
  readonly requestMagicLink?: Maybe<MagicLinkResponse>;
  readonly requestModuleAudioPutUrl?: Maybe<PutUrl>;
  readonly requestModuleImageDocumentPutUrl?: Maybe<PutUrl>;
  readonly requestModuleVideoPutUrl?: Maybe<PutUrl>;
  readonly requestPreSignedSessionContent?: Maybe<PutUrl>;
  readonly requestProfilePhotoPutUrl?: Maybe<PutUrl>;
  readonly sendTemplate?: Maybe<Scalars['Boolean']>;
  readonly setViewedBookmarks?: Maybe<MembershipAnalytics>;
  readonly startSession?: Maybe<SessionProgress>;
  readonly storeEmailEvent?: Maybe<Scalars['Boolean']>;
  readonly stripeCreatePaymentLink: PaymentLinkResponse;
  readonly swapSessionIds?: Maybe<Membership>;
  readonly tokenRescheduleEvent?: Maybe<Scalars['Boolean']>;
  readonly unArchiveEntity?: Maybe<Scalars['Boolean']>;
};


export type MutationAddEmailSendGridIdArgs = {
  id: Scalars['UUID'];
  payload: AddSendGridIdPayload;
};


export type MutationAddModuleResponseIdsArgs = {
  data: ModuleResponseInput;
  ids: ModuleIds;
};


export type MutationAddScheduledTimeSlotArgs = {
  input: ScheduleAvailabilityInput;
};


export type MutationAdminInvitePartnerToMembershipArgs = {
  membershipId: Scalars['UUID'];
  partnerEmail: Scalars['EmailAddress'];
};


export type MutationArchiveEntityArgs = {
  id: Scalars['UUID'];
  kind: Kinds;
};


export type MutationAssignGuideArgs = {
  guideId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
};


export type MutationAssignGuideSupervisorArgs = {
  guideId: Scalars['UUID'];
  supervisorId: Scalars['UUID'];
};


export type MutationCancelEventArgs = {
  id: Scalars['UUID'];
  token?: InputMaybe<Scalars['UUID']>;
};


export type MutationCompleteSessionArgs = {
  data: TrackingTypeInput;
  membershipId: Scalars['UUID'];
  sessionId: Scalars['UUID'];
};


export type MutationCompleteSessionModuleArgs = {
  membershipId: Scalars['UUID'];
  moduleId: Scalars['UUID'];
  nextNodeId?: InputMaybe<Scalars['UUID']>;
  sessionId: Scalars['UUID'];
};


export type MutationCreateBookmarkArgs = {
  bookmark: PutBookmark;
};


export type MutationCreateContentArgs = {
  content: ContentInput;
};


export type MutationCreateHmsRoomArgs = {
  id: Scalars['UUID'];
};


export type MutationCreateInternalSelfHostedEventArgs = {
  sessionId: Scalars['UUID'];
  startTime?: InputMaybe<Scalars['DateTime']>;
};


export type MutationCreateMembershipArgs = {
  membership: CreateMembershipInput;
};


export type MutationCreateMembershipEventArgs = {
  input: ScheduleEventInput;
};


export type MutationCreateModulesArgs = {
  modules: ReadonlyArray<MutateModuleInput>;
};


export type MutationCreateNoteArgs = {
  note: CreateNoteInput;
};


export type MutationCreatePersonalVideoArgs = {
  media: CreatePersonalVideoInput;
};


export type MutationCreateSessionArgs = {
  session: MutateSessionInput;
};


export type MutationFindBookmarkArgs = {
  id: Scalars['UUID'];
};


export type MutationInvitePartnerToMembershipArgs = {
  membershipId: Scalars['UUID'];
  partnerEmail: Scalars['EmailAddress'];
};


export type MutationInviteUserArgs = {
  appKind: AppKind;
  stage: Stage;
  user: InviteUserInput;
};


export type MutationLogMembershipActionArgs = {
  data: Scalars['JSONObject'];
  type: Scalars['String'];
};


export type MutationMarkGuideReviewedArgs = {
  isReviewed: Scalars['Boolean'];
  membershipId: Scalars['UUID'];
  sessionId: Scalars['UUID'];
};


export type MutationMarkLiveSessionIncompleteArgs = {
  membershipId: Scalars['UUID'];
  sessionId: Scalars['UUID'];
};


export type MutationMissEventArgs = {
  id: Scalars['UUID'];
};


export type MutationOnConfirmationCodeArgs = {
  accessToken: Scalars['String'];
  code: Scalars['String'];
};


export type MutationOnRequestEmailChangeArgs = {
  accessToken: Scalars['String'];
  newEmail: Scalars['EmailAddress'];
};


export type MutationOnShareViaEmailArgs = {
  data: EmailShare;
};


export type MutationOnShareViaSmsArgs = {
  data: SmsShare;
};


export type MutationOnUserLoginArgs = {
  timezone: Scalars['Timezone'];
};


export type MutationPatchConfigurationArgs = {
  config: ConfigurationInput;
};


export type MutationPatchContentArgs = {
  content: PatchContentInput;
  id: Scalars['UUID'];
};


export type MutationPatchEventJoinUrlArgs = {
  id: Scalars['UUID'];
  nextJoinUrl: Scalars['String'];
};


export type MutationPatchMembershipArgs = {
  id: Scalars['UUID'];
  membership: PatchMembershipInput;
};


export type MutationPatchModuleArgs = {
  id: Scalars['UUID'];
  module: MutateModuleInput;
};


export type MutationPatchNoteArgs = {
  id: Scalars['UUID'];
  note: PatchNoteInput;
};


export type MutationPatchScheduledTimeSlotArgs = {
  id: Scalars['UUID'];
  input: AvailabilityPatchInput;
};


export type MutationPatchSelfArgs = {
  user: PatchSelfInput;
};


export type MutationPatchSessionArgs = {
  id: Scalars['UUID'];
  session: MutateSessionInput;
};


export type MutationPatchUserArgs = {
  id: Scalars['UUID'];
  user: PatchUserInput;
};


export type MutationPurchaseSessionsForMembershipArgs = {
  cardId: Scalars['String'];
  membershipId: Scalars['UUID'];
  purchaseLocation: Scalars['String'];
  sessionIds: ReadonlyArray<Scalars['UUID']>;
};


export type MutationPutHiFiTranscriptCueArgs = {
  cues: ReadonlyArray<TranscriptCueInput>;
  id: Scalars['UUID'];
};


export type MutationPutSessionXStateArgs = {
  id: Scalars['UUID'];
  xState: ReadonlyArray<XStateInput>;
};


export type MutationPutTranscriptCuesArgs = {
  cues: ReadonlyArray<TranscriptCueInput>;
  id: Scalars['UUID'];
};


export type MutationPutVideoChaptersArgs = {
  chapters: ReadonlyArray<VideoChapterInput>;
  id: Scalars['UUID'];
};


export type MutationRateSessionArgs = {
  membershipId: Scalars['UUID'];
  rating: Scalars['Int'];
  sessionId: Scalars['UUID'];
};


export type MutationRegisterEmailForMembershipArgs = {
  input: RegisterInput;
};


export type MutationRemoveScheduledTimeSlotArgs = {
  id: Scalars['UUID'];
};


export type MutationRequestGuideVideoPutUrlArgs = {
  mimeType: MimeType;
};


export type MutationRequestMagicLinkArgs = {
  appKind: AppKind;
  email: Scalars['String'];
  stage: Stage;
};


export type MutationRequestModuleAudioPutUrlArgs = {
  mimeType: MimeType;
};


export type MutationRequestModuleImageDocumentPutUrlArgs = {
  mimeType: MimeType;
};


export type MutationRequestModuleVideoPutUrlArgs = {
  mimeType: MimeType;
};


export type MutationRequestPreSignedSessionContentArgs = {
  mimeType: MimeType;
};


export type MutationRequestProfilePhotoPutUrlArgs = {
  mimeType: MimeType;
};


export type MutationSendTemplateArgs = {
  membershipId: Scalars['UUID'];
  payload: Scalars['JSONObjectStringString'];
  templateName: SendGridTemplateNames;
};


export type MutationSetViewedBookmarksArgs = {
  membershipId: Scalars['UUID'];
};


export type MutationStartSessionArgs = {
  data: TrackingTypeInput;
  membershipId: Scalars['UUID'];
  sessionId: Scalars['UUID'];
};


export type MutationStoreEmailEventArgs = {
  emailEvent: EmailEventInput;
};


export type MutationStripeCreatePaymentLinkArgs = {
  payment: PaymentLinkRequest;
};


export type MutationSwapSessionIdsArgs = {
  id: Scalars['UUID'];
  sessionIds: ReadonlyArray<Scalars['UUID']>;
};


export type MutationTokenRescheduleEventArgs = {
  id: Scalars['UUID'];
  input: ScheduleEventInput;
  token: Scalars['UUID'];
};


export type MutationUnArchiveEntityArgs = {
  id: Scalars['UUID'];
  kind: Kinds;
};

/** A note is a small entity that is attached to other entities. */
export type Note = {
  readonly __typename?: 'Note';
  readonly content?: Maybe<Scalars['LongString']>;
  readonly createdAt: Scalars['Float'];
  readonly createdByUser?: Maybe<PartialUser>;
  readonly createdByUserId: Scalars['String'];
  readonly entityId?: Maybe<Scalars['UUID']>;
  readonly entityKind?: Maybe<Kinds>;
  readonly id: Scalars['UUID'];
  readonly kind: Kinds;
  readonly updatedAt?: Maybe<Scalars['Float']>;
  readonly updatedByUser?: Maybe<PartialUser>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
};

export type PaginatedMembershipSessions = {
  readonly __typename?: 'PaginatedMembershipSessions';
  readonly count?: Maybe<Scalars['Int']>;
  readonly sessions?: Maybe<ReadonlyArray<Session>>;
};

export type PartialUser = {
  readonly __typename?: 'PartialUser';
  readonly bio?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly fullName?: Maybe<Scalars['String']>;
  readonly funFacts?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['UUID'];
  readonly physicalLocation?: Maybe<Scalars['ShortString']>;
  readonly profilePhotoLocation?: Maybe<Scalars['String']>;
  readonly profilePhotoSignedCDN?: Maybe<Scalars['String']>;
  readonly shortBio?: Maybe<Scalars['String']>;
  readonly signatureS3Key?: Maybe<Scalars['String']>;
  readonly signatureSignedUrl?: Maybe<Scalars['String']>;
  readonly slackUserName?: Maybe<Scalars['ShortString']>;
  readonly whyRelationshipsMatter?: Maybe<Scalars['LongString']>;
};


export type PartialUserProfilePhotoSignedCdnArgs = {
  image?: InputMaybe<ImageParams>;
};

export type PatchBookmark = {
  readonly content?: InputMaybe<Scalars['String']>;
  readonly endTimeStampSec?: InputMaybe<Scalars['Int']>;
  readonly startTimeStampSec?: InputMaybe<Scalars['Int']>;
};

export type PatchContentInput = {
  readonly content?: InputMaybe<Scalars['LongString']>;
  readonly title?: InputMaybe<Scalars['ShortString']>;
};

export type PatchMembershipInput = {
  readonly guideEndsAt?: InputMaybe<Scalars['Date']>;
  readonly membershipCategories?: InputMaybe<ReadonlyArray<PurchaseCategories>>;
  readonly sessionStatus?: InputMaybe<PathSessionStatus>;
};

export type PatchNoteInput = {
  readonly content?: InputMaybe<Scalars['LongString']>;
};

export type PatchSelfInput = {
  readonly birthday?: InputMaybe<Scalars['Date']>;
  readonly fullName?: InputMaybe<Scalars['ShortString']>;
  readonly mobileNumber?: InputMaybe<Scalars['PhoneNumber']>;
  readonly profilePhotoLocation?: InputMaybe<Scalars['ShortString']>;
  readonly pronouns?: InputMaybe<Pronouns>;
  readonly selfReportedReferrer?: InputMaybe<Scalars['ShortString']>;
  readonly timezone?: InputMaybe<Scalars['Timezone']>;
};

export type PatchUserInput = {
  readonly bio?: InputMaybe<Scalars['String']>;
  readonly birthday?: InputMaybe<Scalars['Date']>;
  readonly fullName?: InputMaybe<Scalars['ShortString']>;
  readonly funFacts?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly membershipIds?: InputMaybe<ReadonlyArray<Scalars['UUID']>>;
  readonly mobileNumber?: InputMaybe<Scalars['PhoneNumber']>;
  readonly physicalLocation?: InputMaybe<Scalars['ShortString']>;
  readonly profilePhotoLocation?: InputMaybe<Scalars['ShortString']>;
  readonly pronouns?: InputMaybe<Pronouns>;
  readonly role?: InputMaybe<Role>;
  readonly selfReportedReferrer?: InputMaybe<Scalars['ShortString']>;
  readonly shortBio?: InputMaybe<Scalars['ShortString']>;
  readonly signatureS3Key?: InputMaybe<Scalars['ShortString']>;
  readonly slackUserName?: InputMaybe<Scalars['ShortString']>;
  readonly timezone?: InputMaybe<Scalars['Timezone']>;
  readonly whyRelationshipsMatter?: InputMaybe<Scalars['LongString']>;
};

export const PathSessionStatus = {
  /** This status shows sessions to the couple and "locks-in" the couples guide/partner */
  SessionsReady: 'SessionsReady'
} as const;

export type PathSessionStatus = typeof PathSessionStatus[keyof typeof PathSessionStatus];
export type PaymentBlock = {
  readonly __typename?: 'PaymentBlock';
  readonly bulletPoints?: Maybe<ReadonlyArray<Scalars['ShortString']>>;
  readonly isMostPopular?: Maybe<Scalars['Boolean']>;
  readonly pricingDescription?: Maybe<Scalars['ShortString']>;
  readonly stripeId?: Maybe<Scalars['ShortString']>;
  readonly subtitle?: Maybe<Scalars['ShortString']>;
  readonly title?: Maybe<Scalars['ShortString']>;
};

export type PaymentLinkRequest = {
  readonly email: Scalars['EmailAddress'];
  readonly productIds: ReadonlyArray<Scalars['ShortString']>;
};

export type PaymentLinkResponse = {
  readonly __typename?: 'PaymentLinkResponse';
  readonly url: Scalars['URL'];
  readonly userId?: Maybe<Scalars['UUID']>;
};

export type PaymentMethod = {
  readonly __typename?: 'PaymentMethod';
  readonly brand: CardBrand;
  readonly expMonth: Scalars['Int'];
  readonly expYear: Scalars['Int'];
  readonly id: Scalars['String'];
  readonly last4: Scalars['String'];
};

export const Pronouns = {
  He: 'HE',
  Other: 'OTHER',
  She: 'SHE',
  They: 'THEY'
} as const;

export type Pronouns = typeof Pronouns[keyof typeof Pronouns];
export type PublicEvent = {
  readonly __typename?: 'PublicEvent';
  readonly archivedAt?: Maybe<Scalars['Float']>;
  readonly guide?: Maybe<PartialUser>;
  readonly guideId?: Maybe<Scalars['String']>;
  readonly id: Scalars['UUID'];
  readonly session?: Maybe<PublicEventPublicSession>;
  readonly sessionId?: Maybe<Scalars['String']>;
  readonly startTime?: Maybe<Scalars['DateTime']>;
  readonly timeSlots?: Maybe<ReadonlyArray<TimeSlot>>;
};

export type PublicEventPublicSession = {
  readonly __typename?: 'PublicEventPublicSession';
  readonly durationInMinutes?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
};

export type PurchasableSessionResolved = {
  readonly __typename?: 'PurchasableSessionResolved';
  readonly guideHeadshots?: Maybe<ReadonlyArray<Scalars['URL']>>;
};


export type PurchasableSessionResolvedGuideHeadshotsArgs = {
  membershipId?: InputMaybe<Scalars['UUID']>;
};

export type PurchasableSessions = {
  readonly __typename?: 'PurchasableSessions';
  readonly id: Scalars['UUID'];
  readonly kind: Kinds;
  readonly membershipCategories?: Maybe<ReadonlyArray<PurchaseCategories>>;
  readonly purchasePriceCents?: Maybe<Scalars['Int']>;
  readonly resolvedValues?: Maybe<PurchasableSessionResolved>;
  readonly roundRobinMode?: Maybe<RoundRobinMode>;
  readonly sessionCategories?: Maybe<ReadonlyArray<SessionCategory>>;
  readonly sessionDescription?: Maybe<Scalars['LongString']>;
  readonly title?: Maybe<Scalars['ShortString']>;
  readonly type: SessionType;
};

export type Purchase = {
  readonly __typename?: 'Purchase';
  readonly amountInCents: Scalars['Int'];
  readonly createdAt: Scalars['Float'];
  readonly createdByUserId: Scalars['UUID'];
  readonly id: Scalars['UUID'];
  readonly kind?: Maybe<Kinds>;
  readonly membershipId: Scalars['UUID'];
  readonly purchasedByUserId: Scalars['UUID'];
  readonly resolvedValues?: Maybe<PurchaseResolvedValues>;
  readonly sessionIds?: Maybe<ReadonlyArray<Scalars['UUID']>>;
};

export const PurchaseCategories = {
  Romantic: 'Romantic'
} as const;

export type PurchaseCategories = typeof PurchaseCategories[keyof typeof PurchaseCategories];
export type PurchaseResolvedValues = {
  readonly __typename?: 'PurchaseResolvedValues';
  readonly membership?: Maybe<Membership>;
  readonly purchasedByUser?: Maybe<PartialUser>;
  readonly sessions?: Maybe<ReadonlyArray<Session>>;
};

export const PurchaseSessionMessage = {
  FailedDueToAlreadyOwnSessions: 'FailedDueToAlreadyOwnSessions',
  FailedDueToCard: 'FailedDueToCard',
  Success: 'Success'
} as const;

export type PurchaseSessionMessage = typeof PurchaseSessionMessage[keyof typeof PurchaseSessionMessage];
export type PurchaseSessionResponse = {
  readonly __typename?: 'PurchaseSessionResponse';
  readonly amountChargedInCents?: Maybe<Scalars['Int']>;
  readonly membership?: Maybe<Membership>;
  readonly message: PurchaseSessionMessage;
};

export type PutBookmark = {
  readonly content: Scalars['String'];
  readonly endTimeStampSec: Scalars['Int'];
  readonly id: Scalars['UUID'];
  readonly membershipId: Scalars['UUID'];
  readonly moduleId: Scalars['UUID'];
  readonly sessionId: Scalars['UUID'];
  readonly startTimeStampSec: Scalars['Int'];
};

export type PutUrl = {
  readonly __typename?: 'PutUrl';
  readonly directory?: Maybe<Scalars['String']>;
  readonly key?: Maybe<Scalars['String']>;
  readonly signedDownloadURL?: Maybe<Scalars['String']>;
  readonly signedUploadKey?: Maybe<Scalars['String']>;
  readonly uploadKey?: Maybe<Scalars['String']>;
};

export type Query = {
  readonly __typename?: 'Query';
  /** Fetches the authenticated user */
  readonly fetchSelf?: Maybe<User>;
  readonly findChangeHistory?: Maybe<ReadonlyArray<ChangeHistory>>;
  readonly findContent?: Maybe<Content>;
  readonly findEmail?: Maybe<Email>;
  readonly findEvent?: Maybe<Event>;
  readonly findEventForLiveSession?: Maybe<Event>;
  readonly findMedia?: Maybe<Media>;
  readonly findMembership?: Maybe<Membership>;
  readonly findModule?: Maybe<Module>;
  readonly findNote?: Maybe<Note>;
  readonly findPublicEvent?: Maybe<PublicEvent>;
  readonly findPublicSession?: Maybe<Session>;
  readonly findScheduledEventForSession?: Maybe<EventSession>;
  readonly findSendGridTemplate?: Maybe<Scalars['String']>;
  readonly findSession?: Maybe<Session>;
  readonly findSessionProgress?: Maybe<ReadonlyArray<SessionProgress>>;
  /** Finds a user by their ID */
  readonly findUser?: Maybe<User>;
  readonly getConfiguration?: Maybe<Configuration>;
  readonly getCustomerConfig?: Maybe<CustomerConfig>;
  readonly getLiveEventGuestToken?: Maybe<Scalars['String']>;
  readonly getLiveEventHostToken?: Maybe<Scalars['String']>;
  readonly getUserPaymentMethods: ReadonlyArray<PaymentMethod>;
  readonly getUserStripeBillingPortalLink: Scalars['String'];
  readonly queryContent: QueryContentsResponse;
  readonly queryEntitiesNotes?: Maybe<ReadonlyArray<Note>>;
  readonly queryEvents?: Maybe<QueryEventsResponse>;
  readonly queryGuidesAvailability?: Maybe<SessionAvailabilityResponse>;
  readonly queryMembershipSessionAvailability: SessionAvailabilityResponse;
  readonly queryMemberships?: Maybe<QueryMembershipsResponse>;
  readonly queryModules?: Maybe<QueryModuleResponse>;
  readonly queryPublicSessions?: Maybe<ReadonlyArray<Scalars['UUID']>>;
  readonly queryPurchaseableSessionForMembership: ReadonlyArray<PurchasableSessions>;
  readonly queryScheduleAvailability?: Maybe<ReadonlyArray<ScheduleAvailability>>;
  readonly querySessions?: Maybe<QuerySessionsResponse>;
  readonly querySessionsAvailability?: Maybe<ReadonlyArray<TimeSlot>>;
  /** Queries for users */
  readonly queryUsers?: Maybe<QueryUsersResponse>;
  readonly textAnalysis?: Maybe<TextAnalysisResults>;
};


export type QueryFindChangeHistoryArgs = {
  id: Scalars['ShortString'];
};


export type QueryFindContentArgs = {
  id: Scalars['UUID'];
};


export type QueryFindEmailArgs = {
  id: Scalars['UUID'];
};


export type QueryFindEventArgs = {
  id: Scalars['UUID'];
};


export type QueryFindEventForLiveSessionArgs = {
  id: Scalars['UUID'];
};


export type QueryFindMediaArgs = {
  id: Scalars['UUID'];
};


export type QueryFindMembershipArgs = {
  id: Scalars['UUID'];
};


export type QueryFindModuleArgs = {
  id: Scalars['UUID'];
};


export type QueryFindNoteArgs = {
  id: Scalars['UUID'];
};


export type QueryFindPublicEventArgs = {
  id: Scalars['UUID'];
  token: Scalars['UUID'];
};


export type QueryFindPublicSessionArgs = {
  id: Scalars['UUID'];
};


export type QueryFindScheduledEventForSessionArgs = {
  membershipId: Scalars['UUID'];
  sessionId: Scalars['UUID'];
};


export type QueryFindSendGridTemplateArgs = {
  templateName: SendGridTemplateNames;
};


export type QueryFindSessionArgs = {
  id: Scalars['UUID'];
};


export type QueryFindSessionProgressArgs = {
  membershipId: Scalars['UUID'];
  sessionId: Scalars['UUID'];
};


export type QueryFindUserArgs = {
  id: Scalars['UUID'];
};


export type QueryGetLiveEventGuestTokenArgs = {
  id: Scalars['UUID'];
};


export type QueryGetLiveEventHostTokenArgs = {
  id: Scalars['UUID'];
};


export type QueryQueryContentArgs = {
  filter?: InputMaybe<ContentFilter>;
  query?: InputMaybe<QueryArgs>;
};


export type QueryQueryEntitiesNotesArgs = {
  id: Scalars['UUID'];
  kind: Kinds;
};


export type QueryQueryEventsArgs = {
  filter?: InputMaybe<EventFilter>;
  query?: InputMaybe<QueryArgs>;
};


export type QueryQueryGuidesAvailabilityArgs = {
  guideId: Scalars['UUID'];
  sessionId: Scalars['UUID'];
};


export type QueryQueryMembershipSessionAvailabilityArgs = {
  query: AvailabilityQueryArgs;
};


export type QueryQueryMembershipsArgs = {
  filter?: InputMaybe<MembershipFilters>;
  query?: InputMaybe<QueryArgs>;
  sort?: InputMaybe<ReadonlyArray<MembershipSort>>;
};


export type QueryQueryModulesArgs = {
  filter?: InputMaybe<ModuleFilter>;
  query?: InputMaybe<QueryArgs>;
  sort?: InputMaybe<ReadonlyArray<ModuleSort>>;
};


export type QueryQueryPurchaseableSessionForMembershipArgs = {
  membershipId: Scalars['UUID'];
};


export type QueryQueryScheduleAvailabilityArgs = {
  filter: ScheduleFilter;
};


export type QueryQuerySessionsArgs = {
  filter?: InputMaybe<SessionsFilter>;
  query?: InputMaybe<QueryArgs>;
  sort?: InputMaybe<ReadonlyArray<SessionSort>>;
};


export type QueryQuerySessionsAvailabilityArgs = {
  sessionId: Scalars['UUID'];
};


export type QueryQueryUsersArgs = {
  filter?: InputMaybe<UserFilters>;
  query?: InputMaybe<QueryArgs>;
  sort?: InputMaybe<ReadonlyArray<UserSort>>;
};


export type QueryTextAnalysisArgs = {
  text: Scalars['NonEmptyString'];
};

export type QueryArgs = {
  readonly archive?: InputMaybe<IncludeArchived>;
  readonly search?: InputMaybe<Scalars['String']>;
  readonly skip?: InputMaybe<Scalars['Int']>;
  readonly take?: InputMaybe<Scalars['Int']>;
};

export type QueryContentsResponse = {
  readonly __typename?: 'QueryContentsResponse';
  readonly count?: Maybe<Scalars['Int']>;
  readonly entities?: Maybe<ReadonlyArray<Content>>;
};

export type QueryEmailsResponse = {
  readonly __typename?: 'QueryEmailsResponse';
  readonly count?: Maybe<Scalars['Int']>;
  readonly entities: ReadonlyArray<Email>;
};

export type QueryEventsResponse = {
  readonly __typename?: 'QueryEventsResponse';
  readonly count?: Maybe<Scalars['Int']>;
  readonly entities?: Maybe<ReadonlyArray<Event>>;
};

export type QueryMembershipsResponse = {
  readonly __typename?: 'QueryMembershipsResponse';
  readonly count?: Maybe<Scalars['Int']>;
  readonly entities?: Maybe<ReadonlyArray<Membership>>;
};

export type QueryModuleResponse = {
  readonly __typename?: 'QueryModuleResponse';
  readonly count?: Maybe<Scalars['Int']>;
  readonly entities?: Maybe<ReadonlyArray<Module>>;
};

export type QuerySessionsResponse = {
  readonly __typename?: 'QuerySessionsResponse';
  readonly count?: Maybe<Scalars['Int']>;
  readonly entities?: Maybe<ReadonlyArray<Session>>;
};

export type QueryUsersResponse = {
  readonly __typename?: 'QueryUsersResponse';
  readonly count?: Maybe<Scalars['Int']>;
  readonly entities?: Maybe<ReadonlyArray<User>>;
};

export type QuizPairs = {
  readonly __typename?: 'QuizPairs';
  readonly answer: Scalars['ShortString'];
  readonly question: Scalars['ShortString'];
};

export type RegisterInput = {
  readonly email?: InputMaybe<Scalars['EmailAddress']>;
  readonly fullName?: InputMaybe<Scalars['String']>;
  readonly guideId?: InputMaybe<Scalars['UUID']>;
  readonly onboardingSessionId?: InputMaybe<Scalars['UUID']>;
  readonly paymentInCents: Scalars['Int'];
  readonly productIds: ReadonlyArray<Scalars['String']>;
  readonly purchaseTags: ReadonlyArray<Scalars['String']>;
  readonly sessionIds: ReadonlyArray<Scalars['UUID']>;
  readonly setSessionsReady: Scalars['Boolean'];
  readonly stripeCustomerId: Scalars['String'];
};

/**
 * A Response represents how a use responded to various questions over time during a membership
 * Each user in a membership has their own response object.
 */
export type Response = {
  readonly __typename?: 'Response';
  readonly id: Scalars['String'];
  readonly insideJoke?: Maybe<Scalars['ShortString']>;
  readonly loveLetters?: Maybe<ReadonlyArray<StringDateSequence>>;
  readonly membershipId?: Maybe<Scalars['UUID']>;
  readonly partnerInviteNote?: Maybe<Scalars['LongString']>;
  readonly songThatRemindsMeOfThem?: Maybe<Scalars['ShortString']>;
  readonly userId?: Maybe<Scalars['UUID']>;
  readonly wishICouldTellThem?: Maybe<Scalars['ShortString']>;
};

export const Role = {
  Admin: 'ADMIN',
  Creator: 'CREATOR',
  Customer: 'CUSTOMER',
  ClinicalAdmin: 'ClinicalAdmin',
  Guide: 'GUIDE',
  Supervisor: 'SUPERVISOR'
} as const;

export type Role = typeof Role[keyof typeof Role];
export type RoleFilter = {
  readonly eq?: InputMaybe<Role>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Role>>>;
  readonly neq?: InputMaybe<Role>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Role>>>;
};

export const RoundRobinMode = {
  /** Always round robins */
  ForceRoundRobin: 'ForceRoundRobin',
  /** If the membership has a guide, it uses the guide. Otherwise, rr */
  GuideFallbackRoundRobin: 'GuideFallbackRoundRobin'
} as const;

export type RoundRobinMode = typeof RoundRobinMode[keyof typeof RoundRobinMode];
export type SmsShare = {
  readonly content: Scalars['String'];
  readonly phone: Scalars['PhoneNumber'];
};

/** A users availability for booking */
export type ScheduleAvailability = {
  readonly __typename?: 'ScheduleAvailability';
  /** @deprecated - this used to point to this old availability object */
  readonly availabilityId: Scalars['UUID'];
  readonly createdAt?: Maybe<Scalars['Float']>;
  readonly createdByUser?: Maybe<PartialUser>;
  readonly createdByUserId?: Maybe<Scalars['String']>;
  readonly endDateTime: Scalars['DateTime'];
  readonly id: Scalars['UUID'];
  readonly kind?: Maybe<Scalars['String']>;
  readonly startDateTime: Scalars['DateTime'];
  readonly updatedAt?: Maybe<Scalars['Float']>;
  readonly updatedByUser?: Maybe<PartialUser>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly user?: Maybe<PartialUser>;
  /** Who the schedule is for */
  readonly userId: Scalars['UUID'];
};

export type ScheduleAvailabilityInput = {
  readonly endDateTime: Scalars['DateTime'];
  readonly startDateTime: Scalars['DateTime'];
  readonly userId: Scalars['UUID'];
};

export type ScheduleEventInput = {
  readonly endTime: Scalars['DateTime'];
  readonly guideId: Scalars['UUID'];
  readonly membershipId: Scalars['UUID'];
  /** @deprecated - this is unused and can be removed */
  readonly mode?: InputMaybe<ScheduleEventMode>;
  readonly sessionId: Scalars['UUID'];
  readonly startTime: Scalars['DateTime'];
};

export const ScheduleEventMode = {
  Reschedule: 'Reschedule',
  Schedule: 'Schedule'
} as const;

export type ScheduleEventMode = typeof ScheduleEventMode[keyof typeof ScheduleEventMode];
export type ScheduleFilter = {
  readonly endTime: Scalars['String'];
  readonly startTime: Scalars['String'];
  readonly userIds: ReadonlyArray<Scalars['UUID']>;
};

export type SchedulerTasks = {
  readonly __typename?: 'SchedulerTasks';
  readonly date: Scalars['DateTime'];
  readonly eventName: Scalars['String'];
  readonly id: Scalars['String'];
};

export const SendGridTemplateNames = {
  FinishedProgramCongrats: 'FinishedProgramCongrats',
  GuidePersonalVideo: 'GuidePersonalVideo',
  GuideWeeklyCheckIn: 'GuideWeeklyCheckIn',
  PersonalizedNote: 'PersonalizedNote',
  PersonalizedNoteNoVideo: 'PersonalizedNoteNoVideo',
  ReminderToSchedule: 'ReminderToSchedule',
  RoadmapIsReady: 'RoadmapIsReady',
  WelcomeSessionConfirmation: 'WelcomeSessionConfirmation',
  WelcomeSurveyNeeded: 'WelcomeSurveyNeeded',
  WelcomeSurveyNotCompleteReschedule: 'WelcomeSurveyNotCompleteReschedule'
} as const;

export type SendGridTemplateNames = typeof SendGridTemplateNames[keyof typeof SendGridTemplateNames];
export const Sentiment = {
  Mixed: 'MIXED',
  Negative: 'NEGATIVE',
  Neutral: 'NEUTRAL',
  Positive: 'POSITIVE'
} as const;

export type Sentiment = typeof Sentiment[keyof typeof Sentiment];
export type SentimentScore = {
  readonly __typename?: 'SentimentScore';
  /** The level of confidence that Amazon Comprehend has in the accuracy of its detection of the MIXED sentiment. */
  readonly Mixed?: Maybe<Scalars['Float']>;
  /** The level of confidence that Amazon Comprehend has in the accuracy of its detection of the NEGATIVE sentiment. */
  readonly Negative?: Maybe<Scalars['Float']>;
  /** The level of confidence that Amazon Comprehend has in the accuracy of its detection of the NEUTRAL sentiment. */
  readonly Neutral?: Maybe<Scalars['Float']>;
  /** The level of confidence that Amazon Comprehend has in the accuracy of its detection of the POSITIVE sentiment. */
  readonly Positive?: Maybe<Scalars['Float']>;
};

/**
 * A Session is a whole experience with the couple. It can either be based on modules or live.
 *
 * Live sessions are scheduled and thus have settings related to which to of event to schedule
 * Module sessions contain a xState 'like' object that describes how to move through the session
 */
export type Session = {
  readonly __typename?: 'Session';
  readonly answerMode?: Maybe<SessionAnswerMode>;
  readonly archivedAt?: Maybe<Scalars['Float']>;
  readonly capSessionsAfterXDays?: Maybe<Scalars['Int']>;
  readonly cardDeckS3Key?: Maybe<Scalars['ShortString']>;
  readonly createdAt: Scalars['Float'];
  readonly createdByUser?: Maybe<PartialUser>;
  readonly createdByUserId: Scalars['UUID'];
  readonly durationInMinutes?: Maybe<Scalars['Int']>;
  readonly endingBtnText?: Maybe<Scalars['ShortString']>;
  readonly extraResources?: Maybe<ReadonlyArray<DownloadItem>>;
  readonly id: Scalars['UUID'];
  readonly internalTitle?: Maybe<Scalars['ShortString']>;
  readonly kind: Kinds;
  readonly linkLogo?: Maybe<FeatureFlag>;
  readonly liveSessionAgenda?: Maybe<ReadonlyArray<Scalars['ShortString']>>;
  readonly maxDaysAllowedToBookAfterGuideEnds?: Maybe<Scalars['Int']>;
  readonly maxDaysBeforeLastSlot?: Maybe<Scalars['Int']>;
  readonly membershipCategories?: Maybe<ReadonlyArray<PurchaseCategories>>;
  /** Deprecated fields */
  readonly minAvailableMinBeforeSlot?: Maybe<Scalars['Int']>;
  readonly minHrBeforeFirstSlot?: Maybe<Scalars['Int']>;
  readonly purchasePriceCents?: Maybe<Scalars['Int']>;
  readonly recapText?: Maybe<Scalars['ShortString']>;
  readonly resolvedValues?: Maybe<SessionResolved>;
  readonly roundRobinGuideIds?: Maybe<ReadonlyArray<Scalars['UUID']>>;
  readonly roundRobinMode?: Maybe<RoundRobinMode>;
  readonly sessionBulletPoints?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly sessionCategories?: Maybe<ReadonlyArray<SessionCategory>>;
  readonly sessionDescription?: Maybe<Scalars['LongString']>;
  readonly sessionPublicShareUrl?: Maybe<Scalars['String']>;
  readonly shareImageInstagramS3Key?: Maybe<Scalars['ShortString']>;
  readonly shareImageS3Key?: Maybe<Scalars['ShortString']>;
  readonly shareText?: Maybe<Scalars['String']>;
  readonly slotStartMinuteStep?: Maybe<Scalars['Int']>;
  readonly startBtnText?: Maybe<Scalars['ShortString']>;
  readonly startViewBG?: Maybe<BrandColors>;
  readonly theme?: Maybe<SessionTheme>;
  readonly title?: Maybe<Scalars['ShortString']>;
  readonly type: SessionType;
  readonly updatedAt?: Maybe<Scalars['Float']>;
  readonly updatedByUser?: Maybe<PartialUser>;
  readonly updatedByUserId?: Maybe<Scalars['UUID']>;
  readonly visibility?: Maybe<SessionVisibility>;
  readonly weeklyCapByGuideId?: Maybe<Scalars['JSONObjectStringNumber']>;
};

export const SessionAnswerMode = {
  SeparateAsync: 'SeparateAsync',
  Together: 'Together'
} as const;

export type SessionAnswerMode = typeof SessionAnswerMode[keyof typeof SessionAnswerMode];
export type SessionAvailabilityResponse = {
  readonly __typename?: 'SessionAvailabilityResponse';
  readonly session: Session;
  readonly timeSlots: ReadonlyArray<TimeSlot>;
};

export const SessionCategory = {
  BecomingUs: 'BecomingUs',
  Communication: 'Communication',
  Differences: 'Differences',
  Finance: 'Finance',
  General: 'General',
  Hygiene: 'Hygiene',
  Parenting: 'Parenting',
  RelationalAwareness: 'RelationalAwareness',
  RolesResponsibilities: 'RolesResponsibilities',
  SexIntimacy: 'SexIntimacy',
  Spirituality: 'Spirituality'
} as const;

export type SessionCategory = typeof SessionCategory[keyof typeof SessionCategory];
export type SessionMediaAsset = {
  readonly __typename?: 'SessionMediaAsset';
  readonly id: Scalars['String'];
  readonly kind: MimeType;
  readonly moduleId?: Maybe<Scalars['UUID']>;
  readonly src?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
};

/**
 * Session progress tracks a membership's progress through a particular session.
 *
 * "id" - session.answerMode = Together:  progress#membershipId#sessionId
 * "id" - session.answerMode = Separate:  progress#membershipId#sessionId#userId
 */
export type SessionProgress = {
  readonly __typename?: 'SessionProgress';
  readonly completed?: Maybe<TrackingType>;
  readonly completedModuleIds?: Maybe<ReadonlyArray<Scalars['UUID']>>;
  readonly createdAt: Scalars['Float'];
  readonly createdByUserId?: Maybe<Scalars['String']>;
  /** The nodeId of the xState object where the user currently is */
  readonly currentNodeId?: Maybe<Scalars['UUID']>;
  /** 0->100: Used to determine if the user is "virtually" completed w/ the session. */
  readonly currentSessionProgress?: Maybe<Scalars['Float']>;
  readonly guideReviewed?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['String'];
  readonly kind: Kinds;
  readonly membershipId: Scalars['UUID'];
  readonly resolvedValues?: Maybe<SessionProgressResolved>;
  readonly sessionId: Scalars['UUID'];
  readonly sessionRating?: Maybe<Scalars['Int']>;
  readonly started?: Maybe<TrackingType>;
  readonly studentId?: Maybe<Scalars['UUID']>;
  readonly webrtcRoomName?: Maybe<Scalars['String']>;
  readonly webrtcRoomPassword?: Maybe<Scalars['String']>;
};

export type SessionProgressResolved = {
  readonly __typename?: 'SessionProgressResolved';
  /** Module Responses for a given session */
  readonly moduleResponses?: Maybe<ReadonlyArray<ModuleResponse>>;
  readonly sessionMediaAssets?: Maybe<ReadonlyArray<SessionMediaAsset>>;
  readonly student?: Maybe<PartialUser>;
};

export type SessionResolved = {
  readonly __typename?: 'SessionResolved';
  readonly cardDeckSignedUrl?: Maybe<Scalars['String']>;
  readonly event?: Maybe<Event>;
  readonly moduleDownloadItems?: Maybe<ReadonlyArray<DownloadItem>>;
  readonly modules?: Maybe<ReadonlyArray<Module>>;
  readonly sessionProgresses?: Maybe<ReadonlyArray<SessionProgress>>;
  /**
   * [{id: "123", on: {NEXT: "1"} }]
   * turns into: { "0": { on: { NEXT: "1" } } }
   */
  readonly sessionXState?: Maybe<ReadonlyArray<XState>>;
  readonly signedShareImage?: Maybe<Scalars['String']>;
  readonly signedShareImageInstagram?: Maybe<Scalars['String']>;
};


export type SessionResolvedEventArgs = {
  membershipId: Scalars['UUID'];
};


export type SessionResolvedSessionProgressesArgs = {
  membershipId: Scalars['UUID'];
};

export type SessionSort = {
  readonly field: SessionSortFields;
  readonly order: SortOrder;
};

export const SessionSortFields = {
  CreatedAt: 'createdAt',
  InternalTitle: 'internalTitle',
  Title: 'title'
} as const;

export type SessionSortFields = typeof SessionSortFields[keyof typeof SessionSortFields];
export const SessionTheme = {
  One: 'One',
  Two: 'Two'
} as const;

export type SessionTheme = typeof SessionTheme[keyof typeof SessionTheme];
export const SessionType = {
  Live: 'LIVE',
  Module: 'MODULE'
} as const;

export type SessionType = typeof SessionType[keyof typeof SessionType];
export const SessionVisibility = {
  Private: 'Private',
  Public: 'Public'
} as const;

export type SessionVisibility = typeof SessionVisibility[keyof typeof SessionVisibility];
export type SessionsFilter = {
  readonly id?: InputMaybe<StringFilter>;
  readonly type?: InputMaybe<StringFilter>;
  readonly visibility?: InputMaybe<StringFilter>;
};

export const SortOrder = {
  Asc: 'Asc',
  Desc: 'Desc'
} as const;

export type SortOrder = typeof SortOrder[keyof typeof SortOrder];
export const Stage = {
  Dev: 'dev',
  Local: 'local',
  Production: 'production'
} as const;

export type Stage = typeof Stage[keyof typeof Stage];
export type StringDateSequence = {
  readonly __typename?: 'StringDateSequence';
  readonly content?: Maybe<Scalars['LongString']>;
  readonly createdAt: Scalars['Float'];
  readonly hash: Scalars['ShortString'];
};

export type StringFilter = {
  readonly eq?: InputMaybe<Scalars['String']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly neq?: InputMaybe<Scalars['String']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type StudentResponses = {
  readonly __typename?: 'StudentResponses';
  readonly response?: Maybe<Response>;
  readonly userId: Scalars['UUID'];
};

export type Subscription = {
  readonly __typename?: 'Subscription';
  readonly _?: Maybe<Scalars['String']>;
};

export type TextAnalysisResults = {
  readonly __typename?: 'TextAnalysisResults';
  readonly language?: Maybe<Scalars['String']>;
  readonly sentiment?: Maybe<Sentiment>;
  readonly sentimentScore?: Maybe<SentimentScore>;
  readonly textLength?: Maybe<Scalars['Int']>;
  readonly wordCount?: Maybe<Scalars['Int']>;
};

/** An available TimeSlot for a customer to book in a guides schedule */
export type TimeSlot = {
  readonly __typename?: 'TimeSlot';
  /** The end date of the period */
  readonly endDateTime: Scalars['DateTime'];
  readonly guideId: Scalars['UUID'];
  readonly guideName?: Maybe<Scalars['String']>;
  /** The start date of the period */
  readonly startDateTime: Scalars['DateTime'];
};

export type TrackingType = {
  readonly __typename?: 'TrackingType';
  readonly deviceCategory?: Maybe<DeviceCategory>;
  /** The entityId that the tracking type refers too */
  readonly entityId?: Maybe<Scalars['UUID']>;
  readonly entityKind?: Maybe<Kinds>;
  readonly height?: Maybe<Scalars['Int']>;
  readonly id: Scalars['UUID'];
  readonly isChrome?: Maybe<Scalars['Boolean']>;
  readonly isEdge?: Maybe<Scalars['Boolean']>;
  readonly isFirefox?: Maybe<Scalars['Boolean']>;
  readonly isSafari?: Maybe<Scalars['Boolean']>;
  readonly time?: Maybe<Scalars['Float']>;
  readonly timezone?: Maybe<Scalars['String']>;
  readonly user?: Maybe<PartialUser>;
  readonly userId?: Maybe<Scalars['String']>;
  readonly width?: Maybe<Scalars['Int']>;
};

export type TrackingTypeInput = {
  /** @deprecated */
  readonly deviceCategory?: InputMaybe<DeviceCategory>;
  readonly entityId?: InputMaybe<Scalars['UUID']>;
  readonly entityKind?: InputMaybe<Kinds>;
  /** @deprecated */
  readonly height?: InputMaybe<Scalars['Int']>;
  readonly id: Scalars['UUID'];
  /** @deprecated */
  readonly isChrome?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated */
  readonly isEdge?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated */
  readonly isFirefox?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated */
  readonly isSafari?: InputMaybe<Scalars['Boolean']>;
  readonly time: Scalars['Float'];
  readonly timezone: Scalars['Timezone'];
  /** @todo make this required */
  readonly userId?: InputMaybe<Scalars['String']>;
  /** @deprecated */
  readonly width?: InputMaybe<Scalars['Int']>;
};

export type TranscriptCue = {
  readonly __typename?: 'TranscriptCue';
  readonly end?: Maybe<Scalars['Float']>;
  readonly start?: Maybe<Scalars['Float']>;
  readonly text?: Maybe<Scalars['String']>;
};

export type TranscriptCueInput = {
  readonly end: Scalars['Float'];
  readonly start: Scalars['Float'];
  readonly text: Scalars['String'];
};

export type UuidFilter = {
  readonly eq?: InputMaybe<Scalars['UUID']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UUID']>>>;
  readonly neq?: InputMaybe<Scalars['UUID']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UUID']>>>;
};

export type UploadLinkResponse = {
  readonly __typename?: 'UploadLinkResponse';
  readonly code?: Maybe<Scalars['String']>;
};

/** Users can be admins, guides, or customers. */
export type User = {
  readonly __typename?: 'User';
  readonly archivedAt?: Maybe<Scalars['Float']>;
  readonly bio?: Maybe<Scalars['String']>;
  /** The users birthday as 2007-12-03 */
  readonly birthday?: Maybe<Scalars['Date']>;
  readonly createdAt: Scalars['Float'];
  readonly createdByUser?: Maybe<PartialUser>;
  readonly createdByUserId: Scalars['String'];
  readonly email?: Maybe<Scalars['String']>;
  readonly fullName?: Maybe<Scalars['String']>;
  readonly funFacts?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['UUID'];
  readonly kind: Kinds;
  readonly lastSessionStartTime?: Maybe<Scalars['Float']>;
  readonly latestMembership?: Maybe<Membership>;
  readonly latestMembershipId?: Maybe<Scalars['UUID']>;
  readonly membershipIds?: Maybe<ReadonlyArray<Scalars['UUID']>>;
  /**
   * A field whose value conforms to the standard E.164 format.
   * Basically: +17895551234.
   * Use libphonenumber to parse, format, and use this phone number
   */
  readonly mobileNumber?: Maybe<Scalars['PhoneNumber']>;
  readonly physicalLocation?: Maybe<Scalars['ShortString']>;
  /** This is only used to locate the profile photo in s3. Use "profilePhotoSignedCDN" to display the photo. */
  readonly profilePhotoLocation?: Maybe<Scalars['String']>;
  readonly profilePhotoSignedCDN?: Maybe<Scalars['String']>;
  /** profilePhotoSignedUrl avoids the CDN - use for Admin app or uncached situations only */
  readonly profilePhotoSignedUrl?: Maybe<Scalars['String']>;
  readonly pronouns?: Maybe<Pronouns>;
  readonly role?: Maybe<Role>;
  readonly selfReportedReferrer?: Maybe<Scalars['ShortString']>;
  readonly shortBio?: Maybe<Scalars['String']>;
  readonly signatureS3Key?: Maybe<Scalars['ShortString']>;
  readonly signatureSignedUrl?: Maybe<Scalars['String']>;
  readonly slackUserName?: Maybe<Scalars['ShortString']>;
  readonly stripeCustomerId?: Maybe<Scalars['String']>;
  readonly supervisesGuideIds?: Maybe<ReadonlyArray<Scalars['UUID']>>;
  readonly supervisorId?: Maybe<Scalars['UUID']>;
  readonly supervisorUser?: Maybe<PartialUser>;
  /**
   * The users timezone. Example: "Europe/Prague"
   * import { timezones } from 'graphql-timezone';
   */
  readonly timezone?: Maybe<Scalars['Timezone']>;
  readonly updatedAt?: Maybe<Scalars['Float']>;
  readonly updatedByUser?: Maybe<PartialUser>;
  readonly updatedByUserId?: Maybe<Scalars['String']>;
  readonly whyRelationshipsMatter?: Maybe<Scalars['LongString']>;
};


/** Users can be admins, guides, or customers. */
export type UserProfilePhotoSignedCdnArgs = {
  image?: InputMaybe<ImageParams>;
};

export type UserFilters = {
  readonly role?: InputMaybe<RoleFilter>;
};

export type UserSort = {
  readonly field: UserSortFields;
  readonly order: SortOrder;
};

export const UserSortFields = {
  CreatedAt: 'createdAt',
  FullName: 'fullName'
} as const;

export type UserSortFields = typeof UserSortFields[keyof typeof UserSortFields];
export type VideoAssets = {
  readonly __typename?: 'VideoAssets';
  readonly chapters?: Maybe<ReadonlyArray<VideoChapter>>;
  readonly isGifThumbnailReady?: Maybe<Scalars['Boolean']>;
  readonly signedDownsizedVideoPlaybackMP4?: Maybe<Scalars['String']>;
  readonly signedThumbnailGif?: Maybe<Scalars['String']>;
  readonly signedThumbnailPng?: Maybe<Scalars['String']>;
  readonly signedVideoPlaybackMP4?: Maybe<Scalars['String']>;
  readonly transcriptCues?: Maybe<ReadonlyArray<TranscriptCue>>;
};

export type VideoChapter = {
  readonly __typename?: 'VideoChapter';
  readonly start: Scalars['Float'];
  readonly text: Scalars['String'];
};

export type VideoChapterInput = {
  readonly start: Scalars['Float'];
  readonly text: Scalars['String'];
};

export type XState = {
  readonly __typename?: 'XState';
  readonly data?: Maybe<Scalars['JSONObject']>;
  readonly id: Scalars['UUID'];
  readonly on?: Maybe<Scalars['JSONObject']>;
  readonly type: XStateType;
};

export type XStateInput = {
  readonly data?: InputMaybe<Scalars['JSONObject']>;
  readonly id: Scalars['UUID'];
  readonly on?: InputMaybe<Scalars['JSONObject']>;
  readonly type: XStateType;
};

export const XStateType = {
  Final: 'final',
  IfBoolean: 'ifBoolean',
  Module: 'module',
  Mulitbranch: 'mulitbranch',
  Start: 'start'
} as const;

export type XStateType = typeof XStateType[keyof typeof XStateType];
export type XStateFragment = { readonly __typename?: 'XState', readonly id: string, readonly on?: Record < string, any> | null, readonly type: XStateType, readonly data?: Record < string, any> | null };

export type FindSessionQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type FindSessionQuery = { readonly __typename?: 'Query', readonly findSession?: (
    { readonly __typename?: 'Session' }
    & SessionFieldsFragment
  ) | null };

export type FindPublicSessionQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type FindPublicSessionQuery = { readonly __typename?: 'Query', readonly findPublicSession?: (
    { readonly __typename?: 'Session' }
    & SessionFieldsFragment
  ) | null };

export type QuerySessionsQueryVariables = Exact<{
  query?: InputMaybe<QueryArgs>;
  sort?: InputMaybe<ReadonlyArray<SessionSort> | SessionSort>;
}>;


export type QuerySessionsQuery = { readonly __typename?: 'Query', readonly querySessions?: { readonly __typename?: 'QuerySessionsResponse', readonly count?: number | null, readonly entities?: ReadonlyArray<(
      { readonly __typename?: 'Session' }
      & SessionFieldsFragment
    )> | null } | null };

export type PutSessionXStateMutationVariables = Exact<{
  id: Scalars['UUID'];
  xState: ReadonlyArray<XStateInput> | XStateInput;
}>;


export type PutSessionXStateMutation = { readonly __typename?: 'Mutation', readonly putSessionXState?: { readonly __typename?: 'Session', readonly id: string, readonly resolvedValues?: { readonly __typename?: 'SessionResolved', readonly sessionXState?: ReadonlyArray<(
        { readonly __typename?: 'XState' }
        & XStateFragment
      )> | null } | null } | null };

export type CreateSessionMutationVariables = Exact<{
  session: MutateSessionInput;
}>;


export type CreateSessionMutation = { readonly __typename?: 'Mutation', readonly createSession?: (
    { readonly __typename?: 'Session' }
    & SessionFieldsFragment
  ) | null };

export type StartSessionMutationVariables = Exact<{
  sessionId: Scalars['UUID'];
  membershipId: Scalars['UUID'];
  data: TrackingTypeInput;
}>;


export type StartSessionMutation = { readonly __typename?: 'Mutation', readonly startSession?: { readonly __typename?: 'SessionProgress', readonly id: string } | null };

export type CompleteSessionMutationVariables = Exact<{
  sessionId: Scalars['UUID'];
  membershipId: Scalars['UUID'];
  data: TrackingTypeInput;
}>;


export type CompleteSessionMutation = { readonly __typename?: 'Mutation', readonly completeSession?: { readonly __typename?: 'SessionProgress', readonly id: string } | null };

export type RateSessionMutationVariables = Exact<{
  membershipId: Scalars['UUID'];
  sessionId: Scalars['UUID'];
  rating: Scalars['Int'];
}>;


export type RateSessionMutation = { readonly __typename?: 'Mutation', readonly rateSession?: { readonly __typename?: 'SessionProgress', readonly id: string } | null };

export type CompleteSessionModuleMutationVariables = Exact<{
  moduleId: Scalars['UUID'];
  sessionId: Scalars['UUID'];
  membershipId: Scalars['UUID'];
  nextNodeId: Scalars['UUID'];
}>;


export type CompleteSessionModuleMutation = { readonly __typename?: 'Mutation', readonly completeSessionModule?: { readonly __typename?: 'SessionProgress', readonly id: string } | null };

export type MarkLiveSessionIncompleteMutationVariables = Exact<{
  sessionId: Scalars['UUID'];
  membershipId: Scalars['UUID'];
}>;


export type MarkLiveSessionIncompleteMutation = { readonly __typename?: 'Mutation', readonly markLiveSessionIncomplete?: (
    { readonly __typename?: 'SessionProgress' }
    & SessionProgressFragment
  ) | null };

export type FindNoteQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type FindNoteQuery = { readonly __typename?: 'Query', readonly findNote?: (
    { readonly __typename?: 'Note' }
    & NoteFieldsFragment
  ) | null };

export type QueryEntitiesNotesQueryVariables = Exact<{
  id: Scalars['UUID'];
  kind: Kinds;
}>;


export type QueryEntitiesNotesQuery = { readonly __typename?: 'Query', readonly queryEntitiesNotes?: ReadonlyArray<(
    { readonly __typename?: 'Note' }
    & NoteFieldsFragment
  )> | null };

export type PatchNoteMutationVariables = Exact<{
  id: Scalars['UUID'];
  note: PatchNoteInput;
}>;


export type PatchNoteMutation = { readonly __typename?: 'Mutation', readonly patchNote?: (
    { readonly __typename?: 'Note' }
    & NoteFieldsFragment
  ) | null };

export type CreateNoteMutationVariables = Exact<{
  note: CreateNoteInput;
}>;


export type CreateNoteMutation = { readonly __typename?: 'Mutation', readonly createNote?: (
    { readonly __typename?: 'Note' }
    & NoteFieldsFragment
  ) | null };

export type QueryModulesQueryVariables = Exact<{
  query?: InputMaybe<QueryArgs>;
  sort?: InputMaybe<ReadonlyArray<ModuleSort> | ModuleSort>;
}>;


export type QueryModulesQuery = { readonly __typename?: 'Query', readonly queryModules?: { readonly __typename?: 'QueryModuleResponse', readonly count?: number | null, readonly entities?: ReadonlyArray<(
      { readonly __typename?: 'Module' }
      & ModuleFieldsFragment
    )> | null } | null };

export type PutHiFiTranscriptCueMutationVariables = Exact<{
  id: Scalars['UUID'];
  cues: ReadonlyArray<TranscriptCueInput> | TranscriptCueInput;
}>;


export type PutHiFiTranscriptCueMutation = { readonly __typename?: 'Mutation', readonly putHiFiTranscriptCue?: { readonly __typename?: 'Module', readonly id: string } | null };

export type CreateModulesMutationVariables = Exact<{
  modules: ReadonlyArray<MutateModuleInput> | MutateModuleInput;
}>;


export type CreateModulesMutation = { readonly __typename?: 'Mutation', readonly createModules?: ReadonlyArray<(
    { readonly __typename?: 'Module' }
    & ModuleFieldsFragment
  )> | null };

export type AddModuleResponseIdsMutationVariables = Exact<{
  ids: ModuleIds;
  data: ModuleResponseInput;
}>;


export type AddModuleResponseIdsMutation = { readonly __typename?: 'Mutation', readonly addModuleResponseIds?: boolean | null };

export type FindWithDerivedQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type FindWithDerivedQuery = { readonly __typename?: 'Query', readonly findMembership?: { readonly __typename?: 'Membership', readonly resolvedValues?: { readonly __typename?: 'MembershipResolved', readonly partnerCompletedOnboarding?: boolean | null, readonly purchaserCompletedOnboarding?: boolean | null } | null } | null };

export type AssignGuideToMembershipMutationVariables = Exact<{
  id: Scalars['UUID'];
  guideId?: InputMaybe<Scalars['UUID']>;
}>;


export type AssignGuideToMembershipMutation = { readonly __typename?: 'Mutation', readonly assignGuide?: (
    { readonly __typename?: 'Membership' }
    & MembershipFieldsFragment
  ) | null };

export type RegisterEmailForMembershipMutationVariables = Exact<{
  input: RegisterInput;
}>;


export type RegisterEmailForMembershipMutation = { readonly __typename?: 'Mutation', readonly registerEmailForMembership?: { readonly __typename?: 'Membership', readonly id: string } | null };

export type InvitePartnerToMembershipMutationVariables = Exact<{
  membershipId: Scalars['UUID'];
  partnerEmail: Scalars['EmailAddress'];
}>;


export type InvitePartnerToMembershipMutation = { readonly __typename?: 'Mutation', readonly invitePartnerToMembership?: { readonly __typename?: 'Membership', readonly id: string, readonly studentIds?: ReadonlyArray<string> | null, readonly students?: ReadonlyArray<{ readonly __typename?: 'User', readonly id: string, readonly pronouns?: Pronouns | null, readonly fullName?: string | null, readonly email?: string | null }> | null } | null };

export type AdminInvitePartnerToMembershipMutationVariables = Exact<{
  membershipId: Scalars['UUID'];
  partnerEmail: Scalars['EmailAddress'];
}>;


export type AdminInvitePartnerToMembershipMutation = { readonly __typename?: 'Mutation', readonly adminInvitePartnerToMembership?: { readonly __typename?: 'Membership', readonly id: string, readonly studentIds?: ReadonlyArray<string> | null, readonly students?: ReadonlyArray<{ readonly __typename?: 'User', readonly id: string, readonly pronouns?: Pronouns | null, readonly fullName?: string | null, readonly email?: string | null }> | null } | null };

export type FindMembershipQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type FindMembershipQuery = { readonly __typename?: 'Query', readonly findMembership?: (
    { readonly __typename?: 'Membership' }
    & MembershipFieldsFragment
  ) | null };

export type QueryMembershipsQueryVariables = Exact<{
  query?: InputMaybe<QueryArgs>;
  sort?: InputMaybe<ReadonlyArray<MembershipSort> | MembershipSort>;
}>;


export type QueryMembershipsQuery = { readonly __typename?: 'Query', readonly queryMemberships?: { readonly __typename?: 'QueryMembershipsResponse', readonly count?: number | null, readonly entities?: ReadonlyArray<(
      { readonly __typename?: 'Membership' }
      & MembershipFieldsFragment
    )> | null } | null };

export type PatchMembershipMutationVariables = Exact<{
  id: Scalars['UUID'];
  membership: PatchMembershipInput;
}>;


export type PatchMembershipMutation = { readonly __typename?: 'Mutation', readonly patchMembership?: (
    { readonly __typename?: 'Membership' }
    & MembershipFieldsFragment
  ) | null };

export type CreateMembershipMutationVariables = Exact<{
  membership: CreateMembershipInput;
}>;


export type CreateMembershipMutation = { readonly __typename?: 'Mutation', readonly createMembership?: (
    { readonly __typename?: 'Membership' }
    & MembershipFieldsFragment
  ) | null };

export type MembershipTestingQueryQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type MembershipTestingQueryQuery = { readonly __typename?: 'Query', readonly findMembership?: { readonly __typename?: 'Membership', readonly id: string, readonly combinedStudentsName?: string | null, readonly students?: ReadonlyArray<(
      { readonly __typename?: 'User' }
      & UserFieldsFragment
    )> | null } | null };

export type ArchiveEntityMutationVariables = Exact<{
  kind: Kinds;
  id: Scalars['UUID'];
}>;


export type ArchiveEntityMutation = { readonly __typename?: 'Mutation', readonly archiveEntity?: boolean | null };

export type UnArchiveEntityMutationVariables = Exact<{
  kind: Kinds;
  id: Scalars['UUID'];
}>;


export type UnArchiveEntityMutation = { readonly __typename?: 'Mutation', readonly unArchiveEntity?: boolean | null };

export type PutUrlFieldsFragment = { readonly __typename?: 'PutUrl', readonly directory?: string | null, readonly key?: string | null, readonly signedUploadKey?: string | null, readonly uploadKey?: string | null, readonly signedDownloadURL?: string | null };

export type RequestGuideVideoPutUrlMutationVariables = Exact<{
  mimeType: MimeType;
}>;


export type RequestGuideVideoPutUrlMutation = { readonly __typename?: 'Mutation', readonly requestGuideVideoPutUrl?: (
    { readonly __typename?: 'PutUrl' }
    & PutUrlFieldsFragment
  ) | null };

export type RequestModuleVideoPutUrlMutationVariables = Exact<{
  mimeType: MimeType;
}>;


export type RequestModuleVideoPutUrlMutation = { readonly __typename?: 'Mutation', readonly requestModuleVideoPutUrl?: (
    { readonly __typename?: 'PutUrl' }
    & PutUrlFieldsFragment
  ) | null };

export type RequestModuleAudioPutUrlMutationVariables = Exact<{
  mimeType: MimeType;
}>;


export type RequestModuleAudioPutUrlMutation = { readonly __typename?: 'Mutation', readonly requestModuleAudioPutUrl?: (
    { readonly __typename?: 'PutUrl' }
    & PutUrlFieldsFragment
  ) | null };

export type RequestModuleImageDocumentPutUrlMutationVariables = Exact<{
  mimeType: MimeType;
}>;


export type RequestModuleImageDocumentPutUrlMutation = { readonly __typename?: 'Mutation', readonly requestModuleImageDocumentPutUrl?: (
    { readonly __typename?: 'PutUrl' }
    & PutUrlFieldsFragment
  ) | null };

export type RequestProfilePhotoPutUrlMutationVariables = Exact<{
  mimeType: MimeType;
}>;


export type RequestProfilePhotoPutUrlMutation = { readonly __typename?: 'Mutation', readonly requestProfilePhotoPutUrl?: (
    { readonly __typename?: 'PutUrl' }
    & PutUrlFieldsFragment
  ) | null };

export type RequestPreSignedSessionContentMutationVariables = Exact<{
  mimeType: MimeType;
}>;


export type RequestPreSignedSessionContentMutation = { readonly __typename?: 'Mutation', readonly requestPreSignedSessionContent?: (
    { readonly __typename?: 'PutUrl' }
    & PutUrlFieldsFragment
  ) | null };

export type FindEventQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type FindEventQuery = { readonly __typename?: 'Query', readonly findEvent?: (
    { readonly __typename?: 'Event' }
    & EventFieldsFragment
  ) | null };

export type GetLiveEventHostTokenQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetLiveEventHostTokenQuery = { readonly __typename?: 'Query', readonly getLiveEventHostToken?: string | null };

export type GetLiveEventGuestTokenQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetLiveEventGuestTokenQuery = { readonly __typename?: 'Query', readonly getLiveEventGuestToken?: string | null };

export type CancelEventMutationVariables = Exact<{
  id: Scalars['UUID'];
  token?: InputMaybe<Scalars['UUID']>;
}>;


export type CancelEventMutation = { readonly __typename?: 'Mutation', readonly cancelEvent?: { readonly __typename?: 'PublicEvent', readonly id: string, readonly archivedAt?: number | null } | null };

export type MissEventMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type MissEventMutation = { readonly __typename?: 'Mutation', readonly missEvent?: (
    { readonly __typename?: 'Event' }
    & EventFieldsFragment
  ) | null };

export type CreateMembershipEventMutationVariables = Exact<{
  input: ScheduleEventInput;
}>;


export type CreateMembershipEventMutation = { readonly __typename?: 'Mutation', readonly createMembershipEvent?: { readonly __typename?: 'MembershipEventResponse', readonly event?: (
      { readonly __typename?: 'Event', readonly resolvedValues?: { readonly __typename?: 'EventResolved', readonly membership?: { readonly __typename?: 'Membership', readonly id: string, readonly guideId?: string | null, readonly scheduledEventIds?: ReadonlyArray<string> | null, readonly guide?: { readonly __typename?: 'PartialUser', readonly id: string, readonly fullName?: string | null, readonly email?: string | null } | null, readonly scheduledEvents?: ReadonlyArray<(
            { readonly __typename?: 'Event' }
            & EventFieldsFragment
          )> | null } | null } | null }
      & EventFieldsFragment
    ) | null } | null };

export type PatchEventJoinUrlMutationVariables = Exact<{
  id: Scalars['UUID'];
  nextJoinUrl: Scalars['String'];
}>;


export type PatchEventJoinUrlMutation = { readonly __typename?: 'Mutation', readonly patchEventJoinUrl?: (
    { readonly __typename?: 'Event' }
    & EventFieldsFragment
  ) | null };

export type AddEmailSendGridIdMutationVariables = Exact<{
  id: Scalars['UUID'];
  payload: AddSendGridIdPayload;
}>;


export type AddEmailSendGridIdMutation = { readonly __typename?: 'Mutation', readonly addEmailSendGridId?: boolean | null };

export type SendTemplateMutationVariables = Exact<{
  membershipId: Scalars['UUID'];
  templateName: SendGridTemplateNames;
  payload: Scalars['JSONObjectStringString'];
}>;


export type SendTemplateMutation = { readonly __typename?: 'Mutation', readonly sendTemplate?: boolean | null };

export type FindEmailQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type FindEmailQuery = { readonly __typename?: 'Query', readonly findEmail?: (
    { readonly __typename?: 'Email', readonly content?: { readonly __typename?: 'EmailContent', readonly html?: string | null, readonly text?: string | null } | null }
    & EmailFieldsFragment
  ) | null };

export type FindSendGridTemplateQueryVariables = Exact<{
  templateName: SendGridTemplateNames;
}>;


export type FindSendGridTemplateQuery = { readonly __typename?: 'Query', readonly findSendGridTemplate?: string | null };

export type StoreEmailEventMutationVariables = Exact<{
  emailEvent: EmailEventInput;
}>;


export type StoreEmailEventMutation = { readonly __typename?: 'Mutation', readonly storeEmailEvent?: boolean | null };

export type CreateContentMutationVariables = Exact<{
  content: ContentInput;
}>;


export type CreateContentMutation = { readonly __typename?: 'Mutation', readonly createContent: (
    { readonly __typename?: 'Content' }
    & ContentFragment
  ) };

export type PatchContentMutationVariables = Exact<{
  id: Scalars['UUID'];
  content: PatchContentInput;
}>;


export type PatchContentMutation = { readonly __typename?: 'Mutation', readonly patchContent: (
    { readonly __typename?: 'Content' }
    & ContentFragment
  ) };

export type FindContentQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type FindContentQuery = { readonly __typename?: 'Query', readonly findContent?: (
    { readonly __typename?: 'Content' }
    & ContentFragment
  ) | null };

export type ModuleResponseFragment = { readonly __typename?: 'ModuleResponse', readonly id: string, readonly createdAt: number, readonly label?: string | null, readonly localObjectUrl?: string | null, readonly mediaCaption?: string | null, readonly s3Key?: string | null, readonly signedS3KeyUrl?: string | null, readonly value?: string | null, readonly valueDescription?: string | null, readonly moduleId: string, readonly sessionId: string };

export type DownloadItemFragment = { readonly __typename?: 'DownloadItem', readonly title?: string | null, readonly src?: string | null, readonly description?: string | null };

export type SessionMediaAssetFragment = { readonly __typename?: 'SessionMediaAsset', readonly id: string, readonly kind: MimeType, readonly src?: string | null, readonly title?: string | null, readonly moduleId?: string | null };

export type BookmarkFieldsFragment = { readonly __typename?: 'Bookmark', readonly id: string, readonly kind: Kinds, readonly createdAt: number, readonly createdByUserId: string, readonly content: string, readonly startTimeStampSec: number, readonly endTimeStampSec: number, readonly moduleId: string, readonly sessionId: string, readonly membershipId: string };

export type BookmarkListPageQueryFragment = (
  { readonly __typename?: 'Bookmark', readonly resolvedValues?: { readonly __typename?: 'BookmarkResolved', readonly session?: { readonly __typename?: 'Session', readonly id: string, readonly title?: string | null, readonly resolvedValues?: { readonly __typename?: 'SessionResolved', readonly cardDeckSignedUrl?: string | null } | null } | null, readonly module?: { readonly __typename?: 'Module', readonly id: string, readonly title?: string | null, readonly description?: string | null, readonly openEndedPrompt?: string | null, readonly resolvedValues?: { readonly __typename?: 'ModuleResolvedValues', readonly moduleVideo?: (
          { readonly __typename?: 'VideoAssets' }
          & VideoAssetsFragmentFragment
        ) | null } | null } | null } | null }
  & BookmarkFieldsFragment
);

export type TimeSlotFragment = { readonly __typename?: 'TimeSlot', readonly guideId: string, readonly guideName?: string | null, readonly startDateTime: string, readonly endDateTime: string };

export type ScheduleAvailabilityFragment = { readonly __typename?: 'ScheduleAvailability', readonly id: string, readonly kind?: string | null, readonly userId: string, readonly startDateTime: string, readonly endDateTime: string };

export type ContentFragment = { readonly __typename?: 'Content', readonly id: string, readonly title?: string | null, readonly contentKind: ContentKind, readonly content?: string | null };

export type MediaFragment = { readonly __typename?: 'Media', readonly id: string, readonly creatorId: string, readonly membershipId?: string | null, readonly visibility?: MediaVisibility | null, readonly type?: MediaType | null, readonly imageS3Key?: string | null, readonly videoDirectory?: string | null };

export type ChangeHistoryFragment = { readonly __typename?: 'ChangeHistory', readonly id?: string | null, readonly time?: number | null, readonly eventName?: ChangeEventName | null, readonly kind?: Kinds | null, readonly next?: string | null, readonly prev?: string | null, readonly userId?: string | null, readonly user?: { readonly __typename?: 'PartialUser', readonly id: string, readonly fullName?: string | null } | null };

export type MembershipAppFragment = { readonly __typename?: 'Membership', readonly id: string, readonly guideId?: string | null, readonly purchasedAt?: number | null, readonly completedSessionIds?: ReadonlyArray<string> | null, readonly membershipState?: MembershipState | null, readonly welcomeSessionDate?: string | null, readonly onboardingSessionId?: string | null, readonly sessionStatus?: PathSessionStatus | null, readonly sessionIds?: ReadonlyArray<string> | null, readonly purchaserUserId: string, readonly studentIds?: ReadonlyArray<string> | null, readonly combinedStudentsName?: string | null, readonly combinedStudentsNameExclusive?: string | null, readonly guideEndsAt?: string | null, readonly createdByUserId: string, readonly createdAt: number, readonly updatedAt?: number | null, readonly resolvedValues?: { readonly __typename?: 'MembershipResolved', readonly purchaserUserFullName?: string | null } | null, readonly students?: ReadonlyArray<{ readonly __typename?: 'User', readonly id: string, readonly fullName?: string | null }> | null, readonly guide?: { readonly __typename?: 'PartialUser', readonly id: string, readonly fullName?: string | null } | null };

export type SessionFieldsFragment = { readonly __typename?: 'Session', readonly title?: string | null, readonly internalTitle?: string | null, readonly sessionDescription?: string | null, readonly answerMode?: SessionAnswerMode | null, readonly type: SessionType, readonly id: string, readonly createdAt: number, readonly visibility?: SessionVisibility | null, readonly updatedAt?: number | null, readonly theme?: SessionTheme | null, readonly maxDaysBeforeLastSlot?: number | null, readonly maxDaysAllowedToBookAfterGuideEnds?: number | null, readonly minHrBeforeFirstSlot?: number | null, readonly roundRobinGuideIds?: ReadonlyArray<string> | null };

export type UserFieldsFragment = { readonly __typename?: 'User', readonly id: string, readonly createdAt: number, readonly createdByUserId: string, readonly updatedAt?: number | null, readonly fullName?: string | null, readonly selfReportedReferrer?: string | null, readonly role?: Role | null, readonly email?: string | null, readonly bio?: string | null, readonly timezone?: string | null, readonly pronouns?: Pronouns | null, readonly birthday?: string | null, readonly mobileNumber?: string | null, readonly signatureS3Key?: string | null, readonly signatureSignedUrl?: string | null };

export type PaymentMethodFragment = { readonly __typename?: 'PaymentMethod', readonly brand: CardBrand, readonly expMonth: number, readonly expYear: number, readonly id: string, readonly last4: string };

export type PurchasableSessionsFragment = { readonly __typename?: 'PurchasableSessions', readonly id: string, readonly type: SessionType, readonly title?: string | null, readonly sessionDescription?: string | null, readonly purchasePriceCents?: number | null, readonly sessionCategories?: ReadonlyArray<SessionCategory> | null, readonly resolvedValues?: { readonly __typename?: 'PurchasableSessionResolved', readonly guideHeadshots?: ReadonlyArray<string> | null } | null };

export type EventForAppContextFragment = { readonly __typename?: 'Event', readonly id: string, readonly startTime: string, readonly endTime?: string | null, readonly guideId?: string | null, readonly sessionId: string, readonly membershipId?: string | null, readonly joinUrl?: string | null, readonly archivedAt?: number | null, readonly createdByUserId: string, readonly createdAt: number };

export type SessionForAppContextFragment = { readonly __typename?: 'Session', readonly id: string, readonly title?: string | null, readonly internalTitle?: string | null, readonly sessionBulletPoints?: ReadonlyArray<string> | null, readonly theme?: SessionTheme | null, readonly shareText?: string | null, readonly sessionDescription?: string | null, readonly startBtnText?: string | null, readonly startViewBG?: BrandColors | null, readonly endingBtnText?: string | null, readonly type: SessionType, readonly answerMode?: SessionAnswerMode | null, readonly linkLogo?: FeatureFlag | null, readonly maxDaysBeforeLastSlot?: number | null, readonly maxDaysAllowedToBookAfterGuideEnds?: number | null, readonly minHrBeforeFirstSlot?: number | null, readonly liveSessionAgenda?: ReadonlyArray<string> | null, readonly membershipCategories?: ReadonlyArray<PurchaseCategories> | null, readonly roundRobinGuideIds?: ReadonlyArray<string> | null, readonly weeklyCapByGuideId?: Record<string, number> | null, readonly visibility?: SessionVisibility | null, readonly sessionPublicShareUrl?: string | null, readonly resolvedValues?: { readonly __typename?: 'SessionResolved', readonly cardDeckSignedUrl?: string | null, readonly signedShareImage?: string | null, readonly signedShareImageInstagram?: string | null, readonly modules?: ReadonlyArray<(
      { readonly __typename?: 'Module' }
      & ModuleForAppContextFragment
    )> | null, readonly sessionXState?: ReadonlyArray<(
      { readonly __typename?: 'XState' }
      & XStateFragment
    )> | null } | null, readonly extraResources?: ReadonlyArray<(
    { readonly __typename?: 'DownloadItem' }
    & DownloadItemFragment
  )> | null };

export type ModuleForAppContextFragment = { readonly __typename?: 'Module', readonly id: string, readonly title?: string | null, readonly description?: string | null, readonly answersVisibleTo?: AnswerVisibility | null, readonly layout?: ModuleLayout | null, readonly displayKind?: DisplayKind | null, readonly interactionKind?: InteractionKind | null, readonly macroModuleIds?: ReadonlyArray<string> | null, readonly buttonText?: string | null, readonly buttonLink?: string | null, readonly openEndedPrompt?: string | null, readonly durationInSeconds?: number | null, readonly placeholder?: string | null, readonly upperLimitHelper?: string | null, readonly lowerLimitHelper?: string | null, readonly multipleChoiceVariant?: MultipleChoiceVariant | null, readonly multipleChoiceAllowMany?: MultipleChoiceAllowMany | null, readonly sideEffectActions?: ReadonlyArray<ModuleSideEffect> | null, readonly sentenceBuilderString?: string | null, readonly textDisplayOnly?: string | null, readonly backgroundColor?: BrandColors | null, readonly inputType?: InputType | null, readonly answerMode?: ModuleAnswerMode | null, readonly tags?: ReadonlyArray<ModuleTags> | null, readonly purchasableSessionIds?: ReadonlyArray<string> | null, readonly conditionallyDisabled?: FeatureFlag | null, readonly buttonSecondaryText?: string | null, readonly multipleChoices?: ReadonlyArray<string> | null, readonly animatedVariant?: AnimatedVariant | null, readonly presenterUserId?: string | null, readonly presenterUserName?: string | null, readonly interactionColor?: BrandColors | null, readonly downloadableS3Key?: string | null, readonly photoboothNumberOfPhotos?: number | null, readonly photoboothPrompts?: ReadonlyArray<string> | null, readonly timeInSec?: number | null, readonly schedulerSessionId?: string | null, readonly resolvedValues?: { readonly __typename?: 'ModuleResolvedValues', readonly signedImage?: string | null, readonly signedDownloadable?: string | null, readonly moduleVideo?: (
      { readonly __typename?: 'VideoAssets' }
      & VideoAssetsFragmentFragment
    ) | null, readonly moduleAudio?: (
      { readonly __typename?: 'AudioAssets' }
      & AudioFragmentFragment
    ) | null, readonly purchasableSessions?: ReadonlyArray<(
      { readonly __typename?: 'PurchasableSessions' }
      & PurchasableSessionsFragment
    )> | null } | null, readonly paymentBlocks?: ReadonlyArray<(
    { readonly __typename?: 'PaymentBlock' }
    & PaymentBlockFragment
  )> | null, readonly quizPairs?: ReadonlyArray<{ readonly __typename?: 'QuizPairs', readonly question: string, readonly answer: string }> | null };

export type VideoAssetsFragmentFragment = { readonly __typename?: 'VideoAssets', readonly signedVideoPlaybackMP4?: string | null, readonly signedDownsizedVideoPlaybackMP4?: string | null, readonly signedThumbnailGif?: string | null, readonly signedThumbnailPng?: string | null, readonly chapters?: ReadonlyArray<(
    { readonly __typename?: 'VideoChapter' }
    & VideoChapterFragment
  )> | null, readonly transcriptCues?: ReadonlyArray<(
    { readonly __typename?: 'TranscriptCue' }
    & TranscriptCueFragment
  )> | null };

export type AudioFragmentFragment = { readonly __typename?: 'AudioAssets', readonly signedAudioMP3?: string | null, readonly isMP3Ready?: boolean | null, readonly isTranscriptReady?: boolean | null, readonly transcriptCues?: ReadonlyArray<(
    { readonly __typename?: 'TranscriptCue' }
    & TranscriptCueFragment
  )> | null };

export type ModuleFieldsFragment = { readonly __typename?: 'Module', readonly id: string, readonly createdAt: number, readonly title?: string | null, readonly description?: string | null, readonly tags?: ReadonlyArray<ModuleTags> | null, readonly layout?: ModuleLayout | null, readonly displayKind?: DisplayKind | null, readonly interactionKind?: InteractionKind | null, readonly videoDirectory?: string | null };

export type MembershipFieldsFragment = { readonly __typename?: 'Membership', readonly id: string, readonly createdAt: number, readonly createdByUserId: string, readonly purchaserUserId: string, readonly studentIds?: ReadonlyArray<string> | null, readonly guideId?: string | null, readonly purchasedAt?: number | null, readonly completedSessionIds?: ReadonlyArray<string> | null, readonly scheduledEventIds?: ReadonlyArray<string> | null, readonly guideEndsAt?: string | null };

export type NoteFieldsFragment = { readonly __typename?: 'Note', readonly id: string, readonly createdAt: number, readonly createdByUserId: string, readonly content?: string | null, readonly entityId?: string | null, readonly entityKind?: Kinds | null };

export type SessionProgressFragment = { readonly __typename?: 'SessionProgress', readonly id: string, readonly sessionId: string, readonly membershipId: string, readonly currentSessionProgress?: number | null, readonly sessionRating?: number | null, readonly studentId?: string | null, readonly currentNodeId?: string | null, readonly completedModuleIds?: ReadonlyArray<string> | null, readonly started?: { readonly __typename?: 'TrackingType', readonly id: string, readonly time?: number | null } | null, readonly completed?: { readonly __typename?: 'TrackingType', readonly id: string, readonly time?: number | null } | null };

export type TranscriptCueFragment = { readonly __typename?: 'TranscriptCue', readonly text?: string | null, readonly start?: number | null, readonly end?: number | null };

export type VideoChapterFragment = { readonly __typename?: 'VideoChapter', readonly start: number, readonly text: string };

export type EventFieldsFragment = { readonly __typename?: 'Event', readonly id: string, readonly startTime: string, readonly endTime?: string | null, readonly guideId?: string | null, readonly sessionId: string, readonly membershipId?: string | null, readonly title?: string | null, readonly joinUrl?: string | null, readonly archivedAt?: number | null, readonly createdByUserId: string, readonly createdAt: number, readonly cancelReason?: CancelReason | null };

export type EmailFieldsFragment = { readonly __typename?: 'Email', readonly id: string, readonly to?: ReadonlyArray<string> | null, readonly from?: string | null, readonly bcc?: ReadonlyArray<string> | null, readonly cc?: ReadonlyArray<string> | null, readonly subject?: string | null, readonly userIds?: ReadonlyArray<string> | null, readonly assignedAdminIds?: ReadonlyArray<string> | null, readonly partialBody?: string | null, readonly s3Key?: string | null, readonly attachmentS3Keys?: ReadonlyArray<string> | null, readonly smtpMessageId?: string | null, readonly smtpInReplyId?: string | null, readonly adminRead?: boolean | null, readonly labels?: ReadonlyArray<EmailLabels> | null, readonly emailKind?: EmailKind | null, readonly wasDelivered?: boolean | null, readonly wasOpened?: boolean | null, readonly wasSpamReport?: boolean | null, readonly wasClicked?: boolean | null, readonly wasBounced?: boolean | null, readonly wasBlocked?: boolean | null, readonly wasUnsubscribe?: boolean | null };

export type ResponseFieldsFragment = { readonly __typename?: 'Response', readonly insideJoke?: string | null, readonly partnerInviteNote?: string | null, readonly songThatRemindsMeOfThem?: string | null, readonly wishICouldTellThem?: string | null, readonly loveLetters?: ReadonlyArray<{ readonly __typename?: 'StringDateSequence', readonly createdAt: number, readonly hash: string, readonly content?: string | null }> | null };

export type PaymentBlockFragment = { readonly __typename?: 'PaymentBlock', readonly bulletPoints?: ReadonlyArray<string> | null, readonly isMostPopular?: boolean | null, readonly pricingDescription?: string | null, readonly stripeId?: string | null, readonly subtitle?: string | null, readonly title?: string | null };
