import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { VideoActions } from '../actionTypes';
import { videoSettingsLocalStorage } from '../externalActions/videoSettingsLocalStorage';
import type { VideoContext } from '../types';

export const onVideoVolumeChange = assign<VideoContext, VideoActions>((ctx, ev) => {
  assertType(ev, 'OnVideoVolumeChange');

  videoSettingsLocalStorage.setByKey('volume', ev.volume);
  return produce(ctx, (d) => {
    d.settings.volume = ev.volume;
  });
});
