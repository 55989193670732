import type { Maybe } from '@ours/types';

import { toShortId } from '../id/toShortId';

import { CoupleRoutes } from './RouteConstants';
import { toSlug } from './toSlug';

interface Options {
  membershipId: Maybe<string>;
  sessionId: Maybe<string>;
  title: Maybe<string>;
}

export const toContentSession = ({ membershipId, sessionId, title }: Options): string => {
  if (!membershipId || !sessionId) {
    return CoupleRoutes.Home;
  }

  return `${CoupleRoutes.ContentSession}/${toSlug(title || '')}/${toShortId(
    membershipId
  )}/${toShortId(sessionId)}`;
};
