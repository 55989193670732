import { assertIsUUID, assertNotNil, findNextNodeFromCur } from '@ours/utils';

import { selectXStateBySessionId } from '../../store/lib/selectXStateBySessionId';
import type { SessionEditorContext } from '../types';

export const getSrcAndTarget = (ctx: SessionEditorContext) => {
  const source = ctx.display.focusedNodeId;
  const node = selectXStateBySessionId(ctx.store.sessionId)[source];
  assertNotNil(node, 'node should exist');
  const target = findNextNodeFromCur(node);
  assertIsUUID(target);
  return { source, target };
};
