import { assertIsUUID, assertType, unexpected } from '@ours/utils';
import { assign } from 'xstate';

import type { CSDActions, CSDContext } from '../types';

export const onSyncNextNodeId = assign<CSDContext, CSDActions>((ctx, ev) => {
  assertType(ev, 'SYNC_NEXT_NODE_ID');
  assertIsUUID(ev.nextNodeId);
  // eslint-disable-next-line no-console
  console.log('ev: ', ev);
  if (!ctx.store.sessionMetadata.isMultiplayer) {
    return ctx;
  }

  if (ctx.store.display.currentNodeId === ev.nextNodeId) {
    return ctx;
  }
  throw unexpected({ name: 'SupportMacroModulesForMultiplayer' });
  // return updateSessionPlaybackByNodeId(ev.nextNodeId, ctx, { dir: 'next' });
});
