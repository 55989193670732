import type { FC } from 'react';
import React, { memo } from 'react';

export const Close: FC = memo(() => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.97904 25.8868C4.90789 24.8156 4.90789 23.0789 5.97904 22.0078L24.081 3.90584L27.96 7.78483L9.85803 25.8868C8.78688 26.9579 7.0502 26.9579 5.97904 25.8868Z"
        fill="currentColor"
      />
      <path
        d="M5.97953 5.84566C4.90838 6.91682 4.90838 8.6535 5.97953 9.72465L24.0815 27.8266L27.9604 23.9476L9.85852 5.84567C8.78737 4.77451 7.05069 4.77451 5.97953 5.84566Z"
        fill="currentColor"
      />
    </svg>
  );
});
