import type { NormalizedCacheObject } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { getApiEndpoint } from '@ours/utils';

import { createApolloLink } from './apolloLink/createApolloLink';
import { typePolicies } from './typePolicies';

let client: ApolloClient<NormalizedCacheObject> | undefined;

interface Options {
  app: 'admin' | 'couple';
  ssr: boolean;
  stage: string;
  version: string;
}

/**
 * @see https://www.apollographql.com/docs/react/api/core/ApolloClient/
 * @see https://www.youtube.com/watch?v=C53-rtNNg4k
 * @see https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
 */
export const getApolloClient = (options?: Options): ApolloClient<NormalizedCacheObject> => {
  if (client) {
    return client;
  }
  const ssr = options?.ssr ? '-SSR' : '';

  const stage = (options?.stage || '') as any;

  client = new ApolloClient({
    cache: new InMemoryCache({ typePolicies }),
    connectToDevTools: true,
    link: createApolloLink({ uri: getApiEndpoint(stage) }),
    name: `${options?.app}${ssr}`,
    ssrMode: false,
    version: options?.version,
  });
  return client;
};
