import type { MembershipEventsQuery } from '../../../generated/custom-hooks';
import { MembershipEventsDocument } from '../../../generated/custom-hooks';
import { focusAwarePolling } from '../../../lib/apollo/focusAwarePolling';
import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const listenToMembership = (ctx: AppContext, cb: (args: AppActions) => void) => {
  const onComplete = (data: MembershipEventsQuery) => {
    const sessionIds = data.fetchSelf?.latestMembership?.sessionIds || [];
    const events = data.fetchSelf?.latestMembership?.scheduledEvents || [];
    cb({ events, type: 'ON_UPDATE_ALL_EVENTS' });
    cb({ sessionIds, type: 'ON_PURCHASED_SESSION_SYNC' });
  };

  return focusAwarePolling<MembershipEventsQuery>({
    onComplete,
    pollInterval: 10000,
    query: MembershipEventsDocument,
    skip: ctx.app?.appKind === 'admin',
  });
};
