import { Box, Flex, useToast } from '@chakra-ui/react';
import { Border } from '@ours/utils';
import { Close } from '@ours/web-icons';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { Button } from '../components/Buttons/Button';

import { useEvent } from './useEvent';

export type Toast = (args: UseBrandedToastProps) => void;
export interface UseBrandedToastProps {
  /** The delay before the toast hides (in milliseconds) If set to null, toast will never dismiss. @default 5000ms */
  duration?: null | number;
  id?: string;
  kind?: 'info' | 'error';
  title: string;
}

const toastVariants = {
  error: { bg: 'brand.orange' },
  info: { bg: 'brand.pink' },
};

export const useBrandedToast = (): Toast => {
  const toast = useToast();

  return useEvent(({ duration = 5000, id, kind = 'info', title }: UseBrandedToastProps) => {
    const { bg } = toastVariants[kind];
    if (id && toast.isActive(id)) {
      return;
    }

    toast({
      containerStyle: { marginTop: 0 },
      duration,
      id,
      position: 'top',
      render: ({ onClose }) => {
        return (
          <BrandedToast bg={bg} onClose={onClose}>
            {title}
          </BrandedToast>
        );
      },
    });
  });
};

interface BrandedToastProps {
  bg: string;
  children: ReactNode;
  onClose?: () => void;
}

export const BrandedToast: FC<BrandedToastProps> = memo(({ bg, children, onClose }) => {
  return (
    <Box
      bg={bg}
      border={Border}
      borderBottomRadius="600"
      borderTop="0px"
      data-testid="brandedToast"
      fontSize="400"
      maxW="100%"
      px="300"
      py="50"
      w={{ base: '100%', md: '650px' }}
    >
      <Flex justifyContent="space-between">
        {children}
        {onClose ? (
          <Button as="button" onClick={onClose} size="roundSm" type="button">
            <Box
              alignItems="center"
              display="flex"
              justifyContent="center"
              sx={{ svg: { maxW: '90%' } }}
              textAlign="center"
            >
              <Close />
            </Box>
          </Button>
        ) : null}
      </Flex>
    </Box>
  );
});
