import { assertType, getItemsBetween, uniq, xstateToArray } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import { selectXStateBySessionId } from '../../store/lib/selectXStateBySessionId';
import type { SessionEditorActions, SessionEditorContext } from '../types';

/**
 * if shift: Select all nodes between prev focused node are here
 * if cmd: Add new node to the array
 * else, reset array
 */
export const onSelectNode = assign<SessionEditorContext, SessionEditorActions>((ctx, ev) => {
  assertType(ev, 'ON_SELECT_NODE');

  return produce(ctx, (d) => {
    if (ev.selectMode === 'none') {
      d.display.selectedNodeIds = [ev.nodeId];
      d.display.focusedNodeId = ev.nodeId;
    } else if (ev.selectMode === 'cmd') {
      if (d.display.selectedNodeIds.includes(ev.nodeId)) {
        d.display.selectedNodeIds = d.display.selectedNodeIds.filter((id) => id !== ev.nodeId);
      } else {
        d.display.focusedNodeId = ev.nodeId;
        d.display.selectedNodeIds = uniq([...d.display.selectedNodeIds, ev.nodeId]);
      }
    } else if (ev.selectMode === 'shift') {
      d.display.focusedNodeId = ev.nodeId;
      const orderedIds = xstateToArray(selectXStateBySessionId(ctx.store.sessionId)).map(
        (node) => node.id
      );
      const idsToAdd = getItemsBetween(orderedIds, ctx.display.focusedNodeId, ev.nodeId);
      d.display.selectedNodeIds = uniq([...d.display.selectedNodeIds, ...idsToAdd]);
    }
  });
});
