import { findNodesSources } from './findNodesSources';
import { getModuleIdFromNode } from './getModuleIdFromNode';
import type { IXStateState } from './xStateConstants';

/**
 * given where we currently are, find last possible module
 */
export const findPreviousNodeId = (
  currentNodeId: string,
  xState: IXStateState,
  _completedModuleIds: string[] | undefined
): string | undefined => {
  const completedModuleIds = [...(_completedModuleIds || [])].reverse();
  const sources = findNodesSources(currentNodeId, xState);
  const source = sources[0];
  if (!source) {
    // eslint-disable-next-line no-console
    console.error('NoPrevSource');
    return undefined;
  } else if (sources.length === 1 && xState[sources[0]!]?.type === 'module') {
    return source;
  }

  const findNearestModuleNode = (nodeId: string): string[] => {
    const node = xState[nodeId]!;
    if (node.type === 'module' || node.type === 'start') {
      return [nodeId];
    }

    const sources = findNodesSources(nodeId, xState);
    return sources.flatMap((id) => findNearestModuleNode(id));
  };

  const possibleSourceIds = sources
    .flatMap((id) => findNearestModuleNode(id))
    .map((id) => ({
      id,
      idx:
        xState[id]?.type === 'start'
          ? -2
          : completedModuleIds.findIndex(
              (moduleId) => moduleId === getModuleIdFromNode(xState[id])
            ),
    }))
    .filter(({ idx }) => idx !== -1)
    .sort((a, b) => (a.idx > b.idx ? 1 : -1));

  // console.log(possibleSourceIds);

  return possibleSourceIds[0]?.id || undefined;
};
