export class CustomError extends Error {
  code: string | number;

  constructor({ code, message, name }: ICustomError) {
    super(message);
    this.code = code;
    this.name = name;
  }
}

export interface ICustomError {
  code: string | number;
  message: string;
  name: string;
}
