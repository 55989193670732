import { InteractionKind } from '@ours/utils';

import { selectModuleById } from '../../store/lib/selectModuleById';
import { selectXStateModuleIds } from '../../store/lib/selectXStateModuleIds';
import type { CSDContext } from '../types';

const AVModules: InteractionKind[] = [
  InteractionKind.AudioRecording,
  InteractionKind.Camera,
  InteractionKind.Photobooth,
  InteractionKind.VideoRecording,
];

export const shouldGatherAVPermissions = (ctx: CSDContext): boolean => {
  const moduleIds = selectXStateModuleIds(ctx.store.sessionMetadata.id);
  const modules = moduleIds.map((id) => selectModuleById(id));

  return (
    ctx.store.permissions.hasGrantedAVPermissions === false &&
    !!modules.find((m) => AVModules.includes(m?.interactionKind || InteractionKind.Button))
  );
};
