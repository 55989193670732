import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { CardDeckActions } from '../actionTypes';
import type { CardDeckContext } from '../types';

export const onAnswerCard = assign<CardDeckContext, CardDeckActions>((ctx, ev) => {
  assertType(ev, 'ON_ANSWER_ON_CARD');

  return produce(ctx, (d) => {
    d.answersCurrentStreak = d.answersCurrentStreak + 1;
    d.answersTotalCards = d.answersTotalCards + 1;
  });
});
