import { produce } from 'immer';
import { assign } from 'xstate';

import type { CardDeckActions } from '../actionTypes';
import { userHasCompletedCardOnboarding } from '../actionTypes';
import type { CardDeckContext } from '../types';

export const onPlayDeck = assign<CardDeckContext, CardDeckActions>((ctx) => {
  return produce(ctx, (d) => {
    d.hasCompletedOnboarding = localStorage.getItem(userHasCompletedCardOnboarding) !== null;
  });
});
