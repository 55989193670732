import { Colors } from '@ours/utils';
import type { FC } from 'react';
import React, { memo } from 'react';

const variants = {
  base: { fill: undefined, stroke: 'none' },
  black: { fill: undefined, stroke: 'black' },
  yellow: { fill: Colors.brand.yellow, stroke: 'black' },
} as const;

interface Props {
  variant?: keyof typeof variants;
}

export const OrangeCheck: FC<Props> = memo(({ variant = 'black' }) => {
  const { fill, stroke } = variants[variant];

  return (
    <svg
      fill="none"
      height="55"
      style={{ height: '100%', width: '100%' }}
      viewBox="0 0 68 55"
      width="68"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="" height="55" width="68" />
      <path
        d="M26.2215 31.2812L14.7195 20.253C13.1497 18.6809 11.0292 17.7993 8.8245 17.8023C6.61977 17.8052 4.5114 18.6924 2.96318 20.2687C1.41537 21.8452 0.55457 23.9815 0.570126 26.2077C0.585681 28.4338 1.47632 30.5676 3.04613 32.1395L26.3425 54.9783L67.7074 11.9692L55.9109 0.157551L26.2215 31.2812Z"
        fill={fill || Colors.brand.orange}
        mask="url(#path-3-inside-2_34_158)"
        stroke={stroke}
        strokeWidth="1"
      />
    </svg>
  );
});
