import { pRetry } from '@ours/utils';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

const getQueryParam = (url: string, param: string) => {
  // eslint-disable-next-line no-empty-character-class
  param = param.replace(/[[]/, '[').replace(/[]]/, ']');
  const regexS = '[?&]' + param + '=([^&#]*)';
  const regex = new RegExp(regexS);
  const results = regex.exec(url);
  // @ts-expect-error mixpanel
  if (results === null || (results && typeof results[1] !== 'string' && results[1].length)) {
    return '';
  }
  // @ts-expect-error mixpanel
  return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
};

export const setMixpanelUTMParams = () => {
  const campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');
  let kw = '';
  const params = {} as Record<string, string>;
  const first_params = {} as Record<string, string>;

  for (const word of campaign_keywords) {
    kw = getQueryParam(document.URL, word);
    if (kw.length) {
      params[word + ' [last touch]'] = kw;
    }
  }

  for (const word of campaign_keywords) {
    kw = getQueryParam(document.URL, word);
    if (kw.length) {
      first_params[word + ' [first touch]'] = kw;
    }
  }
  mixpanel.people.set(params);
  mixpanel.people.set_once(first_params);
  mixpanel.register(params);
};

export const useUTMMixpanelEffect = () => {
  useEffect(() => {
    pRetry(
      async () => {
        try {
          setMixpanelUTMParams();
        } catch (err) {
          throw new Error('');
        }
      },
      { retries: 10 }
    );
  }, []);
};
