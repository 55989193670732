import { assertType } from '@ours/utils';

import { isValidSessionConfiguration } from '../../../lib/contentSessions/isValidSessionConfiguration';
import type { CSDActions, CSDContext } from '../types';

export const isInvalidSession = (_: CSDContext, ev: CSDActions): boolean => {
  assertType(ev, 'INIT');
  const _isInvalidSession = isValidSessionConfiguration(ev.session) !== true;

  if (_isInvalidSession) {
    // eslint-disable-next-line no-console
    console.error('This session is invalid because: ', isValidSessionConfiguration(ev.session));
  }

  return _isInvalidSession;
};
