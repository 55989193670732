import type { Maybe } from '@ours/types';

type BaseAmplitude<Type extends string, Obj = unknown> = Obj extends Record<any, any>
  ? { payload: Obj; type: Type }
  : { type: Type };

type MembershipId = { membershipId: string };
type SessionId = { sessionId: string; sessionTitle?: Maybe<string> };
type ModuleId = { moduleId: string };
type MembershipContentEvent = { membershipId: string; moduleId: string; sessionId: string };

type MembershipSessionId = MembershipId & SessionId;

type CompleteWelcomeAssessment = BaseAmplitude<
  'COMPLETE_WELCOME_ASSESSMENT',
  { sessionId: string }
>;

type CompleteSessionModule = BaseAmplitude<
  'COMPLETE_SESSION_MODULE',
  {
    answerLength: number | undefined;
    mcValue: string | undefined;
    membershipId: string;
    moduleId: string;
    numberRating: number | undefined;
    sessionId: string;
  }
>;
type START_CONTENT_SESSION = BaseAmplitude<
  'START_CONTENT_SESSION',
  { isFirst: boolean; isLast: boolean; sessionId: string }
>;
type RESUME_CONTENT_SESSION = BaseAmplitude<
  'RESUME_CONTENT_SESSION',
  { isFirst: boolean; isLast: boolean; sessionId: string }
>;
type CONTENT_SESSION_DURATION = BaseAmplitude<'CONTENT_SESSION_DURATION', { sessionId: string }>;
type CompleteContentSession = BaseAmplitude<'COMPLETE_CONTENT_SESSION', { sessionId: string }>;
type COMPLETE_LIVE_SESSION = BaseAmplitude<'COMPLETE_LIVE_SESSION', MembershipSessionId>;
type CANCEL_LIVE_SESSION = BaseAmplitude<'CANCEL_LIVE_SESSION', MembershipSessionId>;
type RESCHEDULE_LIVE_SESSION = BaseAmplitude<'RESCHEDULE_LIVE_SESSION', MembershipSessionId>;
type Scheduler_Scheduled_Event = BaseAmplitude<'Scheduler_Scheduled_Event', MembershipSessionId>;
type MISS_LIVE_SESSION = BaseAmplitude<'MISS_LIVE_SESSION', MembershipSessionId>;

type CalendlyScheduledEvent = BaseAmplitude<'CALENDLY_SCHEDULED_EVENT', MembershipSessionId>;
type PURCHASED_IN_APP = BaseAmplitude<
  'PURCHASED_IN_APP',
  { amountInCents: number; guideId: string; purchaseLocation: string; sessionId: string }
>;
type STORE_ADD_TO_CART = BaseAmplitude<'STORE_ADD_TO_CART', { sessionId: string }>;
type STORE_REMOVE_FROM_CART = BaseAmplitude<'STORE_REMOVE_FROM_CART', { sessionId: string }>;
type VIEW_SESSION_STORE = BaseAmplitude<'VIEW_SESSION_STORE'>;
type VIEW_SESSION_PURCHASE_MODULE = BaseAmplitude<'VIEW_SESSION_PURCHASE_MODULE'>;

type CalendlyViewedSchedulePage = BaseAmplitude<
  'CALENDLY_VIEWED_SCHEDULE_PAGE',
  MembershipSessionId
>;

type CalendlySelectDateTime = BaseAmplitude<'CALENDLY_SELECTED_DATE_AND_TIME'>;

type SchedulerInit = BaseAmplitude<'Scheduler_Init'>;
type SchedulerSelectDate = BaseAmplitude<'Scheduler_Select_Date'>;
type SchedulerSelectTime = BaseAmplitude<'Scheduler_Select_Time'>;

type CameraTakePhoto = BaseAmplitude<'CAMERA_TAKE_PHOTO'>;
type CameraSelectPhoto = BaseAmplitude<'CAMERA_SELECT_PHOTO'>;

type ImageUploadWebcam = BaseAmplitude<'IMAGE_UPLOAD_WEBCAM', MembershipContentEvent>;
type ImageUploadUpload = BaseAmplitude<'IMAGE_UPLOAD_UPLOAD', MembershipContentEvent>;
type ImageUploadPhoneCodeRequest = BaseAmplitude<
  'IMAGE_UPLOAD_PHONE_CODE_REQUEST',
  MembershipContentEvent
>;
type IMAGE_UPLOAD_PHONE_UPLOAD = BaseAmplitude<'IMAGE_UPLOAD_PHONE_UPLOAD', MembershipContentEvent>;

type REGISTERED_FOR_MEMBERSHIP = BaseAmplitude<
  'REGISTERED_FOR_MEMBERSHIP',
  { amountInCents: number; productIds: readonly string[] }
>;

export type VIDEO_WATCH = BaseAmplitude<
  'VIDEO_WATCH',
  {
    currentTimeInSec: number;
    durationInSec: number;
    membershipId?: string;
    moduleId?: string;
    percentageWatched: number;
    sessionId?: string;
    type: 'seek' | 'play' | 'pause' | 'end';
    videoId: string;
    videoName: string;
  }
>;

export type VIDEO_OPEN_TRANSCRIPT = BaseAmplitude<
  'VIDEO_OPEN_TRANSCRIPT',
  { membershipId: Maybe<string>; moduleId: Maybe<string>; sessionId: Maybe<string> }
>;
export type VIEW_GUIDE_WELCOME_VIDEO = BaseAmplitude<
  'VIEW_GUIDE_WELCOME_VIDEO',
  {
    membershipId: string;
    timeSinceRegistrationInSec: number;
  }
>;
export type VIEW_GUIDE_NOTE_VIDEO = BaseAmplitude<
  'VIEW_GUIDE_NOTE_VIDEO',
  {
    membershipId: string;
    timeSinceCreationInSec: number;
  }
>;

export type BOOKMARK_VIEW_PAGE = BaseAmplitude<'BOOKMARK_VIEW_PAGE', MembershipId>;
export type BOOKMARK_VIEW_BOOKMARK_MODAL = BaseAmplitude<
  'BOOKMARK_VIEW_BOOKMARK_MODAL',
  { content: string; duration: number } & SessionId & ModuleId
>;
export type BOOKMARK_OPEN_CREATE_POPUP = BaseAmplitude<
  'BOOKMARK_OPEN_CREATE_POPUP',
  { membershipId: string; moduleId: string; sessionId: string }
>;
export type BOOKMARK_SAVE_BOOKMARK = BaseAmplitude<
  'BOOKMARK_SAVE_BOOKMARK',
  { content: string; membershipId: string; moduleId: string; sessionId: string }
>;
export type SESSION_CARD_DECK_DOWNLOAD = BaseAmplitude<
  'SESSION_CARD_DECK_DOWNLOAD',
  { location: 'bookmarks' | 'miniRoadmap' | 'roadmap' | 'endOfSession'; sessionId: string }
>;

export type ROADMAP_VIEW_PAGE = BaseAmplitude<'ROADMAP_VIEW_PAGE'>;
export type PROFILE_VIEW_PAGE = BaseAmplitude<'PROFILE_VIEW_PAGE'>;
export type FAQ_VIEW_PAGE = BaseAmplitude<'FAQ_VIEW_PAGE'>;

export type PHOTO_FRAME_SELECTOR_VIEW = BaseAmplitude<'PHOTO_FRAME_SELECTOR_VIEW'>;
export type PHOTO_FRAME_SELECTOR_CHANGE_SELECTION =
  BaseAmplitude<'PHOTO_FRAME_SELECTOR_CHANGE_SELECTION'>;
export type PHOTO_FRAME_SELECTOR_DOWNLOAD = BaseAmplitude<
  'PHOTO_FRAME_SELECTOR_DOWNLOAD',
  { kind: string; moduleId: string; sessionId: string }
>;
export type DOWNLOAD_MODULE_INTERACTION_KIND = BaseAmplitude<
  'DOWNLOAD_MODULE_INTERACTION_KIND',
  { moduleId: string; sessionId: string }
>;

export type SHARE_PROMPT_VIEW = BaseAmplitude<
  'SHARE_PROMPT_VIEW',
  { shareLocation: 'sessionComplete' }
>;
export type SHARE_PUBLIC_PAGE_VIEW = BaseAmplitude<'SHARE_PUBLIC_PAGE_VIEW'>;
export type SHARE_PROMPT_SHARE = BaseAmplitude<'SHARE_PROMPT_SHARE', { shareType: string }>;
export type PHOTOBOOTH_DASHBOARD_OPEN = BaseAmplitude<'PHOTOBOOTH_DASHBOARD_OPEN'>;
export type PHOTOBOOTH_START = BaseAmplitude<'PHOTOBOOTH_START'>;
export type PHOTOBOOTH_DOWNLOAD = BaseAmplitude<'PHOTOBOOTH_DOWNLOAD'>;

export type DRAWING_DOWNLOAD = BaseAmplitude<'DRAWING_DOWNLOAD'>;
export type DRAWING_START = BaseAmplitude<'DRAWING_START'>;
export type DRAWING_DASHBOARD_OPEN = BaseAmplitude<'DRAWING_DASHBOARD_OPEN'>;

export type AUDIO_RECORDER_START = BaseAmplitude<'AUDIO_RECORDER_START'>;
export type AUDIO_RECORDER_COMPLETE = BaseAmplitude<'AUDIO_RECORDER_COMPLETE'>;
export type AUDIO_RECORDER_RESTART = BaseAmplitude<'AUDIO_RECORDER_RESTART'>;

export type VIDEO_RECORDER_START = BaseAmplitude<'VIDEO_RECORDER_START'>;
export type VIDEO_RECORDER_COMPLETE = BaseAmplitude<'VIDEO_RECORDER_COMPLETE'>;
export type COMPLETION_CERTIFICATE_DOWNLOAD = BaseAmplitude<'COMPLETION_CERTIFICATE_DOWNLOAD'>;

export type JOURNALING_START = BaseAmplitude<'JOURNALING_START'>;
export type JOURNALING_DOWNLOAD = BaseAmplitude<'JOURNALING_DOWNLOAD'>;
export type JOURNAL_DASHBOARD_OPEN = BaseAmplitude<'JOURNAL_DASHBOARD_OPEN'>;
export type DISCUSSION_DASHBOARD_OPEN = BaseAmplitude<'DISCUSSION_DASHBOARD_OPEN'>;
export type DISCUSSION_START = BaseAmplitude<'DISCUSSION_START'>;

export type SESSION_DEMO_PAGE_OPEN_SAMPLE = BaseAmplitude<'SESSION_DEMO_PAGE_OPEN_SAMPLE'>;
export type SESSION_DEMO_PAGE_PLAY_TRAILER = BaseAmplitude<'SESSION_DEMO_PAGE_PLAY_TRAILER'>;
export type SESSION_DEMO_PAGE_TO_SIGNUP = BaseAmplitude<'SESSION_DEMO_PAGE_TO_SIGNUP'>;

export type CAPSULE_DOWNLOAD = BaseAmplitude<'CAPSULE_DOWNLOAD'>;
export type CAPSULE_VIEW = BaseAmplitude<'CAPSULE_VIEW'>;

export type BANNER_CLICK = BaseAmplitude<'BANNER_CLICK'>;

export type VISITED_PAYMENT_LINK = BaseAmplitude<'VISITED_PAYMENT_LINK'>;
export type PAYMENT_SUCCESS = BaseAmplitude<'PAYMENT_SUCCESS'>;

export type QUIZ_COMPLETE = BaseAmplitude<'QUIZ_COMPLETE'>;
export type QUIZ_DASHBOARD_START = BaseAmplitude<'QUIZ_DASHBOARD_START'>;

export type VIDEO_CALL_FEEDBACK = BaseAmplitude<'VIDEO_CALL_FEEDBACK', { feedback: string }>;
export type CARD_DECK_ON_SWIPE_NEXT = BaseAmplitude<'CARD_DECK_ON_SWIPE_NEXT'>;
export type CARD_DECK_ON_SHARE_FILE = BaseAmplitude<'CARD_DECK_ON_SHARE_FILE'>;
export type CARD_DECK_ON_DOWNLOAD_FILE = BaseAmplitude<'CARD_DECK_ON_DOWNLOAD_FILE'>;
export type CARD_DECK_SELECT_DECK = BaseAmplitude<'CARD_DECK_SELECT_DECK'>;
export type SCHEDULING_AVAILABILITY_FEEDBACK = BaseAmplitude<
  'SCHEDULING_AVAILABILITY_FEEDBACK',
  { feedback: string }
>;
export type VIDEO_CALL_RATING = BaseAmplitude<'VIDEO_CALL_RATING', { rating: number }>;
export type SCHEDULING_AVAILABILITY_RATING = BaseAmplitude<
  'SCHEDULING_AVAILABILITY_RATING',
  { rating: number }
>;

export type MKG_HERO_2022_BTN_CLICK = BaseAmplitude<'MKG_HERO_2022_BTN_CLICK'>;
export type MKG_EXPLAINER_BLOCK_CTA_CLICK = BaseAmplitude<'MKG_EXPLAINER_BLOCK_CTA_CLICK'>;
export type MKG_TABLE_BLOCK_ENGAGEMENT = BaseAmplitude<'MKG_TABLE_BLOCK_ENGAGEMENT'>;
export type MKG_FAQ_QUESTION_OPENED = BaseAmplitude<
  'MKG_FAQ_QUESTION_OPENED',
  { question: string }
>;
export type MKG_HERO_2022_LINK_CLICK = BaseAmplitude<'MKG_HERO_2022_LINK_CLICK'>;
export type MKG_IMAGE_TEXT_BLOCK_CTA_CLICK = BaseAmplitude<'MKG_IMAGE_TEXT_BLOCK_CTA_CLICK'>;
export type SESSION_RATING_FEEDBACK = BaseAmplitude<
  'SESSION_RATING_FEEDBACK',
  { feedback: string; membershipId: string; sessionId: string; sessionTitle: string }
>;
export type MODULE_MEDIA_RATING_FEEDBACK = BaseAmplitude<
  'MODULE_MEDIA_RATING_FEEDBACK',
  { feedback: string; membershipId: string; moduleId: string; sessionTitle: string; title: string }
>;
export type MODULE_MEDIA_RATING = BaseAmplitude<
  'MODULE_MEDIA_RATING',
  {
    membershipId: string;
    moduleId: string;
    rating: 'up' | 'down';
    sessionTitle: string;
    title: string;
  }
>;
export type SESSION_RATING = BaseAmplitude<
  'SESSION_RATING',
  { membershipId: string; rating: number; sessionId: string; sessionTitle: string }
>;

export type MACRO_MODULE_HOVER_TOOLTIP = BaseAmplitude<'MACRO_MODULE_HOVER_TOOLTIP'>;
export type MACRO_MODULE_CLICK_NON_CLICKABLE_TAB =
  BaseAmplitude<'MACRO_MODULE_CLICK_NON_CLICKABLE_TAB'>;

export const serverSideEvents: Partial<Record<AnalyticsEventType['type'], boolean>> = {
  AUDIO_RECORDER_COMPLETE: true,
  AUDIO_RECORDER_RESTART: true,
  AUDIO_RECORDER_START: true,
  BOOKMARK_OPEN_CREATE_POPUP: true,
  BOOKMARK_SAVE_BOOKMARK: true,
  BOOKMARK_VIEW_BOOKMARK_MODAL: true,
  BOOKMARK_VIEW_PAGE: true,
  CAPSULE_VIEW: true,
  COMPLETION_CERTIFICATE_DOWNLOAD: true,
  DISCUSSION_START: true,
  DOWNLOAD_MODULE_INTERACTION_KIND: true,
  DRAWING_DASHBOARD_OPEN: true,
  DRAWING_DOWNLOAD: true,
  DRAWING_START: true,
  FAQ_VIEW_PAGE: true,
  JOURNALING_DOWNLOAD: true,
  JOURNALING_START: true,
  MODULE_MEDIA_RATING: true,
  MODULE_MEDIA_RATING_FEEDBACK: true,
  PHOTOBOOTH_DASHBOARD_OPEN: true,
  PHOTOBOOTH_DOWNLOAD: true,
  PHOTOBOOTH_START: true,
  PHOTO_FRAME_SELECTOR_CHANGE_SELECTION: true,
  PHOTO_FRAME_SELECTOR_DOWNLOAD: true,
  PHOTO_FRAME_SELECTOR_VIEW: true,
  PROFILE_VIEW_PAGE: true,
  ROADMAP_VIEW_PAGE: true,
  SCHEDULING_AVAILABILITY_FEEDBACK: true,
  SCHEDULING_AVAILABILITY_RATING: true,
  SESSION_CARD_DECK_DOWNLOAD: true,
  SESSION_RATING: true,
  SESSION_RATING_FEEDBACK: true,
  SHARE_PROMPT_SHARE: true,
  SHARE_PROMPT_VIEW: true,
  SHARE_PUBLIC_PAGE_VIEW: true,
  STORE_ADD_TO_CART: true,
  STORE_REMOVE_FROM_CART: true,
  Scheduler_Scheduled_Event: true,
  VIDEO_OPEN_TRANSCRIPT: true,
  VIDEO_RECORDER_COMPLETE: true,
  VIDEO_RECORDER_START: true,
  VIEW_GUIDE_NOTE_VIDEO: true,
  VIEW_GUIDE_WELCOME_VIDEO: true,
};

export type AnalyticsEventType =
  | MACRO_MODULE_HOVER_TOOLTIP
  | MACRO_MODULE_CLICK_NON_CLICKABLE_TAB
  | CARD_DECK_ON_SWIPE_NEXT
  | AUDIO_RECORDER_COMPLETE
  | AUDIO_RECORDER_RESTART
  | AUDIO_RECORDER_START
  | BOOKMARK_OPEN_CREATE_POPUP
  | BOOKMARK_SAVE_BOOKMARK
  | BOOKMARK_VIEW_BOOKMARK_MODAL
  | PURCHASED_IN_APP
  | BOOKMARK_VIEW_PAGE
  | CARD_DECK_ON_SHARE_FILE
  | CONTENT_SESSION_DURATION
  | CARD_DECK_ON_DOWNLOAD_FILE
  | VIEW_SESSION_STORE
  | STORE_ADD_TO_CART
  | STORE_REMOVE_FROM_CART
  | CAPSULE_VIEW
  | COMPLETION_CERTIFICATE_DOWNLOAD
  | DISCUSSION_START
  | DOWNLOAD_MODULE_INTERACTION_KIND
  | DRAWING_DASHBOARD_OPEN
  | DRAWING_DOWNLOAD
  | DRAWING_START
  | FAQ_VIEW_PAGE
  | JOURNALING_DOWNLOAD
  | JOURNALING_START
  | MODULE_MEDIA_RATING
  | MODULE_MEDIA_RATING_FEEDBACK
  | PHOTOBOOTH_DASHBOARD_OPEN
  | PHOTOBOOTH_DOWNLOAD
  | PHOTOBOOTH_START
  | PHOTO_FRAME_SELECTOR_CHANGE_SELECTION
  | PHOTO_FRAME_SELECTOR_DOWNLOAD
  | PHOTO_FRAME_SELECTOR_VIEW
  | PROFILE_VIEW_PAGE
  | ROADMAP_VIEW_PAGE
  | SESSION_CARD_DECK_DOWNLOAD
  | SESSION_RATING
  | SHARE_PROMPT_SHARE
  | SHARE_PROMPT_VIEW
  | SHARE_PUBLIC_PAGE_VIEW
  | Scheduler_Scheduled_Event
  | VIDEO_OPEN_TRANSCRIPT
  | CARD_DECK_SELECT_DECK
  | VIDEO_RECORDER_COMPLETE
  | VIDEO_RECORDER_START
  | VIEW_GUIDE_NOTE_VIDEO
  | VIEW_GUIDE_WELCOME_VIDEO
  | SCHEDULING_AVAILABILITY_FEEDBACK
  | SCHEDULING_AVAILABILITY_RATING
  | SESSION_RATING_FEEDBACK
  | MKG_FAQ_QUESTION_OPENED
  | MKG_TABLE_BLOCK_ENGAGEMENT
  | MKG_EXPLAINER_BLOCK_CTA_CLICK
  | MKG_HERO_2022_BTN_CLICK
  | MKG_HERO_2022_LINK_CLICK
  | MKG_IMAGE_TEXT_BLOCK_CTA_CLICK
  | VIDEO_CALL_FEEDBACK
  | VIDEO_CALL_RATING
  | QUIZ_COMPLETE
  | QUIZ_DASHBOARD_START
  | PAYMENT_SUCCESS
  | VISITED_PAYMENT_LINK
  | CAPSULE_DOWNLOAD
  | BANNER_CLICK
  | SESSION_DEMO_PAGE_OPEN_SAMPLE
  | SESSION_DEMO_PAGE_PLAY_TRAILER
  | SESSION_DEMO_PAGE_TO_SIGNUP
  | VIDEO_WATCH
  | ImageUploadWebcam
  | ImageUploadPhoneCodeRequest
  | ImageUploadUpload
  | IMAGE_UPLOAD_PHONE_UPLOAD
  | CameraTakePhoto
  | CameraSelectPhoto
  | REGISTERED_FOR_MEMBERSHIP
  | SchedulerInit
  | SchedulerSelectDate
  | SchedulerSelectTime
  | CalendlySelectDateTime
  | CalendlyViewedSchedulePage
  | CalendlyScheduledEvent
  | VIEW_SESSION_PURCHASE_MODULE
  | START_CONTENT_SESSION
  | RESUME_CONTENT_SESSION
  | CompleteContentSession
  | CompleteWelcomeAssessment
  | CompleteSessionModule
  | COMPLETE_LIVE_SESSION
  | CANCEL_LIVE_SESSION
  | RESCHEDULE_LIVE_SESSION
  | MISS_LIVE_SESSION;

export type AnalyticsTypeOnlyEvents = AnalyticsEventType & {
  payload: never;
};
