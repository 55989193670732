import type { FC } from 'react';
import { memo } from 'react';

import { BlueLink } from '../../Links/BlueLink';

export interface Props {}

export const A: FC<Props> = memo(({ ...props }) => {
  return (
    // @ts-expect-error err
    <BlueLink as="a" href={props.href || '#'} {...props} target="_blank" />
  );
});
