import { isValidRole } from '@ours/utils';
import type { Role } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const onPresetRoleFromCognito = assign<AppContext, AppActions>((ctx, ev) => {
  const role = (ev as any).data as Role;

  if (isValidRole(role)) {
    return produce(ctx, (draft) => {
      draft.content.user = {
        ...draft.content.user,
        role,
        userId: draft.content.user?.userId || '',
      };
    });
  }
  return ctx;
});
