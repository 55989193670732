import { hasDevicePermission } from '../../../lib/audio/listAudioOptions';
import { store } from '../../store/_useStore';
import type { CSDActions } from '../types';

export const monitorAVPermissions = (cb: (args: CSDActions) => void) => {
  const determinePermissions = async () => {
    const hasGrantedAVPermissions = await hasDevicePermission();
    if (
      store.getState().csd.state.context.store.permissions.hasGrantedAVPermissions !==
      hasGrantedAVPermissions
    ) {
      cb({ hasGrantedAVPermissions, type: 'ON_GRANT_AV_PERMISSIONS' });
    }
  };

  determinePermissions();
  const id = setInterval(determinePermissions, 2000);

  // TODO: subscribe to yjs here
  return () => {
    clearInterval(id);
    // TODO: unsubscribe to yjs here
  };
};
