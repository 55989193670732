import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const onUpdateXState = assign<AppContext, AppActions>((ctx, ev) => {
  assertType(ev, 'ON_UPDATE_XSTATE');

  return produce(ctx, (draft) => {
    draft.content.xStateBySessionId[ev.sessionId] = ev.xState;
  });
});
