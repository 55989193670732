import type { MachineConfig } from 'xstate';
import { createMachine } from 'xstate';

import type { VideoActions } from './actionTypes';
import { onHydrateSettingsFromStorage } from './actions/onHydrateSettingsFromStorage';
import { onSetVideoPlaybackRate } from './actions/onSetVideoPlaybackRate';
import { onSetVideoQuality } from './actions/onSetVideoQuality';
import { onToggleVideoCC } from './actions/onToggleVideoCC';
import { onVideoCanPlay } from './actions/onVideoCanPlay';
import { onVideoCurrentTimeChange } from './actions/onVideoCurrentTimeChange';
import { onVideoDurationChange } from './actions/onVideoDurationChange';
import { onVideoEnded } from './actions/onVideoEnded';
import { onVideoHasStarted } from './actions/onVideoHasStarted';
import { onVideoPause } from './actions/onVideoPause';
import { onVideoPlay } from './actions/onVideoPlay';
import { onVideoSeeked } from './actions/onVideoSeeked';
import { onVideoSeeking } from './actions/onVideoSeeking';
import { onVideoVolumeChange } from './actions/onVideoVolumeChange';
import { onVideoWaiting } from './actions/onVideoWaiting';
import { videoSettingsLocalStorage } from './externalActions/videoSettingsLocalStorage';
import type { VideoContext } from './types';

const appSchema: MachineConfig<VideoContext, any, VideoActions> = {
  context: {
    settings: { displayCC: false, playbackQuality: 'Auto', playbackRate: 1, volume: 1 },
    videosById: {},
  },
  id: 'videoMachine',
  invoke: {
    src: () => (cb: (args: VideoActions) => void) => {
      if (typeof window === 'undefined') {
        return;
      }

      const settings = videoSettingsLocalStorage.getAllWithDefaults();
      cb({ settings, type: 'OnHydrateSettingsFromStorage' });
    },
  },
  on: {
    OnHydrateSettingsFromStorage: { actions: 'onHydrateSettingsFromStorage' },
    OnVideoCanPlay: { actions: 'onVideoCanPlay' },
    OnVideoCurrentTimeChange: { actions: 'onVideoCurrentTimeChange' },
    OnVideoDurationChange: { actions: 'onVideoDurationChange' },
    OnVideoEnded: { actions: 'onVideoEnded' },
    OnVideoHasStarted: { actions: 'onVideoHasStarted' },
    OnVideoPause: { actions: 'onVideoPause' },
    OnVideoPlay: { actions: 'onVideoPlay' },
    OnVideoSeeked: { actions: 'onVideoSeeked' },
    OnVideoSeeking: { actions: 'onVideoSeeking' },
    OnVideoVolumeChange: { actions: 'onVideoVolumeChange' },
    OnVideoWaiting: { actions: 'onVideoWaiting' },
    SetVideoPlaybackRate: { actions: 'onSetVideoPlaybackRate' },
    SetVideoQuality: { actions: 'onSetVideoQuality' },
    ToggleVideoCC: { actions: 'onToggleVideoCC' },
  },
  predictableActionArguments: true,
};

export const videoMachine = createMachine(appSchema, {
  actions: {
    onHydrateSettingsFromStorage,
    onSetVideoPlaybackRate,
    onSetVideoQuality,
    onToggleVideoCC,
    onVideoCanPlay,
    onVideoCurrentTimeChange,
    onVideoDurationChange,
    onVideoEnded,
    onVideoHasStarted,
    onVideoPause,
    onVideoPlay,
    onVideoSeeked,
    onVideoSeeking,
    onVideoVolumeChange,
    onVideoWaiting,
  },
  guards: {},
});
