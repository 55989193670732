import { getModuleIdsFromXState, uniq } from '@ours/utils';

import { store } from '../_useStore';

import { selectModuleById } from './selectModuleById';

export const selectXStateModuleIds = (id: string): string[] => {
  const xState = store.getState().app.state.context.content.xStateBySessionId[id];
  const ids = getModuleIdsFromXState(xState!);
  const mmIds = ids.map((id) => selectModuleById(id)).flatMap((mod) => mod?.macroModuleIds || []);

  return uniq([...ids, ...mmIds]);
};
