/* eslint-disable complexity */
/* eslint-disable react-memo/require-memo */
import { Box } from '@chakra-ui/react';
import { Border, isString } from '@ours/utils';
import type { FC, MouseEventHandler, ReactNode } from 'react';
import { forwardRef } from 'react';

import { Tooltip } from '../../Tooltip/Tooltip';
import { buttonSizes, buttonVariants, rootButtonSizes } from '../buttonVariants';

import { ButtonDropShadow } from './ButtonDropShadow';
import { ButtonLoader } from './ButtonLoader';

export interface ButtonBaseProps {
  ariaLabel?: string;
  as: 'span' | 'button';
  children: ReactNode;
  isDisabled?: boolean | undefined;
  isLoading?: boolean | undefined;
  onClick?: MouseEventHandler<any>;
  size?: keyof typeof buttonSizes;
  testId?: string;
  tooltipText?: string;
  type?: 'submit' | 'button';
  variant?: keyof typeof buttonVariants;
  w?: string;
}

export const ButtonBase: FC<ButtonBaseProps> = forwardRef(
  (
    {
      ariaLabel: _ariaLabel,
      as,
      children,
      isDisabled = false,
      isLoading = false,
      onClick,
      size = 'base',
      testId: _testId,
      tooltipText,
      type,
      variant = 'blue',
      w,
    },
    ref
  ) => {
    const disabled = isDisabled || isLoading;
    const rootStyles = rootButtonSizes[size];
    const { bg, bgHover, ...rest } = buttonVariants[variant];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { boxShadowProps, ...sizeProps } = buttonSizes[size];
    const testId = _testId || (isString(children) ? children : '') || '';
    const ariaLabel = _ariaLabel || (isString(children) ? children : '');

    return (
      <Tooltip isEnabled={!!tooltipText} label={tooltipText || ''}>
        <Box
          _disabled={{ cursor: 'not-allowed' }}
          aria-label={ariaLabel}
          as={as}
          cursor={isLoading ? 'not-allowed' : 'pointer'}
          data-testid={testId}
          disabled={disabled}
          display="inline-block"
          onClick={onClick}
          pos="relative"
          // @ts-expect-error idk
          ref={ref}
          sx={{
            _active: { '.inner-btn': disabled ? {} : { bottom: '-3px', right: '-3px' } },
            _disabled: { '.inner-btn': { color: 'black', cursor: 'not-allowed' } },
          }}
          type={type}
          zIndex="0"
          {...rootStyles}
          w={w}
        >
          <Box
            _hover={{
              '@media (hover: hover)': { bg: bgHover, color: 'white' },
            }}
            alignItems="center"
            bg={bg}
            border={Border}
            bottom="0"
            className="inner-btn"
            data-testid={`${testId} box`}
            display="flex"
            opacity={disabled ? '35%' : undefined}
            pos="relative"
            right="0"
            transition="background .25s ease, right .25s ease, bottom .25s ease"
            userSelect="none"
            zIndex={2}
            {...rest}
            {...sizeProps}
          >
            <ButtonLoader isLoading={isLoading} size={size} testId={testId} />
            <Box
              as="span"
              data-testid={`${testId} span`}
              visibility={isLoading ? 'hidden' : undefined}
            >
              {children}
            </Box>
          </Box>

          <ButtonDropShadow disabled={disabled} size={size} />
        </Box>
      </Tooltip>
    );
  }
);
