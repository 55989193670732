/* eslint-disable import/no-duplicates */
/* eslint-disable import/first */
/* eslint-disable import/order */
import { atPolyfill } from '@ours/web-utils';

atPolyfill();
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import type { NextPageWithLayout } from '@ours/types';

import {
  AnalyticsContextProviderLive,
  GlobalFonts,
  GlobalPreload,
  GlobalStyles,
  UnexpectedErrorBoundary,
  extendTheme,
  getApolloClient,
} from '@ours/web-utils';
import type { AppProps } from 'next/app';
import type { FC } from 'react';
import { memo, useMemo } from 'react';

import { AppWithAuth } from '../components/Auth/AppWithAuth';
import { getStageAndVersion } from '../lib/getStageAndVersion';

const { stage, version } = getStageAndVersion();

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

interface IMemoizedProvidersProps {
  // eslint-disable-next-line
  Layout: any;
  // eslint-disable-next-line
  Component: any;
  isPublic?: boolean;
  // eslint-disable-next-line
  pageProps: any;
}
const GTM_ID = 'GTM-NXRG4XQ';

const MemoizedProviders: FC<IMemoizedProvidersProps> = memo(
  ({ Component, Layout, isPublic, pageProps }) => {
    return (
      <GlobalFonts>
        <ChakraProvider resetCSS theme={extendTheme}>
          <GlobalStyles />
          <GlobalPreload />
          <script
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID}');
            `,
            }}
            id="google-analytics"
          />

          <UnexpectedErrorBoundary>
            <ApolloProvider client={client}>
              <AnalyticsContextProviderLive stage={stage}>
                <AppWithAuth
                  Component={Component}
                  Layout={Layout}
                  isPublic={isPublic}
                  pageProps={pageProps}
                />
              </AnalyticsContextProviderLive>
            </ApolloProvider>
          </UnexpectedErrorBoundary>
        </ChakraProvider>
      </GlobalFonts>
    );
  }
);

const client = getApolloClient({ app: 'couple', ssr: false, stage, version });

// eslint-disable-next-line react-memo/require-memo
const MyApp: FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
  // eslint-disable-next-line
  const Layout = useMemo(() => Component.Layout ?? ((page: any) => page), [Component.Layout]);

  return (
    <MemoizedProviders
      Component={Component}
      Layout={Layout}
      isPublic={Component?.isPublic}
      pageProps={pageProps}
    />
  );
};

export default MyApp;
