import { selectTrackEvent } from '../../appMachine/selectors/selectTrackEvent';
import type { CSDContext } from '../types';

export const onEnd = (ctx: CSDContext) => {
  ctx._private.cbs?.sideEffects.onEnd();
  const sessionId = ctx.store.sessionMetadata.id;
  const trackEvent = selectTrackEvent();

  if (ctx.store.sessionMetadata.mode === 'public') {
    trackEvent({
      payload: { sessionId },
      type: 'COMPLETE_CONTENT_SESSION',
    });
  }

  ctx._private.cbs?.analytics.stop({
    payload: { sessionId },
    type: 'CONTENT_SESSION_DURATION',
  });

  if (ctx.store.sessionMetadata.isWelcomeAssessment) {
    trackEvent({
      payload: { sessionId },
      type: 'COMPLETE_WELCOME_ASSESSMENT',
    });
  }
};
