/* eslint-disable react-memo/require-memo */
import { Box, Flex, Grid } from '@chakra-ui/react';
import type { LoadableComponent } from '@loadable/component';
import loadable from '@loadable/component';
import { DisplayKind } from '@ours/utils';
import { memo } from 'react';
import type { FC } from 'react';

import { useCSDModuleById } from '../../state/contentSessionDriver/selectors/modules/useCSDModuleById';
import { useIsLocalMainPeer } from '../../state/contentSessionDriver/selectors/peers/useIsLocalMainPeer';
import { useIsMultiplayerSession } from '../../state/contentSessionDriver/selectors/useIsMultiplayerSession';
import type { ModuleId } from '../ContentSessionDisplay/lib/types';
import { ContentSessionInteraction } from '../ContentSessionInteraction/ContentSessionInteraction';

import { NotMainPeerUI } from './lib/NotMainPeerUI';

const VideoContentSessionModule = loadable(
  () => import('./lib/VideoContentSessionModule').then((c) => c.VideoContentSessionModule),
  { ssr: false }
);

const ImageContentSessionModule = loadable(
  () => import('./lib/ImageContentSessionModule').then((c) => c.ImageContentSessionModule),
  {
    ssr: false,
  }
);

const NotEnabled = loadable(() => import('./lib/NotEnabled').then((c) => c.NotEnabled), {
  ssr: false,
});
const HStackAudioCaptionPlayer = loadable(
  () => import('./lib/HStackAudioCaptionPlayer').then((c) => c.HStackAudioCaptionPlayer),
  { ssr: false }
);
const HStackText = loadable(() => import('./lib/HStackText').then((c) => c.HStackText), {
  ssr: false,
});

export const hStackDisplayKindLookup: Record<DisplayKind, LoadableComponent<ModuleId>> = {
  Animation: NotEnabled,
  AudioCaptionPlayer: HStackAudioCaptionPlayer,
  Image: ImageContentSessionModule,
  Text: HStackText,
  Video: VideoContentSessionModule,
};
export const ContentSessionHStack: FC<ModuleId> = memo(({ moduleId }) => {
  const module = useCSDModuleById(moduleId);
  const displayKind = module?.displayKind || DisplayKind.Animation;
  const Component = hStackDisplayKindLookup[displayKind];
  const isPeerMainPeer = useIsLocalMainPeer();
  const isMultiplayersession = useIsMultiplayerSession();
  if (!isPeerMainPeer && isMultiplayersession) {
    return <NotMainPeerUI />;
  }

  return (
    <Grid
      columnGap="300"
      h="full"
      px={{ base: '200', md: '0' }}
      rowGap={{ base: '100', md: '100' }}
      templateAreas={{
        base: `"display-content""interactive-content""spacer""footer"`,
        md: `". display-content interactive-content .""spacer spacer spacer spacer""footer footer footer footer"`,
      }}
      templateColumns={{
        base: '1fr',
        lg: '20px 65% auto 20px',
        md: '10px 50% auto 10px',
      }}
      templateRows={{ base: 'max-content', md: 'auto 10px 80px' }}
      w="full"
    >
      <Box data-testid="VideoContentSessionModule.video" gridArea="display-content">
        <Component moduleId={moduleId} />
      </Box>
      <Flex gap="100" gridArea="interactive-content" overflow="hidden">
        <Box overflowX="hidden" w="full">
          <ContentSessionInteraction moduleId={moduleId} />
        </Box>
      </Flex>
      <Box gridArea="spacer" />
    </Grid>
  );
});
