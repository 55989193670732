import { produce } from 'immer';
import { assign } from 'xstate';

import type { CSDActions, CSDContext } from '../types';

export const onToggleTranscript = assign<CSDContext, CSDActions>((ctx) => {
  return produce(ctx, (d) => {
    d.store.display.isShowingTranscriptPlayer = !d.store.display.isShowingTranscriptPlayer;
  });
});
