import { unexpected } from '../error/unexpected';

import { isObject } from './isObject';

type Assert = (str: unknown, errMsg?: string) => asserts str is Record<any, unknown>;

export const assertIsObject: Assert = (str, errMsg) => {
  if (!str || !isObject(str)) {
    throw unexpected({ name: errMsg || 'ValueIsNotAnObject' });
  }
};
