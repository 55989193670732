import { _useStore } from '../../store/_useStore';
import type { StoreState } from '../../store/types';

const select = (s: StoreState) => {
  return s.app.state.context.content.user?.role;
};

export const useUserRole = () => {
  return _useStore(select);
};
