import type { CardDeckType } from './types';

type INIT = { decks: CardDeckType[]; type: 'INIT' };
type ON_SELECT_CARD_DECK = { selectedDeckId: string | undefined; type: 'ON_SELECT_CARD_DECK' };
type ON_TO_PLAY_DECK = { type: 'ON_TO_PLAY_DECK' };
type RESET = { type: 'RESET' };
type ON_PASS_ON_CARD = { type: 'ON_PASS_ON_CARD' };
type ON_ANSWER_ON_CARD = { type: 'ON_ANSWER_ON_CARD' };
type ON_COMPLETE_ONBOARDING = { type: 'ON_COMPLETE_ONBOARDING' };
type ON_PLAY_DIFFERENT_DECK = { type: 'ON_PLAY_DIFFERENT_DECK' };
type ON_SET_ACTIVE_IDX = { idx: number; type: 'ON_SET_ACTIVE_IDX' };

export const userHasCompletedCardOnboarding = 'userHasCompletedCardOnboarding';
export type CardDeckActions =
  | INIT
  | RESET
  | ON_SELECT_CARD_DECK
  | ON_TO_PLAY_DECK
  | ON_COMPLETE_ONBOARDING
  | ON_SET_ACTIVE_IDX
  | ON_PASS_ON_CARD
  | ON_PLAY_DIFFERENT_DECK
  | ON_ANSWER_ON_CARD;
