import { assertType, omit } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const onRemoveSession = assign<AppContext, AppActions>((ctx, ev) => {
  assertType(ev, 'ON_REMOVE_SESSION');

  return produce(ctx, (draft) => {
    draft.content.sessionsById = omit(draft.content.sessionsById, [ev.sessionId]);
  });
});
