import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { SessionEditorActions, SessionEditorContext } from '../types';

export const onInit = assign<SessionEditorContext, SessionEditorActions>((ctx, ev) => {
  assertType(ev, 'INIT');

  return produce(ctx, (draft) => {
    draft.store.sessionId = ev.sessionId;
  });
});
