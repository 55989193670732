import { produce } from 'immer';
import { assign } from 'xstate';

import type { CartActions } from '../actionTypes';
import type { CartContext } from '../types';

export const onClearCart = assign<CartContext, CartActions>((ctx) => {
  return produce(ctx, (d) => {
    d.sessionIdsInCart = [];
  });
});
