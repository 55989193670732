import { unexpected } from '@ours/utils';

import { _useStore, store } from '../../store/_useStore';
import type { StoreState } from '../../store/types';

const select = (s: StoreState) => {
  const stage = s.app.state.context.app?.stage;
  if (!stage) {
    throw unexpected({ name: 'AppContextNotInit' });
  }
  return stage;
};
export const selectAppStage = () => {
  return select(store.getState());
};

export const useAppStage = () => {
  return _useStore(select);
};
