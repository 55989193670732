import type { MachineConfig } from 'xstate';
import { createMachine } from 'xstate';

import { onCopyNode } from './actions/onCopyNode';
import { onDeleteNode } from './actions/onDeleteNode';
import { onDragStart } from './actions/onDragStart';
import { onDrop } from './actions/onDrop';
import { onDuplicateNode } from './actions/onDuplicateNode';
import { onFocusPanel } from './actions/onFocusPanel';
import { onIfBooleanEdit } from './actions/onIfBooleanEdit';
import { onInit } from './actions/onInit';
import { onInsertNode } from './actions/onInsertNode';
import { onLoadSession } from './actions/onLoadSession';
import { onLoadSessionIntoContext } from './actions/onLoadSessionIntoContext';
import { onPasteNode } from './actions/onPasteNode';
import { onReset } from './actions/onReset';
import { onSelectNode } from './actions/onSelectNode';
import { onToDashboard } from './actions/onToDashboard';
import type { SessionEditorActions, SessionEditorContext } from './types';

const sessionEditorSchema: MachineConfig<SessionEditorContext, any, SessionEditorActions> = {
  context: {
    display: {} as any,
    store: {} as any,
  },
  description: '',
  id: 'sessionEditorMachine',
  initial: 'init',
  on: {
    RESET: { actions: 'onReset', target: 'init' },
  },
  predictableActionArguments: true,
  states: {
    editing: {
      on: {
        ON_COPY_NODE: { actions: ['onCopyNode'] },
        ON_DELETE_NODE: { actions: ['onDeleteNode'] },
        ON_DRAG_START: { actions: 'onDragStart' },
        ON_DROP: { actions: 'onDrop' },
        ON_DUPLICATE_NODE: { actions: ['onDuplicateNode'] },
        ON_FOCUS_PANEL: { actions: ['onFocusPanel'] },
        ON_IF_BOOLEAN_EDIT: { actions: ['onIfBooleanEdit'] },
        ON_INSERT_NODE: { actions: ['onInsertNode'] },
        ON_PASTE_NODE: { actions: ['onPasteNode'] },
        ON_SELECT_NODE: { actions: ['onSelectNode'] },
      },
    },

    init: {
      on: {
        INIT: { actions: ['onInit'], target: 'loading' },
      },
    },

    loading: {
      invoke: {
        onDone: {
          actions: ['onLoadSessionIntoContext'],
          target: 'editing',
        },
        onError: { actions: 'onToDashboard' },
        src: 'onLoadSession',
      },
    },
  },
};

export const sessionEditorMachine = createMachine(sessionEditorSchema, {
  actions: {
    onCopyNode,
    onDeleteNode,
    onDragStart,
    onDrop,
    onDuplicateNode,
    onFocusPanel,
    onIfBooleanEdit,
    onInit,
    onInsertNode,
    onLoadSessionIntoContext,
    onPasteNode,
    onReset,
    onSelectNode,
    onToDashboard,
  },
  guards: {},
  services: {
    onLoadSession,
  },
});
