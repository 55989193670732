import { produce } from 'immer';
import { assign } from 'xstate';

import { store } from '../../store/_useStore';
import type { CartActions } from '../actionTypes';
import type { CartContext } from '../types';

export const onPurchaseSuccess = assign<CartContext, CartActions>((ctx) => {
  ctx.sessionIdsInCart.forEach((id) => {
    store.getState().app.send({ sessionId: id, type: 'ON_PURCHASED_SESSION_ADD' });
  });

  // NOTE: Leave sessions in the cart to show success
  return produce(ctx, (d) => {
    d.errorMessage = undefined;
  });
});
