import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { CardDeckActions } from '../actionTypes';
import type { CardDeckContext } from '../types';

export const onSetActiveIdx = assign<CardDeckContext, CardDeckActions>((ctx, ev) => {
  assertType(ev, 'ON_SET_ACTIVE_IDX');

  return produce(ctx, (d) => {
    d.activeIdx = ev.idx;
  });
});
