import { first, getModuleIdFromNode, last } from '@ours/utils';

import { selectModuleById } from '../../store/lib/selectModuleById';
import { selectXStateBySessionId } from '../../store/lib/selectXStateBySessionId';
import type { CSDContext } from '../types';

type NextModuleState = {
  nextMacroModuleId: string | undefined;
  nextModuleId: string | undefined;
  nextNodeId: string;
};
/**
 *  @see https://www.notion.so/withours/Macro-modules-62d325e199e549b3991973db21b5e597?pvs=4#10736dce22904083828718eb3eefc087
 **/
export const determineNextModule = (nextNodeId: string, ctx: CSDContext): NextModuleState => {
  const xstate = selectXStateBySessionId(ctx.store.sessionMetadata.id);
  const currentNodeId = ctx.store.display.currentNodeId!;
  const currentModuleId = ctx.store.display.currentModuleId;
  const currentMacroModuleId = ctx.store.display.currentMacroModuleId;
  const currentMacroModule = currentMacroModuleId
    ? selectModuleById(currentMacroModuleId)
    : undefined;

  const onToNextModule = (): NextModuleState => {
    const nextModuleId = getModuleIdFromNode(xstate[nextNodeId]);
    const nextModule = nextModuleId ? selectModuleById(nextModuleId) : undefined;

    if (nextModule?.interactionKind === 'MacroModules') {
      const mmIds = nextModule.macroModuleIds || [];

      return {
        nextMacroModuleId: nextModuleId,
        nextModuleId: first(mmIds),
        nextNodeId,
      };
    }

    return {
      nextMacroModuleId: undefined,
      nextModuleId,
      nextNodeId,
    };
  };

  const onToNextInMacroModule = (): NextModuleState => {
    const mmIds = currentMacroModule?.macroModuleIds || [];
    const currentModuleIdxInMM = mmIds.indexOf(currentModuleId!);
    const isAtFinalModuleInMM = last(mmIds) === currentModuleId;

    if (isAtFinalModuleInMM) {
      return onToNextModule();
    }

    return {
      nextMacroModuleId: currentMacroModuleId,
      nextModuleId: mmIds.at(currentModuleIdxInMM + 1),
      nextNodeId: currentNodeId,
    };
  };

  return currentMacroModuleId ? onToNextInMacroModule() : onToNextModule();
};
