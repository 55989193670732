import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import type { FC, PropsWithChildren } from 'react';
import { memo, useCallback } from 'react';

import { HoverLink } from '../../../components/Links/HoverLink';

interface Props {
  boxProps?: BoxProps;
  isBold?: boolean;
  link?: string;
  text: string;
}

export const FooterListItem: FC<Props> = memo(({ boxProps, isBold, link, text }) => {
  const Comp = useCallback(
    // eslint-disable-next-line react-memo/require-memo
    ({ children }: PropsWithChildren<unknown>) => (
      <Box as="li" fontWeight={isBold ? '600' : '400'} {...boxProps}>
        {children}
      </Box>
    ),
    [isBold, boxProps]
  );

  if (!link) {
    return <Comp>{text}</Comp>;
  }

  return (
    <Comp>
      <HoverLink as="a" href={link} target="_blank">
        {text}
      </HoverLink>
    </Comp>
  );
});
