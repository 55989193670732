import type { BoxProps } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

export const FullpageLayout: FC<{ boxProps?: BoxProps; children?: ReactNode }> = memo(
  ({ boxProps, children }) => {
    return (
      <Flex
        {...boxProps}
        as="main"
        bg="brand.beige"
        data-testid="FullpageLayout"
        flexDir="column"
        flexGrow={1}
        justifyContent="space-between"
        pos="relative"
        w="full"
      >
        {children}
      </Flex>
    );
  }
);
