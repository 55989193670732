import type { DeepWritable } from 'ts-essentials';

import type { AppContext } from '../types';

export const defaultMembership: DeepWritable<AppContext['content']['membership']> = {
  combinedStudentsName: '',
  id: '',
  isOnboardingSessionComplete: false,
  onboardingSessionId: '',
  purchasedSessionIds: [],
  purchaserUserEmail: '',
  purchaserUserFullName: '',
  purchaserUserSelfReportedReferrer: '',
};
