import Head from 'next/head';
import type { FC } from 'react';
import { memo } from 'react';

import { useMetaDescription } from '../../state/appMachine/selectors/useMetaDescription';
import { useMetaTitle } from '../../state/appMachine/selectors/useMetaTitle';

export const GlobalPreload: FC = memo(() => {
  const metaTitle = useMetaTitle();
  const metaDescription = useMetaDescription();
  const title = metaTitle ? `${metaTitle} | OURS` : 'OURS';
  return (
    <Head>
      <meta content="width=device-width, initial-scale=1, maximum-scale=5" name="viewport" />
      <title>{title}</title>
      <meta content={title} name="og:title" />
      <meta content={metaDescription} name="og:description" />
      <link href="/favicon.ico" rel="icon" />
    </Head>
  );
});
