import type { SessionForAppContextFragment } from '@ours/utils';
import { uniq } from '@ours/utils';

import { clientSideSideEffects } from './performClientSideSideEffects';

type Result = true | string;

const isValidPricingSession = (session: SessionForAppContextFragment): Result => {
  const modules = session.resolvedValues?.modules || [];

  const isInvalidDueToNoEmail = modules.find((m) => m.inputType === 'email');
  if (!isInvalidDueToNoEmail) {
    return 'Invalid due to no email';
  }

  const isInvalidDueToSideEffects = modules.find((m) => {
    return (
      (m.sideEffectActions || []).filter((item) => !clientSideSideEffects.includes(item)).length > 0
    );
  });

  if (isInvalidDueToSideEffects) {
    return 'Invalid due to: Modules contain side-effects which cannot run. Remove them.';
  }

  return true;
};

const isValidPublicSession = (session: SessionForAppContextFragment): Result => {
  const modules = session.resolvedValues?.modules || [];

  if (modules.find((m) => m.interactionKind === 'InAppPurchase')) {
    return 'Public sessions cannot contain in app purchases';
  }

  if (modules.find((m) => m.interactionKind === 'PricingBlock')) {
    return isValidPricingSession(session);
  }

  return true;
};

const isValidPrivateSession = (session: SessionForAppContextFragment): Result => {
  const modules = session.resolvedValues?.modules || [];

  if (modules.find((m) => m.interactionKind === 'PricingBlock')) {
    return 'Private sessions cannot contain pricing blocks';
  }

  return true;
};

export const isValidSessionConfiguration = (session: SessionForAppContextFragment): Result => {
  const modules = session.resolvedValues?.modules || [];

  if (modules.length === 0) {
    return 'Modules are required';
  }

  const macroModules = modules.filter((m) => m.interactionKind === 'MacroModules');
  const isValidMacroModule = macroModules.every((m) => {
    const ids = m.macroModuleIds || [];
    return m.layout === 'InteractionOnly' && ids.length > 0 && uniq(ids).length === ids.length;
  });
  if (!isValidMacroModule) {
    return 'Macro modules can only have interaction only layouts, need moduleIds, and cannot have duplicate moduleIds.';
  }

  return session.visibility === 'Public'
    ? isValidPublicSession(session)
    : isValidPrivateSession(session);
};
