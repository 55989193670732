import { append, calculateLikelyCurrentStepPerc } from '@ours/utils';
import { produce } from 'immer';

import { selectXStateBySessionId } from '../../store/lib/selectXStateBySessionId';
import { onEnd } from '../actions/onEnd';
import type { CSDContext, SessionDirection } from '../types';

interface Options {
  autoPlay?: boolean;
  dir: SessionDirection;
  moduleIdToAppend?: string;
  nextMacroModuleId: string | undefined;
  nextModuleId: string | undefined;
  nextNodeId: string;
}

export const updateSessionPlaybackByNodeId = (
  ctx: CSDContext,
  { autoPlay, dir, moduleIdToAppend, nextMacroModuleId, nextModuleId, nextNodeId }: Options
): CSDContext => {
  const xState = selectXStateBySessionId(ctx.store.sessionMetadata.id);
  const responseDraft = nextModuleId
    ? ctx.store.sessionMetadata.moduleResponses[nextModuleId]
    : undefined;
  ctx._private.yjs?.progress.set('nodeId', nextNodeId);
  ctx.store.display.videoElement?.pause();

  const isEnd = nextNodeId === ctx.store.display.endNodeId;

  if (isEnd) {
    onEnd(ctx);
  }

  return produce(ctx, (d) => {
    d.store.display.shouldGatherAV = false;
    d.store.display.direction = dir;
    d.store.display.autoPlay = !!autoPlay;
    d.store.display.isTransitionComplete = false;
    d.store.display.currentNodeId = nextNodeId;
    d.store.display.currentModuleId = nextModuleId;
    d.store.display.currentMacroModuleId = nextMacroModuleId;
    d.store.display.likelyProgress = calculateLikelyCurrentStepPerc(nextNodeId, xState);
    d.store.interaction.isButtonDisabled = false;
    d.store.interaction.moduleResponseDraft = responseDraft;

    d.store.sessionMetadata.completedModuleIds = moduleIdToAppend
      ? append(moduleIdToAppend, ctx.store.sessionMetadata.completedModuleIds)
      : ctx.store.sessionMetadata.completedModuleIds;
  });
};
