import type { FC } from 'react';
import { BeatLoader, PuffLoader } from 'react-spinners';

export const buttonVariants = {
  beige: {
    bg: 'brand.beige',
    bgHover: 'brand.blueHover',
    justifyContent: 'center',
  },
  blue: {
    bg: 'brand.blue',
    bgHover: 'brand.blueHover',
    justifyContent: 'center',
  },
  orange: {
    bg: 'brand.orange',
    bgHover: 'brand.orangeHover',
    justifyContent: 'center',
  },
  white: {
    bg: 'white',
    bgHover: 'brand.blueHover',
    justifyContent: 'center',
  },
  yellow: {
    bg: 'brand.yellow',
    bgHover: 'brand.yellowHover',
    color: 'black',
    justifyContent: 'center',
  },
} as const;
const baseSmallStyles = {
  borderRadius: '500',
  boxShadowProps: {},
  fontSize: { base: 'xs', md: 'sm' },
  h: undefined,
  px: { base: '50', md: '100' },
  py: { base: '50', md: '50' },
  w: undefined,
};
export const buttonSizes = {
  base: {
    borderRadius: '500',
    boxShadowProps: {},
    fontSize: { base: '300', md: '500' },
    h: undefined,
    px: { base: '250', lg: '400', md: '300' },
    py: { base: '100', md: '200' },
    w: undefined,
  },
  rightRounded: {
    borderRadius: '500',
    borderTopRightRadius: '800',
    boxShadowProps: { borderTopRightRadius: '800' },
    h: '50px',
    justifyContent: 'start',
    minW: '130px',
    px: '200',
    textAlign: 'left',
  },
  roundLg: {
    borderRadius: 'full',
    boxShadowProps: {},
    fontSize: { base: '300', md: '500' },
    h: { base: '45px', md: '55px' },
    px: { base: '0', md: '50' },
    py: { base: '0', md: '50' },
    w: { base: '45px', md: '55px' },
  },
  roundMd: {
    borderRadius: 'full',
    boxShadowProps: {},
    fontSize: { base: '300', md: '500' },
    h: { base: '30px', md: '45px' },
    px: { base: '0', md: '50' },
    py: { base: '0', md: '50' },
    w: { base: '30px', md: '45px' },
  },
  roundSm: {
    borderRadius: 'full',
    boxShadowProps: {},
    fontSize: { base: '300', md: '500' },
    h: { base: '25px', md: '30px' },
    px: { base: '0', md: '50' },
    py: { base: '0', md: '50' },
    w: { base: '25px', md: '30px' },
  },
  roundXl: {
    borderRadius: 'full',
    boxShadowProps: {},
    fontSize: { base: '300', md: '500' },
    h: { base: '100px', md: '150px' },
    px: { base: '100', md: '200' },
    py: { base: '100', md: '200' },
    textAlign: 'center',
    w: { base: '100px', md: '150px' },
  },
  sm: baseSmallStyles,
  smFullW: { ...baseSmallStyles, w: 'full' },
} as const;

export const rootButtonSizes: Record<keyof typeof buttonSizes, Record<any, any>> = {
  base: {},
  rightRounded: {},
  roundLg: {},
  roundMd: {},
  roundSm: {},
  roundXl: {},
  sm: {},
  smFullW: { w: 'full' },
};

interface ButtonLoaderProps {
  Comp: FC<{
    color: string;
    size: number;
    speedMultiplier: number;
  }>;
  loaderColor: string;
  loaderSize: number;
}
export const buttonLoaderBySize: Record<keyof typeof buttonSizes, ButtonLoaderProps> = {
  base: { Comp: BeatLoader, loaderColor: '#fff', loaderSize: 15 },
  rightRounded: { Comp: BeatLoader, loaderColor: '#fff', loaderSize: 15 },
  roundLg: { Comp: PuffLoader, loaderColor: '#000', loaderSize: 40 },
  roundMd: { Comp: PuffLoader, loaderColor: '#000', loaderSize: 40 },
  roundSm: { Comp: PuffLoader, loaderColor: '#000', loaderSize: 30 },
  roundXl: { Comp: PuffLoader, loaderColor: '#000', loaderSize: 80 },
  sm: { Comp: BeatLoader, loaderColor: '#fff', loaderSize: 15 },
  smFullW: { Comp: BeatLoader, loaderColor: '#fff', loaderSize: 15 },
};
