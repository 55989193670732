import type { SessionForAppContextFragment } from '@ours/utils';
import { assertType } from '@ours/utils';
import { produce } from 'immer';
import type { DeepWritable } from 'ts-essentials';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const onUpdateSession = assign<AppContext, AppActions>((ctx, ev) => {
  assertType(ev, 'ON_UPDATE_SESSION');
  return produce(ctx, (draft) => {
    draft.content.sessionsById[ev.sessionId] = {
      ...draft.content.sessionsById[ev.sessionId],
      ...(ev.session as DeepWritable<SessionForAppContextFragment>),
      id: ev.sessionId,
    };
  });
});
