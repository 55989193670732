import { assertType, first } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { CartActions } from '../actionTypes';
import type { CartContext } from '../types';

export const onSyncBillingMethods = assign<CartContext, CartActions>((ctx, ev) => {
  assertType(ev, 'ON_SYNC_BILLING_METHODS');

  return produce(ctx, (d) => {
    d.billingMethods = ev.methods;
    // Keep selected method if still in array. Otherwise, pick the first one
    d.selectedBillingMethodId =
      d.selectedBillingMethodId && ev.methods.find(({ id }) => id === d.selectedBillingMethodId)
        ? d.selectedBillingMethodId
        : first(ev.methods)?.id;
  });
});
