import type { AppActions } from '../actionTypes';

export const listenToIsOnline = (cb: (args: AppActions) => void) => {
  const onOnline = () => {
    cb({ isOnline: navigator.onLine, type: 'ONLINE_CHANGE' });
  };

  window.addEventListener('online', onOnline);
  window.addEventListener('offline', onOnline);
  return () => {
    window.removeEventListener('online', onOnline);
    window.removeEventListener('offline', onOnline);
  };
};
