import type { Maybe } from '@ours/types';

import type { XState } from '../generated/operations';
import { omit } from '../lang/omit';

import type { IXStateState } from './xStateConstants';

export const graphqlXStateToXState = (graphQLXState: Maybe<readonly XState[]>): IXStateState => {
  if (!graphQLXState) {
    return {};
  }

  return graphQLXState.reduce((acc, cur) => {
    if (cur.id) {
      // @ts-expect-error error
      acc[cur.id] = {
        ...omit(cur, ['id']),
      };
    }
    return acc;
  }, {} as IXStateState);
};
