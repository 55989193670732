import { produce } from 'immer';
import { assign } from 'xstate';

import type { CartActions } from '../actionTypes';
import type { CartContext } from '../types';

export const onResetState = assign<CartContext, CartActions>((ctx) => {
  ctx.onCloseCompletedCheckout?.();

  return produce(ctx, (d) => {
    d.sessionIdsInCart = [];
    d.billingDrawerOpen = false;
    d.onCloseCompletedCheckout = undefined;
  });
});
