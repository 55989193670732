import { assertType, unexpected } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { CartActions } from '../actionTypes';
import type { CartContext } from '../types';

export const onChangeBillingMethod = assign<CartContext, CartActions>((ctx, ev) => {
  assertType(ev, 'ON_CHANGE_BILLING_METHOD');

  if (!ctx.billingMethods.find((b) => b.id === ev.selectedBillingMethod)) {
    throw unexpected({ name: 'ChangeBillingMethodCalledWithMethodNotInCart' });
  }

  return produce(ctx, (d) => {
    d.selectedBillingMethodId = ev.selectedBillingMethod;
  });
});
