import { Auth } from '@aws-amplify/auth';
import { formatEmail } from '@ours/utils';

interface ISignInWithCode {
  code: string;
  email: string;
}

export const signInWithCode = async ({ code, email }: ISignInWithCode): Promise<boolean> => {
  const formattedEmail = formatEmail(email);
  if (!formattedEmail) {
    return false;
  }
  const user = await Auth.signIn(formattedEmail);
  // eslint-disable-next-line no-console
  console.log('signInWithCode:', user);
  // This will throw an error if it’s the 3rd wrong answer
  await Auth.sendCustomChallengeAnswer(user, code);
  return true;
};
