import type { Stage } from '../generated/operations';

export const COMPANY_DOMAIN_NAME = 'withours.com' as const;
export const COMPANY_DOMAIN = `https://${COMPANY_DOMAIN_NAME}` as const;
export const COMPANY_MAIL_TO_LINK = `mailto:hi@${COMPANY_DOMAIN_NAME}` as const;
export const FROM_EMAIL = { email: `hi@${COMPANY_DOMAIN_NAME}`, name: 'OURS Wellness' };
export const OUTBOUND_SUBDOMAIN_LOOKUP: Record<Stage, string> = {
  dev: `dev.${COMPANY_DOMAIN_NAME}`,
  local: `local.${COMPANY_DOMAIN_NAME}`,
  production: `talk.${COMPANY_DOMAIN_NAME}`,
};
