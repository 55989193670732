import { toShortId } from '../id/toShortId';

export const CoupleRoutes = {
  Capsule: '/capsule',
  ContentSession: '/s',
  Events: '/events',
  FAQS: '/faq',
  GuidePage: '/guide',
  Home: '/',
  LogOut: '/logout',
  Login: '/login',
  PrivateMeetings: '/meetings',
  Profile: '/profile',
  PublicCancel: '/events/cancel',
  PublicReschedule: '/events/reschedule',
  PublicSession: (id: string) => `/p/${id}`,
  Roadmap: '/roadmap',
  ScheduleSessionEvent: (sessionId: string) => `/events/schedule/${toShortId(sessionId)}`,
  Store: '/store',
  Success: '/success',
  Uploads: '/uploads',
  Video: '/video',
} as const;

export const GuideRoutes = {
  Configuration: '/configuration',
  Content: '/content',
  Couples: '/couples',
  DateFormatter: '/date',
  EventsBase: '/events',
  EventsOverview: '/events',
  EventsRecent: '/events/recent',
  Haikus: '/haiku',
  History: '/history',
  Home: '/',
  IdTranslator: '/id',
  LogOut: '/logout',
  Login: '/login',
  Modules: '/modules',
  Profile: '/profile',
  RequestedLink: '/requested-link',
  ScheduleExceptions: '/schedule',
  SchedulePreview: '/schedule-preview',
  SessionEdit: (sessionId: string) => `/sessions/${toShortId(sessionId)}`,
  SessionModule: (sessionId: string) => `/sessions/${toShortId(sessionId)}/module`,
  SessionPPT: (sessionId: string) => `/sessions/${toShortId(sessionId)}/ppt`,
  SessionPreview: (sessionId: string) => `/sessions/${toShortId(sessionId)}/preview`,
  SessionShare: (sessionId: string) => `/sessions/${toShortId(sessionId)}/share`,
  Sessions: '/sessions',
  Users: '/users',
} as const;
