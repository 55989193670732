import type { BoxProps } from '@chakra-ui/react';
import { Box, Flex, useBoolean } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

import { MarkDown } from '../../components/Text/MarkDown';

import footerSVG from './footer-long.svg';
import { FOOTER_CONTENT } from './footerConstants';
import { PinkFooterDesktop } from './lib/PinkFooterDesktop';
import { PinkFooterMobile } from './lib/PinkFooterMobile';

interface Props {
  boxProps?: BoxProps;
}

export const PinkFooter: FC<Props> = memo(({ boxProps }) => {
  const [isOpen, { toggle }] = useBoolean(false);

  return (
    <Box as="footer" data-testid="pinkFooter" width="full" {...boxProps}>
      <Box mb="-1" overflow="hidden" pos="relative" w="full">
        <Box
          backgroundImage={`url(${footerSVG.src || footerSVG})`}
          backgroundPosition="top"
          backgroundRepeat="repeat-x"
          backgroundSize="contain"
          h={FOOTER_SVG_HEIGHT_PX}
          mb={{ base: '-20px', md: '-30px' }}
          ml="-2px"
          right="0"
          w="calc(100% + 2px)"
        />
      </Box>
      <Flex
        alignItems="center"
        bg="brand.pink"
        flexDir="column"
        justifyContent="center"
        py={{ base: '100', md: '600' }}
      >
        <Box display={{ base: 'block', md: 'none' }}>
          <PinkFooterMobile isOpen={isOpen} toggle={toggle} />
        </Box>
        <Box display={{ base: 'none', md: 'block' }} w="full">
          <PinkFooterDesktop isOpen={isOpen} toggle={toggle} />
        </Box>
        {isOpen ? (
          <Box
            data-testid="PinkFooter.Legal"
            maxW="full"
            pb={{ base: '300', md: '300' }}
            px={{ base: '300', md: '600' }}
            w="container.xl"
          >
            <MarkDown value={FOOTER_CONTENT.LEGAL} />
          </Box>
        ) : null}
      </Flex>
    </Box>
  );
});

export const FOOTER_SVG_HEIGHT_PX = { base: '40px', md: '57px' };
