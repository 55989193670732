/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-misused-promises */
import type { WrapOptions } from 'retry';
import retry from 'retry';

const networkErrorMsgs = new Set([
  'Failed to fetch', // Chrome
  'NetworkError when attempting to fetch resource.', // Firefox
  'The Internet connection appears to be offline.', // Safari
  'Network request failed', // `cross-fetch`
]);

const decorateErrorWithCounts = (error: any, attemptNumber: any, options: any) => {
  // Minus 1 from attemptNumber because the first attempt does not count as a retry
  const retriesLeft = options.retries - (attemptNumber - 1);

  error.attemptNumber = attemptNumber;
  error.retriesLeft = retriesLeft;
  return error;
};

const isNetworkError = (errorMessage: any) => networkErrorMsgs.has(errorMessage);

export class AbortError extends Error {
  public originalError: any;
  constructor(message: any) {
    super();

    if (message instanceof Error) {
      this.originalError = message;
      ({ message } = message);
    } else {
      this.originalError = new Error(message);
      this.originalError.stack = this.stack;
    }

    this.name = 'AbortError';
    this.message = message;
  }
}

export interface FailedAttemptError extends Error {
  readonly attemptNumber: number;
  readonly retriesLeft: number;
}

export interface Options extends WrapOptions {
  readonly onFailedAttempt?: (error: FailedAttemptError) => void | Promise<void>;
}

export async function pRetry<T>(
  input: (attemptCount: number) => PromiseLike<T> | T,
  options?: Options
): Promise<T> {
  return new Promise((resolve, reject) => {
    options = {
      onFailedAttempt: () => {
        //
      },
      retries: 10,
      ...options,
    };

    const operation = retry.operation(options);

    operation.attempt(async (attemptNumber) => {
      try {
        resolve(await input(attemptNumber));
      } catch (error) {
        if (!(error instanceof Error)) {
          reject(new TypeError(`Non-error was thrown: "${error}". You should only throw errors.`));
          return;
        }

        if (error instanceof AbortError) {
          operation.stop();
          reject(error.originalError);
        } else if (error instanceof TypeError && !isNetworkError(error.message)) {
          operation.stop();
          reject(error);
        } else {
          decorateErrorWithCounts(error, attemptNumber, options);

          // eslint-disable-next-line max-depth
          try {
            await options?.onFailedAttempt?.(error as any);
          } catch (error) {
            reject(error);
            return;
          }

          // eslint-disable-next-line max-depth
          if (!operation.retry(error)) {
            reject(operation.mainError());
          }
        }
      }
    });
  });
}
