import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { VideoActions } from '../actionTypes';
import { videoSettingsLocalStorage } from '../externalActions/videoSettingsLocalStorage';
import type { VideoContext } from '../types';

export const onToggleVideoCC = assign<VideoContext, VideoActions>((ctx, ev) => {
  assertType(ev, 'ToggleVideoCC');
  return produce(ctx, (d) => {
    videoSettingsLocalStorage.setByKey('displayCC', !d.settings.displayCC);
    d.settings.displayCC = !d.settings.displayCC;
  });
});
