import type { FC } from 'react';
import { memo } from 'react';

import { ButtonBase } from './ButtonBase';
import type { ButtonPrivateProps } from './types';

export const BButton: FC<ButtonPrivateProps> = memo(({ children, ...props }) => {
  return (
    <ButtonBase {...props} as="button">
      {children}
    </ButtonBase>
  );
});
