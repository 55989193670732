import { unexpected } from '../error/unexpected';
import { floor } from '../math/floor';

export const getItemAtRandom = <T>(arr: readonly T[]): T => {
  if (arr.length === 0) {
    throw unexpected({ name: 'CannotGetAtRandomFromEmptyArray' });
  }

  // @ts-expect-error not an error
  return arr[floor(Math.random() * (arr.length - 1))];
};
