import type { MachineConfig } from 'xstate';
import { createMachine } from 'xstate';

import { onCanUseVirtualBackground } from './actions/onCanUseVirtualBackground';
import { onChangeDrawer } from './actions/onChangeDrawer';
import { onInit } from './actions/onInit';
import { onLocalVideoMinimizeToggle } from './actions/onLocalVideoMinimizeToggle';
import { onLocalVideoMove } from './actions/onLocalVideoMove';
import { onToggleWaitingRoomMode } from './actions/onToggleWaitingRoomMode';
import type { LiveSessionActions, LiveSessionContext } from './types';

const liveSessionSchema: MachineConfig<LiveSessionContext, any, LiveSessionActions> = {
  context: {
    store: {
      display: {
        drawer: 'closed',
        localPeerVideo: { isMinimized: false, location: 'bottom-right' },
      },
      metadata: {
        isHost: false,
        waitingRoomMode: 'manualJoin',
      },
      supports: { isVirtualBackgroundSupported: false },
    },
  },
  description: '',
  id: 'csdMachine',
  initial: 'init',
  on: {
    ON_CHANGE_DRAWER: { actions: 'onChangeDrawer' },
    ON_LOCAL_VIDEO_MINIMIZE_TOGGLE: { actions: 'onLocalVideoMinimizeToggle' },
    ON_LOCAL_VIDEO_MOVE: { actions: 'onLocalVideoMove' },
    ON_TOGGLE_WAITING_ROOM_MODE: { actions: 'onToggleWaitingRoomMode' },
    RESET: { target: 'init' },
  },
  predictableActionArguments: true,
  states: {
    init: {
      on: {
        INIT: { actions: ['onCanUseVirtualBackground', 'onInit'] },
      },
    },
  },
};

export const liveSessionMachine = createMachine(liveSessionSchema, {
  actions: {
    onCanUseVirtualBackground,
    onChangeDrawer,
    onInit,
    onLocalVideoMinimizeToggle,
    onLocalVideoMove,
    onToggleWaitingRoomMode,
  },
  guards: {},
});
