import type { Maybe } from '@ours/types';
import { hydrateUserData } from '@ours/utils';
import mixpanel from 'mixpanel-browser';

import type { AppInitPayloadFragment } from '../../../generated/custom-hooks';

export const listenToAuthedAnalytics = (version: string, user: Maybe<AppInitPayloadFragment>) => {
  const membership = user?.latestMembership;
  const membershipId = membership?.id;
  const userId = user?.id;

  if (membership && userId) {
    const update = hydrateUserData(user);
    mixpanel.identify(userId);
    mixpanel.people.set(update);
    mixpanel.register(update);

    if (membershipId) {
      mixpanel.get_group('membershipId', membershipId).set({
        $email: membership?.resolvedValues?.purchaserUserEmail,
        $name: membership?.combinedStudentsName,
        combinedName: membership?.combinedStudentsName,
        purchaserUserEmail: membership?.resolvedValues?.purchaserUserEmail,
        purchaserUserFullName: membership?.resolvedValues?.purchaserUserFullName,
        purchaserUserSelfReportedReferrer:
          membership?.resolvedValues?.purchaserUserSelfReportedReferrer,
      });
    }
  }

  if (membershipId) {
    mixpanel.add_group('membershipId', membershipId);
    mixpanel.get_group('membershipId', membershipId).set_once({ initialVersion: version });
  }
};
