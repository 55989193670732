import { assertIsObject, assertType, expected, noop, noopThrow } from '@ours/utils';
import mixpanel from 'mixpanel-browser';
import router from 'next/router';
import type { MachineConfig } from 'xstate';
import { createMachine } from 'xstate';

import { initMixpanel } from '../../hooks/analytics/initMixpanel';
import { getApolloClient } from '../../lib/apollo/getApolloClient';
import { configureCognito } from '../../lib/cognito/configureCognito';
import { getCurrentSession } from '../../lib/cognito/getCurrentSession';
import { signInWithCode } from '../../lib/cognito/signInWithCode';
import { signOut } from '../../lib/cognito/signOut';

import type { AppActions } from './actionTypes';
import { onAppHydrateContext } from './actions/onAppHydrateContext';
import { onInit } from './actions/onInit';
import { onInitDeviceInfo } from './actions/onInitDeviceInfo';
import { onLanguageChange } from './actions/onLanguageChange';
import { onLogout } from './actions/onLogout';
import { onOnlineChange } from './actions/onOnlineChange';
import { onPresetRoleFromCognito } from './actions/onPresetRoleFromCognito';
import { onPurchasableSessionsAdd } from './actions/onPurchasableSessionsAdd';
import { onPurchasedSessionAdd } from './actions/onPurchasedSessionAdd';
import { onPurchasedSessionSync } from './actions/onPurchasedSessionSync';
import { onRemoveSession } from './actions/onRemoveSession';
import { onSetMembershipInformation } from './actions/onSetMembershipInformation';
import { onTabVisibility } from './actions/onTabVisibility';
import { onUpdateEvent } from './actions/onUpdateEvent';
import { onUpdateEvents } from './actions/onUpdateEvents';
import { onUpdateMeta } from './actions/onUpdateMeta';
import { onUpdateModule } from './actions/onUpdateModule';
import { onUpdateModules } from './actions/onUpdateModules';
import { onUpdateSession } from './actions/onUpdateSession';
import { onUpdateXState } from './actions/onUpdateXState';
import { onWindowSizeChange } from './actions/onWindowSizeChange';
import { defaultMembership } from './lib/defaultMembership';
import { listenToAuthStatus } from './listeners/listenToAuthStatus';
import { listenToBrowserLanguage } from './listeners/listenToBrowserLanguage';
import { listenToIsOnline } from './listeners/listenToIsOnline';
import { listenToMembership } from './listeners/listenToMembership';
import { listenToTabVisibility } from './listeners/listenToTabVisibility';
import { listenToWindowSize } from './listeners/listenToWindowSize';
import { onInitAuthedData } from './listeners/onInitAuthedData';
import { setupDeviceInfo } from './listeners/setupDeviceInfo';
import type { AppContext } from './types';

const appSchema: MachineConfig<AppContext, any, AppActions> = {
  context: {
    app: undefined,
    content: {
      eventsById: {},
      membership: defaultMembership,
      modulesById: {},
      sessionsById: {},
      sessionsForPurchaseById: {},
      user: undefined,
      xStateBySessionId: {},
    },
    device: {
      category: 'unknown',
      isChrome: false,
      isEdge: false,
      isFirefox: false,
      isOnline: true,
      isSafari: false,
      isVisible: true,
      language: '',
      name: '',
      supports: {
        fullScreenVideo: false,
        hover: false,
        touch: false,
        webShare: false,
      },
      version: '',
      windowSize: { height: 0, width: 0 },
    },
    seo: { description: '', title: 'OURS' },
    toast: noop,
    trackEvent: noopThrow('trackEvent called w/o implementation'),
  },
  id: 'appMachine',
  initial: 'bootstrap',
  invoke: {
    src: () => (cb: (args: AppActions) => void) => {
      if (typeof window === 'undefined') {
        return noop;
      }
      try {
        configureCognito();
      } catch (err) {
        //
      }
      setupDeviceInfo(cb);
      const cleanupLang = listenToBrowserLanguage(cb);
      const cleanupVisibility = listenToTabVisibility(cb);
      const cleanupIsOnline = listenToIsOnline(cb);
      const cleanupWindowSize = listenToWindowSize(cb);

      return () => {
        cleanupLang();
        cleanupVisibility();
        cleanupIsOnline();
        cleanupWindowSize();
      };
    },
  },
  on: {
    INIT_DEVICE_INFO: { actions: 'onInitDeviceInfo' },
    LANG_CHANGE: { actions: 'onLanguageChange' },
    ONLINE_CHANGE: { actions: 'onOnlineChange' },
    ON_APP_HYDRATE_CONTEXT: [
      {
        actions: 'onAppHydrateContext',
        cond: (ctx, s, meta) => {
          return meta.state.matches('bootstrap');
        },
        target: 'determineInitialAuthState',
      },
      { actions: 'onAppHydrateContext' },
    ],
    ON_PURCHASABLE_SESSIONS_ADD: { actions: 'onPurchasableSessionsAdd' },
    ON_PURCHASED_SESSION_ADD: { actions: 'onPurchasedSessionAdd' },
    ON_PURCHASED_SESSION_SYNC: { actions: 'onPurchasedSessionSync' },
    ON_REMOVE_SESSION: { actions: 'onRemoveSession' },
    ON_UPDATE_ALL_EVENTS: { actions: 'onUpdateEvents' },
    ON_UPDATE_EVENT: { actions: 'onUpdateEvent' },
    ON_UPDATE_MODULE: { actions: 'onUpdateModule' },
    ON_UPDATE_MODULES: { actions: 'onUpdateModules' },
    ON_UPDATE_SESSION: { actions: 'onUpdateSession' },
    ON_UPDATE_XSTATE: { actions: 'onUpdateXState' },
    TAB_VISIBILITY_CHANGE: { actions: 'onTabVisibility' },
    UPDATE_META: { actions: 'onUpdateMeta' },
    WINDOW_SIZE_CHANGE: { actions: 'onWindowSizeChange' },
  },
  predictableActionArguments: true,
  states: {
    authenticated: {
      invoke: {
        src: (ctx) => (cb: (args: AppActions) => void) => {
          initMixpanel(ctx.app?.stage);
          onInitAuthedData(ctx, cb);
          const cleanupAuthStatus = listenToAuthStatus(cb);
          const cleanupMembershipSync = listenToMembership(ctx, cb);

          return () => {
            cleanupAuthStatus();
            cleanupMembershipSync();
          };
        },
      },
      on: {
        ON_LOGOUT: { target: 'loggingOut' },
        ON_SET_MEMBERSHIP_INFORMATION: { actions: ['onSetMembershipInformation'] },
      },
    },
    bootstrap: {
      on: {
        ON_INIT: { actions: 'onInit' },
      },
    },
    determineInitialAuthState: {
      invoke: {
        onDone: [{ actions: ['onPresetRoleFromCognito'], target: 'authenticated' }],
        onError: [{ target: 'unauthenticated' }],
        src: async () => {
          const user = await getCurrentSession();
          if (!user) {
            throw new Error('Unauthenticated');
          }
          return user.getIdToken()?.decodePayload?.()?.role;
        },
      },
    },
    loggingIn: {
      invoke: {
        onDone: { target: 'determineInitialAuthState' },
        onError: { target: 'unauthenticated' },
        src: async (_, ev) => {
          assertType(ev, 'ON_LOGIN');
          const validLogin = await signInWithCode({ code: ev.code, email: ev.email });
          if (!validLogin) {
            throw expected({ name: 'InvalidEmailWhileLoggingIn' });
          }
        },
      },
    },
    loggingOut: {
      invoke: {
        onDone: { actions: 'onLogout', target: 'unauthenticated' },
        src: async (ctx) => {
          assertIsObject(ctx.app);
          await router.prefetch('/logout');
          const client = getApolloClient();

          try {
            client.stop();
            await Promise.allSettled([client.clearStore(), signOut(), router.push('/logout')]);
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
          }
          mixpanel.reset();
        },
      },
    },
    unauthenticated: {
      invoke: {
        src: (ctx) => (cb: (args: AppActions) => void) => {
          const id = setInterval(async () => {
            const user = await getCurrentSession();
            if (user) {
              cb({ type: 'ON_ALREADY_LOGGED_IN' });
            }
          }, 6000);

          if (typeof window !== 'undefined') {
            initMixpanel(ctx.app?.stage);
            mixpanel.identify();
          }

          return () => {
            clearInterval(id);
          };
        },
      },
      on: {
        ON_ALREADY_LOGGED_IN: { target: 'determineInitialAuthState' },
        ON_LOGIN: { target: 'loggingIn' },
      },
    },
  },
};

export const appMachine = createMachine(appSchema, {
  actions: {
    onAppHydrateContext,
    onInit,
    onInitDeviceInfo,
    onLanguageChange,
    onLogout,
    onOnlineChange,
    onPresetRoleFromCognito,
    onPurchasableSessionsAdd,
    onPurchasedSessionAdd,
    onPurchasedSessionSync,
    onRemoveSession,
    onSetMembershipInformation,
    onTabVisibility,
    onUpdateEvent,
    onUpdateEvents,
    onUpdateMeta,
    onUpdateModule,
    onUpdateModules,
    onUpdateSession,
    onUpdateXState,
    onWindowSizeChange,
  },
  guards: {},
});
