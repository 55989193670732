import { append, assertType, uniq } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import { store } from '../../store/_useStore';
import type { CartActions } from '../actionTypes';
import type { CartContext } from '../types';

export const onToggleSessionToCart = assign<CartContext, CartActions>((ctx, ev) => {
  assertType(ev, 'ON_TOGGLE_SESSION_TO_CART');
  const shouldRemoveFromCart = ctx.sessionIdsInCart.includes(ev.sessionId);
  store.getState().app.state.context.trackEvent({
    payload: { sessionId: ev.sessionId },
    type: shouldRemoveFromCart ? 'STORE_REMOVE_FROM_CART' : 'STORE_ADD_TO_CART',
  });

  return produce(ctx, (d) => {
    d.sessionIdsInCart = shouldRemoveFromCart
      ? d.sessionIdsInCart.filter((id) => id !== ev.sessionId)
      : uniq(append(ev.sessionId, d.sessionIdsInCart));
  });
});
