// eslint-disable-next-line no-restricted-imports
import { uuid } from '../id/uuid';

import { addNode } from './addNode';
import { findNextNodeFromCur } from './findNextNodeFromCur';
import { findStartNodeId } from './findStartNodeId';
import { graphqlXStateToXState } from './graphqlXStateToXState';
import type { IXStateState } from './xStateConstants';
import { getStartingXStateState } from './xStateConstants';

export const buildXStateFromModuleIds = (
  ids: string[],
  _startNodeId?: string,
  _initialXState?: IXStateState
): IXStateState => {
  let sessionXState = _initialXState || graphqlXStateToXState(getStartingXStateState());
  const startNodeId = _startNodeId || findStartNodeId(sessionXState)!;
  const target = findNextNodeFromCur(sessionXState[startNodeId]!)!;

  let source = startNodeId;
  for (const moduleId of ids) {
    const nodeId = uuid();
    sessionXState = addNode({ data: { moduleId }, id: nodeId, type: 'module' }, sessionXState, {
      source,
      target,
    });
    source = nodeId;
  }

  return sessionXState;
};
