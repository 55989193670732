import { unexpected } from '../error/unexpected';

import { isString } from './isString';

type Assert = (str: unknown, errorMsg?: string) => asserts str is string;

export const assertIsString: Assert = (str, errorMsg) => {
  if (!isString(str)) {
    throw unexpected({ name: errorMsg || 'ValueIsNotString' });
  }
};
