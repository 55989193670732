/* eslint-disable react-memo/require-memo */
import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';

import { adminMachine } from '../adminMachine/adminMachine';
import { adminSlice } from '../adminSlice/adminSlice';
import { appMachine } from '../appMachine/appMachine';
import { cardDeckMachine } from '../cardDeck/cardDeckMachine';
import { cartMachine } from '../cartMachine/cartMachine';
import { csdMachine } from '../contentSessionDriver/contentSessionMachine';
import { liveSessionMachine } from '../liveSessionDriver/liveSessionMachine';
import { sessionEditorMachine } from '../sessionEditor/sessionEditorMachine';
import { videoMachine } from '../videoMachine/videoMachine';
import { xstateToZ } from '../xstateToZ';

import type { StoreState } from './types';

/**
 * The store contains all stateful logic that's more complex than something
 * that can be managed by useState();
 *
 * If it helps manage an entire interface//module, we typically use xstate.
 */
// eslint-disable-next-line react-memo/require-memo
export const store = createStore<StoreState>((set) => ({
  admin: adminSlice(set),
  adminMachine: xstateToZ(adminMachine)(set, 'adminMachine'),
  app: xstateToZ(appMachine)(set, 'app'),
  cardDeck: xstateToZ(cardDeckMachine)(set, 'cardDeck'),
  cart: xstateToZ(cartMachine)(set, 'cart'),
  csd: xstateToZ(csdMachine)(set, 'csd'),
  live: xstateToZ(liveSessionMachine)(set, 'live'),
  sessionEditor: xstateToZ(sessionEditorMachine)(set, 'sessionEditor'),
  video: xstateToZ(videoMachine)(set, 'video'),
}));
export const _useStore = <U>(selector: (s: StoreState) => U, eqFn?: any): U =>
  useStore(store, selector, eqFn);
