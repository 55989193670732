import { isNotNil, uniq } from '@ours/utils';

import { displayKindLookup } from '../../../features/ContentSessionDisplay/lib/DisplayOnlyModule';
import { hStackDisplayKindLookup } from '../../../features/ContentSessionHStack/ContentSessionHStack';
import { interactionKindLookup } from '../../../features/ContentSessionInteraction/ContentSessionInteraction';
import { preloadDynamicComponent } from '../../../lib/nextjs/preloadDynamicComponent';
import { selectModuleById } from '../../store/lib/selectModuleById';
import { selectXStateModuleIds } from '../../store/lib/selectXStateModuleIds';
import type { CSDContext } from '../types';

export const onPreloadContentModules = (ctx: CSDContext) => {
  const moduleIds = selectXStateModuleIds(ctx.store.sessionMetadata.id);

  const usedInteractionKinds = uniq(
    moduleIds.map((id) => selectModuleById(id)?.interactionKind).filter(isNotNil)
  );
  const usedDisplayKinds = uniq(
    moduleIds.map((id) => selectModuleById(id)?.displayKind).filter(isNotNil)
  );

  usedInteractionKinds.forEach((kind) => {
    preloadDynamicComponent(interactionKindLookup[kind]);
  });
  usedDisplayKinds.forEach((kind) => {
    preloadDynamicComponent(displayKindLookup[kind]);
    preloadDynamicComponent(hStackDisplayKindLookup[kind]);
  });
};
