import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { SessionEditorActions, SessionEditorContext } from '../types';

export const onDragStart = assign<SessionEditorContext, SessionEditorActions>((ctx, ev) => {
  assertType(ev, 'ON_DRAG_START');

  return produce(ctx, (d) => {
    d.display.draggingNodeId = ev.nodeId;
  });
});
