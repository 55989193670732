import type { BrandColors } from '../generated/operations';

export const RainbowArray: BrandColors[] = [
  'orange',
  'pink',
  'yellow',
  'blue',
  'orange',
  'yellow',
  'orange',
  'blue',
  'yellow',
  'pink',
  'orange',
  'blue',
];
