import { RainbowArray, assertType, getItemAtRelIndex } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const onPurchasableSessionsAdd = assign<AppContext, AppActions>((ctx, ev) => {
  assertType(ev, 'ON_PURCHASABLE_SESSIONS_ADD');

  return produce(ctx, (draft) => {
    ev.sessions.forEach((s, idx) => {
      draft.content.sessionsForPurchaseById[s.id] = {
        ...s,
        color: getItemAtRelIndex(RainbowArray, idx),
      };
    });
  });
});
