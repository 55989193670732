import { assertType, now, uuid } from '@ours/utils';

import { store } from '../../store/_useStore';
import type { CSDActions, CSDContext } from '../types';

export const onScheduleEvent = (ctx: CSDContext, ev: CSDActions) => {
  const sideEffects = ctx._private.cbs?.sideEffects;
  assertType(ev, 'ON_SCHEDULE_EVENT');

  sideEffects?.onScheduleSessionClaimEvent(ev.event);
  const id = uuid();

  store.getState().app.send({
    event: {
      createdAt: now(),
      createdByUserId: '',
      endTime: ev.event.endDateTime,
      id,
      sessionId: ev.event.sessionId,
      startTime: ev.event.startDateTime,
    },
    eventId: id,
    type: 'ON_UPDATE_EVENT',
  });
};
