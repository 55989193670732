import type { Maybe } from '@ours/types';

import { entries } from '../lang/entries';
import { isString } from '../lang/isString';

const getDefaultValue = (cur: string, key: string): string | undefined => {
  const rgx = new RegExp(`({${key}).*?(?=})`);
  const matchedSubstring = cur.match(rgx);
  const firstValue = matchedSubstring?.[0];
  if (!matchedSubstring || !isString(firstValue)) {
    return cur;
  }
  const [, _defaultValue] = firstValue.split(',');
  return _defaultValue?.trim();
};

const replaceInstances = (cur: string, key: string, value: Maybe<string>): string => {
  const rgx = new RegExp(`({${key}).*?(?=})`);
  const search = cur.search(rgx);
  if (search === -1) {
    return cur;
  }

  const val = value || getDefaultValue(cur, key) || '';
  const next = cur.replace(new RegExp(`{${key}.*?}`), val);
  return replaceInstances(next, key, value);
};

export const replaceVariables = (str: string, dict: Record<string, Maybe<string>>): string => {
  return entries(dict).reduce((acc, [key, val]) => {
    return replaceInstances(acc, key, val);
  }, str);
};
