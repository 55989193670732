import { Box, Flex } from '@chakra-ui/react';
import { DownCaret } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';

import { HoverLink } from '../../../components/Links/HoverLink';
import { FOOTER_CONTENT } from '../footerConstants';

import { FooterListItem } from './FooterListItem';

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

export const PinkFooterDesktop: FC<Props> = memo(({ isOpen, toggle }) => {
  return (
    <Flex justifyContent="center" w="full">
      <Flex
        fontSize="400"
        justifyContent="space-between"
        maxW="full"
        pb={{ base: '100', md: '200' }}
        px={{ base: '0', md: '600' }}
        w="container.xl"
      >
        <Flex flexDir="column" flexGrow={1}>
          <Box as="nav" w="full">
            <Flex
              alignItems="center"
              as="ul"
              flexWrap="wrap"
              gap={{ base: '100', md: '200' }}
              justifyContent={{ base: 'center', sm: 'start' }}
              listStyleType="none"
            >
              {FOOTER_CONTENT.LINKS.map(({ isBold, link, text }) => (
                <FooterListItem
                  boxProps={isBold ? { display: { base: 'none', sm: 'block' } } : undefined}
                  isBold={isBold}
                  key={text}
                  link={link}
                  text={text}
                />
              ))}
            </Flex>
          </Box>
        </Flex>
        <HoverLink as="button" data-testid="PinkFooter.Toggle" onClick={toggle} type="button">
          {FOOTER_CONTENT.DISCLOSURES}{' '}
          <Box
            display="inline-block"
            px="100"
            transform={!isOpen ? `rotate(0deg)` : `rotate(180deg)`}
          >
            <DownCaret />
          </Box>
        </HoverLink>
      </Flex>
    </Flex>
  );
});
