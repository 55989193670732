import { Box, Flex, Text } from '@chakra-ui/react';
import { Colors } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

interface Props {
  text: string;
}

export const YellowCircleWithText: FC<Props> = memo(({ text }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      marginBottom="-2px"
      marginX="auto"
      maxW="80%"
      position="relative"
    >
      <Box bottom="0" left="0" pos="absolute" right="0" top="0">
        <Box alignItems="center" display="flex" h="full" justifyContent="center" pt="300">
          <Text
            fontSize={{ base: '500', md: '800', sm: '700' }}
            lineHeight={{ base: '500', md: '900', sm: '750' }}
            maxH="full"
            maxW={{ base: '62%', sm: '275px' }}
            textAlign="center"
          >
            {text}
          </Text>
        </Box>
      </Box>
      <Box display={{ base: 'block', md: 'none' }}>
        <svg
          fill="none"
          height="100%"
          viewBox="0 0 272 127"
          width="272"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M135.982 1.00001C118.258 0.994455 100.706 4.22357 84.3299 10.5029C67.9535 16.7823 53.0728 25.9888 40.538 37.5967C28.0032 49.2046 18.0598 62.9863 11.2758 78.1548C4.49176 93.3232 0.999999 109.581 1 126H271C270.994 109.585 267.498 93.3314 260.711 78.1678C253.924 63.0042 243.98 49.2275 231.446 37.6241C218.912 26.0207 204.033 16.8179 187.66 10.5412C171.286 4.26445 153.738 1.03674 136.018 1.0423"
            fill="#FFAB00"
          />
          <path
            d="M135.982 1.00001C118.258 0.994455 100.706 4.22357 84.3299 10.5029C67.9535 16.7823 53.0728 25.9888 40.538 37.5967C28.0032 49.2046 18.0598 62.9863 11.2758 78.1548C4.49176 93.3232 0.999999 109.581 1 126H271C270.994 109.585 267.498 93.3314 260.711 78.1678C253.924 63.0042 243.98 49.2275 231.446 37.6241C218.912 26.0207 204.033 16.8179 187.66 10.5412C171.286 4.26445 153.738 1.03674 136.018 1.0423"
            stroke="black"
            strokeMiterlimit="10"
          />
        </svg>
      </Box>
      <Box display={{ base: 'none', md: 'block' }}>
        <svg
          fill="none"
          height="100%"
          viewBox="0 0 477 182"
          width="477"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M238.468 1.00001C207.287 0.992015 176.409 5.64194 147.599 14.6842C118.789 23.7265 92.6096 36.9839 70.5576 53.6993C48.5056 70.4146 31.0126 90.2603 19.0778 112.103C7.14291 133.945 0.999998 157.357 1 181H476C475.989 157.362 469.839 133.957 457.899 112.122C445.96 90.2861 428.465 70.4475 406.414 53.7386C384.363 37.0298 358.188 23.7777 329.383 14.7393C300.577 5.70081 269.706 1.05291 238.532 1.06091"
            fill={Colors.brand.yellow}
          />
          <path
            d="M238.468 1.00001C207.287 0.992015 176.409 5.64194 147.599 14.6842C118.789 23.7265 92.6096 36.9839 70.5576 53.6993C48.5057 70.4146 31.0126 90.2603 19.0778 112.103C7.14291 133.945 0.999998 157.357 1 181H476C475.989 157.362 469.839 133.957 457.899 112.122C445.96 90.2861 428.465 70.4475 406.414 53.7386C384.363 37.0298 358.188 23.7777 329.383 14.7393C300.577 5.70081 269.706 1.05291 238.532 1.06091"
            stroke="black"
            strokeMiterlimit="10"
            strokeWidth={2}
          />
        </svg>
      </Box>
    </Flex>
  );
});
