import { assertType } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const onWindowSizeChange = assign<AppContext, AppActions>((ctx, ev) => {
  assertType(ev, 'WINDOW_SIZE_CHANGE');

  return produce(ctx, (draft) => {
    draft.device.windowSize.height = ev.height;
    draft.device.windowSize.width = ev.width;
  });
});
