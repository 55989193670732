import type { AppActions } from '../actionTypes';

export const listenToTabVisibility = (cb: (args: AppActions) => void) => {
  const handleVisibilityChange = () => {
    cb({ isVisible: !document.hidden, type: 'TAB_VISIBILITY_CHANGE' });
  };

  handleVisibilityChange();

  document.addEventListener('visibilitychange', handleVisibilityChange);

  return () => {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  };
};
