/* eslint-disable complexity */
import {
  browserName,
  browserVersion,
  isChrome,
  isDesktop,
  isEdge,
  isFirefox,
  isMobile,
  isSafari,
  isSmartTV,
  isTablet,
} from 'react-device-detect';

import type { AppActions } from '../actionTypes';

export const setupDeviceInfo = (cb: (args: AppActions) => void) => {
  const video = document.createElement('video');
  const isIOS =
    typeof navigator !== 'undefined' &&
    (/iPad|iPhone|iPod/.test(navigator.userAgent || '') ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));

  const category =
    // @see https://github.com/bendrucker/is-ios/blob/master/index.js
    isIOS && isDesktop
      ? 'tablet'
      : isDesktop
      ? 'desktop'
      : isTablet
      ? 'tablet'
      : isMobile
      ? 'mobile'
      : isSmartTV
      ? 'tv'
      : 'unknown';

  cb({
    category,
    isChrome,
    isEdge,
    isFirefox,
    isSafari,
    name: browserName,
    supports: {
      // @ts-expect-error video has this in safari
      fullScreenVideo: !!(video.webkitEnterFullScreen || video.requestFullscreen),
      hover: matchMedia('(hover: hover)').matches,
      touch:
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        // @ts-expect-error video has this in safari
        navigator.msMaxTouchPoints > 0,
      webShare: !!(
        window.navigator.canShare &&
        window.navigator.canShare({
          files: [],
          text: 'testing',
          title: 'testing',
          url: window.location.href,
        })
      ),
    },
    type: 'INIT_DEVICE_INFO',
    version: browserVersion,
  });
};
