import { unexpected } from '../error/unexpected';
import type { Stage } from '../generated/operations';

const apiEndpoints = {
  dev: 'wss://s6fll79z02.execute-api.us-west-2.amazonaws.com/dev',
  local: 'wss://6b87vmktie.execute-api.us-west-2.amazonaws.com/dev',
  production: 'wss://l1eoaes8qg.execute-api.us-west-2.amazonaws.com/dev',
};

export const getSignalingEndpoints = (stageEnv: Stage): string => {
  const result = apiEndpoints[stageEnv];

  if (!result) {
    throw unexpected({ name: 'MissingSignalingEndpoint' });
  }

  return result;
};
