import { pick } from '@ours/utils';
import { produce } from 'immer';
import { interpret } from 'xstate';
import type { Interpreter, State, StateMachine } from 'xstate';

export type Store<M> = M extends StateMachine<infer Context, infer Schema, infer Event, infer State>
  ? {
      send: Interpreter<Context, Schema, Event, State>['send'];
      state: Pick<
        ReturnType<Interpreter<Context, Schema, Event, State>['getSnapshot']>,
        'context' | 'value' | 'toStrings'
      >;
    }
  : never;
const pickXState = (s: State<any, any, any, any, any>) =>
  pick(s, ['context', 'value', 'toStrings']);

// @see https://github.com/biowaffeln/zustand-middleware-xstate/blob/master/lib/xstate.ts
export const xstateToZ =
  <M extends StateMachine<any, any, any, any, any, any, any>>(machine: M) =>
  (set: any, key: string): Store<M> => {
    const service = interpret(machine)
      .onTransition((state) => {
        const initialStateChanged =
          state.changed === undefined && Object.keys(state.children).length;

        if (state.changed || initialStateChanged) {
          set((oldState: any) => {
            if (!oldState) {
              return oldState;
            }
            return produce(oldState, (draft: any) => {
              draft[key].state = pickXState(state);
            });
          });
        }
      })
      .start();

    return {
      send: service.send,
      state: pickXState(service.getSnapshot()),
    } as Store<M>;
  };
