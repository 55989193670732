import type { Maybe } from '@ours/types';
import { isNotEmpty } from '@ours/utils';

export const arrayTranslator = {
  fromArray: (options: Maybe<readonly string[]>) => {
    return (options || []).filter(isNotEmpty).join('|');
  },
  toArray: (str: string): readonly string[] => {
    return str.split('|').filter(isNotEmpty);
  },
};
