import { gql } from 'graphql-tag';

import type { SyncCustomerBillingInfoQuery } from '../../../generated/custom-hooks';
import { SyncCustomerBillingInfoDocument } from '../../../generated/custom-hooks';
import { focusAwarePolling } from '../../../lib/apollo/focusAwarePolling';
import type { CartActions } from '../actionTypes';

gql`
  query SyncCustomerBillingInfo {
    getUserPaymentMethods {
      ...PaymentMethod
    }
    getUserStripeBillingPortalLink
  }
`;

export const syncCustomersBillingInfo = (cb: (args: CartActions) => void) => {
  const onComplete = (data: SyncCustomerBillingInfoQuery) => {
    cb({ link: data.getUserStripeBillingPortalLink, type: 'ON_ADD_BILLING_LINK' });
    cb({ methods: [...(data.getUserPaymentMethods || [])], type: 'ON_SYNC_BILLING_METHODS' });
  };

  return focusAwarePolling<SyncCustomerBillingInfoQuery>({
    onComplete,
    pollInterval: 6000,
    query: SyncCustomerBillingInfoDocument,
    skip: false,
  });
};
