import { assign } from 'xstate';

import type { CSDActions, CSDContext } from '../types';

export const onShouldGatherAV = assign<CSDContext, CSDActions>((ctx) => {
  return {
    ...ctx,
    store: {
      ...ctx.store,
      display: { ...ctx.store.display, shouldGatherAV: true },
    },
  };
});
