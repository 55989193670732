import type { PurchasableSessionsFragment } from '@ours/utils';
import { isArray } from '@ours/utils';

import type { SessionsForPurchase } from '../types';

export function normalizeGQLPurchaseSession(
  gqlPurchaseSession: Omit<PurchasableSessionsFragment, '__typename'>
): SessionsForPurchase;
export function normalizeGQLPurchaseSession(
  gqlPurchaseSession: Omit<PurchasableSessionsFragment, '__typename'>[]
): SessionsForPurchase[];

export function normalizeGQLPurchaseSession(
  gql:
    | Omit<PurchasableSessionsFragment, '__typename'>
    | Omit<PurchasableSessionsFragment, '__typename'>[]
): SessionsForPurchase | SessionsForPurchase[] {
  if (isArray(gql)) {
    return gql.map((item) => normalizeEntity(item));
  }

  return normalizeEntity(gql);
}

const normalizeEntity = (gql: PurchasableSessionsFragment): SessionsForPurchase => {
  return {
    color: 'blue',
    guideHeadshots: [...(gql.resolvedValues?.guideHeadshots || [])],
    id: gql.id,
    purchasePriceCents: gql.purchasePriceCents || 0,
    sessionCategories: [...(gql.sessionCategories || [])],
    sessionDescription: gql.sessionDescription || '',
    title: gql.title || '',
    type: gql.type,
  };
};
