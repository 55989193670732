import type { conditionsOptions } from '@ours/utils';

import { completedSessionCountDecision } from './decisionFunctions/completedSessionCountDecision';
import { moduleResponseDecision } from './decisionFunctions/moduleResponseDecision';
import type { IConditionConfiguration } from './types';

export const conditionOptionsConfiguration: Record<
  keyof typeof conditionsOptions,
  IConditionConfiguration
> = {
  completedSessionCount: { decide: completedSessionCountDecision },
  moduleResponse: { decide: moduleResponseDecision },
};
