/* eslint-disable react-memo/require-memo */
import type { LoadableComponent } from '@loadable/component';
import loadable from '@loadable/component';
import type { InteractionKind } from '@ours/utils';
import { unexpected } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

import { useCSDModuleById } from '../../state/contentSessionDriver/selectors/modules/useCSDModuleById';

import type { ModuleId } from './lib/types';

const opts = { ssr: false };

export const interactionKindLookup: Record<InteractionKind, LoadableComponent<ModuleId>> = {
  AudioRecording: loadable(
    () =>
      import('./lib/AudioRecordingInteraction/AudioRecordingInteraction').then(
        (c) => c.AudioRecordingInteraction
      ),
    opts
  ),
  Button: loadable(() => import('./lib/ButtonInteraction').then((c) => c.ButtonInteraction), opts),
  Camera: loadable(() => import('./lib/CameraInteraction').then((c) => c.CameraInteraction), opts),
  Discussion: loadable(
    () => import('./lib/DiscussionInteraction').then((c) => c.DiscussionInteraction),
    opts
  ),
  Downloadable: loadable(
    () => import('./lib/DownloadableInteraction').then((c) => c.DownloadableInteraction),
    opts
  ),
  Drawing: loadable(
    () => import('./lib/DrawingInteraction/DrawingInteraction').then((c) => c.DrawingInteraction),
    opts
  ),
  ImageUpload: loadable(
    () =>
      import('./lib/ImageUploadInteraction/ImageUploadInteraction').then(
        (c) => c.ImageUploadInteraction
      ),
    opts
  ),
  InAppPurchase: loadable(
    () =>
      import('./lib/InAppPurchaseInteraction/InAppPurchaseInteraction').then(
        (c) => c.InAppPurchaseInteraction
      ),
    opts
  ),
  Input: loadable(() => import('./lib/InputInteraction').then((c) => c.InputInteraction), opts),
  Journal: loadable(
    () => import('./lib/JournalInteraction').then((c) => c.JournalInteraction),
    opts
  ),
  JournalSeparate: loadable(
    () =>
      import('./lib/JournalSeparateInteraction/JournalSeparateInteraction').then(
        (c) => c.JournalSeparateInteraction
      ),
    opts
  ),
  MacroModules: loadable(
    () =>
      import('./lib/MacroModuleInteraction/MacroModuleInteraction').then(
        (c) => c.MacroModuleInteraction
      ),
    opts
  ),
  MultipleChoice: loadable(
    () => import('./lib/MultipleChoiceInteraction').then((c) => c.MultipleChoiceInteraction),
    opts
  ),
  NumberSelect: loadable(
    () => import('./lib/NumberRankingInteraction').then((c) => c.NumberRankingInteraction),
    opts
  ),
  OpenEndedText: loadable(
    () => import('./lib/OpenEndedTextInteraction').then((c) => c.OpenEndedTextInteraction),
    opts
  ),
  Photobooth: loadable(
    () =>
      import('./lib/PhotoboothInteraction/PhotoboothInteraction').then(
        (c) => c.PhotoboothInteraction
      ),
    opts
  ),
  PricingBlock: loadable(
    () =>
      import('./lib/PricingBlockInteraction/PricingBlockInteraction').then(
        (c) => c.PricingBlockInteraction
      ),
    opts
  ),
  QuizMatching: loadable(
    () => import('./lib/QuizMatchingInteraction').then((c) => c.QuizMatchingInteraction),
    opts
  ),
  Scheduler: loadable(
    () =>
      import('./lib/SchedulerInteraction/SchedulerInteraction').then((c) => c.SchedulerInteraction),
    opts
  ),
  SentenceBuilder: loadable(
    () =>
      import('./lib/SentenceBuilderInteraction/SentenceBuilderInteraction').then(
        (c) => c.SentenceBuilderInteraction
      ),
    opts
  ),
  TrueFalseButton: loadable(
    () => import('./lib/TrueFalseButtonInteraction').then((c) => c.TrueFalseButtonInteraction),
    opts
  ),
  VideoRecording: loadable(
    () =>
      import('./lib/VideoRecordingInteraction/VideoRecordingInteraction').then(
        (c) => c.VideoRecordingInteraction
      ),
    opts
  ),
};

export const ContentSessionInteraction: FC<ModuleId> = memo(({ moduleId }) => {
  const module = useCSDModuleById(moduleId);
  const interactionKind = module.interactionKind;

  if (!interactionKind) {
    throw unexpected({ name: 'ContentSessionInteractionMissingKind' });
  }
  const Component = interactionKindLookup[interactionKind];

  return Component ? <Component moduleId={moduleId} /> : null;
});
