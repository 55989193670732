import { produce } from 'immer';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import { defaultMembership } from '../lib/defaultMembership';
import type { AppContext } from '../types';

export const onLogout = assign<AppContext, AppActions>((ctx) => {
  return produce(ctx, (draft) => {
    draft.content.user = undefined;
    draft.content.membership = defaultMembership;
    draft.content.sessionsById = {};
    draft.content.modulesById = {};
  });
});
