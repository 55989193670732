export const REQUESTED_LINK_CONTENT = {
  DIDNT_WORK: 'Can’t find the magic email? Check your spam or',
  DIDNT_WORK_CTA: 'Contact Us',
  EMAIL_LN_1: 'We sent you a code{email}.',
  EMAIL_LN_2: 'This code expires soon, so go check it out.',
  TITLE_TAG: 'Requested Link',
  WRONG_EMAIL: 'Wrong email address?',
  WRONG_EMAIL_CTA: 'Edit and Resend',
  YELLOW_BOX: 'Check Your Email',
};
