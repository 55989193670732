import type { PinInputProps as ChakraPinInputProps } from '@chakra-ui/react';
import { Box, PinInput as ChakraPinInput, HStack, PinInputField, Spinner } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo, useMemo } from 'react';

export interface PinInputProps {
  autoFocus?: boolean;
  loading?: boolean;
  onChange?: (val: string) => void;
  onComplete: (val: string) => void;
  quantity: number;
  type?: ChakraPinInputProps['type'];
}

export const PinInput: FC<PinInputProps> = memo(
  ({ autoFocus = true, loading, onChange, onComplete, quantity, type = 'number' }) => {
    const arr = useMemo(() => Array(quantity).fill(1), [quantity]);
    if (loading) {
      return (
        <Box h="50px">
          <Spinner />
        </Box>
      );
    }

    return (
      <HStack gap={{ base: '0px', md: '50' }}>
        <ChakraPinInput
          autoFocus={autoFocus}
          onChange={onChange}
          onComplete={onComplete}
          otp
          size={'md'}
          type={type}
        >
          {arr.map((_, idx) => (
            <PinInputField
              borderBottomLeftRadius={{ base: '10px', md: '600' }}
              borderColor="black"
              borderTopRightRadius={{ base: '10px', md: '600' }}
              h={{ base: '40px', md: '50px' }}
              key={idx}
              w={{ base: '40px', md: '50px' }}
            />
          ))}
        </ChakraPinInput>
      </HStack>
    );
  }
);
