import type { IfBooleanNode } from '@ours/utils';
import { CONDITION_PROPERTY, assertType, xStateToGraphQLXState } from '@ours/utils';
import { gql } from 'graphql-tag';

import { OnSessionLogicChangeDocument } from '../../../generated/custom-hooks';
import type {
  OnSessionLogicChangeMutation,
  OnSessionLogicChangeMutationVariables,
} from '../../../generated/custom-hooks';
import { getApolloClient } from '../../../lib/apollo/getApolloClient';
import { sendOnUpdateXState } from '../../appMachine/externalActions/sendOnUpdateXState';
import { selectXStateBySessionId } from '../../store/lib/selectXStateBySessionId';
import type { SessionEditorActions, SessionEditorContext } from '../types';

gql`
  mutation OnSessionLogicChange($id: UUID!, $xState: [XStateInput!]!) {
    putSessionXState(id: $id, xState: $xState) {
      id
      resolvedValues {
        sessionXState {
          ...XState
        }
      }
    }
  }
`;
export const onIfBooleanEdit = (ctx: SessionEditorContext, ev: SessionEditorActions) => {
  assertType(ev, 'ON_IF_BOOLEAN_EDIT');
  const nodeId = ctx.display.focusedNodeId;
  const sessionId = ctx.store.sessionId;
  const staleXState = selectXStateBySessionId(ctx.store.sessionId);
  const node = staleXState[nodeId] as IfBooleanNode;
  const nextXState = {
    ...staleXState,
    [nodeId]: {
      ...node,
      data: { [CONDITION_PROPERTY]: ev.cond },
    },
  };
  const client = getApolloClient();
  client.mutate<OnSessionLogicChangeMutation, OnSessionLogicChangeMutationVariables>({
    context: { debounceKey: 'xstateEditing' },
    mutation: OnSessionLogicChangeDocument,
    variables: {
      id: sessionId,
      xState: xStateToGraphQLXState(nextXState),
    },
  });
  sendOnUpdateXState({ sessionId, xState: nextXState });
};
