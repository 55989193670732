/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { isBoolean, isFinite, isObject, safelyParseJSON } from '@ours/utils';

import { videoPlaybackQuality, videoPlaybackRate } from '../types';
import type { IVideoSettings } from '../types';

export const initialVideoSettings: IVideoSettings = {
  displayCC: false,
  playbackQuality: 'Auto',
  playbackRate: 1,
  volume: 1,
};

const LS_LOCATION = 'videoSettingsLocation20220211';
export const videoSettingsLocalStorage = {
  getAllWithDefaults(): IVideoSettings {
    const data = localStorage.getItem(LS_LOCATION);
    if (!data) {
      return { ...initialVideoSettings };
    }

    const parsedData = safelyParseJSON(data);
    if (!isObject(parsedData)) {
      return { ...initialVideoSettings };
    }
    const playbackRate =
      // @ts-expect-error use zod or io-types for this
      'playbackRate' in parsedData && videoPlaybackRate.includes(parsedData.playbackRate)
        ? parsedData.playbackRate
        : initialVideoSettings.playbackRate;
    const playbackQuality =
      // @ts-expect-error use zod or io-types for this
      'playbackQuality' in parsedData && videoPlaybackQuality.includes(parsedData.playbackQuality)
        ? parsedData.playbackQuality
        : initialVideoSettings.playbackQuality;
    // @ts-expect-error use zod or io-types for this
    const volume: number =
      'volume' in parsedData &&
      isFinite(parsedData.volume) &&
      // @ts-expect-error use zod or io-types for this
      parsedData.volume >= 0 &&
      // @ts-expect-error use zod or io-types for this
      parsedData.volume <= 1
        ? parsedData.volume
        : initialVideoSettings.volume;

    const displayCC =
      'displayCC' in parsedData && isBoolean(parsedData.displayCC)
        ? parsedData.displayCC
        : initialVideoSettings.displayCC;
    const result = { displayCC, playbackQuality, playbackRate, volume };

    // @ts-expect-error use zod or io-types for this
    return result;
  },
  setByKey<T extends keyof IVideoSettings, V extends IVideoSettings[T]>(key: T, value: V) {
    const cur = this.getAllWithDefaults();

    localStorage.setItem(LS_LOCATION, JSON.stringify({ ...cur, [key]: value }));
  },
};
