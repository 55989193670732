import { Center, Flex } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

import { SquiggleLoader } from './lib/SquiggleLoader';

export const FullCenteredSquiggleLoader: FC = memo((props) => {
  return (
    <Center w="full" {...props}>
      <Flex alignItems="center" h="100vh" justifyContent="center" w="full">
        <SquiggleLoader />
      </Flex>
    </Center>
  );
});
