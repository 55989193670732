/* eslint-disable max-lines-per-function */
import { Box, Center, Flex, Spinner, Text } from '@chakra-ui/react';
import { COMPANY_MAIL_TO_LINK } from '@ours/utils';
import { OrangeCheck } from '@ours/web-icons';
import type { FC } from 'react';
import { memo, useEffect } from 'react';

import { PinInput } from '../../../components/Inputs/PinInput';
import { BlueLink } from '../../../components/Links/BlueLink';
import { OursContainer } from '../../../components/OursContainer/OursContainer';
import { YellowCircleWithText } from '../../../components/YellowCircleWithText/YellowCircleWithText';
import { useAppActions } from '../../../state/appMachine/externalActions/useAppActions';
import { PinkFooter } from '../../PinkFooter/PinkFooter';
import { LoginWhiteBox } from '../lib/LoginWhiteBox';

import { REQUESTED_LINK_CONTENT } from './requestedLinkContent';

export interface Props {
  email: string;
  loading: boolean;
  onComplete: (code: string) => void;
  onToLogin: () => void;
}

export const RequestedLink: FC<Props> = memo(({ email, loading, onComplete, onToLogin }) => {
  const { onSetTitle } = useAppActions();
  useEffect(() => {
    onSetTitle(REQUESTED_LINK_CONTENT.TITLE_TAG);
  }, [onSetTitle]);

  return (
    <Flex
      data-testid="RequestedLink"
      flexDir="column"
      h="full"
      justifyContent="space-between"
      w="full"
    >
      <Box as="section" pos="relative" py={{ base: '300', md: '500' }} zIndex="1">
        <OursContainer>
          <YellowCircleWithText text={REQUESTED_LINK_CONTENT.YELLOW_BOX} />
          <LoginWhiteBox>
            <Flex flexDir="column" gap="300" w="full">
              <Box sx={{ svg: { h: '55px !important' } }}>
                <OrangeCheck />
              </Box>

              <Box textStyle={{ base: 'sm', md: 'md' }}>
                <Text data-testid="RequestedLink.emailLine">
                  {REQUESTED_LINK_CONTENT.EMAIL_LN_1.replace(
                    '{email}',
                    email ? ` to ${email}` : ''
                  )}
                </Text>
                <Center pt="200">
                  {loading ? (
                    <Box h="50px">
                      <Spinner />
                    </Box>
                  ) : (
                    <PinInput onComplete={onComplete} quantity={6} />
                  )}
                </Center>
              </Box>

              <Box textStyle={{ base: '2xs', md: 'xs' }}>
                <Text>
                  {REQUESTED_LINK_CONTENT.WRONG_EMAIL}{' '}
                  <BlueLink as="button" onClick={onToLogin} type="button">
                    {REQUESTED_LINK_CONTENT.WRONG_EMAIL_CTA}
                  </BlueLink>
                </Text>
                <Text>
                  {REQUESTED_LINK_CONTENT.DIDNT_WORK}{' '}
                  <BlueLink as="a" href={COMPANY_MAIL_TO_LINK} target="_blank">
                    {REQUESTED_LINK_CONTENT.DIDNT_WORK_CTA}
                  </BlueLink>
                </Text>
              </Box>
            </Flex>
          </LoginWhiteBox>
        </OursContainer>
      </Box>
      <PinkFooter />
    </Flex>
  );
});
