import { assertIsObject, assertIsUUID, assertType, now, uuid } from '@ours/utils';
import { produce } from 'immer';
import { assign } from 'xstate';

import { selectModuleById } from '../../store/lib/selectModuleById';
import type { CSDActions, CSDContext, LocalModuleResponse } from '../types';

export const onAnswerChangeMedia = assign<CSDContext, CSDActions>((ctx, ev) => {
  assertType(ev, 'ON_MODULE_MEDIA_ANSWER_CHANGE');
  const response = ctx.store.interaction.moduleResponseDraft;
  const sessionId = ctx.store.sessionMetadata.id;
  assertIsUUID(ctx.store.display.currentModuleId);
  const module = selectModuleById(ctx.store.display.currentModuleId);
  assertIsObject(module);

  return produce(ctx, (d) => {
    const formattedResponse: LocalModuleResponse = {
      createdAt: now(),
      id: uuid(),
      moduleId: module.id || '',
      sessionId,
      ...response,
      ...ev.args,
      label: response?.label || module.openEndedPrompt,
      value: ev.args.mediaCaption || response?.value,
      valueDescription: ev.args.mediaCaption || response?.valueDescription,
    };
    d.store.interaction.moduleResponseDraft = formattedResponse;
  });
});
