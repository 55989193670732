import {
  assertIsUUID,
  assertType,
  cloneDeep,
  findStartNodeId,
  removeNodeFromXState,
  xStateToGraphQLXState,
} from '@ours/utils';
import { gql } from 'graphql-tag';
import { produce } from 'immer';
import { assign } from 'xstate';

import { OnDeleteNodeDocument } from '../../../generated/custom-hooks';
import type {
  OnDeleteNodeMutation,
  OnDeleteNodeMutationVariables,
} from '../../../generated/custom-hooks';
import { getApolloClient } from '../../../lib/apollo/getApolloClient';
import { sendOnUpdateXState } from '../../appMachine/externalActions/sendOnUpdateXState';
import { selectXStateBySessionId } from '../../store/lib/selectXStateBySessionId';
import type { SessionEditorActions, SessionEditorContext } from '../types';

gql`
  mutation OnDeleteNode($id: UUID!, $xState: [XStateInput!]!) {
    putSessionXState(id: $id, xState: $xState) {
      id
      resolvedValues {
        sessionXState {
          ...XState
        }
      }
    }
  }
`;
export const onDeleteNode = assign<SessionEditorContext, SessionEditorActions>((ctx, ev) => {
  assertType(ev, 'ON_DELETE_NODE');
  const sessionId = ctx.store.sessionId;
  const selectedNodeIds = ctx.display.selectedNodeIds;
  const xState = selectXStateBySessionId(ctx.store.sessionId);

  let nextXState = cloneDeep(xState);
  selectedNodeIds.forEach((nodeId) => {
    nextXState = removeNodeFromXState(nodeId, nextXState);
  });

  const client = getApolloClient();
  client.mutate<OnDeleteNodeMutation, OnDeleteNodeMutationVariables>({
    mutation: OnDeleteNodeDocument,
    variables: { id: sessionId, xState: xStateToGraphQLXState(nextXState) },
  });

  const firstNodeId = findStartNodeId(nextXState);
  assertIsUUID(firstNodeId);

  sendOnUpdateXState({ sessionId: ctx.store.sessionId, xState: nextXState });

  return produce(ctx, (d) => {
    d.display.selectedNodeIds = [firstNodeId];
    d.display.focusedNodeId = firstNodeId;
  });
});
