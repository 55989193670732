import type { MachineConfig, StateMachine } from 'xstate';
import { createMachine } from 'xstate';

import { isImpersonationMode } from './actions/isImpersonationMode';
import { onAddBookmark } from './actions/onAddBookmark';
import { onAnswerChangeMedia } from './actions/onAnswerChangeMedia';
import { onAnswerChangeValue } from './actions/onAnswerChangeValue';
import { onChangeJoiningOrder } from './actions/onChangeJoiningOrder';
import { onChangeLocalIcon } from './actions/onChangeLocalIcon';
import { onChangeLocalName } from './actions/onChangeLocalName';
import { onGrantAVPermissions } from './actions/onGrantAVPermissions';
import { onHome } from './actions/onHome';
import { onInit } from './actions/onInit';
import { onInitPreviewForSession } from './actions/onInitPreviewForSession';
import { onInvalidAnswerToast } from './actions/onInvalidAnswerToast';
import { onModuleRatingChange } from './actions/onModuleRatingChange';
import { onNext } from './actions/onNext';
import { onPreloadContentModules } from './actions/onPreloadContentModules';
import { onPrev } from './actions/onPrev';
import { onRateSession } from './actions/onRateSession';
import { onRemoveBookmark } from './actions/onRemoveBookmark';
import { onReset } from './actions/onReset';
import { onResumeSession } from './actions/onResumeSession';
import { onSaveAnswer } from './actions/onSaveAnswer';
import { onScheduleEvent } from './actions/onScheduleEvent';
import { onSetCurrentMedia } from './actions/onSetCurrentMedia';
import { onShouldGatherAV } from './actions/onShouldGatherAV';
import { onSyncNextNodeId } from './actions/onSyncNextNodeId';
import { onToggleTranscript } from './actions/onToggleTranscript';
import { onTransitionComplete } from './actions/onTransitionComplete';
import { onUpdateJoiningOrder } from './actions/onUpdateJoiningOrder';
import { onUpdatePeers } from './actions/onUpdatePeers';
import { canProgressSession } from './guards/canProgressSession';
import { isInvalidSession } from './guards/isInvalidSession';
import { isNoBookmarkForCurrentModule } from './guards/isNoBookmarkForCurrentModule';
import { isValidAnswer } from './guards/isValidAnswer';
import { shouldGatherAVPermissions } from './guards/shouldGatherAVPermissions';
import { listenToYJS } from './listeners/listenToYJS';
import { listenToYJSJoiningArr } from './listeners/listenToYJSJoiningArr';
import { listenToYJSNodeId } from './listeners/listenToYJSNodeId';
import { listenToYJSPeers } from './listeners/listenToYJSPeers';
import { monitorAVPermissions } from './listeners/monitorAVPermissions';
import { ON_ADD_BOOKMARK } from './transitions/ON_ADD_BOOKMARK';
import { ON_MODULE_MEDIA_ANSWER_CHANGE } from './transitions/ON_MODULE_MEDIA_ANSWER_CHANGE';
import { ON_MODULE_TEXT_ANSWER_CHANGE } from './transitions/ON_MODULE_TEXT_ANSWER_CHANGE';
import { ON_REMOVE_BOOKMARK } from './transitions/ON_REMOVE_BOOKMARK';
import { ON_SAVE_ANSWER } from './transitions/ON_SAVE_ANSWER';
import { ON_SET_CURRENT_MEDIA_ELEMENT } from './transitions/ON_SET_CURRENT_MEDIA_ELEMENT';
import { RESUME_SESSION } from './transitions/RESUME_SESSION';
import { SYNC_NEXT_NODE_ID } from './transitions/SYNC_NEXT_NODE_ID';
import type { CSDActions, CSDContext } from './types';

const csdSchema: MachineConfig<CSDContext, any, CSDActions> = {
  context: {
    _private: { cbs: {}, content: {} },
    store: { display: {}, interaction: {}, sessionMetadata: {} },
  } as any,
  description: '',
  id: 'csdMachine',
  initial: 'init',
  on: {
    INIT: [
      { cond: 'isInvalidSession', target: 'error' },
      { actions: 'onInit', target: 'inSession' },
    ],
    ON_INIT_PREVIEW_FOR_SESSION: { actions: ['onInitPreviewForSession'] },
    RESET: { actions: 'onReset', target: 'init' },
  },
  predictableActionArguments: true,
  states: {
    error: {},

    inSession: {
      invoke: {
        src: (ctx) => (cb: (args: CSDActions) => void) => {
          const cleanup = monitorAVPermissions(cb);
          const cleanupPeers = listenToYJSPeers(ctx, cb);
          const cleanupNodeId = listenToYJSNodeId(ctx, cb);
          const cleanupYJS = listenToYJS(ctx, cb);
          const cleanupYJSJoiningArr = listenToYJSJoiningArr(ctx, cb);

          return () => {
            cleanupPeers();
            cleanupYJSJoiningArr();
            cleanupNodeId();
            cleanupYJS();
            cleanup();
          };
        },
      },
      on: {
        ON_ADD_BOOKMARK,
        ON_CHANGE_JOINING_ORDER: { actions: 'onChangeJoiningOrder' },
        ON_CHANGE_LOCAL_ICON: { actions: 'onChangeLocalIcon' },
        ON_CHANGE_LOCAL_NAME: { actions: 'onChangeLocalName' },
        ON_GRANT_AV_PERMISSIONS: { actions: 'onGrantAVPermissions' },
        ON_HOME: { actions: 'onHome' },
        ON_MODULE_MEDIA_ANSWER_CHANGE,
        ON_MODULE_RATING_CHANGE: { actions: 'onModuleRatingChange' },
        ON_MODULE_TEXT_ANSWER_CHANGE,
        ON_NEXT: [
          { cond: (ctx) => !canProgressSession(ctx) },
          {
            actions: () => {
              // eslint-disable-next-line no-console
              console.log('is invalid draft answer');
            },
            cond: 'isInvalidDraftAnswer',
          },
          { actions: ['onNext'] },
        ],
        ON_PREV: [{ cond: (ctx) => !canProgressSession(ctx) }, { actions: ['onPrev'] }],
        ON_RATE_SESSION: { actions: 'onRateSession' },
        ON_REMOVE_BOOKMARK,
        ON_SAVE_ANSWER,
        ON_SCHEDULE_EVENT: { actions: ['onScheduleEvent'] },
        ON_SET_CURRENT_MEDIA_ELEMENT,
        ON_TOGGLE_TRANSCRIPT: { actions: 'onToggleTranscript' },
        ON_TRANSITION_COMPLETE: { actions: 'onTransitionComplete' },
        RESUME_SESSION,
        START_AFTER_AV_PERMISSIONS: { actions: 'onResumeSession' },
        SYNC_JOINING_ORDER: { actions: 'onUpdateJoiningOrder' },
        SYNC_NEXT_NODE_ID,
        SYNC_PEERS: { actions: 'onUpdatePeers' },
      },
    },

    init: {},
  },
};

export const csdMachine: StateMachine<CSDContext, any, CSDActions> = createMachine(csdSchema, {
  actions: {
    onAddBookmark,
    onAnswerChangeMedia,
    onAnswerChangeValue,
    onChangeJoiningOrder,
    onChangeLocalIcon,
    onChangeLocalName,
    onGrantAVPermissions,
    onHome,
    onInit,
    onInitPreviewForSession,
    onInvalidAnswerToast,
    onModuleRatingChange,
    onNext,
    onPreloadContentModules,
    onPrev,
    onRateSession,
    onRemoveBookmark,
    onReset,
    onResumeSession,
    onSaveAnswer,
    onScheduleEvent,
    onSetCurrentMedia,
    onShouldGatherAV,
    onSyncNextNodeId,
    onToggleTranscript,
    onTransitionComplete,
    onUpdateJoiningOrder,
    onUpdatePeers,
  },
  guards: {
    isBookmarkForCurrentModule: (ctx) => !isNoBookmarkForCurrentModule(ctx),
    isImpersonationMode,
    isInvalidDraftAnswer: (ctx) => isValidAnswer(ctx) !== true,
    isInvalidSession,
    isNoBookmarkForCurrentModule,
    shouldGatherAVPermissions,
  },
});
