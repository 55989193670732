import type { TooltipProps } from '@chakra-ui/react';
import { Tooltip as ChakraTooltip } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

interface Props {
  children: ReactNode;
  color?: 'white' | 'brand.orange';
  isEnabled?: boolean;
  label: string;
  tooltipProps?: Omit<TooltipProps, 'children'>;
}

export const Tooltip: FC<Props> = memo(
  ({ children, color = 'white', isEnabled = true, label, tooltipProps }) => {
    if (!isEnabled) {
      return <>{children}</>;
    }

    return (
      <ChakraTooltip
        {...tooltipProps}
        arrowShadowColor="white"
        bg={color}
        border="1px solid black"
        borderRadius="8px"
        color="black"
        fontSize="14px"
        fontWeight="400"
        hasArrow
        label={label}
        lineHeight="16px"
        px="10px"
        py="5px"
        sx={{
          '--popper-arrow-shadow-color': 'black',
        }}
      >
        {children}
      </ChakraTooltip>
    );
  }
);
