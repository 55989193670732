import { assertIsObject } from '@ours/utils';

import { selectModuleById } from '../../store/lib/selectModuleById';
import { valueValidators } from '../helpers/valueValidators';
import type { CSDContext } from '../types';

export const isValidAnswer = (ctx: CSDContext): boolean | string => {
  const currentModuleId = ctx.store.display.currentModuleId;
  const draft = ctx.store.interaction.moduleResponseDraft;
  const module = selectModuleById(currentModuleId || '');
  assertIsObject(module, 'IsValidAnswerMissingModule');

  if (module.layout === 'DisplayOnly') {
    return true;
  }

  const interactionKind = module.interactionKind || 'Button';
  const inputType = module.inputType || 'text';
  const validator = valueValidators[interactionKind === 'Input' ? inputType : interactionKind];
  return validator(draft?.value || '');
};
