import { assertNotNil } from '../lang/assertNotNil';
import { entries } from '../lang/entries';

import type { IXStateState } from './xStateConstants';

export const findFinalNodeId = (xStateState: IXStateState) => {
  const id = entries(xStateState).find(([, { type }]) => type === 'final')?.[0];
  assertNotNil(id, 'No final node found');
  return id;
};
