import { isEqual } from '@ours/utils';

import { _useStore } from '../../store/_useStore';
import type { StoreState } from '../../store/types';

const select = (s: StoreState) => {
  return s.app.state.context.content.user?.userId;
};

export const useUserId = () => {
  return _useStore(select, isEqual);
};
