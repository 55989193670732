import type { FC } from 'react';
import { memo } from 'react';

interface Props {}

export const SplitLogoUS: FC<Props> = memo(() => {
  return (
    <svg
      fill="none"
      // height="388"
      viewBox="0 0 243 388"
      width="243"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M134.818 27.1067L185.15 116.118L136.883 143.413L86.5509 54.4016L39.3955 81.0623L89.7343 170.073C100.426 188.979 118.189 202.863 139.117 208.671C160.045 214.479 182.423 211.736 201.328 201.045C220.233 190.353 234.117 172.59 239.925 151.662C245.734 130.734 242.99 108.356 232.299 89.451L181.967 0.471191L134.818 27.1067Z"
        fill="#69A1F2"
      />
      <path
        d="M140.926 288.05L84.032 261.64C81.9211 260.66 80.0239 259.274 78.4487 257.561C76.8735 255.848 75.6512 253.841 74.8515 251.655C74.0518 249.47 73.6905 247.148 73.7881 244.823C73.8857 242.498 74.4403 240.214 75.4203 238.104C76.4003 235.993 77.7865 234.095 79.4997 232.52C81.2129 230.945 83.2196 229.723 85.4052 228.923C87.5908 228.123 89.9125 227.762 92.2378 227.86C94.563 227.957 96.8463 228.512 98.9572 229.492L186.863 270.305L201.443 238.91L113.537 198.097C107.301 195.202 100.555 193.564 93.6862 193.276C86.817 192.989 79.9583 194.057 73.5018 196.419C60.4622 201.191 49.8524 210.948 44.0064 223.543C38.1604 236.137 37.557 250.538 42.329 263.578C47.101 276.617 56.8575 287.227 69.4521 293.073L97.2556 305.976L82.3366 338.125L14.5799 306.636L0 338.031L96.8349 383.007C103.076 385.948 109.837 387.625 116.728 387.944C123.62 388.262 130.507 387.216 136.992 384.864C143.478 382.511 149.435 378.901 154.52 374.238C159.606 369.576 163.72 363.955 166.625 357.698C169.53 351.44 171.17 344.67 171.45 337.777C171.731 330.884 170.645 324.003 168.257 317.53C165.869 311.058 162.225 305.121 157.534 300.062C152.844 295.003 147.2 290.92 140.926 288.05"
        fill="#AB4A42"
      />
    </svg>
  );
});
