import { arrayToMap } from '@ours/utils';

import type { CSDActions, CSDContext, CSDPeer } from '../types';

export const listenToYJSPeers = (ctx: CSDContext, cb: (args: CSDActions) => void) => {
  const awareness = ctx._private.yjs?.awareness;

  const handleChange = () => {
    if (awareness) {
      const peers = arrayToMap('sessionId', [...awareness.getStates().values()] as CSDPeer[]);
      cb({ peers, type: 'SYNC_PEERS' });
    }
  };

  handleChange();
  const handleUnload = () => {
    awareness?.setLocalState(null);
  };

  window.addEventListener('beforeunload', handleUnload);

  const id = setInterval(() => {
    if (!window.document.hidden) {
      awareness?.setLocalStateField('heartbeat', (awareness?.getLocalState()?.heartbeat || 1) + 1);
    }
  }, 10000);

  awareness?.on('update', handleChange);

  return () => {
    handleUnload();
    window.removeEventListener('beforeunload', handleUnload);
    awareness?.off('update', handleChange);
    clearInterval(id);
  };
};
