import { assign } from 'xstate';

import { determinePrevModule } from '../helpers/determinePrevModule';
import { updateSessionPlaybackByNodeId } from '../helpers/updateSessionPlaybackByNodeId';
import type { CSDActions, CSDContext } from '../types';

export const onPrev = assign<CSDContext, CSDActions>((ctx) => {
  const { nextMacroModuleId, nextModuleId, nextNodeId } = determinePrevModule(ctx);
  return updateSessionPlaybackByNodeId(ctx, {
    autoPlay: false,
    dir: 'prev',
    nextMacroModuleId,
    nextModuleId,
    nextNodeId,
  });
});
