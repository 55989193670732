import { Colors } from '@ours/utils';

export const ModifiedComponents = {
  Badge: {
    baseStyle: {
      borderRadius: '600',
      fontWeight: '400',
      px: '100',
      py: '1px',
      textTransform: 'none',
    },
    sizes: {
      sm: {
        borderRadius: '400',
        fontSize: '10px',
        px: '4px',
        py: '0px',
      },
    },
  },
  Checkbox: {
    sizes: {
      md: {
        control: {
          height: '25px',
          width: '25px',
        },
        label: {
          marginLeft: '100',
          textStyle: { base: 'sm', md: 'md' },
        },
      },
    },
    variants: {
      checkboxGroup: {
        container: {
          _focusVisible: { outline: '2px solid ' + Colors.brand.yellow },
          _hover: { bg: 'brand.yellow' },
          borderColor: 'black',
          borderRadius: '8px',
          borderWidth: { base: '1px', md: '2px' },
          pl: '100',
          py: '100',
        },
        control: {
          _active: {
            borderColor: 'black',
            boxShadow: '2px 2px 0px 0px #532420',
          },
          _checked: {
            _focusVisible: {
              borderColor: 'black',
              borderWidth: '2px',
              boxShadow: '2px 2px 0px 0px #532420',
            },
            _hover: {},
            bg: 'brand.blue',
            borderColor: 'black',
            boxShadow: '4px 4px 0px 0px #532420',
          },
          _focusVisible: { boxShadow: '2px 2px 0px 0px #532420' },
          borderColor: 'black',
          borderRadius: '8px',
          borderWidth: { base: '1px', md: '2px' },
          boxShadow: '2px 2px 0px 0px #532420',
          padding: '3px',
        },
      },
    },
  },
  Divider: {
    baseStyle: {
      borderColor: 'black',
      borderLeftWidth: '2px',
      opacity: 1,
    },
  },
  FormLabel: {
    baseStyle: {
      fontWeight: { base: '600' },
      marginBottom: '50',
      textStyle: { base: 'xs', md: 'xs' },
    },
  },
  // @see https://chakra-ui.com/docs/theming/component-style#styling-multipart-components
  Modal: {
    baseStyle: {
      body: {
        borderColor: 'black',
        borderTopRadius: '700',
        borderWidth: '2px',
      },
      dialog: {
        borderTopRadius: '700',
      },
    },
    parts: ['body', 'content'],
  },
  Progress: {
    baseStyle: {
      filledTrack: { bg: 'black' },
      track: { bg: 'white' },
    },
    parts: ['filledTrack', 'track'],
    variants: {
      beige: {
        filledTrack: { bg: 'black' },
        track: { bg: 'brand.beige' },
      },
      pink: {
        filledTrack: { bg: 'brand.pink' },
        track: { bg: 'brand.beige' },
      },
      yellow: {
        filledTrack: { bg: 'brand.yellow' },
        track: { bg: 'brand.beige' },
      },
    },
  },
  Skeleton: {
    baseStyle: {
      borderBottomLeftRadius: '600',
      borderTopRightRadius: '600',
    },
  },
  Table: {
    baseStyle: {
      table: {
        fontVariantNumeric: 'initial',
      },
    },
  },
};
