import { Box } from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { memo } from 'react';
import type { HeadingComponent } from 'react-markdown/lib/ast-to-react';

export interface Props {
  children?: ReactNode;
}

export const H2: HeadingComponent = memo(({ children }) => {
  return (
    <Box fontWeight="600" pb="100" textStyle="lg">
      {children}
    </Box>
  );
});
