import type { OperationVariables } from '@apollo/client';
import type { DocumentNode } from 'graphql';

import { getApolloClient } from './getApolloClient';

type Args<T, TVariables> = {
  onComplete: (d: T) => void;
  pollInterval: number;
  query: DocumentNode;
  skip: boolean;
  variables?: TVariables;
};

/**
 * this fn is primarily for use in state machines.
 *
 * it should
 *   - immediately call the api
 *   - poll every x ms.
 *   - avoid polling when tab is not focused
 *   - immediately poll re-focus
 */
export const focusAwarePolling = <T, TVariables extends OperationVariables = never>({
  onComplete,
  pollInterval,
  query,
  skip,
  variables,
}: Args<T, TVariables>) => {
  const getData = async () => {
    if (skip) {
      return;
    }
    const client = getApolloClient();
    const { data } = await client.query<T, TVariables>({
      context: { batch: true },
      fetchPolicy: 'no-cache',
      query,
      variables,
    });
    onComplete(data);
  };

  // immediately call regardless
  getData();

  let isFocused = !document.hidden;
  const handleFocus = () => {
    isFocused = true;
    getData();
  };

  const handleBlur = () => {
    isFocused = false;
  };

  const id = setInterval(() => {
    isFocused && getData();
  }, pollInterval);

  window.addEventListener('focus', handleFocus);
  window.addEventListener('blur', handleBlur);

  return () => {
    clearInterval(id);
    window.removeEventListener('focus', handleFocus);
    window.removeEventListener('blur', handleBlur);
  };
};
