import type { InteractionKind, SessionForAppContextFragment } from '@ours/utils';

export const knownMultiplayerSessions: Record<InteractionKind, boolean> = {
  AudioRecording: false,
  Button: false,
  Camera: false,
  Discussion: false,
  Downloadable: false,
  Drawing: false,
  ImageUpload: false,
  InAppPurchase: false,
  Input: false,
  Journal: false,
  JournalSeparate: true,
  MacroModules: false,
  MultipleChoice: false,
  NumberSelect: false,
  OpenEndedText: false,
  Photobooth: false,
  PricingBlock: false,
  QuizMatching: false,
  Scheduler: false,
  SentenceBuilder: false,
  TrueFalseButton: false,
  VideoRecording: false,
};
export const isMultiplayerSession = (session: SessionForAppContextFragment) => {
  const modules = session.resolvedValues?.modules || [];

  return modules.some((m) => knownMultiplayerSessions[m.interactionKind || 'Button']);
};
