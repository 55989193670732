import type { FC } from 'react';
import React, { memo } from 'react';

export const DownCaret: FC = memo(() => {
  return (
    <svg fill="none" height="12" viewBox="0 0 17 12" width="17" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.92152 4.95348L12.2453 1.62968C12.7629 1.11229 13.4648 0.821639 14.1967 0.821639C14.9285 0.821639 15.6304 1.11229 16.148 1.62968C16.6654 2.14728 16.9561 2.84918 16.9561 3.58103C16.9561 4.31288 16.6654 5.01477 16.148 5.53237L12.8242 8.85467L10.6195 11.0593C10.3966 11.2823 10.1318 11.4592 9.84049 11.5799C9.54915 11.7006 9.23688 11.7627 8.92152 11.7627C8.60617 11.7627 8.29391 11.7006 8.00256 11.5799C7.71121 11.4592 7.44649 11.2823 7.2235 11.0593L5.01763 8.85467L0.397232 4.23398L4.29963 0.331579L8.92152 4.95348Z"
        fill="black"
      />
    </svg>
  );
});
