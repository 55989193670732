/**
 * Requires items to be unique.
 */
export const getItemsBetween = <T extends number | string>(items: T[], a: T, b: T): T[] => {
  const aIdx = items.findIndex((i) => i === a);
  const bIdx = items.findIndex((i) => i === b);
  if (aIdx === -1 || bIdx === -1) {
    throw RangeError('A and B need to exist in the array');
  }

  return items.slice(Math.min(aIdx, bIdx), Math.max(aIdx, bIdx) + 1);
};
