export interface IVideoState {
  currentTime: number;
  duration: number;
  ended: boolean;
  hasStarted: boolean;
  paused: boolean;
  seeking: boolean;
  waiting: boolean;
}
export const initialStateOfNewVideo: IVideoState = {
  currentTime: 0,
  duration: 0,
  ended: false,
  hasStarted: false,
  paused: true,
  seeking: false,
  waiting: false,
};

export const videoPlaybackQuality = ['Auto', '1080', '720'] as const;
export type VideoPlaybackQuality = (typeof videoPlaybackQuality)[number];
export const videoPlaybackRate = [0.5, 1, 1.5, 2] as const;
export type VideoPlaybackRate = (typeof videoPlaybackRate)[number];
export interface IVideoSettings {
  displayCC: boolean;
  playbackQuality: VideoPlaybackQuality;
  playbackRate: VideoPlaybackRate;
  volume: number;
}

export interface VideoContext {
  settings: IVideoSettings;
  videosById: Record<string, IVideoState>;
}
