import { Box } from '@chakra-ui/react';
import { isUnexpected } from '@ours/utils';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

export interface UnexpectedErrorBoundaryProps {
  children: ReactNode;
}

export const UnexpectedErrorBoundary: FC<UnexpectedErrorBoundaryProps> = memo(({ children }) => {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => {
        if (isUnexpected(error)) {
          return (
            <Box>
              <Box>{error.message}</Box>
              <Box>{error.name}</Box>
            </Box>
          );
        }

        // eslint-disable-next-line no-console
        console.log('error: ', error.name, error.message);
        // eslint-disable-next-line no-console
        console.log(error);
        return <div>Unknown error</div>;
      }}
    >
      {children}
    </ErrorBoundary>
  );
});
