import type { ModuleForAppContextFragment } from '@ours/utils';
import { assertType } from '@ours/utils';
import { produce } from 'immer';
import type { DeepWritable } from 'ts-essentials';
import { assign } from 'xstate';

import type { AppActions } from '../actionTypes';
import type { AppContext } from '../types';

export const onUpdateModules = assign<AppContext, AppActions>((ctx, ev) => {
  assertType(ev, 'ON_UPDATE_MODULES');

  return produce(ctx, (draft) => {
    ev.modules.forEach((mod) => {
      draft.content.modulesById[mod.id] = {
        ...draft.content.modulesById[mod.id],
        ...(mod as DeepWritable<ModuleForAppContextFragment>),
        id: mod.id,
      };
    });
  });
});
