/* eslint-disable sort-keys-fix/sort-keys-fix */
export const Spacing = {
  40: '4px',
  80: '8px',
  120: '12px',
  160: '16px',
  200: '20px',
  240: '24px',
  280: '28px',
  320: '32px',
  360: '36px',
  400: '40px',
  440: '44px',
  480: '48px',
  520: '52px',
  560: '56px',
  600: '60px',
  640: '64px',
  680: '68px',
  720: '72px',
  760: '76px',
  800: '80px',
  840: '84px',
  880: '88px',
  920: '92px',
  960: '96px',
  1000: '100px',

  // OLD - we do not use the 5px system as of 3-9-23
  50: '5px',
  100: '10px',
  125: '12px',
  150: '15px',
  250: '25px',
  300: '30px',
  350: '35px',
  450: '45px',
  500: '50px',
  550: '55px',
  650: '65px',
  700: '70px',
  750: '75px',
  850: '85px',
  900: '90px',
  950: '95px',
};
