import type { Maybe } from '@ours/types';

import { entries } from '../lang/entries';
import { values } from '../lang/values';

import type { IXStateState } from './xStateConstants';

export const findNodesSources = (nodeId: Maybe<string>, xStateState: IXStateState): string[] => {
  if (!nodeId) {
    return [];
  }

  const leafs = entries(xStateState).filter(([, value]) =>
    values(value.on || {}).find((_) => _ === nodeId)
  );

  return leafs.map(([nodeId]) => nodeId);
};
